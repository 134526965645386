<template>
  <div @keydown.ctrl.72.prevent.stop="leaveEvent">
    <v-row>
      <v-col cols="2" class="px-0 mr-2">
        <Colunas
          :title="`Não Atendidos (${getEvents.length})`"
          :events="getEvents"
          :btn="true"
        />
      </v-col>
      <v-col cols="2" class="px-0 mr-2">
        <Colunas
          :title="`Em Atendimento (${getEventsAttended.length})`"
          :events="getEventsAttended"
        />
      </v-col>
      <v-col class="px-0">
        <Atendimento @startChat="startChat" />
      </v-col>
    </v-row>

    <!-- Botão de mensagens do chat não lidas -->
    <v-menu
      top
      offset-y
      v-if="totalUnreadMsgs"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          color="success"
          bottom
          right
          fixed
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="warning"
            :content="totalUnreadMsgs > 99 ? '99+' : totalUnreadMsgs"
          >
            <v-icon>mdi-cellphone-message</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list dense class="superdense">
        <v-list-item
          v-for="(item, index) in unreadMessages"
          :key="index"
          @click="startChat({deviceId: item.deviceId })"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.deviceName }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="my-1">
            <v-badge
              color="success"
              inline
              :content="item.count"
            ></v-badge>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-menu>
      
    <DialogMessage ref="dialogMessage" />
    <v-snackbar
      v-model="snackbarChat.show"
      color="warning"
      top
    >
      <div class="d-flex align-center justify-space-between">
        <v-icon class="mr-3">mdi-cellphone-message</v-icon>
        <span>Nova mensagem de <strong>{{ snackbarChat.deviceName }}</strong></span>
        <v-btn small outlined class="ml-4" @click="startChat({ deviceId: snackbarChat.deviceId })">Abrir</v-btn>
      </div>
    </v-snackbar>

    <v-tooltip v-if="getAudioEvent" left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="amber darken-1"
					dark
					absolute
					bottom
					right
					fab
					fixed
					class="mb-10"
					v-bind="attrs"
					v-on="on"
          @click="stopAudio"
				>
					<v-icon color="black">mdi-volume-off</v-icon>
				</v-btn>
			</template>
			<span>Parar Áudio</span>
		</v-tooltip>
  </div>
</template>

<script>
import Colunas from "../components/Monitoramento/ColunasEventos";
import Atendimento from "../components/Monitoramento/Atendimento";
import DialogMessage from "../components/Dispositivos/DialogMessage.vue";
import { mapGetters } from "vuex";
export default {
  name: "Monitoramento",
  data: () => {
    return {
      event: {},
      socket: null,
      isDeviceOnline: false,
      snackbarChat: {
        name: "",
        show: false,
        deviceId: null,
      },
      unreadMessages: [],
      totalUnreadMsgs: 0,
    };
  },
  components: {
    Colunas,
    Atendimento,
    DialogMessage,
  },
  computed: {
    ...mapGetters(["getEvents", "getEventsAttended", "getAudioEvent"]),
  },
  methods: {
    leaveEvent() {
      this.$store.dispatch("setSelectedEvent", {});
    },
    newEvent(data) {
      //this.blinkIcon();
      this.$store.dispatch("appendEvent", data);
      //this.play();
    },
    eventAssumed(data) {
      this.$store.dispatch("updateAssumedEvent", data);
    },
    eventTreated(data) {
      this.$store.dispatch("removeEvent", data);
    },
    startChat(options){
      this.$refs.dialogMessage.open({ deviceId: options.deviceId });
      // remove o dispositivo da lista de não lidos
      this.unreadMessages.splice(this.unreadMessages.findIndex(item => item.deviceId == options.deviceId), 1);
    },
    chatMessageReceived(data) {
      if (data.userId != this.$store.getters.getUsuario.id){
        this.snackbarChat.deviceName = data.deviceName;
        this.snackbarChat.deviceId = data.deviceId;
        this.snackbarChat.show = true;
        this.refreshUnreadMessages();
      }
    },
    refreshUnreadMessages(){
      this.$http.get("chat/newsAdm").then((resp) => {
        this.unreadMessages = resp.data;
      });
    },
    stopAudio() {
      this.$store.dispatch("playAlert", { stop: true });
    },
  },
  created() {
    this.$store.dispatch("loadEvents");
    this.$store.dispatch("loadClientes");
    this.$store.dispatch("loadShortcuts");
    this.$store.dispatch("setSelectedEvent", {});

    this.socket = this.$store.getters.getSocket;

    this.socket.on("newEvent", this.newEvent);
    this.socket.on("eventAssumed", this.eventAssumed);
    this.socket.on("eventTreated", this.eventTreated);
    this.socket.on("message", this.chatMessageReceived);

    this.refreshUnreadMessages();
  },
  beforeRouteLeave(to, from, next) {
    this.socket.off("newEvent", this.newEvent);
    this.socket.off("eventAssumed", this.eventAssumed);
    this.socket.off("eventTreated", this.eventTreated);
    next();
  },
  watch: {
    getEvents(newVal, oldVal) {
      // rola a coluna de eventos não atendidos quando um novo evento de pânico é adicionado
      if (newVal.filter(e => e.type == 'panico').length > oldVal.filter(e => e.type == 'panico').length){
        document.getElementsByClassName('colToScroll')[0].scroll({
          top: 0,
          behavior: "smooth"
        });
      }
    },
    unreadMessages(){
      this.totalUnreadMsgs = this.unreadMessages.reduce((acc, item) => acc + item.count, 0);
    }
  },
};
</script>
<style>
</style>