<template>
  <div>
    <v-card-title class="pb-0">Últimos eventos no cliente</v-card-title>
    <v-data-table
      :headers="headers"
      :items="history"
      :loading="load"
      disable-sort
      :no-data-text="load ? '' : 'Nenhum histórico disponível'"
      hide-default-footer
    >
      <template slot="item.finishedAt" slot-scope="{ item }">
        {{ new Date(item.finishedAt).toLocaleString() }}
      </template>
    </v-data-table>
    <p class="text-caption">OBS: Limitado aos últimos 15 registros.</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Historico",
  props: {
    clientId: { type: Number },
  },
  data: () => ({
    history: [],
    load:false,
    headers: [
      { text: "Data Finalização", value: "finishedAt" },
      { text: "Evento", value: "eventName" },
      { text: "Tratamento", value: "treatment" },
      { text: "Usuário", value: "finishedUserName" }
    ]
  }),
  methods: {
    get() {
      this.load = true
      this.$http(`event/history?clientId=${this.clientId}`).then((resp) => {
        this.history = resp.data;
        this.load = false;
      });
    },
  },
  computed: {
    ...mapGetters(["getUsers"]),
  },
  watch: {
    clientId(val) {
      if (val)
        this.get();
      else
        this.history = [];
    }
  },
  created(){
    if(this.clientId){
      this.get();
    }
  }
};
</script>

<style>
</style>