<template>
  <div class="recursos">
    <div class="form">
      <v-card class="mx-auto py-2" elevation="5" outlined>
        <v-card-title>
          Cadastro de Menus
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="get(false)"> Atualizar </v-btn>
        </v-card-title>
        <v-form>
          <v-card-text>
            <v-row>
              <v-col cols="1">
                <v-checkbox v-model="menu.enabled" label="Ativo"></v-checkbox>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="menu.order"
                  label="Ordem no Menu"
                  hint="Ordem que será exibido"
                  required
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-card outlined height="56">
                  <v-card-text class="d-flex justify-center align-center pa-0" style="height:100%">
                    <v-icon
                      :color="menu.color"
                      :x-large="menu.size == 'x-large'"
                      :large="menu.size == 'large'"
                      :medium="menu.size == 'medium'"
                      :dense="menu.size == 'dense'"
                      :small="menu.size == 'small'"
                      :x-small="menu.size == 'x-small'"
                    >
                      {{ menu.icon }}</v-icon
                    >
                    {{ menu.name }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="menu.name"
                  :counter="50"
                  label="Texto do Menu"
                  hint="Digite como sera exibido o nome desde Menu"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="7">
                <v-card outlined>
                  <v-row>
                    <v-col cols="6">
                      <SelectIcon v-model="menu.icon" />
                    </v-col>
                    <v-col cols="3">
                      <SelectColor v-model="menu.color" />
                    </v-col>
                    <v-col cols="3">
                      <SelectSize v-model="menu.size" />
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :items="routes"
                  v-model="menu.to"
                  outlined
                  @change="showMeta"
                  label="Selecione a Rota"
                  hint="Selecione a rota que o menu envia"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="menu.description"
                  rows="1"
                  label="Descrição"
                  hint="Digite uma descrição se necessario"
                  :counter="255"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="menu.nvAcessoId"
                  :items="niveis"
                  label="Nivel de Acesso"
                  outlined
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-show="meta.configuration">
                <Configuration
                  :configuration="meta.configuration"
                  @newConfig="newConfig"
                  :parametros="menu.parameters"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="json"
                  rows="1"
                  hint="Json que sera enviado para o from nessa rota deste nivel"
                  persistent-hint
                  counter
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="warning" class="mr-2" @click="clear">
              Cancelar
            </v-btn>
            <v-btn depressed color="success" @click="save"> Salvar </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <v-card class="my-1">
        <v-card-title>
          Filtro
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="showFilter = !showFilter">
            {{ showFilter ? "Esconder" : "Mostrar" }} Filtro
          </v-btn></v-card-title
        >
        <v-card-text v-show="showFilter">
          {{ filtro }}
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="filtro.nvAcessoId"
                label="Nivel de Acesso"
                hint="Filtra pelo Nivel de Acesso"
                :items="niveis"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filtro.to"
                :items="routes"
                label="Rota"
                hint="Filtrar pela Rota"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-show="showFilter">
          <v-spacer></v-spacer>
          <v-btn depressed color="warning" @click="filtro = {}"> Limpar </v-btn>
          <v-btn depressed color="primary" @click="get(true)"> Filtrar </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="my-1">
        <v-card-title>
          Copiar Nivel
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="showCopy = !showCopy">
            {{ showCopy ? "Esconder" : "Mostrar" }} Copiar
          </v-btn>
        </v-card-title>
        <v-card-text v-show="showCopy">
          {{ copy }}
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="copy.copy"
                label="Selecione o Nivel"
                hint="Nivel que será o Modelo"
                :items="niveis"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="copy.paste"
                :items="niveis"
                label="Selecione o Nivel"
                hint="Nivel que recebera os dados"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-show="showCopy">
          <v-spacer></v-spacer>
          <v-checkbox
          class="mx-1"
            label="Limpar antes de Copiar"
            v-model="copy.clearBefore"
          ></v-checkbox>
          <v-btn depressed color="warning" @click="copyData()"> Copiar </v-btn>
        </v-card-actions>
      </v-card>
      <v-card>
        <v-card-text>
          <Table
          :headers="header"
          :items="menus"
          @edit="edit($event)"
          :rows="15"
          @delete="delet($event.id)"
          />
        </v-card-text>
      </v-card>

    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
// import routerVUE from '../../router/index'
import Table from "../TableGeneric";
import SelectIcon from "../Template/SelectIcon";
import SelectColor from "../Template/SelectColor";
import SelectSize from "../Template/SelectSize";
import Configuration from "./ConfigurationMenu";
export default {
  name: "Menus",
  components: {
    Table,
    SelectIcon,
    SelectColor,
    SelectSize,
    Configuration,
  },
  data: () => {
    return {
      routes: [],
      text: "",
      snackbar: false,
      menu: {
        id: null,
        enabled: true,
        icon: "",
        size: "",
        color: "",
        order: 1,
      },
      niveis: [],
      menus: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Ordem", value: "order" },
        { text: "Texto", value: "name" },
        { text: "Icone", value: "icon" },
        { text: "Cor", value: "color" },
        { text: "Tamanho", value: "size" },
        { text: "Rota", value: "to" },
        { text: "Descrição", value: "description" },
        { text: "Nivel", value: "nivel" },
        // { text: "Nivel", value: "nvAcessoId" },
        { text: "Ações", value: "actions", width: "85px" },
      ],
      validJson: false,
      json: "",
      meta: "",
      filtro: {},
      showFilter: false,
      copy: { clearBefore: true },
      showCopy: false,
    };
  },
  methods: {
    getAcesso() {
      this.$http("nvacesso").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${element.description})`;
          element.value = element.id;
        });
        this.niveis = resp.data;
        this.get();
      });
    },
    get(filtro) {
      let url = "";
      if (this.filtro.nvAcessoId && this.filtro.to && filtro) {
        url = `nvmenu?nivel=${this.filtro.nvAcessoId}&to=${this.filtro.to}`;
      } else if ((this.filtro.nvAcessoId || this.filtro.to) && filtro) {
        url = `nvmenu?${
          this.filtro.nvAcessoId ? "nivel=" + this.filtro.nvAcessoId : ""
        }${this.filtro.to ? "to=" + this.filtro.to : ""}`;
      } else {
        url = "nvmenu";
      }

      this.$http(url).then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${element.description})`;
          element.value = element.id;
          element.parameters = JSON.parse(element.parameters);
          element.nivel = this.showNivelName(element.nvAcessoId);
        });
        this.menus = resp.data;
      });
    },
    save() {
      const metodo = this.menu.id ? "put" : "post";
      const finalUrl = this.menu.id ? `/${this.menu.id}` : "";
      this.$http[metodo](`/nvmenu${finalUrl}`, this.menu).then((resp) => {
        if(resp.status == 200){
          this.get();
          this.clear();
        (this.snackbar = true), (this.text = "Salvo com Sucesso");
        }
      });
    },
    copyData() {
      this.$http.post(`/nvmenu/copy`, this.copy).then(() => {
        (this.snackbar = true), (this.text = "Copiado com Sucesso");
      });
    },
    edit(rec) {
      this.menu = rec;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
      this.meta = "";
      this.showMeta(rec.to);
      this.json = JSON.stringify(rec.parameters);
    },
    delet(id) {
      this.$http.delete(`/nvmenu/${id}`).then(() => {
        this.get();
        (this.snackbar = true), (this.text = "Deletado com Sucesso");
      });
    },
    clear() {
      this.menu = {
        id: null,
        enabled: true,
        order: 1,
      };
      this.json = "";
    },
    icon(icon) {
      this.menu.icon = icon.icon;
      this.menu.size = icon.size;
      this.menu.color = icon.color;
    },
    newConfig(e) {
      this.menu.parameters = e;
      this.json = JSON.stringify(e);
      this.save();
    },
    showMeta(item) {
      let idx = this.routes.findIndex((e) => {
        return e.value == item;
      });
      this.meta = this.routes[idx].meta;
      this.json = "";
      console.log(this.meta);
    },
    showNivelName(id) {
      let nv = this.niveis.find((e) => e.id == id);
      if(nv){
      return `${nv.id} - ${nv.name}`
      }else{
        return id
      }
    },
  },

  mounted() {
    // this.get();
    this.getAcesso();
    //pegando as rotas disponiveis na aplicação
    let routes = this.$router.options.routes;
    routes.forEach((ele) => {
      this.routes.push({
        text: `${ele.name} - (${ele.path})`,
        value: ele.path,
        meta: ele.meta,
      });
    });
  },
};
</script>

<style>
</style>