import axios from 'axios'
import { queryStringBuilder } from '../../Utils/queryStringBuilder'
export default {
  state: {
    documentos: [],
    documentosReport: [],
  },
  mutations: {
    setDocumentos(state, payload) {
      state.documentos = payload
    },
    setDocumentosReport(state, payload) {
      state.documentosReport = payload
    },
  },
  actions: {
    setDocumentos(context, payload) {
      context.commit('setDocumentos', payload)
    },
    setDocumentosReport(context, payload) {
      context.commit('setDocumentosReport', payload)
    },
    loadDocumentos(context, payload) {
      let url = payload ? `/document/client/${payload}` : '/document';
      axios(url).then((resp) => {
        context.dispatch('setDocumentos', resp.data)
      });
    },
    loadReportDocumentos(context, payload) {
      let url = `/document/run/report?`;
      // gerador de query para relatorio
      url = queryStringBuilder(url, payload);
      axios(url).then((resp) => {
        context.dispatch('setDocumentosReport', resp.data);
        if (resp.data.length == 0) {
          context.dispatch("resetSnack");
          context.dispatch("showErrorSnack", "Nenhum registro encontrado de acordo com o filtro");
        }
      });
    },
    saveDocumento(context, payload) {
        let formData = new FormData();
        for (const key in payload) {
            if (Object.hasOwnProperty.call(payload, key)) {
                const element = payload[key];
                if (element != null && element != undefined)
                  formData.append(key, element);
            }
        }
        const metodo = payload.id ? "put" : "post";
        const finalUrl = payload.id ? `/${payload.id}` : "";
        axios[metodo](`/document${finalUrl}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((resp) => {
            if (resp.status < 299) {
                if (typeof payload.callback == 'function') {
                    payload.callback();
                }
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Documento salvo com Sucesso");
            }
        });
    },
    deleteDocumento(context, payload) {
      axios.delete(`/document/${payload.id}`).then(() => {
        context.dispatch("resetSnack");
        context.dispatch("showSuccessSnack", "Documento deletado com Sucesso");
        context.dispatch("loadDocumentos", payload.groupId);
      });
    },
  },
  getters: {
    getDocumentos(state) {
      return state.documentos
    },
    getDocumentosReport(state) {
      return state.documentosReport
    },
    getDocumentoNameById: (state) => (id) => {
      if (!state.documentos) {
        return id
      }
      let gp = state.documentos.find(g =>
        g.id == id
      )
      return `${gp.id} - ${gp.name}`
    },
  },
}