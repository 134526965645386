import axios from 'axios'
export default {
    state: {
        drivers: [],
        driver: {},
        driverFilter: '',
        clientId: 0
    },
    mutations: {
        setDrivers(state, payload) {
            state.drivers = payload
        },
        setDriver(state, payload) {
            state.drivers = payload
        },
        setClientId(state, payload) {
            state.clientId = payload
        },
        setFilterDriver(state, payload) {
            state.driverFilter = payload
        },
    },
    actions: {
        setDrivers(context, payload) {
            context.commit('setDrivers', payload)
        },
        setDriver(context, payload) {
            context.commit('setDriver', payload)
        },
        loadDrivers(context, payload) {
            context.commit('setClientId', payload)
            let aux = payload ? `?clientId=${payload}` : ''
            axios(`motorista${aux}`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`,
                        element.value = element.id
                });
                context.commit('setDrivers', resp.data)
            });
        },
        saveDriver(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";

            if (typeof payload.picture === 'object') {
                let formData = new FormData();
                for (let valor in payload) {
                    formData.append(valor, payload[valor])
                }
                axios[metodo](`motorista${finalUrl}?clientId=${context.state.clientId}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((resp) => {
                    if (resp.status < 299) {
                        context.dispatch("resetSnack");
                        context.dispatch("showSuccessSnack", "Motorista salvo com Sucesso");
                        context.dispatch('loadDrivers', context.state.clientId)
                    }
                });
            } else {
                axios[metodo](`motorista${finalUrl}?clientId=${context.state.clientId}`, payload).then((resp) => {
                    if (resp.status < 299) {
                        context.dispatch("resetSnack");
                        context.dispatch("showSuccessSnack", "Motorista salvo com Sucesso");
                        context.dispatch('loadDrivers', context.state.clientId)
                    }
                });
            }
        },
        saveDriver2(context, payload) {
            delete payload.picture;
            let formData = new FormData();
            for (const key in payload) {
                if (Object.hasOwnProperty.call(payload, key)) {
                    const element = payload[key];
                    formData.append(key, element);
                }
            }
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/motorista2${finalUrl}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((resp) => {
                if (resp.status < 299) {
                    if (typeof payload.callback == 'function') {
                        payload.callback();
                    }
                    context.dispatch('loadDrivers')
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Motorista salvo com Sucesso");
                }
            });
        },
        deletDriver(context, payload) {
            axios.delete(`motorista/${payload.id}?clientId=${context.state.clientId}`).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Motorista Deletado com Sucesso");
                    context.dispatch('loadDrivers', context.state.clientId)
                }
            });
        },
    },
    getters: {
        getDrivers(state) {
            return state.drivers
        },
        getDriver(state) {
            return state.driver
        },
        getClientId(state) {
            return state.clientId
        },
        getDriversSelect(state) {
            return state.drivers.map((v) => {
                return {
                    value: v.id,
                    text: `${v.id} - ${v.name}`,
                    picture: v.picture,
                };
            });
        },
        getDriverHeaders() {
            return [
                { text: "ID", value: "id" },
                {
                    text: "Imagem",
                    align: "center",
                    filterable: false,
                    value: "picture",
                    sortable: false 
                },
                { text: "Nome", value: "name" },
                { text: "Descrição", value: "description", sortable: false  },
                { text: "Ações", value: "actions", sortable: false, width: "84px" },
            ]
        },
        getDriversFilter(state) {
            const filterModelStr = (str = "", search = "") => {
                if (typeof str == 'string'){
                    str = str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    return str.includes(search);
                }
                else return true;
            }
            let cf = state.drivers;
            // filtro por texto
            cf = cf.filter((driver) => {
                return filterModelStr(driver.name, state.driverFilter);
            });
            return cf
        },
    },
}