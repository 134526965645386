<template>
  <v-dialog
      v-model="dialog"
      width="700"
      scrollable
    >
    <v-card>
      <v-card-title class="text-h5 pb-4">Detalhes da Frota</v-card-title>
      <v-card-text class="pb-0">
        <v-card-title class="intermed-title mt--2">Dados iniciais</v-card-title>
        <v-card outlined class="mb-3">
          <v-card-text>
            <div class="mb-5">
              <!-- Carousel customizado para múltiplas colunas-->
              <v-carousel
                v-if="item.pictures && item.pictures.length"
                v-model="carousel1Position"
                height="200"
                hide-delimiters
                class="mb-5"
              >
                <template v-for="(img, index) in item.pictures">
                  <v-carousel-item v-if="(index + 1) % carouselColumns === 1 || carouselColumns === 1" :key="index"> 
                    <v-row class="flex-nowrap" style="height:100%; margin:-4px"> 
                      <template v-for="(col, i) in carouselColumns"> 
                        <template v-if="(+index + i) < item.pictures.length"> 
                          <v-col :key="i" :cols="12 / carouselColumns" class="pa-1">
                            <v-img :src="item.pictures[+index + i]" width="100%" height="100%" class="d-block rounded"></v-img>
                          </v-col> 
                        </template> 
                      </template> 
                    </v-row> 
                  </v-carousel-item> 
                </template> 
              </v-carousel>
              <div v-else class="text-center"><i>Sem imagens iniciais</i></div>
            </div>
            <div class="d-flex justify-space-between">
              <div>
                <span class="mr-8"><strong>Início:</strong> {{ formataData(item.createdAt) }}</span>
                <span><strong>Km Inicial:</strong> {{ item.kmStart }}</span><br />
                <span><strong>Descrição inicial:</strong> {{ item.description }}</span>
              </div>
              <v-btn
                v-if="actions"
                color="orange lighten-1 text-white"
                @click="$emit('out', item)"
                class="mt-1"
                dark
              ><v-icon>mdi-exit-to-app</v-icon> Registrar saída</v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-card outlined class="mb-3 pb-2">
          <v-card-title class="py-2">Motoristas Iniciais</v-card-title>
          <v-list
            class="py-0"
            v-for="driver in item.drivers"
            :key="driver.id"
          >
            <ItemMotorista
              :item="driver"
            />
          </v-list>
        </v-card>
        
        <!-- card dos veiculos selecionados -->
        <v-card outlined class="pb-1">
          <v-card-title class="py-2">Veículos Iniciais</v-card-title>
          <v-list
            class="pt-0 pb-1"
            v-for="vehicle in item.fleetVehicles"
            :key="vehicle.id"
          >
            <ItemVeiculo
              :item="vehicle"
            />
          </v-list>
        </v-card>

        <template v-if="item.finished">
          <v-card-title class="intermed-title mt-5">Dados finais</v-card-title>
          <v-card outlined class="mb-3">
            <!--v-card-title class="pt-2">Detalhes</v-card-title-->
            <v-card-text>
              <div class="mb-5">
                <!-- Carousel customizado para múltiplas colunas-->
                <v-carousel
                  v-if="item.finalPictures && item.finalPictures.length"
                  v-model="carousel2Position"
                  height="200"
                  hide-delimiters
                  class="mb-5"
                >
                  <template v-for="(img, index) in item.finalPictures">
                    <v-carousel-item v-if="(index + 1) % carouselColumns === 1 || carouselColumns === 1" :key="index"> 
                      <v-row class="flex-nowrap" style="height:100%; margin:-4px"> 
                        <template v-for="(col, i) in carouselColumns"> 
                          <template v-if="(+index + i) < item.finalPictures.length"> 
                            <v-col :key="i" :cols="12 / carouselColumns" class="pa-1">
                              <v-img :src="item.finalPictures[+index + i]" width="100%" height="100%" class="d-block rounded"></v-img>
                            </v-col> 
                          </template> 
                        </template> 
                      </v-row> 
                    </v-carousel-item> 
                  </template> 
                </v-carousel>
                <div v-else class="text-center"><i>Sem imagens finais</i></div>
              </div>
              <div>
                <span class="mr-8"><strong>Chegada:</strong> {{ formataData(item.updatedAt) }}</span>
                <span><strong>Tempo total:</strong> {{ getIntervalo(item.createdAt, item.updatedAt) }}</span><br />
                <span class="mr-8"><strong>Km Final:</strong> {{ item.kmEnd }}</span>
                <span><strong>Km Percorrido:</strong> {{ Math.abs(item.kmEnd - item.kmStart) }}</span><br />
                <span><strong>Descrição final:</strong> {{ item.descriptionFinish }}</span>
              </div>
            </v-card-text>
          </v-card>

          <v-card outlined class="mb-3 pb-2">
            <v-card-title class="py-2">Motoristas Finais</v-card-title>
            <v-list
              class="py-0"
              v-for="driver in item.finalDrivers"
              :key="driver.id"
            >
              <ItemMotorista
                :item="driver"
              />
            </v-list>
          </v-card>
          
          <!-- card dos veiculos selecionados -->
          <v-card outlined class="pb-1">
            <v-card-title class="py-2">Veículos Finais</v-card-title>
            <v-list
              class="pt-0 pb-1"
              v-for="vehicle in item.finalFleetVehicles"
              :key="vehicle.id"
            >
              <ItemVeiculo
                :item="vehicle"
              />
            </v-list>
          </v-card>
        </template>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          @click="dialog = false"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ItemVeiculo from "@/components/Veiculo/item_veiculo.vue";
import ItemMotorista from "@/components/Motoristas/item_motorista.vue";
import { differenceDate } from "@/Utils/DateDiff";

export default {
  name: "FleetMultiDetails",
  components: {
    ItemVeiculo,
    ItemMotorista,
  },
  props: {
    actions: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      item: {},
      carouselColumns: 3,
      carousel1Position: 0,
      carousel2Position: 0,
      dialog: false,
    };
  },
  methods: {
    open(item) {
      this.item = item;
      this.dialog = true;
    },
    close() { // chamado externamente
      this.dialog = false;
    },
    formataData(dataUTC) {
      return new Date(dataUTC).toLocaleString();
    },
    getIntervalo(inicio, fim) {
      let dif = differenceDate(inicio, fim);
      return `${dif.day}d, ${dif.hour}h, ${dif.minute}m`;
    },
  },
  watch: {
    dialog(value) {
      if (!value)
        this.carousel1Position = 0; // reseta a posição do carousel
        this.carousel2Position = 0;
    },
  },
};
</script>