<template>
  <div class="pendentes">
    <div class="form">
      <v-card class="mx-auto mb-1" elevation="5" outlined>
        <v-card-title> Log do Sistema </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <DateTime @date="dataInicial = $event" title="Data Inicial" />
              <Time
                @timePic="horaInicial = $event"
                class="ml-1"
                title="Hora Inicial"
                timeStart="00:00"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6">
              <DateTime @date="dataFinal = $event" title="Data Final" />
              <Time
                @timePic="horaFinal = $event"
                class="ml-1"
                title="Hora Final"
                timeStart="23:59"
              />
            </v-col>
          </v-row>
          <v-card>
            <v-card-title>
              Filtros
            </v-card-title>
            <v-row class="ma-2">
              <v-col>
                <v-select
                  v-model="metodo"
                  :items="methods"
                  label="Metodo"
                  hint="Selecione um Metodo"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="usuario"
                  :items="usuarios"
                  label="Usuário"
                  hint="Selecione um Usuário"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="grupo"
                  :items="grupos"
                  label="Grupos"
                  hint="Selecione um Grupo"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
        <v-card-actions>

            <v-spacer></v-spacer>
          
            <v-btn color="success" @click="get">Filtrar</v-btn>
        </v-card-actions>
          
        
      </v-card>
      <v-card>
        <v-card-text>
          <Table :headers="header" :items="logs" :rows="15" />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import Table from "../TableGeneric";
import DateTime from "../Template/DatePicker";
import Time from "../Template/TimePicker2";
export default {
  name: "LogSistema",
  components: {
    Table,
    DateTime,
    Time,
  },
  data: () => {
    return {
      dataInicial: "",
      horaInicial: "",
      dataFinal: "",
      horaFinal: "",
      log: {},
      logs: [],
      methods: ["get", "post", "put", "delete"],
      metodo: "",
      usuarios: [],
      usuario: null,
      grupos: [],
      grupo: null,
      header: [
        { text: "ID", value: "id" },
        { text: "Recurso", value: "resource" },
        { text: "Metodo", value: "method" },
        { text: "Path", value: "path" },
        { text: "Dados Enviados", value: "body" },
        { text: "Parametros", value: "params" },
        { text: "Query", value: "query" },
        { text: "Grupo", value: "groupId" },
        { text: "Grupo Nome", value: "groupName" },
        { text: "Usuário", value: "userId" },
        { text: "Usuário Nome", value: "userName" },
        { text: "Momento", value: "createdAt" },
      ],
    };
  },
  methods: {
    get() {
      let now = new Date().toISOString().substr(0, 10);
      let incio = `${now} 00:00:00`;
      let final = `${now} 23:59:59`;
      let metodo = this.metodo ? `&method=${this.metodo}` : "";
      let user = this.usuario ? `&userId=${this.usuario}` : "";
      let group = this.grupo ? `&groupId=${this.grupo}` : "";
      let url = `log?start=${incio}&end=${final}${metodo}${group}${user}`;
      this.$http(encodeURI(url)).then((resp) => {
        resp.data.forEach((element) => {
          element.createdAt = new Date(element.createdAt).toLocaleString();
        });
        this.logs = resp.data;
      });
    },
    getU() {
      this.$http(`usuario`).then((resp) => {
        resp.data.forEach((ele) => {
          ele.text = `${ele.id} - ${ele.name} - (${ele.userName})`;
          ele.value = ele.id;
        });
        this.usuarios = resp.data;
      });
    },
    getG() {
      this.$http(`grupo`).then((resp) => {
        resp.data.forEach((ele) => {
          ele.text = `${ele.id} - ${ele.name}`;
          ele.value = ele.id;
        });
        this.grupos = resp.data;
      });
    },
  },
  mounted() {
    this.get();
    this.getU();
    this.getG();
  },
};
</script>

<style>
</style>