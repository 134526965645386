<template>
  <div class="cliPosto">
    <v-card-title>Cadastros de Veículos de Frota</v-card-title>
    <v-card-text>
      <DataTable
        :items="fleetVeic"
        :headers="getFleetVehicleHeaders"
      >

			<template slot="item.picture" slot-scope="{ item }">
        <v-avatar size="70" class="my-2">
          <v-img
            :src="item.picture"
            lazy-src="/img/nopic.jpg"
          ></v-img>
        </v-avatar>
      </template>

      <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="editar(item)"
                color="warning"
                class="mr-1"
              >mdi-pencil</v-icon>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
          <!-- botão excluir -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="excluir(item)"
                color="red"
              >mdi-trash-can</v-icon>
            </template>
            <span>Excluir : {{ item.name }}</span>
          </v-tooltip>
        </template>

      </DataTable>
		</v-card-text>

		<!-- dialog de cadastro -->
		<v-dialog v-model="dialog" persistent max-width="700" scrollable>
			<v-card>
				<v-card-title class="text-h5 pr-4 pb-4">{{ fleet.id ? "Editar" : "Cadastrar" }} Veículo de Frota<v-spacer></v-spacer>
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pb-0">
					<v-form ref="formVeiFrota" class="my-1">
						<div class="text-center">
							<v-avatar size="250" class="mb-7">
								<v-img
									:src="tempPicture"
									lazy-src="/img/nopic.jpg"
								></v-img>
							</v-avatar>
							<Webcam
								@base64="tempPicture = $event"
								@file="fleet.picFile = $event"
							/>
						</div>

						<v-text-field
							class="mt-2"
							v-model="fleet.plate"
							label="Placa"
							v-mask="plateMask"
							@input="plateToUppercase"
							:rules="requiredField"
							required
							outlined
						></v-text-field>
						<v-text-field
							v-model="fleet.prefix"
							label="Prefixo"
							required
							outlined
						></v-text-field>
						<v-text-field
							v-model="fleet.model"
							label="Modelo"
							:rules="requiredField"
							required
							outlined
						></v-text-field>
						<v-textarea
							v-model="fleet.description"
							:counter="255"
							label="Descrição"
							outlined
						></v-textarea>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4">
					<v-spacer></v-spacer>
					<v-btn text @click="dialog = false">Cancelar</v-btn>
					<v-btn color="success" @click="save">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="amber darken-1" dark absolute bottom right fab fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Veículo</span>
    </v-tooltip>	
    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import DataTable from "../../DataTableGeneric";
import ConfirmDialog from '@/components/ConfirmDialog';
import Webcam from "@/components/Webcam/WbDialog.vue";
import { mapGetters } from "vuex";
export default {
  name: "CliPosto",
  components: { DataTable, Webcam, ConfirmDialog },
  props: ["fleetVeic", "groupId"],
  data() {
    return {
      dialog: false,
      requiredField,
      tempPicture: "",
			plateMask: "",
      fleet: {},
    };
  },
  methods: {
    editar(item) {
      this.fleet = item;
      this.dialog = true;
    },
		excluir(item) {
      this.$refs.confirmDialog.open({
        title: "Excluir Veículo",
        text: `Deseja excluir o registro de <strong>${item.plate}</strong>?`,
        confirmCallback: () => {
          this.$store.dispatch("deletFleetVehicle", item);
        },
      });
    },
    save() {
			if (!this.$refs.formVeiFrota.validate())
        return;
      this.$store.dispatch("saveFleetVehicle", this.fleet);
      this.dialog = false;
    },
		plateToUppercase() {
      if (!this.fleet.id) this.fleet.plate = this.fleet.plate.toUpperCase();
    },
  },
  computed: {
    ...mapGetters(["getFleetVehicleHeaders"]),
  },
	watch: {
    dialog(value) {
      if (value){
        if (this.fleet.id)
          this.tempPicture = this.fleet.picture; // Utiliza a imagem em uma variável temporária para não alterar o estado antes de salvar
        else
          this.plateMask = "AAA-#N##"; // Cria a máscara na abertura do dialog p/ contornar bug do v-mask
        
        this.$nextTick(() => {
          this.$refs.formVeiFrota.resetValidation();
        });        
      } else {
        this.tempPicture = "../img/nopic.jpg";
        this.plateMask = "";
				this.fleet = {};
      }
    }
  },
};
</script>

<style>
</style>