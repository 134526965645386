export default {
    state: {
        lastNotification: null
    },
    mutations: {
        showNotification(state, payload) {
            console.log('teste store');
            if (state.lastNotification != payload) {
                console.log(payload);
                console.log("diferente");
            }
            // Verifica se o browser suporta notificações
            if (!("Notification" in window)) {
                alert("Este browser não suporta notificações de Desktop");
            }

            // Let's check whether notification permissions have already been granted
            else if (Notification.permission === "granted") {
                console.log("permitido");
                // If it's okay let's create a notification
                let n = new Notification(payload.title, { body: payload.body, icon: payload.icon });
                setTimeout(n.close.bind(n), 4000);
            }

            // Otherwise, we need to ask the user for permission
            else if (Notification.permission !== "denied") {
                Notification.requestPermission(function(permission) {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        // new Notification("Hi there!");
                        let n = new Notification(payload.title, { body: payload.body, icon: payload.icon });
                        setTimeout(n.close.bind(n), 4000);
                    }
                });
            }

        }
    },
    actions: {
        showNotification(context, payload) {
            context.commit('showNotification', payload)
        },
    },
    getters: {},
}