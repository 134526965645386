<template>
  <div>
    <v-dialog v-model="value" persistent max-width="300">
      <v-card>
        <v-card-title>
          {{ title }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$store.dispatch('setShowDialog', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-center">
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="progress"
              color="primary"
              v-show="progress"
            >
              {{ progress }} %
            </v-progress-circular>
            <v-progress-circular
              v-show="!progress"
              :size="100"
              :width="15"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div class="d-flex justify-center">
            {{ message }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogLoading",
  props: ["value", "title", "message", "progress"],
};
</script>

<style scoped>
</style>