import axios from 'axios'
export default {
    state: {
        listaApp: [],
        progress: 0,
        configApp: {}
    },
    mutations: {
        setListaApp(state, payload) {
            state.listaApp = payload
        },
        setConfigApp(state, payload) {
            state.configApp = payload
        },
        setProgress(state, payload) {
            state.progress = payload
        },
    },
    actions: {
        setListaApp(context, payload) {
            context.commit('setListaApp', payload)
        },
        setConfigApp(context, payload) {
            context.commit('setConfigApp', payload)
        },
        loadListaApps(context) {
            axios(`/app`).then((resp) => {
                resp.data.forEach(element => {
                    element.enviado = new Date(element.createdAt).toLocaleString();
                });
                context.commit('setListaApp', resp.data)
            });
        },
        saveApp(context, obj) {
            let formData = new FormData();
            formData.append("app", obj.app[0]);
            formData.append("description", obj.description);
            formData.append("version", obj.version);
            var config = {
                method: "post",
                url: "app",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (event) => {
                    context.commit('setProgress', Math.round((event.loaded * 100) / event.total));
                },
                data: formData,
            };
            axios(config).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "App Enviado com Sucesso");
                context.dispatch('loadListaApps')
                context.commit('setProgress', 0);
            });
        },
        deleteApp(context, id) {
            var config = {
                method: "delete",
                url: `app/${id}`,
            };
            axios(config).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "App Deletado com Sucesso");
                context.dispatch('loadListaApps')
            });
        },
        saveConfigApp(context, obj) {
            const metodo = obj.clientId ? "put" : "post";
            const finalUrl = obj.clientId ? `${obj.clientId}` : "";
            axios[metodo](`/app/config/${finalUrl}`, obj).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Configuração salva com Sucesso");
                    context.dispatch("loadConfigApp", obj);
                }
            });
        },
        loadConfigApp(context, payload) {
            axios(`/app/config/${payload.clientId}`).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch('setConfigApp', resp.data)
                }
            });
        },
    },
    getters: {
        getListaApp(state) {
            return state.listaApp
        },
        getConfigApp(state) {
            return state.configApp
        },
        getProgressApp(state) {
            return state.progress
        },
        getHeadersApps() {
            return [
                { text: "Id", value: "id" },
                { text: "Nome do App", value: "fileName" },
                { text: "Versão", value: "version" },
                { text: "Descrição", value: "description" },
                { text: "Download", value: "downloads" },
                { text: "Enviado", value: "enviado" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        }
    },
}