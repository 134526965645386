<template>
  <v-card>
    <v-card-title> {{ editar ? "Editar" : "Cadastrar" }} Usuário do Sistema </v-card-title>
    <v-card-text class="pb-0">
      <v-form ref="formUser" :disabled="!usuario.enabled">
        <v-text-field
          v-model="usuario.name"
          label="Nome"
          :rules="reqFieldMinLength(4)"
          required
          outlined
        ></v-text-field>
        <v-text-field
          v-model="usuario.userName"
          :rules="reqFieldMinLength(4)"
          label="Usuário"
          required
          outlined
        ></v-text-field>

        <v-text-field
          v-model="usuario.password"
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass ? 'text' : 'password'"
          @click:append="showPass = !showPass"
          :rules="reqFieldMinLength(4)"
          label="Senha"
          required
          outlined
        ></v-text-field>

        <SelectNiveis v-model="usuario.type" outlined :disabled="disableType" />

        <v-text-field
          v-model="usuario.description"
          label="Descrição"
          outlined
        ></v-text-field>
      </v-form>
      <v-switch
        v-model="usuario.enabled"
        label="Ativo"
        required
        class="mt-0 pt-0"
      ></v-switch>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="pa-4">
      <v-spacer></v-spacer>
      <v-btn text @click="reset">Cancelar</v-btn>
      <v-btn color="success" @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { reqFieldMinLength } from "../../Utils/InputValidation";
import SelectNiveis from "../Template/SelectNiveisForm";
export default {
  props: ["editar", "groupId", "type", "disableType"],
  components: { SelectNiveis },
  data: () => {
    return {
      showPass: false,
      usuario: {
        id: null,
        groupId: 0,
        type: 3,
        userName: "",
        name: "",
        picture: "",
        description: "",
        enabled: true,
      },
      reqFieldMinLength,
    };
  },
  methods: {
    save() {
      // const metodo = this.usuario.id ? "put" : "post";
      if (this.usuario.id) {
        if (this.usuario.password == "") {
          delete this.usuario.password;
        }
      }
      // const finalUrl = this.usuario.id ? `/${this.usuario.id}` : "";
      // this.$http[metodo](`/usuario${finalUrl}`, this.usuario).then(() => {
      //   this.$store.dispatch("resetSnack");
      //   this.$store.dispatch("showSuccessSnack", "Usuário salvo com Sucesso");
      //   // this.reset();
      //   // this.$router.push({ path: "/usuarios" });
      //   this.$router.go(-1)
      // });
      this.usuario.cb = () => {
        this.$router.go(-1);
      };
      this.$store.dispatch("saveUser", this.usuario);
    },
    reset() {
      if (this.editar || this.groupId || this.type) {
        // this.$router.push({ path: "/usuarios" });
        this.$router.go(-1);
      } else {
        Object.assign(this.$data, this.$options.data());
      }
    },
  },
  mounted() {
    //caso venha o parametro seto ele
    this.editar ? (this.usuario = { ...this.editar }) : this.usuario;
    //caso venha o parametro seto ele
    this.groupId ? (this.usuario.groupId = this.groupId) : this.usuario.groupId;
    //caso venha o parametro seto ele
    this.type ? (this.usuario.type = this.type) : this.usuario.type;
  },
};
</script>

<style>
</style>