<template>
  <div class="cliente">
    <ClienteCard :cliente="cliente" @refresh="refresh" />
    <v-card>
      <v-tabs fixed-tabs background-color="success" show-arrows class="rounded-b-0">
        <v-tab><v-icon class="mr-1">mdi-document-multiple-outline</v-icon>Documentos ({{ getDocumentos.length }})</v-tab>
        <v-tab-item>
          <Documentos :groupId="cliente.groupId" />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-human-greeting</v-icon>Visitantes ({{ getVisitantes.length }})</v-tab>
        <v-tab-item>
          <Visitantes :groupId="cliente.groupId" />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-human-handsup</v-icon> {{cliente.typeCli != 2 ? "Visitados" : "Moradores"}} ({{ getVisitados.length }})</v-tab>
        <v-tab-item>
          <Visitados :groupId="cliente.groupId" :typeCli="cliente.typeCli" />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-account</v-icon>Usuários ({{ usuarios.length }})</v-tab>
        <v-tab-item>
          <CliUser :groupId="cliente.groupId" :clientName="cliente.name" />
        </v-tab-item>
        <v-tab v-show="cliente.typeCli != 2"><v-icon class="mr-1">mdi-card-account-details</v-icon>Motoristas ({{ drivers.length }})</v-tab>
        <v-tab-item>
          <CliDriver :drivers="drivers" :clientId="cliente.id" />
        </v-tab-item>
        <v-tab v-show="cliente.typeCli != 2"><v-icon class="mr-1">mdi-car-multiple</v-icon>Frota ({{ fleetVeic.length }})</v-tab>
        <v-tab-item>
          <CliFleetVeic :fleetVeic="fleetVeic" :clientId="cliente.id" />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-video</v-icon>Camêras ({{ getSnapshots.length }})</v-tab>
        <v-tab-item>
          <CliCameras :enderecos="getSnapshots" :clientId="cliente.id" />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ClienteCard from "./components/ClienteCard";
import Visitantes from "@/components/Visitantes/Visitantes";
import Visitados from "@/components/Visitados/Visitados";
import CliUser from "./components/CliUser";
import CliDriver from "./components/CliDriver";
import CliFleetVeic from "./components/CliFleetVeic";
import Documentos from "../Documentos/Documentos";
import { mapGetters } from "vuex";
import CliCameras from './components/CliCameras.vue';

export default {
  name: "ClienteCadastros",
  components: {
    ClienteCard,
    CliUser,
    Visitados,
    Visitantes,
    CliDriver,
    CliFleetVeic,
    Documentos,
    CliCameras
},
  computed: {
    ...mapGetters(["getDocumentos", "getSnapshots", "getVisitantes", "getVisitados"]),
    cliente: {
      get() {
        return this.$store.getters.getCliente;
      },
    },
    drivers: {
      get() {
        return this.$store.getters.getDrivers;
      },
    },
    fleetVeic: {
      get() {
        return this.$store.getters.getFleetsVehicles;
      },
    },
  },
  data: () => {
    return {
      usuarios: [],
      visitados: [],
      visitantes: [],
    };
  },
  methods: {
    getUser() {
      this.$http(`usuario?groupId=${this.cliente.groupId}`).then((resp) => {
        resp.data.forEach((ele) => {
          ele.ativo = ele.enabled ? "Sim" : "Não";
        });
        this.usuarios = resp.data;
        this.$store.dispatch("setUsers", this.usuarios);
      });
    },
    refresh() {
      this.getUser();
      this.$store.dispatch("loadDrivers", this.cliente.id);
      this.$store.dispatch("loadFleetsVehicles", this.cliente.id);
      this.$store.dispatch("loadDocumentos", this.cliente.groupId);
      // this.$store.dispatch("loadEnderecos", this.cliente.id);
      this.$store.dispatch("loadSnapshots", this.cliente.id);
      this.$store.dispatch("loadVisitantes", this.cliente.groupId);
      this.$store.dispatch("loadVisitados", this.cliente.groupId);
    },
  },
  created() {
    this.refresh();
  },
};
</script>