import axios from 'axios'
export default {
    state: {
        shortcuts: [],
    },
    mutations: {
        setShortcuts(state, payload) {
            state.shortcuts = payload
        },
    },
    actions: {
        setShortcuts(context, payload) {
            context.commit('setShortcuts', payload)
        },
        loadShortcuts(context) {
            axios(`shortcut`).then((resp) => {
                context.commit('setShortcuts', resp.data)
            });
        },
        saveShortcut(context, payload) {
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios.post(`shortcut${finalUrl}`, payload).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch('showSuccessSnack', "Atalho salvo com sucesso");
                    context.dispatch("loadShortcuts");
                }
            });
        },
    },
    getters: {
        getShortcuts(state) {
            return state.shortcuts
        },
    },
}