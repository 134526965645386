<template>
  <v-card class="mb-2">
    <v-card-title class="pt-6">
      <span>{{ cliente.name }}</span>
      <v-spacer></v-spacer>
      <v-btn color="warning" @click="voltar"><v-icon class="mr-1">mdi-swap-horizontal</v-icon>Trocar Cliente</v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4">
          <strong>ID:</strong>
          {{ cliente.id }}
          <br>
          <strong>Tipo:</strong>
          {{ cliente.tipo }}
          <br>
          <strong>Grupo:</strong>
          {{ cliente.groupName }}
        </v-col>
        <v-col cols="4">
          <strong>Endereço:</strong>
          {{ cliente.address }}
        </v-col>
        <v-col cols="4">
          <strong>Telefone:</strong>
          {{ cliente.phone }}
          <br>
          <strong>CNPJ:</strong>
          {{ cliente.cnpj }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex">
          <span><strong>Descrição:</strong> {{ cliente.description }}</span>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="$emit('refresh')" class="mt--2 ml-2">
            <v-icon>mdi-refresh</v-icon>Atualizar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-tabs color="#1f5f36" class="px-4" show-arrows>
      <v-tabs-slider color="#1f5f36"></v-tabs-slider>
      <v-tab to="/configCliente"><v-icon class="mr-1">mdi-walk</v-icon>Presentes</v-tab>
      <v-tab to="/clientes/relatorios"><v-icon class="mr-1">mdi-file-document</v-icon>Relatórios</v-tab>
      <v-tab to="/clientes/cadastros"><v-icon class="mr-1">mdi-file-edit</v-icon>Cadastros</v-tab>
      <v-tab to="/clientes/rondas"><v-icon>mdi-flashlight</v-icon>Rondas</v-tab>
      <v-tab to="/clientes/facilities"><v-icon class="mr-1">mdi-spray-bottle</v-icon>Facilities</v-tab>
      <v-tab to="/clientes/jornadas"><v-icon class="mr-1">mdi-briefcase-clock</v-icon>Jornadas</v-tab>
      <v-tab to="/clientes/app"><v-icon class="mr-1">mdi-android</v-icon>App Config</v-tab>
      <v-tab to="/clientes/avisoeventos"><v-icon class="mr-1">mdi-email-check</v-icon>Aviso de Eventos</v-tab>
    </v-tabs>
  </v-card>
</template>

<script>
export default {
  props: ["cliente"],
  name: "ClienteCard",
  methods: {
    voltar() {
      this.$store.dispatch("setWorkGroup", this.$store.state.groupId);
      this.$router.push("/clientes");
    },
  },
};
</script>
