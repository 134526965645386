<template>
	<div>
		<template v-if="!report">
			<v-card-title class="pt-6">Rondas Virtuais</v-card-title>
			<v-card-subtitle>Clique na tabela para mais detalhes</v-card-subtitle>
		</template>
		<v-card-text>
			<DataTableGeneric
				:headers="headers"
				:items="items"
				@headersChanged="$emit('headersChanged', $event)"
				@rowClick="openModalRondasVirtuais"
			>
				<template v-slot:item.finished="{ item }">
					<span>{{ item.finished ? "Sim" : "Não" }}</span>
				</template>

				<template v-slot:item.horario="{ item }">
					<span>{{
						formataData(item.createdAt).split(", ")[1]
					}}</span>
				</template>

				<template v-slot:item.tipo="{ item }">
					<span>{{
						item.horarioId ? "Execução Agendada" : "Execução Extra"
					}}</span>
				</template>

				<template v-slot:item.createdAt="{ item }">
					<span>{{ getDia(item.createdAt) }}</span>
				</template>
				<template v-slot:item.payload.questions="{ item }">
					<span class="mr-1"
						>{{ item.payload.questions ? item.payload.questions.length : '0' }} Questões Registradas</span
					>
					<v-menu open-on-hover top offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
						</template>
						<v-list dense>
							<v-list-item
								v-for="(item, index) in item.payload.questions"
								:key="index"
							>
								<v-list-item-content>
									<v-list-item-title>{{ item.name }}</v-list-item-title>
									<v-list-item-subtitle>{{
										formataQuestion(item)
									}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>

				<template v-slot:item.userId="{ item }">
					<span>{{ getUserNameById(item.userId).split(" - ")[1] }}</span>
				</template>

				<template v-slot:item.clientId="{ item }">
					<span>{{ getClienteNameById(item.clientId).split(" - ")[1] }}</span>
				</template>

				<template v-slot:item.payload.observacao="{ item }">
					<TextTooltip :text="item.payload.observacao? item.payload.observacao : ''" />
				</template>
			</DataTableGeneric>
		</v-card-text>

		<DialogRondaVirtual ref="dialogRonda" />
		<ConfirmDialog v-if="!report" ref="confirmDialog" />
	</div>
</template>

<script>
import DataTableGeneric from "../DataTableGeneric";
import ConfirmDialog from "../ConfirmDialog";
//import { differenceDate } from "@/Utils/DateDiff";
import DialogRondaVirtual from "./DialogRondaVirtual.vue";
//import PDF from "@/Utils/PDF";
import { mapGetters } from "vuex";
import TextTooltip from '../Template/TextTooltip.vue';

export default {
	name: "TableRondasVirtuais",
	computed: {
		...mapGetters(["getClienteNameById", "getUserNameById"]),
	},
	components: {
		DataTableGeneric,
		ConfirmDialog,
		DialogRondaVirtual,
		TextTooltip
	},
	data: () => {
		return {
			search: "",
			selected: [],
		};
	},
	props: {
		items: {
			type: Array,
		},
		headers: {
			type: Array,
		},
		report: {
			type: Boolean,
			default: false,
		},
		actions: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		formataData(dataUTC) {
			return new Date(dataUTC).toLocaleString();
		},
		getDia(dataUTC) {
			return new Date(dataUTC).toLocaleDateString();
		},
		openModalRondasVirtuais(item) {
			this.$refs.dialogRonda.openRonda(item);
		},
		formataQuestion(question) {
			if (question.value != null && question.value != undefined && question.value != "") {
				if (question.type == "boolean") {
					return question.value ? "Sim" : "Não";
				}
				return question.value;
			}else{
				return "Não respondida";
			}
		},
	},
	created() {
		// this.$store.dispatch(
		// 	"loadClientes",
		// 	this.$store.getters.getCliente.groupId || null
		// );
	},
	watch: {},
};
</script>

<style scoped>
.intermed-title {
	font-size: 1.2rem;
	font-weight: bold;
}
</style>