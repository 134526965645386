import axios from 'axios'
import { queryStringBuilder } from '../../Utils/queryStringBuilder'

export default {
    state: {
        events: [],
        selectedEvent: {},
        loadingEvents: false,
        iconACT: false,
        relEvents: [],
        audioEvent: null,
    },
    mutations: {
        setEvents(state, payload) {
            state.events = payload
        },
        setIconAct(state, payload) {
            state.iconACT = payload
        },
        appendEvent(state, payload) {
            state.events.push(payload)
        },
        setLoadingEvents(state, payload) {
            state.loadingEvents = payload
        },
        setSelectedEvent(state, payload) {
            state.selectedEvent = payload
        },
        setRelEvents(state, payload) {
            state.relEvents = payload
        },
        setAudioEvent(state, payload) {
            state.audioEvent = payload
        },
    },
    actions: {
        setIconAct(context, payload) {
            context.commit('setIconAct', payload)
        },
        setEvents(context, payload) {
            context.commit('setEvents', payload)
        },
        setLoadingEvents(context, payload) {
            context.commit('setLoadingEvents', payload)
        },
        setSelectedEvent(context, payload) {
            context.commit('setSelectedEvent', payload)
        },
        setRelEvents(context, payload) {
            context.commit('setRelEvents', payload)
        },
        setAudioEvent(context, payload) {
            context.commit('setAudioEvent', payload)
        },
        updateAssumedEvent(context, payload) {
            function continuar() {
                let events = [...context.state.events];
                const index = events.findIndex(ele => ele.id == payload.id);
                events[index].finishedUserId = payload.finishedUserId;
                events[index].finishedUserName = payload.finishedUserName;
                context.commit('setEvents', events);

                if (context.getters.getUsuario.id != payload.finishedUserId) {
                    context.dispatch("resetSnack");
                    context.dispatch('showSuccessSnack', `Evento #${payload.id} ${payload.lastAction} por ${payload.finishedUserName}`);
                }
            }
            // busca o elemento do card para piscar
            let card = document.querySelector(`[data-event-id='${payload.id}']`)
            if (payload.lastAction == 'assumido') {
                continuar();
                if (!payload.blinkColor) {
                    card.classList.add('blink-orange');
                    // aguarda a animação de 1500ms do css e remove a classe
                    setTimeout(() => {
                        card.classList.remove('blink-orange');
                    }, 1500);
                }
            } else {
                card.classList.add('hide');
                // aguarda a animação de 300ms (um pouco menos p/ compensar o delay do vue) do css para continuar a execução
                setTimeout(() => {
                    continuar();
                    if (!payload.blinkColor) {
                        // aguarda empiricamente 300ms para renderização da lista de eventos em atendimento
                        setTimeout(() => {
                            // pega novamente o elemento para piscar
                            let card = document.querySelector(`[data-event-id='${payload.id}']`)
                            if (card) {
                                card.classList.add('blink-green');
                                // aguarda a animação de 1500ms do css e remove a classe
                                setTimeout(() => {
                                    card.classList.remove('blink-green');
                                }, 1500);
                            }
                        }, 300);
                    }
                }, 200);
            }

        },
        appendEvent(context, payload) {
            context.commit('appendEvent', payload)
            context.dispatch('playAlert', payload)
        },
        removeEvent(context, payload) {
            let events = [...context.state.events];
            context.commit('setEvents', events.filter(event => event.id != payload.id));

            // limpa o evento selecionado se for o mesmo que foi fechado
            if (context.getters.getSelectedEvent.id == payload.id)
                context.dispatch('setSelectedEvent', {})

            if (context.getters.getUsuario.id != payload.finishedUserId) {
                context.dispatch("resetSnack");
                context.dispatch('showSuccessSnack', `Evento #${payload.id} tratado por ${payload.finishedUserName}`)
            }
        },
        assumeEvent(context, payload) {
            axios.put(`event/assume/${payload.id}`);
            // updateAssumedEvent é chamado pelo websocket
        },
        loadEvents(context) {
            context.dispatch('setLoadingEvents', true)
            axios(`event`).then((resp) => {
                if (resp.data) {
                    context.commit('setEvents', resp.data)
                    context.dispatch('setLoadingEvents', false)
                }
            });
        },
        playAlert(context, payload) {
            let type = null;
            let typeid = null;
            if (context.state.audioEvent != null) {
                context.state.audioEvent.audio.pause();
                context.state.audioEvent.audio.currentTime = 0;
                type = context.state.audioEvent.type;
                typeid = context.state.audioEvent.id;
                if (payload.stop) {
                    context.dispatch('setAudioEvent', null);
                    return;
                }
            }
            if (payload.type == "panico" || type == "panico") {
                context.dispatch('setAudioEvent', 
                { 
                    audio: new Audio(require('@/assets/alertPanic.mp3')), 
                    id: payload.type == 'panico' ? payload.id : typeid, 
                    type: payload.type 
                });
            } else {
                context.dispatch('setAudioEvent', 
                { 
                    audio: new Audio(require('@/assets/alertEvent.mp3')), 
                    id: payload.id, 
                    type: payload.type 
                });
            }
            let audio = context.state.audioEvent.audio;

            audio.loop = true;

            audio.play();
        },
        treatEvent(context, payload) {
            context.dispatch('setLoadingEvents', true)
            axios.put(`event/finish/${payload.id}`, payload).then((resp) => {
                if (resp.status < 300) {
                    //context.dispatch('removeEvent', payload);
                    context.dispatch("resetSnack");
                    context.dispatch('showSuccessSnack', "Evento Fechado com Sucesso")
                    context.dispatch('setSelectedEvent', {})
                }
            });
            context.dispatch('setLoadingEvents', false);
        },
        loadReportEvents(context, payload) {
            let url = `/event/report?`;
            // gerador de query para relatorio
            url = queryStringBuilder(url, payload);
            axios(url).then((resp) => {
                context.commit("setRelEvents", resp.data);
                if (resp.data.length == 0) {
                    context.dispatch("resetSnack");
                    context.dispatch("showErrorSnack", "Nenhum registro encontrado de acordo com o filtro");
                }
            });
        },
    },
    getters: {
        getIconAct(state) {
            return state.iconACT
        },
        getEvents(state) {
            return panicFirst(state.events).filter(ele => ele.finishedUserId == null)
        },
        getEventsAttended(state) {
            return panicFirst(state.events).filter(ele => ele.finishedUserId != null)
        },
        getLoadingEvents(state) {
            return state.loadingEvents
        },
        getSelectedEvent(state) {
            return state.selectedEvent
        },
        getRelEvents(state) {
            return state.relEvents
        },
        getAudioEvent(state) {
            return state.audioEvent
        },
    },
}

function panicFirst(data) {
    // coloca os pânicos no topo da lista
    let panic = data.filter(event => event.type == 'panico');
    let notPanic = data.filter(event => event.type != 'panico');
    return [...panic, ...notPanic];
}