<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="warning" v-bind="attrs" v-on="on" :disabled="disabled" class="px-2">
          <v-icon class="mr-1">{{ dropIcon }}</v-icon> {{ dropName }}
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-for="rep in reportItens" :key="rep.name" @click="genereate(rep)">
          <v-list-item-icon class="mr-1">
            <v-icon class="mr-1">{{ rep.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ rep.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <DialogLoading v-model="$store.getters.getShowDialog" :title="$store.getters.getTitleDialog"
      :message="$store.getters.getMessageDialog" :progress="$store.getters.getValueDialog" />
  </div>
</template>

<script>
import PDF from "@/Utils/PDF";
import Excel from "@/Utils/Excel";
import DialogLoading from "@/components/Template/DialogLoading.vue";
export default {
  name: "MenuExportReport",
  components: {
    DialogLoading,
  },
  props: {
    dropName: {
      type: String,
      default: "Exportar Relatório"
    },
    dropIcon: {
      type: String,
      default: "mdi-file-download-outline"
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
    reportItens: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Relatório",
    },
    filename: {
      type: String,
      default: "relatorio",
    },
  },
  data() {
    return {
      module: "",
    };
  },
  methods: {
    // gera o pdf
    gerarPdf(comImagens = false) {
      this.$store.dispatch("setTitleDialog", "Gerando o Relátorio");
      this.$store.dispatch("setShowDialog", true);
      PDF[this.module](
        this.items,
        this.title,
        this.filename,
        comImagens
      );
    },
    // gera o Excel
    gerarExcel() {
      Excel[this.module](
        this.items,
        this.title,
        this.filename,
      );
    },
    genereate(item) {
      switch (item.type) {
        case 'pdf':
          this.module = item.module;
          this.gerarPdf(false);
          break;
        case 'pdfimg':
          this.module = item.module;
          this.gerarPdf(true);
          break;
        case 'excel':
          this.module = item.module;
          this.gerarExcel();
          break;

        default:
          alert('Tipo de relatório não encontrado\n possui tipos: pdf, pdfimg, excel');
          break;
      }
    }
  },
};
</script>

<style></style>