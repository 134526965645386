import axios from 'axios'
export default {
    state: {
        visitantes: [],
        visitanteFilter: '',
    },
    mutations: {
        setVisitantes(state, payload) {
            state.visitantes = payload
        },
        setFilterVisitante(state, payload) {
            state.visitanteFilter = payload
        },
    },
    actions: {
        loadVisitantes(context, payload) {
            let gp = payload ? `?groupId=${payload}` : ''
            axios(`visitante${gp}`).then((resp) => {
                resp.data.forEach((element) => {
                    element.groupName = context.getters.getGroupNameById(element.groupId)
                });
                context.commit('setVisitantes', resp.data)
            });
        },
        saveVisitante(context, visitante) {
            const metodo = visitante.id ? "put" : "post";
            const finalUrl = visitante.id ? `/${visitante.id}` : "";
            axios[metodo](`/visitante${finalUrl}`, visitante).then(() => {
                context.dispatch('loadVisitantes')
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Visitante salvo com Sucesso");
            });
        },
        saveVisitante2(context, payload) {
            delete payload.picture;
            let formData = new FormData();
            for (const key in payload) {
                if (Object.hasOwnProperty.call(payload, key)) {
                    formData.append(key, payload[key]);
                }
            }
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/visitante2${finalUrl}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((resp) => {
                if (resp.status < 299) {
                    if (typeof payload.callback == 'function') {
                        payload.callback();
                    }
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Visitante salvo com Sucesso");
                }
            });
        },
        deleteVisitante(context, visitante) {
            axios.delete(`/visitante/${visitante.id}`).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch("resetSnack");
                    context.dispatch(
                        "showSuccessSnack",
                        "Visitante deletado com Sucesso!"
                    );
                    context.dispatch('loadVisitantes');
                }
            });
        },
    },
    getters: {
        getVisitantes(state) {
            return state.visitantes
        },
        getVisitantesSelect(state) {
            return state.visitantes.map((v) => {
                return {
                    value: v.id,
                    text: `${v.id} - ${v.name}`,
                    picture: v.picture,
                };
            });
        },
        getVisitante: (state) => (id) => {
            if (!state.visitantes) {
                return id
            }
            let vte = state.visitantes.find(g =>
                g.id == id
            )
            return vte ? `${vte.id} - ${vte.name}` : ''
        },
        getHeadersVisitantes() {
            return [
                { text: "Id", value: "id" },
                {
                    text: "Imagem",
                    align: "center",
                    filterable: false,
                    value: "picture",
                },
                { text: "Nome", value: "name" },
                { text: "Telefone", value: "phone" },
                { text: "Documento", value: "document" },
                { text: "Referencia", value: "reference" },
                { text: "Grupo", value: "groupName" },
                { text: "Observação", value: "obs" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        },
        getVisitantesFilter(state) {
            const filterModelStr = (str = "", search = "") => {
                if (typeof str == 'string'){
                    str = str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    return str.includes(search);
                }
                else return true;
            }
            let cf = state.visitantes;
            // filtro por texto
            cf = cf.filter((visitante) => {
                return filterModelStr(visitante.name, state.visitanteFilter) ||
                       filterModelStr(visitante.document, state.visitanteFilter);
            });
            return cf
        },
    }

}