import axios from 'axios'
export default {
    state: {
        icones: [],
    },
    mutations: {
        setIcones(state, payload) {
            state.icones = payload
        },
    },
    actions: {
        setIcones(context, payload) {
            context.commit('setIcones', payload)
        },
        loadIcones(context) {
            axios(`/icones`).then((resp) => {
                context.commit('setIcones', resp.data)
            });
        },
        updateIcones(context, payload) {
            axios.post(`/icones`, {url: payload}).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Icones atualizados com Sucesso");
                context.dispatch('loadIcones')
            });
        },
    },
    getters: {
        getIcones(state) {
            return state.icones
        },
    },
}