<template>
<div class="dash">

  <v-card>
    <v-card-title>Indicadores do Sistema</v-card-title>
    <v-card-text>
      <v-simple-table id="tabela">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center" >Indicador</th>
                <th class="text-center" >Hoje</th>
                <th class="text-center" >Ultimos 7 Dias</th>
                <th class="text-center" >Ultimos 14 Dias</th>
                <th class="text-center" >Ultimo Mês</th>
                <th class="text-center" >Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in dashBoard" :key="index">
                <td class="text-center">{{ item.title }}</td>
                <td class="text-center">{{ item.today }}</td>
                <td class="text-center">{{ item.lastWeek }}</td>
                <td class="text-center">{{ item.last14Days }}</td>
                <td class="text-center">{{ item.last30Days }}</td>
                <td class="text-center">{{ item.absolute }}</td>                
              </tr>
            </tbody>
          </template>
        </v-simple-table>
    </v-card-text>
  </v-card>
  <v-card class="mt-1">
    <v-card-title>
      Gráfico de Cadastros
    </v-card-title>
    <v-card-text>
  <SparkLine v-model="graphData"/>

    </v-card-text>
  </v-card>
</div>
</template>

<script>
import SparkLine from '../components/Template/SparkLine'
export default {
  name:"DashBoard",
  components:{
    SparkLine
  },
  computed:{
    dashBoard:{
      get(){
        return this.$store.getters.getDashBoard
      },
      set(value){
        this.$store.dispatch('setDashBoard', value)
      }
    },
    graphData:{
      get(){
        return this.$store.getters.graphData
      },
    },
  },
  mounted(){
    this.$store.dispatch('loadDashBoard')
  },
};
</script>

<style>
</style>