<template>
  <v-dialog v-model="dialog" width="900" v-if="deviceId" scrollable>
    <v-card>
      <v-card-title>
        <span>Conversa com {{ deviceName }}</span>
        <v-spacer></v-spacer>
        <v-btn fab text @click="dialog = false" small>
          <v-icon>mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="chat-wrapper" style="height:500px;">
          <header>
            <v-avatar size="40" class="mr-2">
              <img :src="userOnline.picture" lazy-src="/img/nopic.jpg" />
            </v-avatar>
            <div class="contact-info">
              <span class="contact-name">{{ userOnline.name }}</span>
              <span class="status">{{ deviceOnline ? 'online' : 'offline' }}</span>
            </div>
          </header>
          <div id="chat-container" class="scrollable">
            <div id="chat">
              <template v-for="item in messages">
                <div :key="item.id">
                  <div v-if="item.showDate" class="date">
                    <p><span>{{ dateFormat(item.createdAt) }}</span></p>
                  </div>
                  <div v-if="item.showUnread" class="unread">
                    <p><span>Mensagens não lidas</span></p>
                  </div>
                  <div :class="item.fromServer ? 'me' : 'they'">
                    <p :class="item.showLug ? 'lug' : ''">
                      <span>{{ item.message }}</span><span class="end-gap"></span>
                      <sub>
                        <small>{{ getTime(item.createdAt) }}</small>
                        <v-icon
                          v-if="item.fromServer"
                          small
                          :color="item.viewed ? 'blue' : 'gray'"
                          class="ml-1 mb-1"
                        >mdi-check-all</v-icon>
                      </sub>
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <footer>
            <div class="d-flex align-center">
              <v-text-field
                class="mr-3"
                v-model="message"
                dense
                solo
                flat
                hide-details
                label="Mensagem"
                @keypress.enter="sendMessage">
              </v-text-field>
              <v-icon class="btn-send" @click="sendMessage">mdi-send</v-icon>
            </div>
          </footer>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DialogMessage",
  data: () => {
    return {
      dialog: false,
      deviceId: null,
      devices: [],
      socket: null,
      message: "",
      messages: [],
      lastMsgDay: null,
      lastMsgFromServer: null,
      markedAsUnread: false,
    };
  },
  computed: {
    ...mapGetters(["getOnline", "getUsers", "getDispositivos"]),
    deviceOnline() {
      let online = false;
      if (this.deviceId)
        online = this.getOnline.find((item) => item.user.deviceId == this.deviceId);
      return online;
    },
    userOnline() {
      let userOnline = { name: "Sem usuário online", picture: "/img/nopic.jpg" };
      if (this.deviceOnline && this.getUsers){
        let user = this.getUsers.find((item) => item.id == this.deviceOnline.user.id);
        if (user) userOnline = user;
      }
      return userOnline;
    },
    deviceName() {
      let deviceName = "Dispositivo desconhecido";
      let device = this.getDispositivos.find((item) => item.identificador === this.deviceId);
      if (device) deviceName = device.name;
      return deviceName;
    },
  },
  methods: {
    open(options){
      this.deviceId = options.deviceId;
      this.dialog = true;
    },
    sendMessage() {
      this.socket.emit("message", {
        deviceId: this.deviceId,
        message: this.message,
        event: "message",
      });
      this.message = "";
    },
    addAttributes(data){
      // verificando se é um novo dia para mostrar a data
      if (new Date(data.createdAt).toLocaleDateString() !== this.lastMsgDay) {
        data.showDate = true;
        this.lastMsgDay = new Date(data.createdAt).toLocaleDateString();
      }
      // verificando se é um novo lado para mostrar a orelha
      if (this.lastMsgFromServer !== data.fromServer || data.showDate) {
        data.showLug = true;
        this.lastMsgFromServer = data.fromServer;
      }
      // verificando se é mensagem não lida	
      if (data.unread && !data.fromServer && !this.markedAsUnread) {
        data.showUnread = true;
        this.markedAsUnread = true;
      }
    },
    loadHistory() {
      this.$http("chat?deviceId=" + this.deviceId).then((res) => {
        this.messages = res.data.reverse();
        this.messages.forEach((item) => {
          this.addAttributes(item);
        });
        this.markedAsUnread = false;
        this.scrollDown("#chat-container", ".unread");
      });
    },
    recebedor(data) {
      if (this.dialog && data.deviceId === this.deviceId) {

        this.addAttributes(data);

        this.messages.push(data);
        this.scrollDown("#chat-container");
        // informando que vi
        this.socket.emit("viewed", [data.id]);
      }
    },
    viewed(data) {
      if (data.deviceId === this.deviceId) {
        let idx = this.messages.findIndex((item) => item.id == data.messageId);
        if (idx >= 0) {
          this.messages[idx].viewed = 1;
        }
      }
    },
    getTime(date) {
      let newDate = new Date(date);
      let h = newDate.getHours();
      let m = newDate.getMinutes();
      if (h < 10) h = "0" + h;
      if (m < 10) m = "0" + m;
      return `${h}:${m}`;
    },
    dateFormat(date) {
      let today = new Date().toLocaleDateString();
      let dateLocale = new Date(date).toLocaleDateString();
      if (today == dateLocale)
        return "HOJE";
      else
        return dateLocale;
    },
    scrollDown(wrapper, selector) {
      setTimeout(() => {
        if (selector && document.querySelector(selector)) {
          document.querySelector(selector).scrollIntoView({
            behavior: "smooth",
          });
        } else if (document.querySelector(wrapper)){
          document.querySelector(wrapper).scroll({
            top: 100000,
            behavior: "smooth",
          });
        }
      }, 1);
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        if (!this.socket) {
          this.socket = this.$store.getters.getSocket;
        }
        this.loadHistory();
        this.socket.on("message", this.recebedor);
        this.socket.on("viewed", this.viewed);
      } else {
        this.socket.off("message", this.recebedor);
        this.socket.off("viewed", this.viewed);
      }
    },
  },
};
</script>

<style scoped>

.chat-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: #EFEAE2 url(~@/assets/bg-chat.webp);
}

.chat-wrapper header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    height: 60px;
    background-color: #F0F2F5;
}

.chat-wrapper header .contact-picture {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
}

.chat-wrapper header .contact-info {
  flex-grow: 1;
  line-height: 1.4em !important;
}

.chat-wrapper header .contact-name {
  display: block;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.chat-wrapper header .status {
  display: block;
  font-size: 13px;
  color: #667781;
}

.chat-wrapper footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 62px;
  padding: 0 20px 0 45px;
  background-color: #F0F2F5;
}

.chat-wrapper footer .btn-send {
  width: 30px;
  height: 30px;
}

#chat-container {
  height: calc(100% - 120px);
  overflow-y: scroll;
}

#chat {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
  box-sizing: border-box;
  padding: 15px 45px 5px ;
  user-select: text;
}

#chat div {
  display: flex;
  flex-direction: column;
}

#chat p {
  position: relative;
  max-width: 490px;
  font-size: 14px !important;
  color: #111B21 !important;
  line-height: 1.4em !important;
  border-radius: 7px;
  box-shadow: 0 1px 0.5px #0b141a21;
  padding: 5px 9px !important;
  margin-bottom: 3px !important;
}

#chat .date p {
  align-self: center;
  font-size: 12.5px !important;
  color: #667781 !important;
  background-color: #FFF;
}

#chat .unread {
  padding: 5px 0;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0 1px 0 #0b141a21;
  margin: 10px -45px;
}

#chat .unread p {
  padding: 6px 22px !important;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  align-self: center;
  font-size: 12.5px !important;
  background-color: #FFF;
  border-radius: 16px;
  box-shadow: none;
}

#chat .me p {
  align-self: flex-end;
  background-color: #D9FDD3;
}

#chat .they p {
  align-self: flex-start;
  background-color: #FFF;
}
#chat .date,
#chat p.lug {
  margin-top: 10px;
}

#chat .me p.lug {
  border-top-right-radius: 0;
}

#chat .they p.lug {
  border-top-left-radius: 0;
}

#chat p.lug::before {
  position: absolute;
  width: 8px;
  height: 13px;
  top: -2px;
}

#chat .me p.lug::before {
  content: url(~@/assets/me-lug.svg);
  right: -8px;
}

#chat .they p.lug::before {
  content: url(~@/assets/they-lug.svg);
  left: -8px;
}

#chat p .end-gap {
  display: inline-block;
  width: 75px;
  height: 1em;
}

#chat p sub {
  position: absolute;
  right: 9px;
  bottom: -1px;
  line-height: unset !important;
}

#chat p sub small {
  font-size: 11px;
  color: #667781;
}
</style>