<template>
  <v-card>
    <v-card-title>Editar Usuário</v-card-title>
    <v-card-text>
      <v-form ref="formUser">
        <v-row>
          <v-col class="d-flex flex-column justify-center align-center">
            <v-avatar size="270" class="mb-4">
              <v-img
                lazy-src="@/assets/nopic.jpg"
                class="d-flex justify-center"
                id="avatar"
                :src="picture"
                refs="avatar"
              ></v-img>
            </v-avatar>
            <Dialog @base64="img64($event)" />
            <span class="d-block mb-4 lh-05">ou</span> 
            <v-file-input
              v-model="tmpFile"
              placeholder="Fazer upload da imagem"
              prepend-icon="mdi-image-area"
              outlined
              dense
              hide-details
              @change="pickFile"
            >
            </v-file-input>
          </v-col>
          <v-col>
            <v-text-field
              label="Usuário"
              disabled
              v-model="user.userName"
              outlined
            ></v-text-field>
            <v-text-field
              label="Nome"
              v-model="tmpName"
              required
              :rules="requiredField"
              outlined
            ></v-text-field>
            <v-card outlined>
              <v-card-text class="pb-0">
                <v-checkbox
                  v-model="changePass"
                  label="Alterar senha"
                  hide-details
                  class="d-inline-block mt-0 pt-0 mb-4"
                ></v-checkbox>
                <v-text-field
                  label="Senha Atual"
                  v-model="user.currPass"
                  outlined
                  :disabled="!changePass"
                  :append-icon="showCurrPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showCurrPass ? 'text' : 'password'"
                  :required="changePass"
                  :rules="changePass ? requiredField : []"
                  @click:append="showCurrPass = !showCurrPass"
                ></v-text-field>
                <v-text-field
                  label="Nova Senha"
                  v-model="user.password"
                  outlined
                  :disabled="!changePass"
                  :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNewPass ? 'text' : 'password'"
                  :required="changePass"
                  :rules="changePass ? requiredField : []"
                  @click:append="showNewPass = !showNewPass"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="px-4 pb-4 pt-3">
      <v-spacer></v-spacer>
      <v-btn color="success" @click="send">Salvar Alterações</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import { mapGetters } from 'vuex';
import { requiredField } from "../../Utils/InputValidation";
import Dialog from "../Webcam/WbDialog";
export default {
  components: {
    Dialog,
  },
  data: () => {
    return {
      currPass: "",
      senha: "",
      confirmacao: "",
      picture: "",
      tmpName: "",
      tmpFile: null,
      file: null,
      user: {},
      requiredField,
      changePass: false,
      showCurrPass: false,
      showNewPass: false,
    };
  },
  methods: {
    img64(IMGbase64) {
      this.picture = IMGbase64;
      this.file = this.dataURItoBlob(this.picture);
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      // let blob = new Blob([ia], { type: mimeString });
      // blob.lastModifiedDate = new Date();
      // blob.name = `WebCam ${new Date().toDateString()}.${mimeString.split('/')[1]}`;

      // return new Blob([ia], { type: mimeString });
      return new File([ia], `WebCam ${new Date().toDateString()}.${mimeString.split('/')[1]}`) ;
    },
    
    send() {
      // console.log("enviar");
      // console.log(this.file);
      // console.log(typeof this.file);
      if (!this.$refs.formUser.validate()) {
        return;
      }
      this.user.name = this.tmpName;
      if (typeof this.file == File || typeof this.file == Blob) {
      // console.log("é arquivo");
      }
      let bodyFormData = new FormData();
      if (this.file) {
        bodyFormData.append("picture", this.file);
      }
      if (this.user.name) {
        bodyFormData.append("name", this.user.name);
      }
      if (this.user.currPass) {
        bodyFormData.append("currPass", this.user.currPass);
      }
      if (this.user.password) {
        bodyFormData.append("password", this.user.password);
      }
      this.$http
        .put("/usuario", bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.status < 299) {
            this.$store.dispatch("resetSnack");
            this.$store.dispatch("showSuccessSnack", "Alterações Aplicadas");
            //this.picture = this.$store.state.user.picture;
            this.$store.commit("setUser", resp.data);
          }
        });
    },
    pickFile() {
      if (this.tmpFile) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.picture = e.target.result;
        };
        reader.readAsDataURL(this.tmpFile);
        this.file = this.tmpFile;
      }
    },
  },
  created() {
    this.user = this.$store.state.user;
    this.tmpName = this.user.name;
    if (this.$store.state.user.picture) {
      this.picture = this.$store.state.user.picture;
    }
  },
  watch: {
    changePass(val) {
      if (!val) {
        this.user.currPass = "";
        this.user.password = "";
      }
    },
  },
};
</script>

<style scoped>
  .lh-05 {
    line-height: 0.5;
  }
</style>