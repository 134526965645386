<template>
  <div>
    <template v-if="!report">
      <v-card-title>Visitantes Presentes</v-card-title>
      <v-card-subtitle>Clique na tabela para mais detalhes</v-card-subtitle>
    </template>
    <v-card-text>
      <DataTableGeneric
        :headers="headers"
        :items="items"
        @rowClick="openModalVisita"
        @headersChanged="$emit('headersChanged', $event)"
      >
        <template v-slot:item.visitanteName="{ item }">
          <v-list-item class="px-0">
            <v-list-item-avatar width="70" height="70" class="my-2">
              <v-avatar size="70">
                <v-img :src="item.visitantePic" lazy-src="/img/nopic.jpg"></v-img>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content class="pt-1 pb-0">
              <v-list-item-title class="mb-2">{{ item.visitanteName }}</v-list-item-title>
              <v-list-item-subtitle class="mb-1">Documento: {{ item.visitanteDoc }}</v-list-item-subtitle>
              <v-list-item-subtitle class="mb-1">Referência: {{ item.visitanteRef }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:item.visitadoName="{ item }">
          <v-list-item class="px-0">
            <v-list-item-avatar width="70" height="70" class="my-2">
              <v-avatar size="70">
                <v-img :src="item.visitadoPic" lazy-src="/img/nopic.jpg"></v-img>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content class="pt-1 pb-0">
              <v-list-item-title class="mb-2">{{ item.visitadoName }}</v-list-item-title>
              <v-list-item-subtitle class="mb-1">Local: {{ item.visitadoPlace }}</v-list-item-subtitle>
              <v-list-item-subtitle class="mb-1">OBS: {{ item.visitadoObs }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:item.visitadoNameReport="{ item }">
          <div class="d-flex my-2">
            <v-avatar size="70" class="mr-3">
              <v-img :src="item.visitadoPic" lazy-src="/img/nopic.jpg"></v-img>
            </v-avatar>
            <div class="d-flex align-center" style="max-width: 145px">
              <span class="text-truncate">{{ item.visitadoName }}</span>
            </div>
          </div>
        </template>
        
        <template v-slot:item.visitanteNameReport="{ item }">
          <div class="d-flex my-2">
            <v-avatar size="70" class="mr-3">
              <v-img :src="item.visitantePic" lazy-src="/img/nopic.jpg"></v-img>
            </v-avatar>
            <div class="d-flex align-center" style="max-width: 145px">
              <span class="text-truncate">{{ item.visitanteName }}</span>
            </div>
          </div>
        </template>

        <template v-slot:item.createdAtReport="{ item }">
          {{ formataData(item.createdAt) }}
        </template>

        <template v-slot:item.updatedAtReport="{ item }">
          {{ formataData(item.updatedAt) }}
        </template>

        <template v-slot:item.aprovText="{ item }">
          <span v-if="item.aprovTime">{{item.aprovText}}</span>
          <Dialog v-else @emit="$emit('aprovada', {texto: $event, id: item.id})" title="Aprovar Visita?" btnText="Aprovar" btnSendText="Aprovar" lblInput="Mensagem" :required="true" />
        </template>

        <template v-slot:item.perm="{ item }">
          {{ getIntervalo(item.createdAt, item.updatedAt, item.perm) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                large
                color="orange lighten-1"
                @click.stop="regOut(item)"
              >
                <v-icon>mdi-exit-to-app</v-icon>
              </v-btn>
            </template>
            <strong>Registrar Saída</strong>
          </v-tooltip>
        </template>

      </DataTableGeneric>
    </v-card-text>
    <VisitaDetails ref="modalVisitaDetails" :actions="report ? false : actions" @out="regOut($event)" />
    <ConfirmDialog v-if="!report" ref="confirmDialog" />
  </div>
</template>

<script>

import DataTableGeneric from '../DataTableGeneric'
import ConfirmDialog from '../ConfirmDialog'
import Dialog from '../Template/DialogFormGeneric'
import VisitaDetails from './VisitaDetails.vue';
import { differenceDate } from "@/Utils/DateDiff";

export default {
  name: "TableDialog",
  components:{
    DataTableGeneric,
    ConfirmDialog,
    Dialog,
    VisitaDetails,
  },
  data: () => {
    return {
      search: "",
      selected: [],
      dialogSaida: true,
    };
  },
  props: {
    items: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    report: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    openModalVisita(item) {
      this.$refs.modalVisitaDetails.open(item);
    },
    regOut(item) {
      this.$refs.confirmDialog.open({
        title: "Registrar Saída",
        text: `Deseja registrar a saída de <strong>${item.visitanteName}</strong>?`,
        confirmCallback: () => {
          this.$store.dispatch("registrarSaida", item);
          this.$refs.modalVisitaDetails.close();
        },
      });
    },
    formataData(dataUTC) {
      return new Date(dataUTC).toLocaleString();
    },
    getIntervalo(inicio, fim, perm) {
      let dif = differenceDate(inicio, fim);
      if (dif.day > 0)
        return `${dif.day}d, ${dif.hour}h, ${dif.minute}m`;
      else
        return perm;
    },
  },
  /*watch: {
    selected() {
      this.$emit("selected", this.selected[0]);
    },
  },*/
};
</script>

<style>
</style>