<template>
  <div>
    <v-text-field
      v-model="filter"
      label="Pesquisa"
      outlined
      hide-details
      class="mb-5"
      @input="$store.commit('setFilterFleetsVehicle', $event)"
    ></v-text-field>
    <v-virtual-scroll
      v-if="getFleetVehiclesFilter.length "
      :items="getFleetVehiclesFilter"
      bench="1"
      :item-height="scrollItemHeight"
      :height="scrollHeight"
    >
      <template v-slot:default="{ item }">
        <v-card
          outlined
          class="mb-1"
          :color="selected.includes(item.id) ? 'green lighten-2' : ''"
          :class="getFleetVehiclesFilter.length > 3 ? 'mr-1' : ''"
          @click="selectItem(item)"
        >
          <Item
            :item="item"
          />
        </v-card>
      </template>
    </v-virtual-scroll>
    <div v-else :style="`height: ${scrollHeight}px`" class="d-flex justify-center align-center">
      <span v-if="filter">Nenhum dado encontrado</span>
      <span v-else>Não há dados disponíveis</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Item from "./item_veiculo.vue";
export default {
  name: "ListaVeiculo",
  computed: {
    ...mapGetters(["getFleetVehiclesFilter"]),
  },
  components: {
    Item,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItems: [],
      filter: "",
      scrollHeight: 248,
      scrollItemHeight: 84,
    };
  },
  methods: {
    selectItem(item) {
      if (this.multiple) {
        if (this.selectedItems.includes(item.id)) {
          this.selectedItems = this.selectedItems.filter((i) => i !== item.id);
        } else {
          this.selectedItems.push(item.id);
        }
        this.$emit("selectedItem", this.selectedItems);
      } else {
        this.$emit("selectedItem", item.id);
      }
    },
    clear() {
      this.selectedItems = [];
      this.filter = "";
      this.$store.commit("setFilterFleetsVehicle", "");
    }
  },
};
</script>

<style>
</style>