<template>
  <div class="pendentes">
    <div class="form">
      <v-card class="mt-1">
        <v-card-title>Dispositivos Online</v-card-title>
        <v-card-text>
          <DataTableGeneric :headers="header" :items="online">
            <template slot="item.start" slot-scope="{ item }">
              <v-btn fab small text color="success" @click="start(item.user.deviceId)" :disabled="!item.user.deviceId">
                <v-icon>mdi-cellphone-message</v-icon>
              </v-btn>
              <v-btn fab small text color="success" @click="openCommands(item.user.deviceId)" :disabled="!item.user.deviceId">
                <v-icon>mdi-powershell</v-icon>
              </v-btn>
            </template>
            <template slot="item.user.iat" slot-scope="{ item }">
              {{ new Date(item.user.iat * 1000).toLocaleString() }}
            </template>
            <template slot="item.user.groupId" slot-scope="{ item }">
              {{ $store.getters.getGroupNameById(item.user.groupId) }}
            </template>
            <template slot="item.user.clientId" slot-scope="{ item }">
              {{ $store.getters.getClienteNameById(item.user.clientId) }}
            </template>
          </DataTableGeneric>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
    </v-snackbar>
    <DialogMessage ref="dialogMessage" :deviceId="deviceId" />
    <DialogCommands ref="dialogCommand" :deviceId="deviceId" />
  </div>
</template>

<script>
import DialogMessage from "./DialogMessage.vue";
import DialogCommands from "./DialogCommands.vue";
import DataTableGeneric from "../DataTableGeneric.vue";
export default {
  name: "DispositivoWB",
  components: {
    DataTableGeneric,
    DialogMessage,
    DialogCommands,
  },
  data: () => {
    return {
      socket: null,
      text: "",
      snackbar: false,
      deviceId: "",
      history: [],
      header: [
        { text: "Nome", value: "user.name" },
        { text: "Usuário", value: "user.userName" },
        { text: "Grupo", value: "user.groupId" },
        { text: "Dispositivo", value: "user.deviceId" },
        { text: "Cliente", value: "user.clientId" },
        { text: "Logado", value: "user.iat" },
        { text: "Interagir", value: "start" },
      ],
    };
  },
  computed: {
    online() {
      return this.$store.getters.getOnline;
    },
  },
  methods: {
    // seleciona o dispositivo para interação
    start(deviceId) {
      this.$refs.dialogMessage.open({ deviceId: deviceId });
      this.deviceId = deviceId;
    },
    openCommands(deviceId) {
      this.$refs.dialogCommand.open({ deviceId: deviceId });
    },
  },
  mounted() {
    this.$store.dispatch("loadClientes");
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("loadDispositivos");
  },
  /*
  beforeRouteLeave(to, from, next) {
    this.socket.off("users", this.updateUsers);
    this.socket.off("replyOnLine", this.updateUsers);
    next();
  },*/
};
</script>

<style></style>