<template>
	<div class="cliRonda">
		<v-card-title>Cadastros de Rondas Virtuais</v-card-title>
		<v-card-text>
			<DataTable :items="rondas" :headers="headers">
				<template slot="item.daysOfWeek" slot-scope="{ item }">
					{{ daysToString(item.daysOfWeek) }}
				</template>

				<template slot="item.questions" slot-scope="{ item }">
					<span class="mr-1">{{ item.questions.length }} Questões</span>
					<v-menu open-on-hover top>
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
						</template>
						<v-list dense class="dropdown-list superdense">
							<v-list-item v-for="(item, index) in item.questions" :key="index">
								<v-list-item-content>
									<v-list-item-title>{{ item.name }}</v-list-item-title>
									<v-list-item-subtitle
										><strong>Resposta Esperada</strong
										>{{ verificaTipo(item.type) }}</v-list-item-subtitle
									>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>

				<template slot="item.actions" slot-scope="{ item }">
					<!-- botão editar -->
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								@click="editar(item)"
								color="warning"
								class="mr-1"
								>mdi-pencil</v-icon
							>
						</template>
						<span>Editar: {{ item.name }}</span>
					</v-tooltip>
					<!-- botão excluir -->
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								@click="excluir(item)"
								color="red"
								class="mr-1"
								>mdi-trash-can</v-icon
							>
						</template>
						<span>Excluir: {{ item.name }}</span>
					</v-tooltip>
					<!-- botão executar -->
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								@click="executar(item)"
								color="success"
								class="mr-1"
								>mdi-check-underline-circle</v-icon
							>
						</template>
						<span>Executar: {{ item.name }}</span>
					</v-tooltip>
				</template>
			</DataTable>
		</v-card-text>

		<!-- dialog de cadastro -->
		<v-dialog v-model="dialogCadastro" persistent max-width="815" scrollable>
			<v-card>
				<v-card-title class="text-h5 pr-4 mb-2"
					>{{ ronda.id ? "Editar" : "Cadastrar" }} Ronda<v-spacer></v-spacer>
					<v-btn icon @click="closeCadastro">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pt-1 pb-0">
					<v-form ref="formRonda">
						<v-row>
							<v-col class="pb-0">
								<v-text-field
									v-model="ronda.name"
									label="Nome da Ronda"
									required
									:rules="requiredField"
									outlined
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="pt-0">
								<v-text-field
									v-model="ronda.start"
									label="Hora Inicial"
									type="time"
									required
									:rules="requiredField"
									outlined
								></v-text-field>
							</v-col>
							<v-col class="pt-0">
								<v-text-field
									v-model="ronda.end"
									ref="rondaEnd"
									label="Hora Final"
									type="time"
									required
									:rules="[
										requiredField[0],
										ronda.end !== ronda.start ||
											'Hora final deve ser diferente da inicial',
									]"
									outlined
								></v-text-field>
							</v-col>
							<v-col class="pt-0">
								<v-text-field
									v-model.number="ronda.interval"
									label="Intervalo"
									hint="Intervalo entre Rondas (minutos)"
									type="number"
									:min="minInterval"
									required
									:rules="[
										requiredField[0],
										validaInterval || 'Intervalo maior que o tempo total',
										(v) => v >= minInterval || `Mínimo ${minInterval} minutos`,
									]"
									outlined
								></v-text-field>
							</v-col>
						</v-row>
						<v-card outlined class="mb-8">
							<v-card-subtitle>
								<span>Horários das rondas virtuais</span>
								<span class="text-caption d-inline-block ml-2">
									<template v-if="rondaRepeats.length"
										>({{ rondaRepeats.length }}
										{{
											rondaRepeats.length > 1 ? "repetições" : "repetição"
										}})</template
									>
									<template v-else
										>(Preencha os campos <strong>Hora Inicial</strong>,
										<strong>Hora Final</strong> e
										<strong>Intervalo</strong> para visualizar)</template
									>
								</span>
							</v-card-subtitle>
							<v-card-text class="pt-0 pb-4 px-4" v-if="rondaRepeats.length">
								<div id="card-repeats">
									<v-chip
										v-for="(repeat, index) in rondaRepeats"
										:key="index"
										small
									>
										{{ `${repeat.start} - ${repeat.end}` }}
									</v-chip>
								</div>
							</v-card-text>
						</v-card>

						<!-- select dias da semana -->
						<v-select
							v-model="ronda.daysOfWeek"
							:items="daysOfWeek"
							label="Dias da semana"
							multiple
							chips
							deletable-chips
							small-chips
							outlined
							:rules="[(v) => v.length > 0 || 'Selecione pelo menos um dia']"
						>
							<template v-slot:prepend-item>
								<v-list-item
									ripple
									@mousedown.prevent
									@click="selAllSelectSemana"
								>
									<v-list-item-action>
										<v-icon>{{ iconSelectSemana }}</v-icon>
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>Todos os dias</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template>
						</v-select>

						<!-- tabela questões -->
						<v-card class="mb-4" outlined>
							<v-card-subtitle>Questões</v-card-subtitle>
							<v-card-text class="pt-0 pb-4 px-4">
								<v-row>
									<v-col cols="12" md="5">
										<v-text-field
											v-model="ronda.questionName"
											label="Nome da Questão"
											outlined
											:rules="[
												(v) =>
													ronda.questions.length >= 1 ||
													'Adicione ao menos uma questão',
											]"
										></v-text-field>
									</v-col>
									<v-col cols="12" md="5">
										<v-select
											v-model="ronda.questionType"
											:items="[
												{ text: 'Texto', value: 'text' },
												{ text: 'Número', value: 'number' },
												{ text: 'Sim/Não', value: 'boolean' },
											]"
											label="Tipo de Questão"
											outlined
										></v-select>
									</v-col>
									<v-col cols="12" md="2">
										<v-btn
											fab
											elevation="0"
											color="success"
											@click="addQuestion"
											:disabled="!ronda.questionName || !ronda.questionType"
										>
											<v-icon> mdi-plus </v-icon>
										</v-btn>
									</v-col>
								</v-row>
								<div v-if="ronda.questions.length">
									<v-data-table
										class="fixed-header-table draggable mt--1 mb-4"
										hide-default-footer
										hide-default-header
										id="table-questions"
									>
										<template v-slot:header>
											<thead>
												<tr>
													<th class="text-left">Nome</th>
													<th class="text-left">Tipo</th>
													<th class="text-center">Ações</th>
												</tr>
											</thead>
										</template>
										<template v-slot:body>
											<draggable :list="ronda.questions" tag="tbody">
												<tr v-for="(item, idx) in ronda.questions" :key="idx">
													<td class="text-left">{{ item.name }}</td>
													<td class="text-left">
														{{ verificaTipo(item.type) }}
													</td>
													<td class="text-center">
														<v-btn icon @click="editQuestion(idx)">
															<v-icon color="warning">mdi-pencil</v-icon>
														</v-btn>
														<v-btn
															icon
															@click="deleteQuestion(idx)"
															:disabled="
																ronda.questions
																	? ronda.questions.length <= 1
																	: true
															"
														>
															<v-icon color="red">mdi-trash-can </v-icon>
														</v-btn>
													</td>
												</tr>
											</draggable>
										</template>
									</v-data-table>
								</div>
							</v-card-text>
						</v-card>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4">
					<v-spacer></v-spacer>
					<v-btn text @click="closeCadastro">Cancelar</v-btn>
					<v-btn color="success" @click="save()"> Salvar </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- botão de cadastrar -->
		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }" in>
				<v-btn
					color="amber darken-1"
					dark
					absolute
					bottom
					right
					fab
					fixed
					class="mb-10"
					v-bind="attrs"
					v-on="on"
					@click="dialogCadastro = true"
				>
					<v-icon color="black">mdi-plus</v-icon>
				</v-btn>
			</template>
			<span>Adicionar Ronda Virtual</span>
		</v-tooltip>

		<!-- dialog Edição de questão -->
		<v-dialog v-model="dialogEditQuestion" max-width="400">
			<v-card>
				<v-card-title>Editar Questão</v-card-title>
				<v-card-text>
				<v-row>
					<v-col cols="6">
						<v-text-field
							v-model="question.questionName"
							label="Nome da Questão"
							outlined
							hide-details
						></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-select
							v-model="question.questionType"
							:items="[
								{ text: 'Texto', value: 'text' },
								{ text: 'Número', value: 'number' },
								{ text: 'Sim/Não', value: 'boolean' },
							]"
							label="Tipo de Questão"
							outlined
							hide-details
						></v-select>
					</v-col>
				</v-row>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
							text
							elevation="0"
							color="success"
							@click="saveEditQuestion"
							:disabled="!question.questionName || !question.questionType"
						>
							Salvar
						</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<ConfirmDialog ref="confirmDialog" />
		<ExecRondaVirtual ref="execRondaVirtual" />
	</div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import DataTable from "../../DataTableGeneric";
import ConfirmDialog from "@/components/ConfirmDialog";
import draggable from "vuedraggable";
import ExecRondaVirtual from "./ExecRondaVirtual";

export default {
	name: "CliRondasVirtuais",
	components: {
		DataTable,
		ConfirmDialog,
		draggable,
		ExecRondaVirtual,
	},
	props: ["rondas"],
	data: () => {
		return {
			dialogCadastro: false,
			requiredField,
			ronda: {},
			minInterval: 30,
			selectedGroup: [],
			dialogEditQuestion: false,
			question: {},
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Ronda Virtual", value: "name" },
				{ text: "Questões", value: "questions" },
				{ text: "Início", value: "start" },
				{ text: "Fim", value: "end" },
				{ text: "Intervalo", value: "interval" },
				{ text: "Dias da Semana", value: "daysOfWeek", sortable: false },
				{
					text: "Ações",
					value: "actions",
					align: "center",
					sortable: false,
					filterable: false,
					width: 125,
				},
			],
			daysOfWeek: [
				{ text: "domingo", value: 0, short: "dom" },
				{ text: "segunda-feira", value: 1, short: "seg" },
				{ text: "terça-feira", value: 2, short: "ter" },
				{ text: "quarta-feira", value: 3, short: "qua" },
				{ text: "quinta-feira", value: 4, short: "qui" },
				{ text: "sexta-feira", value: 5, short: "sex" },
				{ text: "sábado", value: 6, short: "sab" },
			],
			questionsHeaders: [
				{ text: "Nome", value: "name" },
				{ text: "Tipo", value: "type" },
				{
					text: "Ações",
					value: "actions",
					align: "center",
					sortable: false,
					filterable: false,
					width: 112,
				},
			],
		};
	},
	computed: {
		iconSelectSemana() {
			if (this.ronda.daysOfWeek.length == this.daysOfWeek.length)
				return "mdi-close-box";
			else if (this.ronda.daysOfWeek.length > 0) return "mdi-minus-box";
			else return "mdi-checkbox-blank-outline";
		},
		validaInterval() {
			// verifica se o intervalo é menor que o tempo total
			if (!this.ronda.start || !this.ronda.end) return true;
			return this.ronda.interval <= this.getTimeDiff;
		},
		rondaRepeats() {
			if (!this.ronda.interval || !this.ronda.start || !this.ronda.end)
				return [];
			const interval = this.ronda.interval;
			const minsStart = this.timeToMinutes(this.ronda.start);
			const repeats = Math.floor(this.getTimeDiff / interval);
			let rondaRepeat = [];
			for (let i = 0; i < repeats; i++) {
				rondaRepeat.push({
					start: this.minutesToTime(minsStart + interval * i),
					end: this.minutesToTime(minsStart + interval * (i + 1)),
				});
			}
			this.scrollDown("#card-repeats");
			return rondaRepeat;
		},
		getTimeDiff() {
			// retorna a diferença entre o horário inicial e final, utilizado em 2 lugares
			let dif =
				this.timeToMinutes(this.ronda.end) -
				this.timeToMinutes(this.ronda.start);
			return dif < 0 ? (dif += 24 * 60) : dif;
		},
	},
	methods: {
		reset() {
			this.ronda = {
				id: null,
				name: "",
				start: "",
				end: "",
				interval: this.minInterval,
				daysOfWeek: [],
				questions: [],
			};
		},
		addQuestion() {
			this.ronda.questions.push({
				name: this.ronda.questionName,
				type: this.ronda.questionType,
			});
			this.ronda.questionName = "";
		},
		saveEditQuestion() {
			const idx = this.ronda.questions.findIndex(
				(item) => item.name == this.question.name
			);
			this.ronda.questions[idx].name = this.question.questionName;
			this.ronda.questions[idx].type = this.question.questionType;
			this.dialogEditQuestion = false;
			this.question = {};
			this.$store.dispatch("saveRondaVirtual", this.ronda);
		},
		editQuestion(index) {
			this.question = { ...this.ronda.questions[index] };
			this.dialogEditQuestion = true;
		},
		deleteQuestion(idx) {
			this.$refs.confirmDialog.open({
				title: "Excluir Questão",
				text: `Deseja excluir a questão <strong>${this.ronda.questions[idx].name}</strong>?`,
				confirmCallback: () => {
					this.ronda.questions.splice(idx, 1);
				},
			});
		},
		executar(item) {
			this.$refs.execRondaVirtual.open(item);
		},
		verificaTipo(tipo) {
			switch (tipo) {
				case "text":
					return " - Texto";
				case "number":
					return " - Número";
				case "boolean":
					return " - Sim/Não";
				default:
					return "";
			}
		},
		save() {
			if (!this.$refs.formRonda.validate()) return;
			this.ronda.repeats = this.rondaRepeats;
			this.ronda.clientId = this.$store.getters.getCliente.id;
			this.ronda.groupId = this.$store.getters.getCliente.groupId;
			this.$store.dispatch("saveRondaVirtual", this.ronda);
			this.closeCadastro();
		},
		editar(item) {
			this.ronda = { ...item };
			this.dialogCadastro = true;
		},
		excluir(item) {
			this.$refs.confirmDialog.open({
				title: "Excluir Ronda Virtual",
				text: `Deseja excluir a ronda virtual <strong>${item.name}</strong>?`,
				confirmCallback: () => {
					this.$store.dispatch("deleteRondaVirtual", item);
				},
			});
		},
		closeCadastro() {
			this.dialogCadastro = false;
			this.$refs.formRonda.resetValidation();
			this.reset();
		},
		selAllSelectSemana() {
			if (this.ronda.daysOfWeek.length == this.daysOfWeek.length)
				this.ronda.daysOfWeek = [];
			else this.ronda.daysOfWeek = this.daysOfWeek.map((dia) => dia.value);
		},
		daysToString(days) {
			let daysShort = this.daysOfWeek
				.filter((dia) => days.includes(dia.value))
				.map((dia) => dia.short);
			return daysShort.join(", ");
		},
		scrollDown(selector) {
			setTimeout(() => {
				document.querySelector(selector).scroll({
					top: 10000,
					behavior: "smooth",
				});
			}, 1);
		},
		timeToMinutes(time) {
			const minutes = (h, m) => parseInt(h) * 60 + parseInt(m);
			return minutes(...time.split(":"));
		},
		minutesToTime(min) {
			let h = Math.floor(min / 60);
			let m = min % 60;
			if (h >= 24) h -= 24;
			if (h < 10) h = "0" + h;
			if (m < 10) m = "0" + m;
			return `${h}:${m}`;
		},
	},
	created() {
		this.reset();
	},
};
</script>

<style scoped>
#table-questions td {
	padding-top: 8px;
	padding-bottom: 8px;
}
.btn-dropdown {
	background-color: #fff;
}
#card-repeats {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
	max-height: 88px;
	overflow-y: auto;
}
</style>

<style>
</style>