<template>
  <v-card>
    <v-card-title>Cadastros de {{ typeCli == 1 ? 'Visitados' : 'Moradores' }}</v-card-title>
    <v-card-text>
      <DataTable :headers="typeCli == 1 ? headersEmp : headersCond" :items="getVisitados">
        <!-- formata a data -->
        <template slot="item.createdAt" slot-scope="{ item }">
          {{ new Date(item.createdAt).toLocaleString() }}
        </template>
        <template slot="item.updatedAt" slot-scope="{ item }">
          {{ new Date(item.updatedAt).toLocaleString() }}
        </template>
        <!-- imagem do avatar -->
        <template slot="item.picture" slot-scope="{ item }">
          <v-avatar size="70" class="my-2">
            <v-img
              :src="item.picture"
              lazy-src="/img/nopic.jpg"
            ></v-img>
          </v-avatar>
        </template>
        <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="editar(item)"
                color="warning"
                class="mr-1"
              >mdi-pencil</v-icon>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
          <!-- botão excluir -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="excluir(item)"
                color="red"
              >mdi-trash-can</v-icon>
            </template>
            <span>Excluir : {{ item.name }}</span>
          </v-tooltip>
        </template>
      </DataTable>
    </v-card-text>
    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="amber darken-1" dark absolute bottom right fab fixed class="mb-10" v-bind="attrs" v-on="on"
          @click="dialogForm = true">
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Visitado</span>
    </v-tooltip>
    <Dialog v-model="dialogForm" :typeCli="typeCli" :item="item" @close="item = {}" @save="salvar" />
    <ConfirmDialog ref="confirmDialog" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import DataTable from "@/components/DataTableGeneric.vue";
import ConfirmDialog from '@/components/ConfirmDialog';
import Dialog from "./Dialog_visitado.vue";
export default {
  name: "visitados",
  components: {
    DataTable,
    Dialog,
    ConfirmDialog
  },
  computed: {
    ...mapGetters(["getVisitados"]),
  },
  props: {
    typeCli: {
      type: Number,
      default: 1,
    },
    groupId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogForm: false,
      headersEmp: [
        { text: "Id", value: "id", show: false },
        { text: "Foto", value: "picture", sortable: false, filterable: false, width: 70 },
        { text: "Nome", value: "name" },
        { text: "Telefone / Ramal", value: "phone", sortable: false },
        { text: "Celular", value: "celPhone", sortable: false },
        { text: "Função", value: "place" },
        { text: "Hierarquia", value: "hierarchy", show: false },
        { text: "Observações", value: "obs" },
        { text: "Cadastrado", value: "createdAt", show: false },
        { text: "Atualizado", value: "updatedAt", show: false },
        { text: "Ações", value: "actions", sortable: false, filterable: false, width: 85 },
      ],
      headersCond: [
        { text: "Id", value: "id", show: false },
        { text: "Foto", value: "picture", sortable: false, filterable: false, width: 70 },
        { text: "AP", value: "place" },
        { text: "Bloco", value: "bloco" },
        { text: "Nome", value: "name" },
        { text: "Tipo", value: "tipoMorador" },
        { text: "Telefone", value: "phone", sortable: false, filterable: false },
        { text: "Celular", value: "celPhone", sortable: false, filterable: false },
        { text: "Carro", value: "tipoVeiculo" },
        { text: "Cor Carro", value: "corVeiculo" },
        { text: "Placa", value: "plate" },
        { text: "Box", value: "boxs" },
        { text: "Cadastrado", value: "createdAt", show: false },
        { text: "Atualizado", value: "updatedAt", show: false },
        { text: "Ações", value: "actions", sortable: false, filterable: false, width: 85 },
      ],
      item: {},
    };
  },
  methods: {
    editar(item) {
      this.item = {...item};
      this.dialogForm = true;
    },
    excluir(item) {
      this.$refs.confirmDialog.open({
        title: "Excluir Visitado",
        text: `Deseja excluir o registro de <strong>${item.name}</strong>?`,
        confirmCallback: () => {
          this.$store.dispatch("deleteVisitado", item);
        },
      });
    },
    salvar(item) {
      item.groupId = this.groupId; // no efetivo será propositalmente null
      item.callback = () => {
        this.dialogForm = false;
        this.item = {};
        this.$store.dispatch("loadVisitados", this.groupId);
      };
      this.$store.dispatch("saveVisitado2", item);
    },
  },
};
</script>

<style></style>