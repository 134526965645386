var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Pontos de Ronda Cadastrados")]),_c('v-card-text',[_c('DataTable',{attrs:{"headers":_vm.headers,"items":_vm.checkpoints,"enableSelect":true},on:{"update:selectedItems":function($event){_vm.selecionados = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('TooltipIcon',{attrs:{"text":("Editar: " + (item.name)),"icon":"mdi-pencil","top":"true","color":"warning"},on:{"click":function($event){return _vm.editarQR(Object.assign({}, item))}}}),_c('TooltipIcon',{attrs:{"text":("Gerar QR Code: " + (item.name)),"icon":"mdi-qrcode","top":"true"},on:{"click":function($event){return _vm.print(item)}}})]}},{key:"item.rondas",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.rondas.length)+" Rondas")]),(item.rondas.length > 0)?_c('v-menu',{attrs:{"open-on-hover":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('v-list',{staticClass:"dropdown-list superdense",attrs:{"dense":""}},_vm._l((item.rondas),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)],1)}),1)],1):_vm._e()]}}])}),_c('div',{staticClass:"btn-print ml-5",staticStyle:{"margin-top":"-42px"}},[_c('v-icon',{staticClass:"mr-2 mb-2"},[_vm._v("mdi-arrow-up-left")]),_c('v-btn',{attrs:{"disabled":_vm.selecionados == 0},on:{"click":function($event){return _vm.print(_vm.selecionados)}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-qrcode")]),_vm._v(" Gerar QR Codes")],1)],1)],1),_c('ConfirmDialog',{ref:"confirmDialog"}),_c('CheckpointsDialog',{ref:"checkpointsDialog"}),_c('CadCheckpoints',{ref:"cadCheckpoints"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }