import axios from 'axios'
export default {
    state: {
        eventos: [],
    },
    mutations: {
        setEventos(state, payload) {
            state.eventos = payload
        },
    },
    actions: {
        setEventos(context, payload) {
            context.commit('setEventos', payload)
        },
        loadEventos(context, payload) {
            let gp = payload ? `?clientId=${payload}` : ''
            axios(`/agenda${gp}`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`,
                        element.value = element.id
                });
                context.commit('setEventos', resp.data)
            });
        },
        saveEvent(context, grupo) {
            const metodo = grupo.id ? "put" : "post";
            const finalUrl = grupo.id ? `/${grupo.id}` : "";
            axios[metodo](`/agenda${finalUrl}`, grupo).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Evento salvo com Sucesso");
                context.dispatch('loadEventos')
            });
        },
        deletEvent(context, grupo) {
            axios.delete(`/agenda/${grupo.id}`).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Evento Deletado com Sucesso");
                context.dispatch('loadEventos')
            });
        },
    },
    getters: {
        // Conflito com Eventos
        //getEvents(state) {
        //    return state.eventos
        //},
    },
}