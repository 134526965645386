<template>
  <div class="cliente">
    <ClienteCard :cliente="cliente" @refresh="refresh" />
    <v-card>
      <v-tabs fixed-tabs background-color="success" show-arrows class="rounded-b-0">
        <v-tab><v-icon class="mr-1">mdi-leaf</v-icon>Tarefas ({{getTarefas.length}})</v-tab>
        <v-tab-item>
          <CliTarefas :tarefas="getTarefas" :ambientes="getAmbientes" />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-map-marker</v-icon>Ambientes ({{getAmbientes.length}})</v-tab>
        <v-tab-item>
          <CliAmbientes :ambientes="getAmbientes" />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ClienteCard from "./components/ClienteCard";
import CliTarefas from "./components/CliTarefas.vue";
import CliAmbientes from './components/CliAmbientes.vue';
import { mapGetters } from "vuex";

export default {
  name: "ClienteFacilities",
  components: {
    ClienteCard,
    CliTarefas,
    CliAmbientes,
  },
  computed: {
    ...mapGetters(["getTarefas", "getAmbientes"]),
    cliente: {
      get() {
        return this.$store.getters.getCliente;
      },
    },
  },
  data: () => {
    return {
    };
  },
  methods: {
    refresh() {
      this.$store.dispatch("loadTarefas", this.$store.getters.getCliente.id);
      this.$store.dispatch("loadAmbientes", this.$store.getters.getCliente.id);
      this.$store.dispatch("loadDispositivos", this.$store.getters.getCliente.groupId);
    },
  },
  created() {
    this.refresh();
  },
};
</script>