import axios from 'axios'
import { queryStringBuilder } from '../../Utils/queryStringBuilder'
export default {
    state: {
        fleets: [],
        fleetsMulti: [],
        fleetsStarted: [],
        fleetsReport: [],
        fleetsReportM: [],
        fleet: {},
        lastKmFleet: 0,
        lastDriver: 0
    },
    mutations: {
        setFleets(state, payload) {
            state.fleets = payload
        },
        setFleetsMulti(state, payload) {
            state.fleetsMulti = payload
        },
        setFleet(state, payload) {
            state.fleets = payload
        },
        setFleetStarted(state, payload) {
            state.fleetsStarted = payload
        },
        setFleetReport(state, payload) {
            state.fleetsReport = payload
        },
        setFleetReportM(state, payload) {
            state.fleetsReportM = payload
        },
        setFleetLastKm(state, payload) {
            state.lastKmFleet = payload
        },
        setFleetLastDriver(state, payload) {
            state.lastDriver = payload
        },
    },
    actions: {
        setFleets(context, payload) {
            context.commit('setFleets', payload)
        },
        setFleetsMulti(context, payload) {
            context.commit('setFleetsMulti', payload)
        },
        setFleet(context, payload) {
            context.commit('setFleet', payload)
        },
        setFleetStarted(context, payload) {
            context.commit('setFleetStarted', payload)
        },
        setFleetReport(context, payload) {
            context.commit('setFleetReport', payload)
        },
        setFleetReportM(context, payload) {
            context.commit('setFleetReportM', payload)
        },
        setFleetLastKm(context, payload) {
            context.commit('setFleetLastKm', payload)
        },
        setFleetLastDriver(context, payload) {
            context.commit('setFleetLastDriver', payload)
        },
        loadFleets(context, payload) {
            context.commit('setClientId', payload)
            let aux = payload ? `?clientId=${payload}` : ''
            axios(`frota${aux}`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`,
                        element.value = element.id
                });
                context.commit('setFleets', resp.data)
            });
        },
        loadFleetsMultiStarted(context) {
            axios(`frotaM/started`).then((resp) => {
                if (resp.status < 299) {
                    context.commit('setFleetsMulti', resp.data)
                }
            });
        },
        loadFleetsLastKm(context, payload) {
            axios(`frota/lastKm/${payload}`).then((resp) => {
                context.commit('setFleetLastKm', resp.data.kmEnd)
                context.commit('setFleetLastDriver', resp.data.driverId)
            });
        },
        loadFleetsStarted(context, payload) {
            context.commit('setClientId', payload)
            let aux = payload ? `?clientId=${payload}` : ''
            axios(`frota/started${aux}`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                });
                context.commit('setFleetStarted', resp.data)
            });
        },
        loadFleetsStarted2(context, payload) {
            context.commit('setClientId', payload)
            let aux = payload ? `?clientId=${payload}` : ''
            axios(`frota2/started${aux}`).then((resp) => {
                context.commit('setFleetStarted', resp.data)
            });
        },
        loadFleetsReport(context, payload) {

            // let aux = payload ? `?clientId=${payload}` : ''
            let urlQuery = ''
            for (let valor in payload) {
                urlQuery += `&${valor}=${payload[valor]}`
            }
            console.log(urlQuery);
            // axios(`frota/started${aux}`).then((resp) => {
            //     resp.data.forEach(element => {
            //         element.text = `${element.id} - ${element.name}`,
            //             element.value = element.id
            //     });
            //     context.commit('setFleetReport', resp.data)
            // });
        },
        loadFleetsMReport(context, payload) {
            let url = `relatorios/frotaM?`;
            // gerador de query para relatorio
            url = queryStringBuilder(url, payload);

            axios(url).then((resp) => {
                context.commit("setFleetReportM", resp.data);
                if (resp.data.length == 0) {
                    context.dispatch("resetSnack");
                    context.dispatch("showErrorSnack", "Nenhum registro encontrado de acordo com o filtro");
                }
            });
        },
        // request do relatorio de frota simples
        loadFleetsSReport(context, payload) {
            let url = `relatorios/frota2?`;
            // gerador de query para relatorio
            url = queryStringBuilder(url, payload);

            axios(url).then((resp) => {
                context.commit("setFleetReport", resp.data);
                if (resp.data.length == 0) {
                    context.dispatch("resetSnack");
                    context.dispatch("showErrorSnack", "Nenhum registro encontrado de acordo com o filtro");
                }
            });
        },
        finishFleet(context, payload) {
            const finalUrl = payload.id ? `${payload.id}` : "";
            axios.put(`frota/finish/${finalUrl}?clientId=${context.getters.getClientId}`, payload).then((resp) => {
                if (resp.status == 200) {

                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Frota Finalizada com Sucesso");
                    context.dispatch('loadFleetsStarted', context.getters.getClientId)
                }
            });
        },
        saveFleet(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";

            if (typeof payload.picture === 'object') {
                let formData = new FormData();
                for (let valor in payload) {
                    formData.append(valor, payload[valor])
                }
                axios[metodo](`frota${finalUrl}?clientId=${context.getters.getClientId}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then(() => {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Frota salvo com Sucesso");
                    context.dispatch('loadFleets', context.getters.getClientId)
                });
            } else {
                axios[metodo](`frota${finalUrl}?clientId=${context.getters.getClientId}`, payload).then(() => {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Frota salvo com Sucesso");
                    context.dispatch('loadFleetsStarted', context.getters.getClientId)
                });
            }
        },
        deletFleet(context, payload) {
            axios.delete(`frota/${payload.id}?clientId=${context.getters.getClientId}`).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Frota Deletado com Sucesso");
                context.dispatch('loadFleets', context.getters.getClientId)
            });
        },
        registrarInicioFrota(context, payload) {
            let data = payload;
            let callback = payload.callback
            // removendo os dados do base64
            delete data.base64;
            delete data.callback;
            let formData = new FormData();
            // adicionando os arquivos se existirem
            if (Array.isArray(data.images) && data.images.length > 0) {
                data.images.forEach((ele, index) => {
                    formData.append(`file${index}`, ele);
                });
            }
            // removendo os arquivos do data
            delete data.images;
            // adicionando os dados do veiculo
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const element = data[key];
                    formData.append(key, element);
                }
            }

            axios.post(`frotaM2`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch('loadFleetsMultiStarted');
                    if (typeof callback == 'function') {
                        callback();
                    }
                }
            });
        },
        regInicioFrota(context, payload) {
            let data = payload;
            let callback = payload.callback
            // removendo os dados do base64
            delete data.base64;
            delete data.callback;
            let formData = new FormData();
            // adicionando os arquivos se existirem
            if (Array.isArray(data.images) && data.images.length > 0) {
                data.images.forEach((ele, index) => {
                    formData.append(`file${index}`, ele);
                });
            }
            // removendo os arquivos do data
            delete data.images;
            // adicionando os dados do veiculo
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const element = data[key];
                    formData.append(key, element);
                }
            }

            axios.post(`frota2`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch('loadFleetsStarted2');
                    if (typeof callback == 'function') {
                        callback();
                    }
                }
            });
        },
        regFimFrota(context, payload) {
            let data = {...payload};
            let callback = payload.callback
            // removendo os dados do base64
            delete data.base64;
            delete data.callback;
            let formData = new FormData();
            // adicionando os arquivos se existirem
            if (Array.isArray(data.images) && data.images.length > 0) {
                data.images.forEach((ele, index) => {
                    formData.append(`file${index}`, ele);
                });
            }
            // removendo os arquivos do data
            delete data.images;
            // adicionando os dados do veiculo
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const element = data[key];
                    formData.append(key, element);
                }
            }

            axios.put(`frota2/finish/${payload.id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch('loadFleetsStarted2');
                    if (typeof callback == 'function') {
                        callback();
                    }
                }
            });
        },
        registrarFimFrota(context, payload) {
            let data = {...payload};
            let callback = payload.callback
            // removendo os dados do base64
            delete data.base64;
            delete data.callback;
            let formData = new FormData();
            // adicionando os arquivos se existirem
            if (Array.isArray(data.images) && data.images.length > 0) {
                data.images.forEach((ele, index) => {
                    formData.append(`file${index}`, ele);
                });
            }
            // removendo os arquivos do data
            delete data.images;
            // adicionando os dados do veiculo
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const element = data[key];
                    formData.append(key, element);
                }
            }

            axios.put(`frotaM2/finish/${payload.id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch('loadFleetsMultiStarted');
                    if (typeof callback == 'function') {
                        callback();
                    }
                }
            });
        },
    },
    getters: {
        getFleets(state) {
            return state.fleets
        },
        getFleetsMultiStarted(state) {
            return state.fleetsMulti
        },
        getFleetStarted(state) {
            return state.fleetsStarted
        },
        getFleetReport(state) {
            return state.fleetsReport
        },
        getFleetReportM(state) {
            return state.fleetsReportM
        },
        getFleet(state) {
            return state.fleet
        },
        getFleetLastKmFleet(state) {
            return state.lastKmFleet
        },
        getFleetLastDriverIdFleet(state) {
            return state.lastDriver
        },
        getFleetHeaders() {
            return [
                { text: "ID", value: "id" },
                {
                    text: "Imagem",
                    align: "center",
                    filterable: false,
                    value: "picture",
                },
                { text: "Prefixo", value: "prefix" },
                { text: "Modelo", value: "model" },
                { text: "Placa", value: "plate" },
                { text: "Descrição", value: "description" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        }
    },
}