var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.report)?[_c('v-card-title',{staticClass:"pt-6"},[_vm._v("Rondas Virtuais")]),_c('v-card-subtitle',[_vm._v("Clique na tabela para mais detalhes")])]:_vm._e(),_c('v-card-text',[_c('DataTableGeneric',{attrs:{"headers":_vm.headers,"items":_vm.items},on:{"headersChanged":function($event){return _vm.$emit('headersChanged', $event)},"rowClick":_vm.openModalRondasVirtuais},scopedSlots:_vm._u([{key:"item.finished",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.finished ? "Sim" : "Não"))])]}},{key:"item.horario",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formataData(item.createdAt).split(", ")[1]))])]}},{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.horarioId ? "Execução Agendada" : "Execução Extra"))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDia(item.createdAt)))])]}},{key:"item.payload.questions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.payload.questions ? item.payload.questions.length : '0')+" Questões Registradas")]),_c('v-menu',{attrs:{"open-on-hover":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.payload.questions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.formataQuestion(item)))])],1)],1)}),1)],1)]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getUserNameById(item.userId).split(" - ")[1]))])]}},{key:"item.clientId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getClienteNameById(item.clientId).split(" - ")[1]))])]}},{key:"item.payload.observacao",fn:function(ref){
var item = ref.item;
return [_c('TextTooltip',{attrs:{"text":item.payload.observacao? item.payload.observacao : ''}})]}}])})],1),_c('DialogRondaVirtual',{ref:"dialogRonda"}),(!_vm.report)?_c('ConfirmDialog',{ref:"confirmDialog"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }