var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.report)?[_c('v-card-title',{staticClass:"pt-6"},[_vm._v("Eventos")]),_c('v-card-subtitle',[_vm._v("Clique na tabela para mais detalhes")])]:_vm._e(),_c('v-card-text',[_c('DataTableGeneric',{attrs:{"headers":_vm.headers,"items":_vm.items},on:{"headersChanged":function($event){return _vm.$emit('headersChanged', $event)},"rowClick":_vm.openModalEvento},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formataData(item.createdAt)))])]}},{key:"item.startedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.startedAt ? _vm.formataData(item.startedAt) : ''))])]}},{key:"item.finishedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.finishedAt ? _vm.formataData(item.finishedAt) : ''))])]}},{key:"item.deviceId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.deviceId ? _vm.getDispositivoNameById(item.deviceId) : "Dispositivo não encontrado"))])]}},{key:"item.createdUserId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.verificaUsuarios(item.createdUserId)))])]}},{key:"item.finishedUserId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.finishedUserId ? _vm.getUserNameById(item.finishedUserId) : ''))])]}},{key:"item.clientId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getClienteNameById(item.clientId)))])]}},{key:"item.treatment",fn:function(ref){
var item = ref.item;
return [_c('TextTooltip',{attrs:{"text":item.treatment,"maxWidth":'250'}})]}}])})],1),_c('DialogEvento',{ref:"dialogEvento"}),(!_vm.report)?_c('ConfirmDialog',{ref:"confirmDialog"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }