<template>
  <v-card>
    <v-card-title>Dispositivos Cadastrados<v-spacer></v-spacer>
      <v-btn color="warning" @click="dialogLiberar = true">
        <v-icon class="mr-1">mdi-key-change</v-icon>Liberar APP LOCK
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="$store.dispatch('loadDispositivos')">
        <v-icon class="mr-1">mdi-refresh</v-icon>Atualizar
      </v-btn>
    </v-card-title>
    <v-card-text>
      <DataTable :headers="header" :items="getDispositivos">
        <template slot="item.createdAt" slot-scope="{ item }">
          {{ new Date(item.createdAt).toLocaleString() }}
        </template>
        <template slot="item.updatedAt" slot-scope="{ item }">
          {{ new Date(item.updatedAt).toLocaleString() }}
        </template>
        <template slot="item.ativo" slot-scope="{ item }">
          {{ item.enabled ? "Sim" : "Não" }}
        </template>
        <template slot="item.postoName" slot-scope="{ item }">
          {{ getPostoNameByGroupId(item.groupId) }}
        </template>
        <template slot="item.notifyEvents" slot-scope="{ item }">
          {{ item.notifyEvents ? "Sim" : "Não" }}
        </template>
        <template slot="item.version" slot-scope="{ item }">
          {{ item && item.info && item.info.user ? item.info.user.appVersion : "" }}
        </template>
        <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click="editar(item)" color="warning" class="mr-1">mdi-pencil</v-icon>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
          <!-- botão excluir -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click="excluir(item)" color="red">mdi-trash-can</v-icon>
            </template>
            <span>Excluir : {{ item.name }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click="config(item)" color="primary">mdi-cog</v-icon>
            </template>
            <span>Configurar : {{ item.name }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="secondary">mdi-information-outline</v-icon>
            </template>
            <span v-html="showInfo(item)">
            </span>
          </v-tooltip>
        </template>
      </DataTable>
    </v-card-text>

    <!-- dialog de cadastro -->
    <v-dialog v-model="dialog" persistent max-width="700" scrollable>
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2">{{ dispositivo.id ? "Editar" : "Cadastrar" }}
          Dispositivo<v-spacer></v-spacer>
          <v-btn icon @click="clear">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-form ref="formDispositivo" :disabled="!dispositivo.enabled">
            <v-row>
              <v-col class="pb-0">
                <v-text-field v-model="dispositivo.name" label="Nome do Dispositivo"
                  hint="Nome para identificar este Dispositivo" required :rules="requiredField" outlined></v-text-field>
              </v-col>
              <v-col class="pb-0">
                <v-autocomplete label="Posto do Dispositivo" :items="postosList" v-model="dispositivo.groupId"
                  item-text="name" item-value="groupId" outlined>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-text-field v-model="dispositivo.identificador" label="Identificador do Dispositivo" required
                  :rules="requiredField" outlined></v-text-field>
              </v-col>
              <v-col class="py-0">
                <v-text-field v-model="dispositivo.phone" label="Número de telefone do Dispositivo" v-mask="celMask"
                  outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <v-text-field v-model="dispositivo.description" rows="1" label="Descrição do Dispositivo"
                  outlined></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <v-switch v-model="dispositivo.enabled" label="Ativo" required outlined class="mt-0 pt-0"></v-switch>
              </v-col>
              <v-col class="pt-0">
                <v-switch v-model="dispositivo.notifyEvents" label="Monitorar Status" required outlined
                  class="mt-0 pt-0"></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="clear">Cancelar</v-btn>
          <v-btn color="success" @click="save"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog de liberar senha -->
    <v-dialog v-model="dialogLiberar" persistent max-width="700">
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2">Gerador de senha para o dispositivo<v-spacer></v-spacer>
          <v-btn icon @click="dialogLiberar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-row>
            <v-col class="pb-0">
              <v-text-field v-model="password" label="Codigo informado pelo APP LOCK"
                hint="Codigo informado pelo APP LOCK" required :rules="reqFieldMinMaxLength(6, 6)" outlined
                @input="genAwsPassword" type="number" maxlength="6"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="answer" label="Codigo para liberar APP LOCK" outlined readonly
                append-icon="mdi-content-copy" @click:append="copyUrl"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="closePassDialog">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="amber darken-1" dark absolute bottom right fab fixed class="mb-10" v-bind="attrs" v-on="on"
          @click="dialog = true">
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Dispositivo</span>
    </v-tooltip>
    <ConfirmDialog ref="confirmDialog" />
    <DialogConfig ref="dialogConfig" />
  </v-card>
</template>

<script>
import { requiredField, reqFieldMinMaxLength } from "../../Utils/InputValidation";
import DataTable from "../DataTableGeneric";
import ConfirmDialog from "@/components/ConfirmDialog";
import DialogConfig from "./DialogConfigApp.vue";
import { mapGetters } from "vuex";

export default {
  name: "AguardandoLiberacao",
  components: {
    DataTable,
    ConfirmDialog,
    DialogConfig,
  },
  data: () => {
    return {
      dialog: false,
      dialogConfig: false,
      dialogLiberar: false,
      password: "",
      answer: "",
      dispositivo: {
        id: null,
        enabled: true,
        notifyEvents: true,
      },
      dispositivos: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Identificador", value: "identificador" },
        { text: "Numero", value: "phone" },
        { text: "Descrição", value: "description" },
        { text: "Posto", value: "postoName" },
        { text: "Grupo", value: "groupId" },
        { text: "Primeiro Cadastro", value: "createdAt" },
        { text: "Editado", value: "updatedAt" },
        { text: "Monitorar Status", value: "notifyEvents" },
        { text: "Versão", value: "version" },
        { text: "Ativo", value: "ativo" },
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          filterable: false,
          width: 85,
        },
      ],
      requiredField,
      reqFieldMinMaxLength,
      celMask: "",
    };
  },
  computed: {
    ...mapGetters(["getDispositivos", "getPostos"]),
    postosList() {
      if (!this.getPostos) return [];
      return this.getPostos.filter((item) => item.enabled);
    },
  },
  methods: {
    getPostoNameByGroupId(groupId) {
      let posto = this.getPostos.find((item) => item.groupId == groupId);
      return posto ? posto.name : "SEM POSTO VINCULADO";
    },
    save() {
      if (!this.$refs.formDispositivo.validate()) return;
      delete this.dispositivo.createdAt;
      delete this.dispositivo.updatedAt;

      this.dispositivo.callback = () => {
        this.dialog = false;
      };

      this.$store.dispatch("saveDispositivo", this.dispositivo);
    },
    editar(rec) {
      this.dispositivo = rec;
      this.dialog = true;
    },
    config(rec) {
      this.$refs.dialogConfig.open(rec);
    },
    excluir(item) {
      this.$refs.confirmDialog.open({
        title: "Excluir Dispositivo",
        text: `Deseja excluir o dispositivo <strong>${item.name}</strong>?`,
        confirmCallback: () => {
          this.$store.dispatch("deletDispositivo", item);
        },
      });
    },
    groupName(id) {
      if (!id) {
        return "Sem Grupo";
      }
      let gp = this.$store.state.grupos;
      return gp.find((g) => g.id == id).name;
    },
    clear() {
      this.dispositivo = {
        id: null,
        enabled: true,
      };
      this.dialog = false;
      this.celMask = "";
      this.$refs.formDispositivo.resetValidation();
    },
    genAwsPassword(password) {
      if (password.length == 6) {
        this.genAuth(password);
      } else {
        this.answer = "";
      }
    },
    closePassDialog() {
      this.dialogLiberar = false
      this.password = ""
      this.answer = ""
    },
    genAuth(pass) {
      let str = `${pass}`;
      // gerando verificador
      let contra = "";
      contra += parseInt(Math.pow(parseInt(str[0]), 2));
      contra += parseInt(parseInt(str[1]) * 3);
      contra += parseInt(Math.pow(parseInt(str[2]), 3));

      this.answer = contra;
    },
    copyUrl() {
      if (this.answer == "") return;
      navigator.clipboard.writeText(this.answer);
      this.$store.dispatch("resetSnack");
      this.$store.dispatch(
        "showSuccessSnack",
        `Codigo copiado para a área de transferência!`
      );
    },
    showInfo(item) {
      if(item && item.info && item.info.user){
        return `
          <div>
            <span>
              <strong>
                Usuário:
              </strong>
            </span>
            <span>${item.info.user.name}</span>
          </div>
          <div>
            <span>
              <strong>
                Versão:
              </strong>
            </span>
            <span>${item.info.user.appVersion}</span>
          </div>
          <div>
            <span>
              <strong>
                Bateria:
              </strong>
            </span>
            <span>${item.info.status ? item.info.status.batteryLevel : ""}%</span>
          </div>
        `
      }
      return "Sem informações"
    },
  },
  created() {
    this.$store.dispatch("loadDispositivos");
    this.$store.dispatch("loadPostos");
  },
  watch: {
    dialog(value) {
      if (value && !this.dispositivo.id) this.celMask = "(##) ####-#####"; // Cria a máscara na abertura do dialog p/ contornar bug do v-mask
    },
  },
};
</script>

<style></style>