<template>
  <div>
    <v-card>
      <v-card-title> Relatório de Frota </v-card-title>
      <v-card-text>
        <!-- linha do filtro -->
        <v-card outlined>
          <v-card-text class="pb-0">
            <p class="text-subtitle-1 pt-1 pb-3">Filtros</p>
            <v-form ref="formReport">
              <!-- linha dos input -->
              <v-row>
                <v-col class="py-0">
                  <AutComImg
                    v-model="relatorio.driverIds"
                    :items="getDriversSelect"
                    label="Selecione os Motoristas iniciais"
                  />
                </v-col>
                <v-col class="py-0">
                  <AutComImg
                    v-model="relatorio.fleetVehicleIds"
                    :items="getFleetsSelect"
                    label="Selecione os Veículos iniciais"
                  />
                </v-col>
              </v-row>
              <!-- linha dos input -->
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model="relatorio.start"
                    label="Data Inicial"
                    required
                    outlined
                    type="datetime-local"
                    @input="$refs.relatorioEnd.validate()"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field
                    ref="relatorioEnd"
                    v-model="relatorio.end"
                    label="Data Final"
                    required
                    outlined
                    type="datetime-local"
                    :rules="[v => validaDataFinal(v) || 'A data final deve ser maior que a inicial']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-4">
            <MenuExportReport
              :disabled="!getFleetReport.length"
              :items="getFleetReport"
              module="fleetSimple"
              title="Relatório de Frotas"
              filename="relatorio_de_frotas"
            />
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="loadReport">
              <v-icon class="mr-1">mdi-magnify</v-icon> Buscar Relatório
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
      <TableFrotaSimples
        :headers="headers"
        :items="getFleetReport"
        :report="true"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AutComImg from "@/components/Relatorios/AutoCompleteImages.vue";
import TableFrotaSimples from "@/components/Frota/TableFleetSimple";
import MenuExportReport from "@/components/Relatorios/MenuExportReport.vue";
export default {
  name: "RelatorioVeiculo",
  components: {
    AutComImg,
    TableFrotaSimples,
    MenuExportReport,
  },
  props: ["cliente"],
  computed: {
    ...mapGetters(["getFleetReport", "getFleetsSelect", "getDriversSelect"]),
  },
  data() {
    return {
      headers: [
        { text: "Imagens Iniciais", value: "picturesStart", align:'center', sortable: false, filterable: false, width: 70 },
        { text: "Motorista", value: "driver", width: 300 },
        { text: "Veículo", value: "vehicle", width: 300 },
        { text: "Descrição", value: "description", width: 200 },
        { text: "Imagens Finais", value: "picturesExit", align:'center', sortable: false, filterable: false, width: 70 },
        { text: "Sentido", value: "direction" },
        { text: "Início", value: "createdAt" },
        { text: "Fim", value: "updatedAt" },
        { text: "Tempo", value: "timeDif" },
        { text: "Km Inicial", value: "kmStart" },
        { text: "Km Final", value: "kmEnd" },
        { text: "Km Percorrido", value: "kmDif" },
      ],
      relatorio: {
        clientId: this.cliente ? this.cliente.id : undefined,
        withPictures: true,
        driverIds: [],
        finalDriverIds: [],
        fleetVehicleIds: [],
        finalFleetVehicleIds: [],
        description: "",
        start: "",
        end: "",
        kmStart: "",
        kmEnd: "",
      },
    };
  },
  methods: {
    loadReport() {
      if (this.$refs.formReport.validate()){
        this.relatorio.groupId = this.$store.getters.getCliente.groupId;
        this.$store.dispatch("loadFleetsSReport", this.relatorio);
      }
    },
    calcDiff(item) {
      return Math.abs(item.kmEnd - item.kmStart);
    },
    validaDataFinal(dataFinal) { // função auxiliar pois este teste direto no rules não funciona corretamente
      return new Date(dataFinal) > new Date(this.relatorio.start);
    },
  },
  created() {
    this.$store.dispatch("setFleetReport", []);
    this.$store.dispatch("loadDrivers", this.$store.getters.getCliente.id);
    this.$store.dispatch("loadFleetsVehicles", this.$store.getters.getCliente.id);
    // pega a data atual e seta no relatorio
    let now = new Date();
    let onlyDate = now.toISOString().substring(0, 11);
    this.relatorio.start = onlyDate + "00:00";
    this.relatorio.end = onlyDate + "23:59";
  },
};
</script>

<style>
</style>