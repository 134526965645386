<template>
  <div class="userCRUD">
    <v-card class="mb-1" v-if="formUsr || tipo.id">
      <v-card-title>
        {{ tipo.id ? "Edição Tipo" : "Cadastro de Tipo" }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="tipo.name"
          :counter="255"
          label="Nome"
          required
        ></v-text-field>

        <v-textarea
          v-model="tipo.description"
          name="input-7-1"
          :counter="255"
          label="Descrição"
        ></v-textarea>

        <v-checkbox v-model="tipo.enabled" label="Ativo" required></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" class="mr-4" @click="clearObj"
          >Limpar Campos</v-btn
        >
        <v-btn color="success" class="mr-4" @click="save(tipo)">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="div d-flex justify-end ma-2" v-else>
      <v-btn color="success" @click="formUsr = true">Cadastrar</v-btn>
    </div>
    <v-card>
      <v-card-text>
        <div class="tbl mt-1">
          <Table
            :items="getClientTypes"
            :headers="getClientTypesHeaders"
            :enablePicture="true"
            @edit="editar($event)"
            @delete="delet($event)"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Table from "../../TableGeneric";
import { mapGetters} from 'vuex'
export default {
  data() {
    return {
      formUsr: false,
      tipo: {
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters(["getClientTypes","getClientTypesHeaders"])
  },
  components: {
    Table,
  },
  methods: {
    get() {
      this.edicao = false;
      this.$store.dispatch("loadAllClientTypes");
    },
    save(objeto) {
      this.$store.dispatch("saveClientType", objeto);
      setTimeout(() => {
        this.$store.dispatch("loadAllClientTypes");
      }, 500);
    },
    editar(event) {
      this.tipo = event;
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
    delet(event) {
      this.$store.dispatch("deletClientType", event);
      this.get();
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
    findGroupName(id) {
      let groups = this.$store.getters.getGroups;
      let gp = groups.find((e) => e.id == id);
      return gp ? `${gp.id} - ${gp.name}` : "";
    },
  },
  created() {
    this.get();
  },
};
</script>

<style>
</style>