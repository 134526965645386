<template>
  <v-app-bar app class="d-print-none topBar" dark>
    <v-toolbar-title>STV Segurança - Sistema de Controle de Acesso</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu offset-y v-if="showMenu">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar class="ma-2" size="45" v-bind="attrs" v-on="on">
          <img :src="user.picture" :alt="user.name" />
        </v-avatar>
        <span>
          {{ user.name }}
        </span>
      </template>
      <v-list dense>
        <v-list-item key="Trocar Senha" to="/myUser">
          <v-list-item-icon class="mr-3"><v-icon>mdi-key-variant</v-icon></v-list-item-icon>
          <v-list-item-title>Editar Usuário</v-list-item-title>
        </v-list-item>
        <v-list-item key="sair" @click="logOff">
          <v-list-item-icon class="mr-3"><v-icon>mdi-logout-variant</v-icon></v-list-item-icon>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "AppBar",
  data: () => {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapState(["user", "grupos", "user"]),
    ...mapGetters(["getUsuario", "getLogged"]),
    workGroup: {
      get() {
        return this.$store.state.workGroup;
      },
      set(value) {
        this.$store.dispatch("setWorkGroup", value);
      },
    },
  },
  methods: {
    ...mapActions(["logOff", "setWorkGroup"]),
    getGrupos() {
      this.$http("grupo").then((resp) => {
        let grupos = resp.data;
        grupos.forEach((element) => {
          element.text = `${element.id} - ${element.name}`;
          element.value = element.id;
        });
        this.$store.commit("setGrupos", grupos);
      });
    },
  },
  watch: {
    getLogged() {
      if (this.getLogged) {
        this.showMenu = true;
      } else {
        this.showMenu = false;
      }
    },
  },
};
</script>
<style scoped>
.topBar{
  background: #005f36 !important;
}
</style>
