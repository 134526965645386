<template>
  <v-card>
    <v-card-title>Alterar Senha</v-card-title>
    <v-card-text>
      <v-form ref="formUser">
        <v-row>
          <!--v-col class="d-flex flex-column justify-center align-center">
            <v-avatar size="270" class="mb-4">
              <v-img
                lazy-src="@/assets/nopic.jpg"
                class="d-flex justify-center"
                id="avatar"
                :src="picture"
                refs="avatar"
              ></v-img>
            </v-avatar>
            <Dialog @base64="img64($event)" />
            <span class="d-block mb-4 lh-05">ou</span> 
            <v-file-input
              v-model="tmpFile"
              placeholder="Fazer upload da imagem"
              prepend-icon="mdi-image-area"
              outlined
              dense
              hide-details
              @change="pickFile"
            >
            </v-file-input>
          </v-col-->
          <v-col class="d-flex flex-column justify-center align-center mb-6">
            <v-card class="pa-2" outlined tile>
              <v-img
                class="d-flex justify-center"
                id="avatar"
                :src="user.picture"
                refs="avatar"
                width="250"
                :height="250 * 0.75"
              ></v-img>
            </v-card>

            <Dialog @base64="img64($event)" />
            <v-btn @click="enviaFoto" color="success" class="ma-1">Salvar Imagem</v-btn>
          </v-col>
          <v-col>
            <v-text-field
              label="Usuário"
              disabled
              v-model="user.userName"
              outlined
            ></v-text-field>
            <v-text-field
              label="Nome"
              disabled
              v-model="user.name"
              outlined
            ></v-text-field>
            <v-text-field
              label="Descrição"
              disabled
              v-model="user.description"
              outlined
            ></v-text-field>

            <v-text-field
              label="Nova Senha"
              v-model="senha"
              outlined
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              :rules="[
                () => !!senha || 'Campo obrigatório',
                () => senha.length > 2 || 'Minimo 3 caracteres',
              ]"
              @click:append="showPass = !showPass"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="px-4 pb-4 pt-3">
      <v-spacer></v-spacer>
      <v-btn color="success" @click="change">Salvar Senha</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Dialog from "../Webcam/WbDialog";
export default {
  name:"ChagePasswordAdm",
  props: ["editar"],
  components: {
    Dialog,
  },
  data: () => {
    return {
      showPass: false,
      user:{},
      senhaAtual: "",
      senha: "",
      confirmacao: "",
      picture: "",
      tmpFile: null,
    };
  },
  methods: {
    img64(IMGbase64) {
      this.user.picture = IMGbase64;
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    enviaFoto() {
      let imageFile = this.dataURItoBlob(this.user.picture);
      let bodyFormData = new FormData();
      bodyFormData.append("picture", imageFile);
      bodyFormData.append("id", this.editar.id);
      this.$http
        .post("/usuario/upload", bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch("showSuccessSnack", "Foto Enviada");
        });
    },
    change() {
      if (this.senha && this.senha.length > 2) {
        this.$http
          .put(`/usuario/passADM`, { newPassword: this.senha , id: this.editar.id })
          .then((resp) => {
            if(resp.status === 200){
              this.senha = "";
              this.confirmacao = "";
              this.$store.dispatch("resetSnack");
              this.$store.dispatch(
                "showSuccessSnack",
                "Senha alterada com sucesso!"
              );
            }
          });
      } else {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch(
          "showErrorSnack",
          "Senhas não conferem ou menor que 3 digitos"
        );
      }
    },
  },
  // computed: {
  //   user: {
  //     get() {
  //       return this.$store.state.user;
  //     },
  //   },
  // },
  mounted(){
    console.log(this.editar);
    this.user = this.editar
  }
};
</script>

<style>
</style>