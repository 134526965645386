import axios from 'axios'
import { queryStringBuilder } from '../../Utils/queryStringBuilder'

export default {
  state: {
    tarefas: [],
    ambientes: [],
    relTarefas: []
  },
  mutations: {
    setTarefas(state, payload) {
      state.tarefas = payload
    },
    setAmbientes(state, payload) {
      state.ambientes = payload
    },
    setRelTarefas(state, payload) {
      state.relTarefas = payload
    }
  },
  actions: {
    setTarefas(context, payload) {
      context.commit('setTarefas', payload)
    },
    setAmbientes(context, payload) {
      context.commit('setAmbientes', payload)
    },
    setRelTarefas(context, payload) {
      context.commit('setRelTarefas', payload)
    },
    loadTarefas(context, payload) {
      let gp = payload ? `?clientId=${payload}` : ''
      axios(`/tarefa${gp}`).then((resp) => {
        context.dispatch('setTarefas', resp.data)
      });
    },
    loadAmbientes(context, payload) {
      let gp = payload ? `?clientId=${payload}` : ''
      axios(`/ambiente${gp}`).then((resp) => {
        context.dispatch('setAmbientes', resp.data)
      });
    },
    loadReportTarefas(context, payload) { 
      let url = `/tarefa/run/report?`;
      // gerador de query para relatorio
      url = queryStringBuilder(url, payload);
      axios(url).then((resp) => {
        context.commit("setRelTarefas", resp.data);
        if (resp.data.length == 0) {
          context.dispatch("resetSnack");
          context.dispatch("showErrorSnack", "Nenhum registro encontrado de acordo com o filtro");
        }
      });
    },
    saveTarefa(context, payload) {
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/tarefa${finalUrl}`, payload).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Tarefa salva com Sucesso");
          context.dispatch("loadTarefas", payload.clientId);
          if (typeof payload.callback == 'function')
            payload.callback(resp.data);
        }
      });
    },
    saveAmbiente(context, payload) {
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/ambiente${finalUrl}`, payload).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Ambiente salvo com Sucesso");
          context.dispatch("loadAmbientes", payload.clientId);
          context.dispatch("loadCheckpoints", payload.clientId);
          if (typeof payload.callback == 'function')
            payload.callback(resp.data);
        }
      });
    },
    deleteTarefa(context, payload) {
      axios.delete(`/tarefa/${payload.id}`).then(() => {
        context.dispatch("resetSnack");
        context.dispatch("showSuccessSnack", "Tarefa Deletada com Sucesso");
        context.dispatch("loadTarefas", payload.clientId);
      });
    },
    deleteAmbiente(context, payload) {
      axios.delete(`/ambiente/${payload.id}`).then(() => {
        context.dispatch("resetSnack");
        context.dispatch("showSuccessSnack", "Ambiente deletado com Sucesso");
        context.dispatch("loadAmbientes", payload.clientId);
      });
    },
  },
  getters: {
    getTarefas(state) {
      return state.tarefas
    },
    getAmbientes(state) {
      return state.ambientes
    },
    getRelTarefas(state) {
      return state.relTarefas
    },
    getTarefaNameById: (state) => (id) => {
      if (!state.tarefas) {
          return id
      }
      let gp = state.tarefas.find(g =>
          g.id == id
      )
      return gp ? `${gp.id} - ${gp.name}` : ''
    },
    getAmbienteNameById: (state) => (id) => {
      if (!state.ambientes) {
          return id
      }
      let gp = state.ambientes.find(g =>
          g.id == id
      )
      return gp ? gp.name : ''
    },
  },
}