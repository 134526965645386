<template>
  <div class="home ma-2">
    <v-card class="ma-2">
      <v-card-title>Ops!</v-card-title>
      <v-card-text>
        <v-col>
          <v-row> Você não tem acesso ao sistema pelo navegador!!! </v-row>
          <v-row>
            Nesta pagina voce pode clicar no circulo do lado do seu nome para
            trocar a senha ou ainda a sua foto, a operação não esta disponivel
          </v-row>
        </v-col>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Logout",
};
</script>
