<template>
  <div class="recursos">
    <div class="form">
      <v-card class="mx-auto py-2" elevation="5" outlined>
        <v-card-title>
          Cadastro de Menus
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="getAcesso(false)"> Atualizar </v-btn>
        </v-card-title>
        <v-form>
          <v-card-text>
            <v-row>
              <v-col cols="1">
                <v-checkbox v-model="menu.enabled" label="Ativo"></v-checkbox>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="menu.order"
                  label="Ordem no Menu"
                  hint="Ordem que sera exibido"
                  required
                  persistent-hint
                  type="number"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="menu.name"
                  :counter="50"
                  label="Texto do Menu"
                  hint="Digite como sera exibido o nome desde Menu"
                  persistent-hint
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="7">
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="menu.icon"
                      counter
                      label="Icone"
                      hint="Usar a String do Flutter"
                      persistent-hint
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="menu.color"
                      counter
                      label="Cor"
                      hint="Usar a String do Flutter"
                      persistent-hint
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="3">
                    <v-text-field
                      v-model="menu.size"
                      counter
                      label="Tamanho"
                      hint="Usar a String do Flutter"
                      persistent-hint
                      outlined
                      required
                    ></v-text-field>
                  </v-col> -->
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :items="routes"
                  v-model="menu.to"
                  outlined
                  @change="showMeta"
                  label="Selecione a Rota"
                  hint="Selecione a rota que o menu envia"
                  persistent-hint
                  prepend-inner-icon="mdi-plus"
                  @click:prepend-inner="dialogRoutes = true"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="menu.description"
                  rows="1"
                  label="Descrição"
                  hint="Digite uma descrição se necessario"
                  :counter="255"
                  outlined
                  persistent-hint
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="menu.nvAcessoId"
                  :items="niveis"
                  label="Nivel de Acesso"
                  hint="Selecione o Nivel de acesso"
                  outlined
                  required
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-show="meta">
                <Configuration
                  :configuration="meta"
                  @newConfig="newConfig"
                  :parametros="menu.parameters"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="json"
                  rows="1"
                  hint="Json que sera enviado para o from nessa rota deste nivel"
                  persistent-hint
                  counter
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="warning" class="mr-2" @click="clear">
              Cancelar
            </v-btn>
            <v-btn depressed color="success" @click="save"> Salvar </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <v-card class="my-1">
        <v-card-title>
          Filtro
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="showFilter = !showFilter">
            {{ showFilter ? "Esconder" : "Mostrar" }} Filtro
          </v-btn></v-card-title
        >
        <v-card-text v-show="showFilter">
          {{ filtro }}
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="filtro.nvAcessoId"
                label="Nivel de Acesso"
                hint="Filtra pelo Nivel de Acesso"
                :items="niveis"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filtro.to"
                :items="routes"
                label="Rota"
                hint="Filtrar pela Rota"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-show="showFilter">
          <v-spacer></v-spacer>
          <v-btn depressed color="warning" @click="filtro = {}"> Limpar </v-btn>
          <v-btn depressed color="primary" @click="get(true)"> Filtrar </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="my-1">
        <v-card-title>
          Copiar Nivel
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="showCopy = !showCopy">
            {{ showCopy ? "Esconder" : "Mostrar" }} Copiar
          </v-btn>
        </v-card-title>
        <v-card-text v-show="showCopy">
          {{ copy }}
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="copy.copy"
                label="Selecione o Nivel"
                hint="Nivel que será o Modelo"
                :items="niveis"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="copy.paste"
                :items="niveis"
                label="Selecione o Nivel"
                hint="Nivel que recebera os dados"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-show="showCopy">
          <v-spacer></v-spacer>
          <v-checkbox
            class="mx-1"
            label="Limpar antes de Copiar"
            v-model="copy.clearBefore"
          ></v-checkbox>
          <v-btn depressed color="warning" @click="copyData()"> Copiar </v-btn>
        </v-card-actions>
      </v-card>
      <AppRoutes
        :dialog="dialogRoutes"
        @close="dialogRoutes = false"
        :appRoutes="routes"
        @updateRoutes="getRoutes"
      />

      <v-card class="my-1">
        <v-card-text>
          <Table
            :headers="header"
            :items="menus"
            @edit="edit($event)"
            :rows="15"
            @delete="delet($event.id)"
          />
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import Table from "../TableGeneric";
import Configuration from "./ConfigurationMenu";
import AppRoutes from "./AppRoutes";
export default {
  name: "Menus",
  components: {
    Table,
    Configuration,
    AppRoutes,
  },
  data: () => {
    return {
      routes: [],
      text: "",
      snackbar: false,
      menu: {
        id: null,
        enabled: true,
        icon: "",
        size: "",
        color: "",
        order: 1,
      },
      niveis: [],
      menus: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Ordem", value: "order" },
        { text: "Texto", value: "name" },
        { text: "Icone", value: "icon" },
        { text: "Cor", value: "color" },
        // { text: "Tamanho", value: "size" },
        { text: "Rota", value: "to" },
        { text: "Descrição", value: "description" },
        { text: "Nivel", value: "nivel" },
        { text: "Ações", value: "actions", width: "85px" },
      ],
      validJson: false,
      json: "",
      meta: "",
      filtro: {},
      showFilter: false,
      copy: { clearBefore: true },
      showCopy: false,
      dialogRoutes: false,
    };
  },
  methods: {
    getAcesso() {
      this.$http("nvacesso").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${element.description})`;
          element.value = element.id;
        });
        this.niveis = resp.data;
        this.getRoutes();
      });
    },
    getRoutes() {
      this.$http("approutes").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${element.description})`;
          element.value = element.path;
          try {
            element.parameters = JSON.parse(element.parameters);
          } catch (error) {
            delete element.parameters;
          }
        });
        this.get();
        this.routes = resp.data;
      });
    },
    get(filtro) {
      let url = "";
      if (this.filtro.nvAcessoId && this.filtro.to && filtro) {
        url = `nvmenuApp?nivel=${this.filtro.nvAcessoId}&to=${this.filtro.to}`;
      } else if ((this.filtro.nvAcessoId || this.filtro.to) && filtro) {
        url = `nvmenuApp?${
          this.filtro.nvAcessoId ? "nivel=" + this.filtro.nvAcessoId : ""
        }${this.filtro.to ? "to=" + this.filtro.to : ""}`;
      } else {
        url = "nvmenuApp";
      }

      this.$http(url).then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${element.description})`;
          element.value = element.to;
          try {
            element.parameters = JSON.parse(element.parameters);
          } catch (error) {
            delete element.parameters;
          }
          element.nivel = this.showNivelName(element.nvAcessoId);
        });
        this.menus = resp.data;
      });
    },
    save() {
      const metodo = this.menu.id ? "put" : "post";
      const finalUrl = this.menu.id ? `/${this.menu.id}` : "";
      this.$http[metodo](`/nvmenuApp${finalUrl}`, this.menu).then((resp) => {
        if (resp.status == 200) {
          this.get();
          this.clear();
          (this.snackbar = true), (this.text = "Salvo com Sucesso");
        }
      });
    },
    copyData() {
      this.$http.post(`/nvmenuApp/copy`, this.copy).then(() => {
        (this.snackbar = true), (this.text = "Copiado com Sucesso");
      });
    },
    edit(rec) {
      this.menu = rec;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
      this.meta = "";
      this.showMeta(rec.to);
      this.json = JSON.stringify(rec.parameters);
    },
    delet(id) {
      this.$http.delete(`/nvmenuApp/${id}`).then(() => {
        this.get();
        (this.snackbar = true), (this.text = "Deletado com Sucesso");
      });
    },
    clear() {
      this.menu = {
        id: null,
        enabled: true,
        order: 1,
      };
      this.json = "";
    },
    icon(icon) {
      this.menu.icon = icon.icon;
      this.menu.size = icon.size;
      this.menu.color = icon.color;
    },
    newConfig(e) {
      this.menu.parameters = e;
      this.json = JSON.stringify(e);
      this.save();
    },
    showMeta(item) {
      let idx = this.routes.findIndex((e) => {
        return e.value == item;
      });
      this.meta = this.routes[idx].parameters;
      this.json = "";
    },
    showNivelName(id) {
      let nv = this.niveis.find((e) => e.id == id);
      if (nv) {
        return `${nv.id} - ${nv.name}`;
      } else {
        return id;
      }
    },
    teste() {
      console.log("click");
      this.dialogRoutes = true;
    },
  },

  mounted() {
    // this.get();
    this.getAcesso();
    // this.getRoutes();
  },
};
</script>

<style>
</style>