<template>
	<!-- tooltip do texto -->
	<v-tooltip left top>
		<template v-slot:activator="{ on, attrs }">
			<span
				v-bind="attrs"
				v-on="on"
				class="d-inline-block text-truncate"
				:style="`max-width: ${maxWidth}px`"
			>
				{{ text }}
			</span>
		</template>
		<!-- texto completo -->
		<span>
			{{ text }}
		</span>
	</v-tooltip>
</template>
<script>
export default {
	name: "StatusToolTip",
	props: {
		text: {
			type: String,
			default: "Informe o texto na propriedade text",
		},
		maxWidth: {
			type: String,
			default: "150",
		},
	},
};
</script>

<style>
</style>