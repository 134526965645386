<template>
	<div>
		<template v-if="!report">
			<v-card-title class="pt-6">Eventos</v-card-title>
			<v-card-subtitle>Clique na tabela para mais detalhes</v-card-subtitle>
		</template>
		<v-card-text>
			<DataTableGeneric :headers="headers" :items="items" @headersChanged="$emit('headersChanged', $event)"
				@rowClick="openModalEvento">
				<template v-slot:item.createdAt="{ item }">
					<span>{{ formataData(item.createdAt) }}</span>
				</template>
				<template v-slot:item.startedAt="{ item }">
					<span>{{ item.startedAt ? formataData(item.startedAt) : '' }}</span>
				</template>
				<template v-slot:item.finishedAt="{ item }">
					<span>{{ item.finishedAt ? formataData(item.finishedAt) : '' }}</span>
				</template>
				<template v-slot:item.deviceId="{ item }">
					<span>{{
						item.deviceId
							? getDispositivoNameById(item.deviceId)
							: "Dispositivo não encontrado"
					}}</span>
				</template>
				<template v-slot:item.createdUserId="{ item }">
					<span>{{ verificaUsuarios(item.createdUserId) }}</span>
				</template>
				<template v-slot:item.finishedUserId="{ item }">
					<span>{{ item.finishedUserId ? getUserNameById(item.finishedUserId) : '' }}</span>
				</template>
				<template v-slot:item.clientId="{ item }">
					<span>{{ getClienteNameById(item.clientId) }}</span>
				</template>
				<template v-slot:item.treatment="{ item }">
					<TextTooltip :text="item.treatment" :maxWidth="'250'" />
				</template>

			</DataTableGeneric>
		</v-card-text>

		<DialogEvento ref="dialogEvento" />
		<ConfirmDialog v-if="!report" ref="confirmDialog" />
	</div>
</template>

<script>
import DataTableGeneric from "../DataTableGeneric";
import ConfirmDialog from "../ConfirmDialog";
import { mapGetters } from "vuex";
import TextTooltip from '../Template/TextTooltip.vue';
import DialogEvento from './DialogEvento.vue';

export default {
	name: "TableEventos",
	computed: {
		...mapGetters([
			"getClienteNameById",
			"getUserNameById",
			"getTarefas",
			"getAmbienteNameById",
			"getDispositivos",
			"getDispositivoNameById",
		]),
	},
	components: {
		DataTableGeneric,
		ConfirmDialog,
		TextTooltip,
		DialogEvento,
	},
	data: () => {
		return {
			search: "",
			selected: [],
		};
	},
	props: {
		items: {
			type: Array,
		},
		headers: {
			type: Array,
		},
		report: {
			type: Boolean,
			default: false,
		},
		actions: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		formataData(dataUTC) {
			return new Date(dataUTC).toLocaleString();
		},
		openModalEvento(item) {
			this.$refs.dialogEvento.openEvento(item);
		},
		verificaUsuarios(id) {
			if (id == null || id == 0) {
				return "Gerado automaticamente";
			} else {
				return this.getUserNameById(id);
			}
		},
	},
	created() {
		this.$store.dispatch("loadClientes");
	},
	watch: {},
};
</script>

<style scoped>
.intermed-title {
	font-size: 1.2rem;
	font-weight: bold;
}
</style>