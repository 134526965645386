<template>
  <v-dialog
      v-model="dialog"
      scrollable
      width="1000"
      v-if="visitante && visitado"
    >
    <v-card>
      <v-card-title class="text-h5 pb-4">Detalhes da Visita</v-card-title>
        <v-card-text class="py-0">
          <v-card outlined>
            <v-card-text>
              <v-row class="mb-3 py-3">
                <v-col class="py-0">
                  <div class="d-flex">
                    <div class="mr-5">
                      <v-avatar size="180">
                        <v-img :src="visitante.picture" lazy-src="/img/nopic.jpg"></v-img>
                      </v-avatar>
                    </div>
                    <div>
                      <h6 class="mb-3 text-h6">Visitante</h6>
                      <span class="text-subtitle-1">{{ visitante.name }}</span><br />
                      <span><strong>Documento:</strong> {{ visitante.document }}</span><br />
                      <span><strong>Telefone:</strong> {{ visitante.phone }}</span><br />
                      <span><strong>Referencia / De Onde?:</strong> {{ visitante.reference }}</span>
                    </div>
                  </div>
                </v-col>
                <v-divider vertical class="mx-1"></v-divider>
                <v-col class="py-0">
                  <div class="d-flex">
                    <div class="mr-4">
                      <v-avatar size="180">
                        <v-img :src="visitado.picture" lazy-src="/img/nopic.jpg"></v-img>
                      </v-avatar>
                    </div>
                    <div>
                      <h6 class="mb-3 text-h6">Visitado</h6>
                      <span class="text-subtitle-1">{{ visitado.name }}</span><br />
                      <span><strong>Local:</strong> {{ visitado.place }}</span><br />
                      <span><strong>Posição:</strong> {{ visitado.hierarchy }}</span><br />
                      <span><strong>Celular:</strong> {{ visitado.celPhone }}</span><br />
                      <span><strong>Telefone / Ramal:</strong> {{ visitado.phone }}</span>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <div class="d-flex justify-space-between">
                <div>
                  <span class="mr-8"><strong>Entrada:</strong> {{ formataData(item.createdAt) }}</span>
                  <template v-if="item.exited">
                    <span class="mr-8"><strong>Saída:</strong> {{ formataData(item.updatedAt) }}</span>
                    <span class="mr-8"><strong>Permanência:</strong> {{ getIntervalo(item.createdAt, item.updatedAt, item.perm) }}</span>
                  </template>
                  <br />
                  <span><strong>Motivo / Descrição da Visita:</strong> {{ item.description }}</span>
                </div>
                <v-btn
                  v-if="actions"
                  color="orange lighten-1 text-white"
                  @click="$emit('out', item)"
                  class="mt-1"
                  dark
                ><v-icon>mdi-exit-to-app</v-icon> Registrar saída</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="dialog = false"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import { differenceDate } from "@/Utils/DateDiff";
export default {
  name: "VisitaDetails",
  data: () => {
    return {
      item: null,
      visitante: null,
      visitado: null,
      dialog: false,
    };
  },
  props: {
    actions: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    open(item) {
      this.item = item;
      this.getVisitanteById(item.visitanteId);
      this.getVisitadoById(item.visitadoId);
      this.dialog = true;
    },
    close() { // chamado externamente
      this.dialog = false;
    },
    async getVisitanteById(id) {
      await this.$http(`visitante/${id}`).then((resp) => {
        this.visitante = resp.data[0];
      });
    },
    async getVisitadoById(id) {
      await this.$http(`visitado/${id}`).then((resp) => {
        this.visitado = resp.data[0];
      });
    },
    formataData(dataUTC) {
      return new Date(dataUTC).toLocaleString();
    },
    getIntervalo(inicio, fim, perm) {
      let dif = differenceDate(inicio, fim);
      if (dif.day > 0)
        return `${dif.day}d, ${dif.hour}h, ${dif.minute}m`;
      else
        return perm;
    },
  },
};
</script>

<style>
.img {
  max-width: 300px;
}
</style>