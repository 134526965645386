import Vue from 'vue'


const formValidation = {
    bool: (route) => {
        console.log("Validation bool", route)
    },
    string: (route) => {
        console.log("Validation string", route)
    }
}

Vue.use({
    install(Vue) {
        Vue.prototype.$formValidation = formValidation
    }
})