<template>
  <v-dialog
      v-model="dialog"
      width="1000"
      scrollable
      v-if="veiculo"
    >
    <v-card>
      <v-card-text class="pt-5 pb-0">
        <v-card outlined class="mb-3">
          <v-card-title class="pt-2">Detalhes iniciais do Veículo ({{ veiculo.placa }})</v-card-title>
          <v-card-text>
            <div class="mb-5">
              <!-- Carousel customizado para múltiplas colunas-->
              <v-carousel
                v-if="veiculo.picturesEnter && veiculo.picturesEnter.length"
                v-model="carousel1Position"
                height="200"
                hide-delimiters
                class="mb-5"
              >
                <template v-for="(img, index) in veiculo.picturesEnter">
                  <v-carousel-item v-if="(index + 1) % carouselColumns === 1 || carouselColumns === 1" :key="index"> 
                    <v-row class="flex-nowrap" style="height:100%; margin:-4px"> 
                      <template v-for="(col, i) in carouselColumns"> 
                        <template v-if="(+index + i) < veiculo.picturesEnter.length"> 
                          <v-col :key="i" :cols="12 / carouselColumns" class="pa-1">
                            <v-img :src="veiculo.picturesEnter[+index + i]" width="100%" height="100%" class="d-block rounded"></v-img>
                          </v-col> 
                        </template> 
                      </template> 
                    </v-row> 
                  </v-carousel-item> 
                </template> 
              </v-carousel>
              <div v-else class="text-center"><i>Sem imagens iniciais do veículo</i></div>
            </div>
            <div class="d-flex justify-space-between">
              <div>
                <span class="mr-8"><strong>Placa:</strong> {{ veiculo.placa }}</span>
                <span class="mr-8"><strong>Entrada:</strong> {{ formataData(veiculo.createdAt) }}</span><br />
                <span><strong>Descrição da entrada:</strong> {{ veiculo.description }}</span>
              </div>
              <v-btn
                v-if="actions"
                color="orange lighten-1 text-white"
                @click="$emit('out', veiculo)"
                class="mt-1"
                dark
              ><v-icon>mdi-exit-to-app</v-icon> Registrar saída</v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-card outlined>
          <v-card-title class="pt-2">Detalhes da Visita</v-card-title>
          <v-card-text v-if="visita">
            <v-row class="mb-3" v-if="visitante && visitado">
              <v-col>
                <div class="d-flex">
                  <div class="mr-5">
                    <v-avatar size="180">
                      <v-img :src="visitante.picture" lazy-src="/img/nopic.jpg"></v-img>
                    </v-avatar>
                  </div>
                  <div>
                    <h6 class="mb-3 text-h6">Visitante</h6>
                    <span class="text-subtitle-1">{{ visitante.name }}</span><br />
                    <span><strong>Documento:</strong> {{ visitante.document }}</span><br />
                    <span><strong>Telefone:</strong> {{ visitante.phone }}</span><br />
                    <span><strong>Referencia / De Onde?:</strong> {{ visitante.reference }}</span>
                  </div>
                </div>
              </v-col>
              <v-divider vertical class="mx-1"></v-divider>
              <v-col>
                <div class="d-flex">
                  <div class="mr-4">
                    <v-avatar size="180">
                      <v-img :src="visitado.picture" lazy-src="/img/nopic.jpg"></v-img>
                    </v-avatar>
                  </div>
                  <div>
                    <h6 class="mb-3 text-h6">Visitado</h6>
                    <span class="text-subtitle-1">{{ visitado.name }}</span><br />
                    <span><strong>Local:</strong> {{ visitado.place }}</span><br />
                    <span><strong>Posição:</strong> {{ visitado.hierarchy }}</span><br />
                    <span><strong>Celular:</strong> {{ visitado.celPhone }}</span><br />
                    <span><strong>Telefone / Ramal:</strong> {{ visitado.phone }}</span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <div>
              <span class="mr-8"><strong>Entrada:</strong> {{ formataData(visita.createdAt) }}</span>
              <span v-if="visita.createdAt != visita.updatedAt"><strong>Saída:</strong> {{ formataData(visita.updatedAt) }}</span><br />
              <span><strong>Motivo / Descrição da Visita:</strong> {{ visita.description }}</span>
            </div>
          </v-card-text>
          <v-card-text v-else class="text-center"><i>Sem visita vinculada</i></v-card-text>
        </v-card>

        <v-card outlined class="mt-3" v-if="veiculo.exited">
          <v-card-title class="pt-2">Detalhes finais do Veículo</v-card-title>
          <v-card-text>
            <div class="mb-5">
              <!-- Carousel customizado para múltiplas colunas-->
              <v-carousel
                v-if="veiculo.picturesExit && veiculo.picturesExit.length"
                v-model="carousel2Position"
                height="200"
                hide-delimiters
                class="mb-5"
              >
                <template v-for="(img, index) in veiculo.picturesExit">
                  <v-carousel-item v-if="(index + 1) % carouselColumns === 1 || carouselColumns === 1" :key="index"> 
                    <v-row class="flex-nowrap" style="height:100%; margin:-4px"> 
                      <template v-for="(col, i) in carouselColumns"> 
                        <template v-if="(+index + i) < veiculo.picturesExit.length"> 
                          <v-col :key="i" :cols="12 / carouselColumns" class="pa-1">
                            <v-img :src="veiculo.picturesExit[+index + i]" width="100%" height="100%" class="d-block rounded"></v-img>
                          </v-col> 
                        </template> 
                      </template> 
                    </v-row> 
                  </v-carousel-item> 
                </template> 
              </v-carousel>
              <div v-else class="text-center"><i>Sem imagens finais do veículo</i></div>
            </div>
            <div>
              <span class="mr-8"><strong>Saída:</strong> {{ formataData(veiculo.updatedAt) }}</span>
              <span><strong>Permanência:</strong> {{ getIntervalo(veiculo.createdAt, veiculo.updatedAt, veiculo.perm) }}</span><br />
              <span><strong>Descrição da saída:</strong> {{ veiculo.descriptionExit }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          @click="dialog = false"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { differenceDate } from "@/Utils/DateDiff";
export default {
  name: "VeiculoDetails",
  props: {
    actions: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      veiculo: {},
      imagens: [],
      visitante: null,
      visitado: null,
      visita: null,
      carouselColumns: 4,
      carousel1Position: 0,
      carousel2Position: 0,
      dialog: false,
    };
  },
  methods: {
    open(veiculo) {
      this.veiculo = veiculo;
      this.visita = this.getVisitaById(veiculo.visitaId);
      this.dialog = true;
    },
    close() { // chamado externamente
      this.dialog = false;
    },
    getVisitaById(id) {
      this.$http(`visita/${id}`).then((resp) => {
        this.getVisitanteById(resp.data.visitanteId);
        this.getVisitadoById(resp.data.visitadoId);
        this.visita = resp.data;
      });
    },
    getVisitanteById(id) {
      this.$http(`visitante/${id}`).then((resp) => {
        this.visitante = resp.data[0];
      });
    },
    getVisitadoById(id) {
      this.$http(`visitado/${id}`).then((resp) => {
        this.visitado = resp.data[0];
      });
    },
    formataData(dataUTC) {
      return new Date(dataUTC).toLocaleString();
    },
    getIntervalo(inicio, fim, perm) {
      let dif = differenceDate(inicio, fim);
      if (dif.day > 0)
        return `${dif.day}d, ${dif.hour}h, ${dif.minute}m`;
      else
        return perm;
    },
  },
  watch: {
    dialog(val) {
      if (!val)
        this.carousel1Position = 0; // reseta a posição do carousel
        this.carousel2Position = 0;
    },
  },
};
</script>