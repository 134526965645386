/**
 * 
 * @param {String} url string que tem o caminho da api
 * @param {Object} objt2query objeto que tem os parametros que serão passados para a api,  os arrays serão convertidos em querys
 * @returns {String} retorna a url com os parametros
 */

exports.queryStringBuilder = function (url = "", objt2query = {}) {
  let originalUrl = url;
  let query = ''
  for (const key in objt2query) {
    if (Object.hasOwnProperty.call(objt2query, key)) {
      const element = objt2query[key];
      if (element === undefined || typeof element === "function" || element === null) continue;
      if (Array.isArray(element)) {
        element.forEach((ele) => {
          query += `&${key}=${ele}`
        });
      } else {
        query += `&${key}=${element}`
      }
    }
  }
  originalUrl += query.substring(1);
  return originalUrl;
}

exports.arrayBufferToBase64 = (buffer, cntntype) => {
  return `data:${cntntype};base64,${Buffer.from(buffer).toString("base64")}`;
}