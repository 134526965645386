var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cliDriver"},[_c('v-card-title',[_vm._v("Cadastros de Motoristas")]),_c('v-card-text',[_c('DataTable',{attrs:{"items":_vm.drivers,"headers":_vm.getDriverHeaders},scopedSlots:_vm._u([{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-2",attrs:{"size":"70"}},[_c('v-img',{attrs:{"src":item.picture,"lazy-src":"/img/nopic.jpg"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.editar(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar : "+_vm._s(item.name))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.excluir(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-trash-can")])]}}],null,true)},[_c('span',[_vm._v("Excluir : "+_vm._s(item.name))])])]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"700","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 pr-4 mb-2"},[_vm._v(_vm._s(_vm.driver.id ? "Editar" : "Cadastrar")+" Motorista"),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-1 pb-0"},[_c('v-form',{ref:"formMotorista"},[_c('div',{staticClass:"text-center"},[_c('v-avatar',{staticClass:"mb-7",attrs:{"size":"250"}},[_c('v-img',{attrs:{"src":_vm.tempPicture,"lazy-src":"/img/nopic.jpg"}})],1),_c('Webcam',{on:{"base64":function($event){_vm.tempPicture = $event},"file":function($event){_vm.driver.picFile = $event}}})],1),_c('v-text-field',{staticClass:"mt-2",attrs:{"label":"Nome","rules":_vm.requiredField,"required":"","outlined":""},model:{value:(_vm.driver.name),callback:function ($$v) {_vm.$set(_vm.driver, "name", $$v)},expression:"driver.name"}}),_c('v-textarea',{attrs:{"counter":255,"label":"Descrição","outlined":""},model:{value:(_vm.driver.description),callback:function ($$v) {_vm.$set(_vm.driver, "description", $$v)},expression:"driver.description"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-10",attrs:{"color":"amber darken-1","dark":"","absolute":"","bottom":"","right":"","fab":"","fixed":""},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Adicionar Motorista")])]),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }