<template>
	<div>
		<v-card>
			<v-card-title> Relatório de Documentos </v-card-title>
			<v-card-text>
				<!-- linha do filtro -->
				<v-card outlined>
					<v-card-text class="pb-0">
						<p class="text-subtitle-1 pt-1 pb-3">Filtros</p>
						<v-form ref="formReport">
							<v-row>
								<v-col class="py-0">
									<v-text-field
										v-model="relatorio.start"
										label="Data Inicial"
										required
										outlined
										type="datetime-local"
										@input="$refs.relatorioEnd.validate()"
									></v-text-field>
								</v-col>
								<v-col class="py-0">
									<v-text-field
										ref="relatorioEnd"
										v-model="relatorio.end"
										label="Data Final"
										required
										outlined
										type="datetime-local"
										:rules="[
											(v) =>
												validaDataFinal(v) ||
												'A data final deve ser maior que a inicial',
										]"
									></v-text-field>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-card-actions class="px-4">
						<MenuExportReport
							:disabled="!getDocumentosReport.length"
							:items="[...getDocumentosReport]"
							module="documents"
							title="Relatório de documentos visualizados"
							filename="documents"
							:optionImg="false"
						/>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="loadReport">
							<v-icon class="mr-1">mdi-magnify</v-icon> Buscar Relatório
						</v-btn>
					</v-card-actions>
				</v-card>
				<!-- linha da tabela -->
			</v-card-text>
			<!-- {{getDocumentosReport}} -->
			<TableDocuments
				:headers="headers"
				:items="getDocumentosReport"
				:report="true"
			/>
		</v-card>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuExportReport from "@/components/Relatorios/MenuExportReport.vue";
import TableDocuments from './TableDocuments.vue';

export default {
	name: "RelatorioEventos",
	components: {
		MenuExportReport,
		TableDocuments,
	},
	computed: {
		...mapGetters([
			"getRelEvents",
			"getUserNameById",
			"getClienteNameById",
			"getDocumentosReport",
		]),
	},
	data() {
		return {
			ocorrencia: {},
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Nome do Documento", value: "docName" },
				// { text: "Cliente", value: "clientName" },
				{ text: "Grupo", value: "groupName"},
				{ text: "Usuário", value: "userName" },
				{ text: "Primeira Visualização", value: "createdAt"},
				{ text: "Ultima Visualização", value: "updatedAt"},
			],
			relatorio: {},
		};
	},
	methods: {
		loadReport() {
			if (this.$refs.formReport.validate()) {
				this.relatorio.clientId = this.$store.getters.getCliente.id;
				this.$store.dispatch("loadReportDocumentos", this.relatorio);
			}
		},
		verificaNomeDispositivo(id) {
			let dispositivo = this.dispositivos.find(
				(dispositivo) => dispositivo.id === id
			);
			if (dispositivo) {
				return dispositivo.name;
			}
			return "Dispositivo não encontrado";
		},
		verificaUsuarios(id) {
			if (id == null || id == 0) {
				return "Gerado automaticamente";
			} else {
				return this.getUserNameById(id);
			}
		},

		geraitensRel(itens) {
			let itensRel = [];
			if (itens) {
				itens.forEach((item) => {
					let itemRel = { ...item };
					itemRel.clientId = this.getClienteNameById(item.clientId).split(" - ")[1];
					itemRel.deviceId = this.verificaNomeDispositivo(item.deviceId);
					itemRel.createdUserId = this.verificaUsuarios(item.createdUserId);
					itemRel.finishedUserId = this.getUserNameById(item.finishedUserId).split(" - ")[1];
					itemRel.duration = this.calculaIntervalo(itemRel);
					itensRel.push({...itemRel});
				});
			}
			return itensRel;
		},
		validaDataFinal(dataFinal) {
			// função auxiliar pois este teste direto no rules não funciona corretamente
			return new Date(dataFinal) > new Date(this.relatorio.start);
		},
	},
	created() {
		// pega a data atual e seta no relatorio
		let now = new Date();
		let onlyDate = now.toISOString().substring(0, 11);
		this.relatorio.start = onlyDate + "00:00";
		this.relatorio.end = onlyDate + "23:59";
	},
};
</script>

<style>
</style>