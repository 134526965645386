<template>
  <v-dialog v-model="dialog" width="600" v-if="deviceId" scrollable>
    <v-card>
      <v-card-title>
        <span>Comandos</span>
        <v-spacer></v-spacer>
        <v-btn fab text @click="dialog = false" small>
          <v-icon>mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn color="success" @click="sendMessage('clearDb')" block>Limpar banco</v-btn>
          </v-col>
          <v-col>
            <v-btn color="success" @click="sendMessage('loadRondas')" block>Carregar Rondas</v-btn>
          </v-col>
          <v-col>
            <v-btn color="success" @click="sendMessage('logoff')" block>Deslogar</v-btn>
          </v-col>
          <v-col>
            <v-btn color="success" @click="sendMessage('getVersion')" block>Pegar Versão</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DialogCommands",
  data: () => {
    return {
      dialog: false,
      deviceId: null,
      devices: [],
      socket: null,
      message: "",
      messages: [],
      lastMsgDay: null,
      lastMsgFromServer: null,
      markedAsUnread: false,
    };
  },
  computed: {
    ...mapGetters(["getOnline", "getUsers", "getDispositivos"]),
    deviceOnline() {
      let online = false;
      if (this.deviceId)
        online = this.getOnline.find((item) => item.user.deviceId == this.deviceId);
      return online;
    },
    userOnline() {
      let userOnline = { name: "Sem usuário online", picture: "/img/nopic.jpg" };
      if (this.deviceOnline && this.getUsers) {
        let user = this.getUsers.find((item) => item.id == this.deviceOnline.user.id);
        if (user) userOnline = user;
      }
      return userOnline;
    },
    deviceName() {
      let deviceName = "Dispositivo desconhecido";
      let device = this.getDispositivos.find((item) => item.identificador === this.deviceId);
      if (device) deviceName = device.name;
      return deviceName;
    },
  },
  methods: {
    open(options) {
      this.deviceId = options.deviceId;
      this.dialog = true;
    },
    sendMessage(command, args) {
      this.socket.emit("command", {
        deviceId: this.deviceId,
        command,
        args,
      });
    },
    recebedor(data) {
      // console.log(data);
      this.$store.dispatch("resetSnack");
      if (data.command == "clearDb")
        this.$store.dispatch("showSuccessSnack", "Banco limpo com Sucesso");
      if (data.command == "loadRondas")
        this.$store.dispatch("showSuccessSnack", "Rondas carregadas com Sucesso");
      if (data.command == "logoff")
        this.$store.dispatch("showSuccessSnack", "Deslogado com Sucesso");
      if (data.command == "getVersion")
        this.$store.dispatch("showSuccessSnack", "Versão: " + data.message);
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        if (!this.socket) {
          this.socket = this.$store.getters.getSocket;
          this.socket.on("commandReply", this.recebedor);
        }
      } else {
        this.socket.off("commandReply", this.recebedor);
      }
    },
  },
};
</script>

<style scoped></style>