import axios from 'axios'
import { queryStringBuilder } from '../../Utils/queryStringBuilder'
export default {
  state: {
    rondas: [],
    checkPoints: [],
    checkpointGroups: [],
    relRondas: [],
    emailsAvisoRondas: []
  },
  mutations: {
    setRondas(state, payload) {
      state.rondas = payload
    },
    setCheckpoints(state, payload) {
      state.checkPoints = payload
    },
    setCheckpointGroups(state, payload) {
      state.checkpointGroups = payload
    },
    setRelRondas(state, payload) {
      state.relRondas = payload
    },
    setEmailsAvisoRondas(state, payload) {
      state.emailsAvisoRondas = payload
    }
  },
  actions: {
    setRondas(context, payload) {
      context.commit('setRondas', payload)
    },
    setCheckpoints(context, payload) {
      context.commit('setCheckpoints', payload)
    },
    setCheckpointGroups(context, payload) {
      context.commit('setCheckpointGroups', payload)
    },
    setRelRondas(context, payload) {
      context.commit('setRelRondas', payload)
    },
    setEmailsAvisoRondas(context, payload) {
      context.commit('setEmailsAvisoRondas', payload)
    },
    loadRondas(context, payload) {
      let gp = payload ? `?clientId=${payload}` : ''
      axios(`/ronda${gp}`).then((resp) => {
        context.dispatch('setRondas', resp.data)
      });
    },
    loadCheckpoints(context, payload) {
      let gp = payload ? `?clientId=${payload}` : ''
      axios(`/checkpoint${gp}`).then((resp) => {
        context.commit('setCheckpoints', resp.data)
      });
    },
    loadCheckpointGroups(context, payload) {
      let gp = payload ? `?clientId=${payload}` : ''
      axios(`/checkpoint_group${gp}`).then((resp) => {
        context.commit('setCheckpointGroups', resp.data)
      });
    },
    loadReportRonda(context, payload) {
      let url = `/ronda/run/report?`;
      // gerador de query para relatorio
      url = queryStringBuilder(url, payload);
      axios(url).then((resp) => {
        context.commit("setRelRondas", resp.data);
        if (typeof payload.callback == 'function') {
          payload.callback(resp.data);
        }
      });
    },
    saveRonda(context, payload) {
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/ronda${finalUrl}`, payload).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Ronda salva com Sucesso");
          context.dispatch("loadRondas", payload.clientId);
          context.dispatch("loadCheckpoints", payload.clientId);
          if (typeof payload.callback == 'function')
            payload.callback(resp.data);
        }
      });
    },
    saveCheckpoint(context, payload) {
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/checkpoint${finalUrl}`, payload).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Checkpoint salvo com Sucesso");
          context.dispatch("loadCheckpoints", payload.clientId);
          if (typeof payload.callback == 'function')
            payload.callback(resp.data);
        }
      });
    },
    saveCheckpointGroup(context, payload) {
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/checkpoint_group${finalUrl}`, payload).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Grupo de Pontos salvo com Sucesso");
          context.dispatch("loadCheckpointGroups", payload.clientId);
          if (typeof payload.callback == 'function')
            payload.callback(resp.data);
        }
      });
    },
    deleteRonda(context, payload) {
      axios.delete(`/ronda/${payload.id}`).then(() => {
        context.dispatch("resetSnack");
        context.dispatch("showSuccessSnack", "Ronda Deletada com Sucesso");
        context.dispatch("loadRondas", payload.clientId);
      });
    },
    deleteCheckpointGroup(context, payload) {
      axios.delete(`/checkpoint_group/${payload.id}`).then(() => {
        context.dispatch("resetSnack");
        context.dispatch("showSuccessSnack", "Grupo de Pontos Deletado com Sucesso");
        context.dispatch("loadCheckpointGroups", payload.clientId);
      });
    },
    loadAvisosRondas(context, payload) {
      let gp = payload ? `/ronda/${payload}` : ''
      axios(`/aviso-rondas${gp}`).then((resp) => {
        context.commit('setEmailsAvisoRondas', resp.data)
      });
    },
    saveAvisosRonda(context, payload) {
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      console.log(payload);
      
      axios[metodo](`/aviso-rondas${finalUrl}`, payload).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Aviso salvo com Sucesso");
          context.dispatch("loadAvisosRondas", payload.rondaId);
          if (typeof payload.callback == 'function')
            payload.callback(resp.data);
        }
      });
    }
  },
  getters: {
    getRondas(state) {
      return state.rondas
    },
    getRelRondas(state) {
      return state.relRondas
    },
    getCheckpoints(state) {
      return state.checkPoints.filter(cp => cp.facilities == 0)
    },
    getCheckpointsFacilities(state) {
      return state.checkPoints.filter(cp => cp.facilities == 1)
    },
    getCheckpointGroups(state) {
      return state.checkpointGroups
    },
    getRondaNameById: (state) => (id) => {
      if (!state.rondas) {
          return id
      }
      let gp = state.rondas.find(g =>
          g.id == id
      )
      return gp ? `${gp.id} - ${gp.name}` : ''
    },
    getCheckpointNameById: (state) => (id) => {
      if (!state.checkPoints) {
          return id
      }
      let gp = state.checkPoints.find(g =>
          g.id == id
      )
      return gp ? `${gp.id} - ${gp.name}` : ''
    },
    getAvisosRondas(state) {
      return state.emailsAvisoRondas
    },
  },
}