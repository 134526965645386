<template>
  <v-card
    width="100%"
    outlined
    @click="selectEvent"
    class="border overflow-hidden"
    :class="event.blinkColor ? 'blink' : ''"
    :data-event-id="event.id"
    :style="`
      border-left-color: ${event.eventColor};
      ${event.blinkColor ? 'background-color:' + event.blinkColor : ''}
    `"
  >
    <v-card-text class="px-2 py-0">
      <v-row>
        <v-col>
          <small>#{{ event.id }}</small>
        </v-col>
        <v-col class="text-right" v-if="getAudioEvent && getAudioEvent.id == event.id">
          <small><tooltip-icon :xsmall="true" @click="stopAudio" :left="'true'" :text="'Parar Áudio'" :icon="'mdi-volume-off'"/></small>
        </v-col>
      </v-row>
      <p class="text-subtitle-2 text-truncate mb-0">{{ event.eventName || event.type }}</p>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <p class="text-truncate my-0" v-bind="attrs" v-on="on">{{ getClienteNameById(event.clientId) }}</p>
        </template>
        <span>{{ getClienteNameById(event.clientId) }}</span>
      </v-tooltip>
      <div class="d-flex mt-1" v-if="event.finishedUserName">
        <v-icon small class="mr-1">mdi-account</v-icon>
        <span class="d-inline-block text-truncate caption">{{ event.finishedUserName }}</span>
      </div>
    </v-card-text>
    <v-btn v-if="btn" block text small color="success" class="rounded-0 btn-attend" @click="attendEvent">Atender</v-btn>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import TooltipIcon from '../Template/TooltipIcon.vue';

export default {
  name: "eventCard",
  props: {
    event: {
      type: Object,
    },
    btn: {
      type: Boolean,
    },
  },
  components: {
    TooltipIcon,
  },
  data: () => ({
    color: "primary",
  }),
  computed: {
    ...mapGetters(["getClienteNameById", "getAudioEvent"]),
  },
  methods: {
    selectEvent() {
      this.$store.dispatch('setSelectedEvent', this.event);
    },
    attendEvent() {
      this.$store.dispatch("assumeEvent", this.event);
    },
    getType() {
      let tipo = "Evento não Cadastrado";
      if (this.event.type) {
        tipo = this.event.type;
      }
      let attr = JSON.parse(this.event.attributes);
      if (Object.keys(attr).length) {
        if (attr.alarm) {
          tipo = attr.alarm;
        }
      }
      let trans = this.$store.getters.findTranslate(tipo);
      this.color = trans.color;
      return trans.translation;
    },
    blink() {
      if (this.event.blink){
        setTimeout(() => {
          this.event.blink = false;
        }, 3000);
        return "blink";
      }
      return "";
    },
    stopAudio() {
      this.$store.dispatch("playAlert", { stop: true });
    },
  },
};
</script>

<style scoped>
.border {
  border-left: 6px solid;
  min-height: 96px;
}
.btn-attend {
  transition: all 300ms;
}
.btn-attend:hover {
  color: #fff !important;
  background-color: #28a745;
}

.blink {
  animation: blinker 1s infinite;
}
@keyframes blinker {
  50% {
    background-color: #fff;
  }
}
</style>