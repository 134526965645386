import axios from 'axios'
export default {

    state: {
        translates: [],
    },
    mutations: {
        setTranslates(state, payload) {
            state.translates = payload
        },
    },
    actions: {
        setTranslates(context, payload) {
            context.commit('setTranslates', payload)
        },

        loadTranslates(context) {
            axios(`translate`).then((resp) => {
                context.commit('setTranslates', resp.data)
            });
        },

    },
    getters: {
        getTranslates(state) {
            return state.translates
        },
        findTranslate: (state) => (eventTypeOrAtribbtes) => {
            let tl = state.translates.find(t =>
                t.identifier === eventTypeOrAtribbtes
            )
            return tl ? tl : eventTypeOrAtribbtes
        },
    },
}