<template>
  <v-dialog
      v-model="dialog"
      width="700"
      scrollable
    >
    <v-card>
      <v-card-title class="text-h5 pb-4">Detalhes da Frota</v-card-title>
      <v-card-text class="pb-0">
        <v-card-title class="intermed-title mt--2">Dados iniciais</v-card-title>
        <v-card outlined class="mb-3">
          <v-card-text>
            <div class="mb-5">
              <!-- Carousel customizado para múltiplas colunas-->
              <v-carousel
                v-if="item.picturesStart && item.picturesStart.length"
                v-model="carouselPosition"
                height="200"
                hide-delimiters
                class="mb-5"
              >
                <template v-for="(img, index) in item.picturesStart">
                  <v-carousel-item v-if="(index + 1) % carouselColumns === 1 || carouselColumns === 1" :key="index"> 
                    <v-row class="flex-nowrap" style="height:100%; margin:-4px"> 
                      <template v-for="(col, i) in carouselColumns"> 
                        <template v-if="(+index + i) < item.picturesStart.length"> 
                          <v-col :key="i" :cols="12 / carouselColumns" class="pa-1">
                            <v-img :src="item.picturesStart[+index + i]" width="100%" height="100%" class="d-block rounded"></v-img>
                          </v-col> 
                        </template> 
                      </template> 
                    </v-row> 
                  </v-carousel-item> 
                </template> 
              </v-carousel>
              <div v-else class="text-center"><i>Sem imagens iniciais</i></div>
            </div>
            <div class="d-flex justify-space-between">
              <div>
                <span class="mr-8"><strong>Início:</strong> {{ formataData(item.createdAt) }}</span>
                <span class="mr-8"><strong>Km Inicial:</strong> {{ item.kmStart }}</span><br />
                <span><strong>Descrição:</strong> {{ item.description }}</span>
              </div>
              <v-btn
                v-if="actions"
                color="orange lighten-1 text-white"
                @click="$emit('out', item)"
                class="mt-1"
                dark
              ><v-icon>mdi-exit-to-app</v-icon> Registrar saída</v-btn>
            </div>
          </v-card-text>
        </v-card>

        <v-card outlined class="mb-3 pb-2">
          <v-card-title class="py-2">Motorista</v-card-title>
          <ItemMotorista
            :item="getSelectedDriver()"
          />
        </v-card>
        
        <!-- card dos veiculos selecionados -->
        <v-card outlined class="pb-1">
          <v-card-title class="py-2">Veículo</v-card-title>
          <ItemVeiculo
            :item="getSelectedVehicle()"
          />
        </v-card>

        <template v-if="item.finished">
          <v-card-title class="intermed-title mt-5">Dados finais</v-card-title>
          <v-card outlined>
            <v-card-text>
              <div class="mb-5">
                <!-- Carousel customizado para múltiplas colunas-->
                <v-carousel
                  v-if="item.picturesExit && item.picturesExit.length"
                  v-model="carouselPosition"
                  height="200"
                  hide-delimiters
                  class="mb-5"
                >
                  <template v-for="(img, index) in item.picturesExit">
                    <v-carousel-item v-if="(index + 1) % carouselColumns === 1 || carouselColumns === 1" :key="index"> 
                      <v-row class="flex-nowrap" style="height:100%; margin:-4px"> 
                        <template v-for="(col, i) in carouselColumns"> 
                          <template v-if="(+index + i) < item.picturesExit.length"> 
                            <v-col :key="i" :cols="12 / carouselColumns" class="pa-1">
                              <v-img :src="item.picturesExit[+index + i]" width="100%" height="100%" class="d-block rounded"></v-img>
                            </v-col> 
                          </template> 
                        </template> 
                      </v-row> 
                    </v-carousel-item> 
                  </template> 
                </v-carousel>
                <div v-else class="text-center"><i>Sem imagens finais</i></div>
              </div>
              <div>
                <span class="mr-8"><strong>Chegada:</strong> {{ formataData(item.updatedAt) }}</span>
                <span class="mr-8"><strong>Tempo total:</strong> {{ getIntervalo(item.createdAt, item.updatedAt) }}</span><br />
                <span class="mr-8"><strong>Km Final:</strong> {{ item.kmEnd }}</span>
                <span><strong>Km Percorrido:</strong> {{ Math.abs(item.kmEnd - item.kmStart) }}</span>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          text
          @click="dialog = false"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ItemVeiculo from "@/components/Veiculo/item_veiculo.vue";
import ItemMotorista from "@/components/Motoristas/item_motorista.vue";
import { differenceDate } from "@/Utils/DateDiff";

export default {
  name: "FleetSimpleDetails",
  components: {
    ItemVeiculo,
    ItemMotorista,
  },
  props: {
    actions: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      item: {},
      carouselColumns: 3,
      carouselPosition: 0,
      dialog: false,
    };
  },
  methods: {
    open(item) {
      this.item = item;
      this.dialog = true;
    },
    close() { // chamado externamente
      this.dialog = false;
    },
    formataData(dataUTC) {
      return new Date(dataUTC).toLocaleString();
    },
    getSelectedDriver() {
      return (
        this.$store.getters.getDrivers.find(
          (item) => item.id == this.item.driverId
        ) || {}
      );
    },
    getSelectedVehicle() {
      return (
        this.$store.getters.getFleetsVehicles.find(
          (item) => item.id == this.item.fleetVehicleId
        ) || {}
      );
    },
    getIntervalo(inicio, fim) {
      let dif = differenceDate(inicio, fim);
      return `${dif.day}d, ${dif.hour}h, ${dif.minute}m`;
    },
  },
  watch: {
    dialog(value) {
      if (!value)
        this.carouselPosition = 0; // reseta a posição do carousel
    },
  },
};
</script>