<template>
	<v-card>
		<v-card-title>Pontos de Ronda Cadastrados</v-card-title>
		<v-card-text>
			<DataTable
				:headers="headers"
				:items="checkpoints"
				:enableSelect="true"
				@update:selectedItems="selecionados = $event"
			>
				<template slot="item.actions" slot-scope="{ item }">
					<!--botão editar Qr Code -->
					<!-- <v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								@click="editarQR({ ...item })"
								color="warning"
								class="mr-1"
								>mdi-pencil</v-icon
							>
						</template>
						<span>Editar : {{ item.name }}</span>
					</v-tooltip> -->
					<TooltipIcon 
					:text="`Editar: ${item.name}`"
					icon="mdi-pencil"
					top="true"
					color="warning"
					@click="editarQR({...item})"
					/>
					<TooltipIcon
						:text="`Gerar QR Code: ${item.name}`"
						icon="mdi-qrcode"
						top="true"
						@click="print(item)"
					/>
					</template>
				
				<!--Mostrar rondas-->
				<template slot="item.rondas" slot-scope="{ item }">
					<span class="mr-1">{{ item.rondas.length }} Rondas</span>
					<v-menu v-if="item.rondas.length > 0" open-on-hover top>
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
						</template>
						<v-list dense class="dropdown-list superdense">
							<v-list-item v-for="(item, index) in item.rondas" :key="index">
								<v-list-item-content>
									<v-list-item-title>{{ item.name }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>
			</DataTable>

			<!-- botão de gerar varios QR Code -->
			<div class="btn-print ml-5" style="margin-top: -42px">
				<v-icon class="mr-2 mb-2">mdi-arrow-up-left</v-icon>
				<v-btn :disabled="selecionados == 0" @click="print(selecionados)"
					><v-icon class="mr-1">mdi-qrcode</v-icon> Gerar QR Codes</v-btn
				>
			</div>
		</v-card-text>

		<ConfirmDialog ref="confirmDialog" />
		<CheckpointsDialog ref="checkpointsDialog" />
		<CadCheckpoints ref="cadCheckpoints" />
	</v-card>
</template>


<script>
import {
	reqFieldMinLength,
	requiredField,
} from "../../../Utils/InputValidation";
import DataTable from "../../DataTableGeneric";
import ConfirmDialog from "../../ConfirmDialog.vue";
import CheckpointsDialog from "./CheckpointsDialog.vue";
import CadCheckpoints from "./CadCheckpoints.vue";
import { mapGetters } from "vuex";
import TooltipIcon from '../../Template/TooltipIcon.vue';

export default {
	name: "cliCheckpoints",
	components: {
		DataTable,
		ConfirmDialog,
		CheckpointsDialog,
		CadCheckpoints,
		TooltipIcon,
	},
	props: ["checkpoints"],
	data: () => {
		return {
			checkpoint: {
				id: null,
				name: "",
				enabled: true,
				clientId: null,
				rondas: [],
			},
			dialogQR: false,
			reqFieldMinLength,
			requiredField,
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Nome", value: "name" },
				{ text: "Rondas vinculadas:", value: "rondas" },
				{
					text: "Ações",
					value: "actions",
					align: "center",
					sortable: false,
					filterable: false,
					width: 100,
				},
			],
			selecionados: [],
		};
	},
	computed: {
		...mapGetters(["getClienteSelect", "getRondas"]),
	},
	methods: {
		reset() {
			this.dialogQR = false;
			this.checkpoint.name = "";
			this.checkpoint.enabled = true;
			this.checkpoint.clientId = null;
			this.checkpoint.rondas = [];
		},
		editarQR(item) {
			this.$refs.cadCheckpoints.dialog = true;
			this.$refs.cadCheckpoints.checkpoint = item;
		},

		update(item) {
			this.$store.dispatch("saveCheckpoint", item);
		},
		print(item) {
			let qrcodes = [];
			if (Array.isArray(item)) {
				qrcodes = item.map((item) => {
					return {
						id: item.id,
						name: item.name,
						qrcode: item.qrcode,
					};
				});
			} else {
				qrcodes.push({
					id: item.id,
					name: item.name,
					qrcode: item.qrcode,
				});
			}
			this.$refs.checkpointsDialog.open({
				checkpoints: qrcodes,
			});
		},
	},
	created() {},
	mounted() {
		// this.$store.dispatch("loadRondas");
		// this.$store.dispatch("loadClientes");
	},
};
</script>
<style scoped>
#table-qrcodes {
	max-height: 180px;
	overflow-y: auto;
}
#table-qrcodes th {
	height: 35px !important;
	vertical-align: top;
}
</style>
<style>
.sortable-ghost {
	background-color: #ccc;
}
</style>