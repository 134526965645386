<template>
  <div>
    <v-card>
      <v-card-title>Agendamentos</v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Nome</th>
                <th class="text-center">Cron</th>
                <th class="text-center">Descrição</th>
                <th class="text-center">Ativo</th>
                <th class="text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getSchedules" :key="item.id">
                <td class="text-center">{{ item.name }}</td>
                <td class="text-center">{{ item.cronString }}</td>
                <td class="text-center">{{ item.description }}</td>
                <td class="text-center">{{ item.enabled ? "SIM" : "NÃO" }}</td>
                <td class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        color="warning"
                        v-on="on"
                        v-bind="attrs"
                        @click="editar(item)"
                      >mdi-pencil</v-icon>
                    </template>
                    <span>Editar agendamento</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        color="primary"
                        v-on="on"
                        v-bind="attrs"
                        @click="$router.push({
                          name: 'TarefasCron',
                          params: { cron: { ...item } },
                        })"
                      >mdi-calendar-blank-multiple</v-icon>
                    </template>
                    <span>Ver / Adicionar Tarefas neste Agendamento</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-on="on"
                        v-bind="attrs"
                        color="error"
                        @click="del(item)"
                      >mdi-delete</v-icon>
                    </template>
                    <span>Excluir Agendamento</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>


        <v-dialog v-model="dialog" persistent max-width="700" scrollable>
          <v-card>
            <v-card-title class="text-h5 pr-4 mb-2">{{ value.id ? "Editar" : "Adicionar"}} Agendamento<v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="value.name"
                label="Nome da Tarefa"
                required
                outlined
              ></v-text-field>
              <v-text-field
                v-model="value.cronString"
                @change="validation"
                :append-icon="valid"
                label="String do Cron"
                required
                outlined
              ></v-text-field>
              <v-textarea
                v-model="value.description"
                name="input-7-1"
                label="Descrição da Tarefa"
                outlined
              ></v-textarea>
              <v-spacer></v-spacer>
              <v-switch
                class="mt-0 pt-0"
                v-model="value.enabled"
                label="Habilitado"
              ></v-switch>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>Manual</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <pre>
                      # ┌────────────── Segundo (Opcional)
                      # │ ┌──────────── Minuto
                      # │ │ ┌────────── Hora
                      # │ │ │ ┌──────── Dia do Mês
                      # │ │ │ │ ┌────── Mês
                      # │ │ │ │ │ ┌──── Dia da Semana
                      # │ │ │ │ │ │
                      # │ │ │ │ │ │
                      # * * * * * *
                    </pre>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>Campo</th>
                          <th>Valor</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Segundo</td>
                          <td>0-59</td>
                        </tr>
                        <tr>
                          <td>Minuto</td>
                          <td>0-59</td>
                        </tr>
                        <tr>
                          <td>Hora</td>
                          <td>0-23</td>
                        </tr>
                        <tr>
                          <td>Dia do Mês</td>
                          <td>1-31</td>
                        </tr>
                        <tr>
                          <td>Mês</td>
                          <td>1-12 (ou nomes)</td>
                        </tr>
                        <tr>
                          <td>Dia da Semana</td>
                          <td>0-7 (ou nomes, 0 ou 7 é sabado)</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <div class="d-flex justify-center pt-4 pb-2">
                      <v-btn
                        href="https://www.npmjs.com/package/node-cron"
                        target="_blank"
                        color="primary"
                      >Manual da Biblioteca</v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="py-4">
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">Cancelar</v-btn>
              <v-btn color="success" @click="save">Salvar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="amber darken-1" dark absolute bottom right fab fixed class="mb-10" v-bind="attrs" v-on="on"
          @click="dialog = true">
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Agendamento</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "cron",
  methods: {
    load(event) {
      this.$store.dispatch("setSchedule", event);

    },
    get() {
      this.$store.dispatch("loadSchedules");
    },
    save() {
      this.$store.dispatch("saveSchedule", this.value);
      this.dialog = false;
      this.value = {
        enabled: true,
      }
    },
    editar(value) {
      this.value = value;
      this.dialog = true;
    },
    del(value) {
      this.$store.dispatch("deleteSchedule", value);
    },
    validation() {
      this.$http
        .post("cron/validate", { cronString: this.value.cronString })
        .then((resp) => {
          this.valid = "mdi-coffin";
          if (resp.status == 200) {
            this.valid = "mdi-check-circle";
          }
        });
    },
  },
  computed: {
    ...mapGetters(["getSchedules", "getSchedule"]),
  },
  data() {
    return {
      valid: "mdi-progress-question",
      dialog: false,
      value: {
        enabled: true,
      },
      cron: [
        {
          id: 1,
          groupId: 1,
          name: "Teste PNM1",
          cronString: "*/20 * * * * *",
          description: "Tarefa executa a cada 20 segundos",
          enabled: false,
          createdAt: "2021-09-27T13:55:05.000Z",
          updatedAt: "2021-09-28T18:07:46.000Z",
        },
        {
          id: 3,
          groupId: 1,
          name: "Teste PNM",
          cronString: "0 10 15 * * *",
          description: null,
          enabled: true,
          createdAt: "2021-09-28T18:07:13.000Z",
          updatedAt: "2021-09-28T18:07:13.000Z",
        },
      ],
      tasks: [
        {
          id: 13,
          name: "allPdf",
          description: null,
          options: '{"clientId":15,"period":"month","email":"aaaa"}',
          schedulerId: 7,
          groupId: 1,
          enabled: true,
          createdAt: "2021-08-24T12:49:46.000Z",
          updatedAt: "2021-08-24T12:49:46.000Z",
        },
        {
          id: 18,
          name: "allPdf",
          description: null,
          options: '{"clientId":15,"period":"month","email":""}',
          schedulerId: 17,
          groupId: 1,
          enabled: true,
          createdAt: "2021-08-25T13:11:05.000Z",
          updatedAt: "2021-08-25T14:47:00.000Z",
        },
        {
          id: 19,
          name: "allPdf",
          description: null,
          options: '{"clientId":15,"period":"month","email":""}',
          schedulerId: 19,
          groupId: 1,
          enabled: true,
          createdAt: "2021-08-25T13:15:18.000Z",
          updatedAt: "2021-08-25T14:47:31.000Z",
        },
        {
          id: 20,
          name: "allExcel",
          description: null,
          options:
            '{"clientId":15,"period":60,"email":"gomes98@gmail.com","contato":"MAURICIO FELIPE BATISTA GOMES","emailId":1}',
          schedulerId: 5,
          groupId: 1,
          enabled: true,
          createdAt: "2021-09-23T11:36:17.000Z",
          updatedAt: "2021-09-23T11:44:23.000Z",
        },
        {
          id: 23,
          name: "opeFail",
          description: null,
          options:
            '{"clientId":15, "period": "12H", "email":"mauricio.gomes@stv.com.br", "contato":"Monitoramento","emailId":1}',
          schedulerId: 1,
          groupId: 1,
          enabled: true,
          createdAt: "2021-09-27T14:55:53.000Z",
          updatedAt: "2021-09-27T14:55:53.000Z",
        },
      ],
    };
  },
  mounted() {
    this.get();
  },
};
</script>

<style>
</style>