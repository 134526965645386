import axios from 'axios'
import { queryStringBuilder } from '../../Utils/queryStringBuilder'
export default {
  state: {
    ocorrencias: [],
    relOcorencias: [],
  },
  mutations: {
    setOcorrencias(state, payload) {
      state.ocorrencias = payload
    },
    setRelOcorrencias(state, payload) {
      state.relOcorencias = payload
    },
  },
  actions: {
    setOcorrencias(context, payload) {
      context.commit('setOcorrencias', payload)
    },
    loadOcorrencias(context, payload) {
      let gp = payload ? `?clientId=${payload}` : ''
      axios(`/ocorrencia${gp}`).then((resp) => {
        context.dispatch('setOcorrencias', resp.data)
      });
    },
    setRelOcorrencias(context, payload) {
      context.commit('setRelOcorrencias', payload)
    },
    loadReportOcorrencia(context, payload) {
      let url = `/ocorrencia?`;
      // gerador de query para relatorio
      url = queryStringBuilder(url, payload);
      axios(url).then((resp) => {
        context.commit("setRelOcorrencias", resp.data);
        if (resp.data.length == 0) {
          context.dispatch("resetSnack");
          context.dispatch("showErrorSnack", "Nenhum registro encontrado de acordo com o filtro");
        }
      });
    },
  },
  getters: {
    getOcorrencias(state) {
      return state.ocorrencias
    },
    getRelOcorrencias(state) {
      return state.relOcorencias
    },
    getOcorrenciaNameById: (state) => (id) => {
      if (!state.ocorrencias) {
        return id
      }
      let gp = state.ocorrencias.find(g =>
        g.id == id
      )
      return `${gp.id} - ${gp.name}`
    },
  },
}