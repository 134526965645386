<template>
  <div>
    <v-card-title class="pb-0">Notas vinculadas ao cliente</v-card-title>
    <v-data-table
      :headers="headers"
      :items="notes"
      :loading="load"
      disable-sort
      class="mb-2"
      :no-data-text="load ? '' : 'Nenhuma Nota cadastrada'"
      :footer-props="{'items-per-page-options': [15, 30, 50, 100, -1]}"
    >
    
      <template slot="item.createdAt" slot-scope="{ item }">
        {{ new Date(item.createdAt).toLocaleString() }}
      </template>

      <template slot="item.actions" slot-scope="{ item }">
        <!-- botão editar -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="editar(item)"
              color="warning"
              class="mr-1"
            >mdi-pencil</v-icon>
          </template>
          <span>Editar : {{ item.name }}</span>
        </v-tooltip>
        <!-- botão excluir -->
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="excluir(item)"
              color="red"
            >mdi-trash-can</v-icon>
          </template>
          <span>Excluir : {{ item.name }}</span>
        </v-tooltip>
      </template>

    </v-data-table>
    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        @click="add"
      >
        <v-icon class="mr-1">mdi-plus</v-icon> Nova Nota
      </v-btn>
    </div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>{{ dialogFields.id ? "Editar Nota" : "Cadastrar Nota" }}</v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="formNote">
            <v-textarea
              outlined
              label="Nota"
              v-model="dialogFields.note"
              counter="255"
              maxlength="255"
              required
              :rules="requiredField"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="success" @click="save(dialogFields)">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ConfirmDialog from '@/components/ConfirmDialog';
import { requiredField } from "@/Utils/InputValidation";
export default {
  name: "Notas",
  props: {
    clientId: { type: Number },
  },
  components: {
    ConfirmDialog
  },
  data: () => ({
    dialog: false,
    requiredField,
    dialogFields: {},
    notes: [],
    load: false,
    headers: [
      { text: "Data", value: "createdAt" },
      { text: "Nota", value: "note" },
      { text: "Usuário", value: "userName" },
      { text: "Ações", value: "actions", width: 84 }
    ]
  }),
  computed: {
    ...mapGetters(["getUsers", "getUsuario"]),
    },
  methods: {
    add() {
      this.dialog = true;
      this.dialogFields = {};
      this.$nextTick(() => {
        this.$refs.formNote.resetValidation();
      });
    },
    editar(item) {
      this.dialog = true;
      this.dialogFields = item;
    },
    excluir(item) {
      this.$refs.confirmDialog.open({
        title: "Excluir Nota",
        text: `Deseja mesmo excluir a nota?`,
        confirmCallback: () => {
          this.$http.delete(`eventNote/${item.id}`).then((resp) => {
            if (resp.status < 300) {
              this.get();
              this.$store.dispatch("resetSnack");
              this.$store.dispatch('showSuccessSnack', "Nota excluída com sucesso");
            }
          });
        },
      });
      
    },
    get() {
      this.load = true;
      this.$http(`eventNote?clientId=${this.clientId}`).then((resp) => {
        resp.data.forEach((element) => {
          element.userName = this.getUsers.find((user) => user.id == element.userId).name;
        });
        this.notes = resp.data;
        this.load = false;
      });
    },
    save() {
      if (!this.$refs.formNote.validate())
        return;
      this.dialog = false;
      this.dialogFields.clientId = this.clientId;
      this.dialogFields.userId = this.getUsuario.id;
      if (this.dialogFields.id) {
        this.$http
          .put(`eventNote/${this.dialogFields.id}`, this.dialogFields).then((resp) => {
            if (resp.status < 300) {
              this.get();
              this.$store.dispatch("resetSnack");
              this.$store.dispatch('showSuccessSnack', "Nota editada com sucesso");
            }
          });
      } else {
        this.$http.post(`eventNote`, this.dialogFields).then((resp) => {
          if (resp.status < 300) {
            this.get();
            this.$store.dispatch("resetSnack");
            this.$store.dispatch('showSuccessSnack', "Nota cadastrada com sucesso");
          }
        });
      }
    },
  },
  watch: {
    clientId(val) {
      if (val)
        this.get();
      else
        this.notas = [];
    }
  },
  created(){
    if(this.clientId){
      this.get();
    }
    
  }
};
</script>

<style>
</style>