<template>
  <div class="cliente">
    <ClienteCard :cliente="cliente" @refresh="refresh" />
    <v-card>
      <v-tabs fixed-tabs background-color="success" show-arrows class="rounded-b-0">
        <v-tab class="d-print-none">
          <v-icon class="mr-1">mdi-human-greeting</v-icon>Pessoas ({{ visitas.length }})
        </v-tab>
        <v-tab-item>
          <TableVisitas
            :items="visitas"
            :headers="headersVisitas"
            :actions="false"
          />
        </v-tab-item>     
        <v-tab class="d-print-none">
          <v-icon class="mr-1">mdi-car</v-icon>Veículos ({{ veiculos.length }})
        </v-tab>
        <v-tab-item>
          <TableVeiculos
            :headers="headersVeiculos"
            :items="veiculos"
            :actions="false"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ClienteCard from "./components/ClienteCard";
import TableVisitas from "../Visitas/TablePresents";
import TableVeiculos from "../Veiculo/TableReportVeiculos";

export default {
  name: "ConfigCliente",
  components: {
    ClienteCard,
    TableVisitas,
    TableVeiculos,
  },
  computed: {
    cliente: {
      get() {
        return this.$store.getters.getCliente;
      },
    },
  },
  data: () => {
    return {
      headersVisitas: [
        { text: "Visitante", value: "visitanteName" },
        { text: "Visitado", value: "visitadoName" },
        { text: "Descrição", value: "description" },
        { text: "Entrada", value: "createdAt" },
      ],
      headersVeiculos: [
        {text: "Imagens Iniciais", value: "id", sortable: false, filterable: false, align: "center", width: 70, },
        { text: "Placa", value: "placa" },
        { text: "Descrição", value: "description" },
        { text: "Entrada", value: "createdAt" },
        { text: "Visita", filterable: false, sortable: false, value: "visitaId", width: 450, },
      ],
      visitas: [],
      veiculos: [],
    };
  },
  methods: {
    getVisitas() {
      this.$http(`visita/in?groupId=${this.cliente.groupId}`).then((resp) => {
        resp.data.forEach((element) => {
          element.createdAt = new Date(element.createdAt).toLocaleString();
        });
        this.visitas = resp.data;
      });
    },
    getVeiculos() {
      this.$http(`veiculo2/in?groupId=${this.cliente.groupId}`).then((resp) => {
        this.veiculos = resp.data;
      });
    },
    refresh() {
      this.getVisitas();
      this.getVeiculos();
    },
  },
  created() {
    this.refresh();
  },
};
</script>
<style>
  .theme--light.v-tabs-items {
    background-color: transparent !important;
  }
</style>
