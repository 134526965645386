<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="warning"
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
          class="px-2"
        >
          <v-icon class="mr-1">mdi-file-download-outline</v-icon> Exportar Relatório
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item @click="gerarPdf()">
          <v-list-item-icon class="mr-1">
            <v-icon class="mr-1">mdi-file-document-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>PDF sem imagens</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="optionImg" @click="gerarPdf(true)">
          <v-list-item-icon class="mr-1">
            <v-icon class="mr-1">mdi-file-image-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>PDF com imagens</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="gerarExcel()">
          <v-list-item-icon class="mr-1">
            <v-icon class="mr-1">mdi-file-excel-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Excel sem imagens</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <DialogLoading
      v-model="$store.getters.getShowDialog"
      :title="$store.getters.getTitleDialog"
      :message="$store.getters.getMessageDialog"
      :progress="$store.getters.getValueDialog"
    />
  </div>
</template>

<script>
import PDF from "@/Utils/PDF";
import Excel from "@/Utils/Excel";
import DialogLoading from "@/components/Template/DialogLoading.vue";
export default {
  name: "MenuExportReport",
  components: {
    DialogLoading,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Relatório",
    },
    filename: {
      type: String,
      default: "relatorio",
    },
    optionImg: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    // gera o pdf
    gerarPdf(comImagens = false) {
      this.$store.dispatch("setTitleDialog", "Gerando o Relátorio");
      this.$store.dispatch("setShowDialog", true);
      console.log(this.module)
      PDF[this.module](
        this.items,
        this.title,
        this.filename,
        comImagens
      );
    },
    // gera o Excel
    gerarExcel() {
      Excel[this.module](
        this.items,
        this.title,
        this.filename,
      );
    },
  },
};
</script>

<style>
</style>