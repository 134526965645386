import axios from 'axios'
export default {
    state: {
        fleetVehicles: [],
        fleetVehicle: {},
        fleetVehicleFilter: '',
        fClientId: 0
    },
    mutations: {
        setFleetsVehicles(state, payload) {
            state.fleetVehicles = payload
        },
        setFleetVehicle(state, payload) {
            state.fleetVehicles = payload
        },
        setfClientId(state, payload) {
            state.fClientId = payload
        },
        setFilterFleetsVehicle(state, payload) {
            state.fleetVehicleFilter = payload
        },
    },
    actions: {
        setFleetsVehicles(context, payload) {
            context.commit('setFleetsVehicles', payload)
        },
        setFleetVehicle(context, payload) {
            context.commit('setFleetVehicle', payload)
        },
        loadFleetsVehicles(context, payload) {
            context.commit('setfClientId', payload)
            let aux = payload ? `?clientId=${payload}` : ''
            axios(`frotaVeiculo${aux}`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - (${element.prefix}) ${element.plate}`,
                        element.value = element.id
                });
                context.commit('setFleetsVehicles', resp.data)
            });
        },
        saveFleetVehicle(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";

            if (typeof payload.picture === 'object') {
                let formData = new FormData();
                for (let valor in payload) {
                    formData.append(valor, payload[valor])
                }
                axios[metodo](`frotaVeiculo${finalUrl}?clientId=${context.state.fClientId}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((resp) => {
                    if (resp.status < 299) {
                        context.dispatch("resetSnack");
                        context.dispatch("showSuccessSnack", "Frota salvo com Sucesso");
                        context.dispatch('loadFleetsVehicles', context.state.fClientId)
                    }
                });
            } else {
                axios[metodo](`frotaVeiculo${finalUrl}?clientId=${context.state.fClientId}`, payload).then((resp) => {
                    if (resp.status < 299) {
                        context.dispatch("resetSnack");
                        context.dispatch("showSuccessSnack", "Frota salvo com Sucesso");
                        context.dispatch('loadFleetsVehicles', context.state.fClientId)

                    }
                });
            }
        },
        saveFleetVehicle2(context, payload) {
            delete payload.picture;
            let formData = new FormData();
            for (const key in payload) {
                if (Object.hasOwnProperty.call(payload, key)) {
                    const element = payload[key];
                    formData.append(key, element);
                }
            }
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/frotaVeiculo2${finalUrl}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((resp) => {
                if (resp.status < 299) {
                    if (typeof payload.callback == 'function') {
                        payload.callback();
                    }
                    context.dispatch('loadFleetsVehicles')
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Frota salva com Sucesso");
                }
            });
            // const metodo = payload.id ? "put" : "post";
            // const finalUrl = payload.id ? `/${payload.id}` : "";

            // if (typeof payload.picture === 'object') {
            //     let formData = new FormData();
            //     for (let valor in payload) {
            //         formData.append(valor, payload[valor])
            //     }
            //     axios[metodo](`frotaVeiculo${finalUrl}?clientId=${context.state.fClientId}`, formData, {
            //         headers: {
            //             "Content-Type": "multipart/form-data",
            //         },
            //     }).then((resp) => {
            //         if (resp.status < 299) {
            //             context.dispatch("resetSnack");
            //             context.dispatch("showSuccessSnack", "Frota salvo com Sucesso");
            //             context.dispatch('loadFleetsVehicles', context.state.fClientId)
            //         }
            //     });
            // } else {
            //     axios[metodo](`frotaVeiculo${finalUrl}?clientId=${context.state.fClientId}`, payload).then((resp) => {
            //         if (resp.status < 299) {
            //             context.dispatch("resetSnack");
            //             context.dispatch("showSuccessSnack", "Frota salvo com Sucesso");
            //             context.dispatch('loadFleetsVehicles', context.state.fClientId)

            //         }
            //     });
            // }
        },
        deletFleetVehicle(context, payload) {
            axios.delete(`frotaVeiculo/${payload.id}?clientId=${context.state.fClientId}`).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Frota Deletado com Sucesso");
                    context.dispatch('loadFleetsVehicles', context.state.fClientId)
                }
            });
        },
    },
    getters: {
        getFleetsVehicles(state) {
            return state.fleetVehicles
        },
        getFleetVehicle(state) {
            return state.fleetVehicle
        },
        getfClientId(state) {
            return state.fClientId
        },
        getFleetsSelect(state) {
            return state.fleetVehicles.map((v) => {
                return {
                    value: v.id,
                    text: `${v.id} - ${v.plate}`,
                    picture: v.picture,
                };
            });
        },
        getFleetVehicleHeaders() {
            return [
                { text: "ID", value: "id" },
                {
                    text: "Imagem",
                    align: "center",
                    filterable: false,
                    value: "picture",
                    sortable: false 
                },
                { text: "Prefixo", value: "prefix" },
                { text: "Modelo", value: "model" },
                { text: "Placa", value: "plate" },
                { text: "Descrição", value: "description", sortable: false },
                { text: "Ações", value: "actions", sortable: false, width: "84px" },
            ]
        },
        getFleetVehiclesFilter(state) {
            const filterModelStr = (str = "", search = "") => {
                if (typeof str == 'string'){
                    str = str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    return str.includes(search);
                }
                else return true;
            }
            let cf = state.fleetVehicles;
            // filtro por texto
            cf = cf.filter((fleetVehicle) => {
                return filterModelStr(fleetVehicle.plate, state.fleetVehicleFilter)
                || filterModelStr(fleetVehicle.prefix, state.fleetVehicleFilter);
            });
            return cf
        },
    },
}