<template>
  <v-autocomplete
    v-bind="value"
    v-model="valueIn"
    :items="items"
    :label="label"
    outlined
    multiple
    @change="change"
    small-chips
    clearable
    chips 
    :persistent-hint="persistenthint"
    :hint="hint"
     >
    <!-- lista dos chips -->
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-img :src="data.item.picture" lazy-src="/img/nopic.jpg" />
        </v-avatar>
        {{ data.item.text }}
      </v-chip>
    </template>
    <!-- lista do select -->
    <template v-slot:item="data">
      <template>
        <v-list-item-avatar>
          <v-img :src="data.item.picture" lazy-src="/img/nopic.jpg" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "AutoCompleteImages",
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
      default: () => "",
    },
    persistenthint: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valueIn: [],
    };
  },
  methods: {
    change() {
      this.$emit("input", this.valueIn);
      this.$emit("change", this.valueIn);
    },
    remove(item) {
      const index = this.valueIn.indexOf(item.value);
      if (index >= 0) this.valueIn.splice(index, 1);
      this.change();
    },
  },
};
</script>

<style>
</style>