export default {
    state: {
        niveis: [],
        nivel: 3
    },
    mutations: {
        setNiveis(state, payload) {
            state.niveis = payload
        },
        setNivel(state, payload) {
            state.nivel = payload
        },
    },
    actions:{
        setNiveis(context, payload) {
            context.commit('setNiveis', payload)
        },
        setNivel(context, payload) {
            context.commit('setNivel', payload)
        },
    },
    getters: {
        getNiveis(state) {
            return state.niveis
        },
        getNivel(state) {
            return state.niveis
        },
    }
}