<template>
  <div class="AppCRUD">
    <v-card class="mb-1" v-if="formUsr || app.id">
      <v-card-title>
        {{ app.id ? "Edição App" : "Cadastro de App" }}
      </v-card-title>
      <v-card-text>

        <v-text-field
          v-model="app.version"
          label="Versão"
          required
          type="number"
        ></v-text-field>

        <v-textarea
          v-model="app.description"
          name="input-7-1"
          :counter="255"
          label="Descrição"
        ></v-textarea>

        <v-file-input
          chips
          multiple
          label="Selecione o Arquivo do App"
          counter
          :show-size="1000"
          v-model="app.app"
        ></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" class="mr-4" @click="clearObj"
          >Limpar Campos</v-btn
        >
        <v-btn color="success" class="mr-4" @click="save"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
    <div class="div d-flex justify-end ma-2" v-else>
      <v-btn color="success" @click="formUsr = true">Enviar Novo APP</v-btn>
      <v-btn icon @click="get"><v-icon>mdi-refresh</v-icon></v-btn>
    </div>
    <v-card>
      <v-card-text>
        <v-progress-linear
          v-if="getProgressApp"
          color="light-green darken-4"
          height="30"
          :value="getProgressApp"
          striped
        >
          {{ `${getProgressApp}%` }}
        </v-progress-linear>
        <div class="tbl mt-1">
          <Table
            :items="lista"
            :headers="headers"
            :enablePicture="true"
            @edit="editar($event)"
            @delete="delet($event)"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Table from "../../TableGeneric";
import {mapGetters} from 'vuex';
export default {
  data() {
    return {
      formUsr: false,
      description: "",
      file: null,
      app: {
        id: null,
        version: 1.0,
      },
    };
  },
  computed: {
    lista: {
      get() {
        return this.$store.getters.getListaApp;
      },
    },
    headers: {
      get() {
        return this.$store.getters.getHeadersApps;
      },
    },
  ...mapGetters(['getProgressApp'])
  },
  components: {
    Table,
  },
  watch: {
    file() {
      console.log(this.file[0]);
    },
  },
  methods: {
    get() {
      this.edicao = false;
      this.$store.dispatch("loadListaApps");
    },
    save() {
      if(!this.app.app || !this.app.description){
        return
      }
      this.$store.dispatch("saveApp", this.app);
      this.clearObj();
    },
    editar() {
      alert("Edição não Implementada")
    },
    delet(event) {
      this.$store.dispatch("deleteApp", event.id);
      this.get();
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
    formataData(dataUTC) {
      return new Date(dataUTC).toLocaleString();
    },
  },
  created() {
    this.get();
  },
};
</script>

<style>
</style>