<template>
  <v-list-item>
    <v-list-item-avatar width="75" height="75">
      <v-avatar size="75">
        <v-img :src="item.visitantePic" lazy-src="/img/nopic.jpg"></v-img>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="mb-2">{{ item.visitanteName }}</v-list-item-title>
      <v-list-item-subtitle class="mb-1">Visitado: {{ item.visitadoName }}</v-list-item-subtitle>
      <v-list-item-subtitle class="mb-1">Entrada: {{ item.createdAt }}</v-list-item-subtitle>
      <v-list-item-subtitle>Motivo: {{ item.description }}</v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action v-if="remove">
      <v-btn icon large @click="$emit('remove', item)">
        <v-icon color="red">mdi-trash-can</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: "ItemVisita",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    remove: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style>
</style>