<template>
  <!--v-card
    v-show="value"
    outlined
    width="480"
    class="my-2"
  >
    <v-card-text class="pa-1"-->
      <div class="d-flex" v-if="value">
        <v-avatar size="70" class="mr-3">
          <v-img :src="visitante.picture" lazy-src="/img/nopic.jpg"></v-img>
        </v-avatar>
        <div class="text-no-wrap text-truncate">
          <span class="d-block text-subtitle-1 my-2">{{ visitante.name }}</span>
          <div class="d-flex text-caption">
            <div class="mr-4">
              <span><strong>Referencia:</strong> {{ visitante.reference }}</span><br>
              <span><strong>Documento:</strong> {{ visitante.document }}</span>
            </div>            
            <div>
              <span><strong>Chegou:</strong> {{ formataData(visita.createdAt) }}</span><br />
              <span><strong>Recebido Por:</strong> {{ visitado.name }}</span>
            </div>
          </div>
        </div>
      </div>
    <!--/v-card-text>
  </v-card-->
</template>

<script>
export default {
  name: "CardVisitaVeiculo",
  props: ["value"],
  data: () => {
    return {
      visita: {},
      visitante: {},
      visitado: {},
    };
  },
  methods: {
    getVisitaById() {
      if (this.value == null) {
        return;
      }
      this.$http(`visita/${this.value}`).then((resp) => {
        this.visita = resp.data;
        if(resp.data.id){
          this.getVisitanteById(resp.data.visitanteId);
          this.getVisitadoById(resp.data.visitadoId);
        }
      });
    },
    getVisitanteById(id) {
      this.$http(`visitante/${id}`).then((resp) => {
        this.visitante = resp.data[0];
      });
    },
    getVisitadoById(id) {
      this.$http(`visitado/${id}`).then((resp) => {
        this.visitado = resp.data[0];
      });
    },
    formataData(dataUTC) {
      return new Date(dataUTC).toLocaleString();
    },
  },
  created() {
    if(this.value !=null){
        this.getVisitaById();
    }
  },
};
</script>

<style>
</style>