<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="700">
    <v-card>
      <v-card-title class="text-h5 pr-4 pb-4">Saída de Veículo<v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-card outlined class="mb-3">
          <v-card-title class="pt-2">Informações do veículo</v-card-title>
          <v-card-text>
            <span class="mr-8"><strong>Placa:</strong> {{ item.placa }}</span>
            <span class="mr-8"><strong>Entrada:</strong> {{ formataData(item.createdAt) }}</span><br />
            <span class="mr-8"><strong>Descrição:</strong> {{ item.description }}</span>
          </v-card-text>
        </v-card>
        <v-card outlined class="mb-3" v-if="visita">
          <v-card-title class="pt-2 pb-0">Visita</v-card-title>
          <ItemVisita
            :item="visita"
            :remove="false"
          />
        </v-card>
        <!-- card com as imagens -->
        <v-card outlined class="mb-3">
          <v-card-title class="py-2">Imagens da saída</v-card-title>
          <v-card-text class="pa-3 pt-0">
            <Webcam @file="addFile" @base64="addBase64" />
            <div class="d-flex flex-wrap">
              <div class="pa-1" style="width:25%" v-for="(img, idx) in veiculo.base64" :key="idx">
                <v-card color="orange lighten-1 transition">
                  <v-btn text block @click="removeImage(idx, $event)">
                    <v-icon color="red">mdi-trash-can</v-icon>
                  </v-btn>
                  <v-img :src="img" width="100%"></v-img>
                </v-card>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-textarea
          v-model="veiculo.description"
          :counter="255"
          label="Descrição da saída"
          outlined
        ></v-textarea>
      </v-card-text>
      <v-divider></v-divider>
      <!-- ações do card -->
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancelar</v-btn>
        <v-btn color="success" @click="registrarSaida"> Registrar Saída </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Webcam from "@/components/Webcam/WbDialog.vue";
import ItemVisita from "./item_visita.vue";
export default {
  name: "DialogVeiculoExit",
  components: {
    Webcam,
    ItemVisita,
  },
  data() {
    return {
      dialog: false,
      item: {},
      visita: {},
      veiculo: {
        images: [],
        base64: [],
        description: null,
      },
    };
  },
  methods: {
    open(item) {
      this.dialog = true;
      this.item = item;
      this.visita = this.$store.getters.getVisitas.find(
        (item) => item.id == this.item.visitaId
      );
    },
    registrarSaida() {
      this.veiculo.id = this.item.id;
      this.veiculo.callback = () => {
        this.close();
        this.$emit("closeVehicleDetails"); // fecha o dialog de detalhes do veículo pois a saída pode ter sido chamada por lá
      };
      // this.$store.dispatch("registrarEntradaVeiculo", this.veiculo);
      this.$store.dispatch("registrarSaidaVeiculo", this.veiculo);
    },
    close() {
      this.veiculo = {
        visitaId: null,
        placa: null,
        descriptionExit: null,
        images: [],
        base64: [],
      };
      this.dialog = false;
    },
    addFile(file) {
      this.veiculo.images.push(file);
    },
    addBase64(base64) {
      this.veiculo.base64.push(base64);
    },
    removeImage(idx, event) {
      // adicionando efeito de transição ao remover a imagem
      let card = event.srcElement.closest(".transition");
      card.style.opacity = 0;
      setTimeout(() => {
        this.veiculo.base64.splice(idx, 1);
        this.veiculo.images.splice(idx, 1);
        card.style.opacity = 1;
      }, 200);
    },
    formataData(dataUTC) {
      return new Date(dataUTC).toLocaleString();
    },
  },
};
</script>

<style>
.finishScroll {
  overflow-y: auto;
  min-height: 300px;
}
</style>