<template>
	<v-card>
		<v-card-title>Cadastros de Ambientes</v-card-title>
		<v-card-text>
			<DataTable
				:headers="headers"
				:items="ambientes"
				:enableSelect="true"
				@update:selectedItems="selecionados = $event"
			>
				<template slot="item.name" slot-scope="{ item }">
					<TextTooltip :text="item.name" :maxWidth="'200'" />
				</template>
				<template slot="item.enabled" slot-scope="{ item }">
					<span>{{ (item.ativo = item.enabled ? "Sim" : "Não") }}</span>
				</template>
				<template slot="item.description" slot-scope="{ item }">
					<span
						><TextTooltip :text="item.description" :maxWidth="'250'"
					/></span>
				</template>
				<template slot="item.actions" slot-scope="{ item }">
					<!--botão editar Ambiente -->
					<TooltipIcon
						color="warning"
						:text="`Editar: ${item.name}`"
						icon="mdi-pencil"
						top="true"
						@click="editarAmbiente({ ...item })"
					/>
					<!--botão excluir Ambiente -->
					<TooltipIcon
						color="red"
						:text="`Excluir: ${item.name}`"
						icon="mdi-trash-can"
						top="true"
						@click="delAmbiente({ ...item })"
					/>
					<!--botão gerar QR Code -->
					<TooltipIcon
						:text="`Gerar QR Code: ${item.name}`"
						icon="mdi-qrcode"
						top="true"
						@click="print(item)"
					/>
				</template>
			</DataTable>
			<!-- botão de gerar varios QR Code -->
			<div class="btn-print ml-5" style="margin-top: -42px">
				<v-icon class="mr-2 mb-2">mdi-arrow-up-left</v-icon>
				<v-btn :disabled="selecionados == 0" @click="printSelect(selecionados)"
					><v-icon class="mr-1">mdi-qrcode</v-icon> Gerar QR Codes</v-btn
				>
			</div>
		</v-card-text>

		<!-- botão de cadastrar -->
		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="amber darken-1"
					dark
					absolute
					bottom
					right
					fab
					fixed
					class="mb-10"
					v-bind="attrs"
					v-on="on"
					@click="cadastrarAmbiente"
				>
					<v-icon color="black">mdi-plus</v-icon>
				</v-btn>
			</template>
			<span>Adicionar Ambiente</span>
		</v-tooltip>

		<ConfirmDialog ref="confirmDialog" />
		<CadAmbientes ref="cadAmbientes" />
		<CheckpointsDialog ref="checkpointsDialog" />
	</v-card>
</template>


<script>
import {
	reqFieldMinLength,
	requiredField,
} from "../../../Utils/InputValidation";
import DataTable from "../../DataTableGeneric";
import ConfirmDialog from "../../ConfirmDialog.vue";
import CadAmbientes from "./CadAmbientes.vue";
import { mapGetters } from "vuex";
import TextTooltip from "../../Template/TextTooltip.vue";
import TooltipIcon from "../../Template/TooltipIcon.vue";
import CheckpointsDialog from "./CheckpointsDialog.vue";

export default {
	name: "cliAmbientes",
	components: {
		DataTable,
		ConfirmDialog,
		CadAmbientes,
		TextTooltip,
		TooltipIcon,
		CheckpointsDialog,
	},
	props: ["ambientes"],
	data: () => {
		return {
			ambiente: {
				id: null,
				name: "",
				enabled: true,
				clientId: null,
				description: "",
				checkPointId: null,
			},
			reqFieldMinLength,
			requiredField,
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Ambiente", value: "name" },
				{ text: "Ativo", value: "enabled" },
				{ text: "Descrição:", value: "description"},
				{ text: "QR Code", value: "checkPointId" },
				{
					text: "Ações",
					value: "actions",
					align: "center",
					sortable: false,
					filterable: false,
					width: 150,
				},
			],
			selecionados: [],
		};
	},
	computed: {
		...mapGetters([
			"getClienteSelect",
			"getCheckpointsFacilities",
			"getCheckpointNameById",
		]),
	},
	methods: {
		reset() {
			this.$refs.cadAmbiente.reset();
		},
		editarAmbiente(item) {
			this.$refs.cadAmbientes.open();
			this.$refs.cadAmbientes.ambiente = item;
		},
		delAmbiente(item) {
			this.$refs.confirmDialog.open({
				title: "Excluir Ambiente",
				text: `Deseja realmente excluir o ambiente <strong>${item.name}</strong>?`,
				confirmCallback: () => {
					this.$store.dispatch("deleteAmbiente", item);
				},
			});
		},
		cadastrarAmbiente() {
			this.$refs.cadAmbientes.ambiente = {
				id: null,
				name: "",
				enabled: true,
				clientId: this.getClienteSelect.id,
				description: "",
				checkPointId: null,
			};
			this.$refs.cadAmbientes.open();
		},
		print(item) {
			let checkpoint = [this.getCheckpointsFacilities.find((ck) => ck.id == item.checkPointId)];
			this.$refs.checkpointsDialog.open({
				checkpoints: checkpoint,
				isFacilities: true
			});
		},
		printSelect() {
			let checkpoints = [];
			this.selecionados.forEach((item) => {
				checkpoints.push(
					this.getCheckpointsFacilities.find((ck) => ck.id == item.checkPointId)
				);
			});
			this.$refs.checkpointsDialog.open({
				checkpoints: checkpoints,
				isFacilities: true
			});
		},
	},
	created() {
		this.$store.dispatch("loadClientes");
		this.$store.dispatch("loadCheckpoints", this.$store.getters.getCliente.id);
	},
};
</script>
<style>
</style>