<template>
  <div class="cliPosto">
    <v-card-title>Cadastros de Postos</v-card-title>
    <v-card-text>
      <DataTable
        :items="postos"
        :headers="headers"
      >
        <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="editar(item)"
                color="warning"
                class="mr-1"
              >mdi-pencil</v-icon>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
        </template>

      </DataTable>
    </v-card-text>

    <!-- dialog de cadastro -->
    <v-dialog v-model="dialog" persistent max-width="700" scrollable>
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2">{{ posto.id ? "Editar" : "Cadastrar" }} Posto<v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-form ref="formPosto" :disabled="!posto.enabled">
            <v-text-field
              v-model="posto.name"
              label="Nome"
              required
              :rules="requiredField"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="posto.address"
              label="Endereço"
              required
              :rules="requiredField"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="posto.phone"
              label="Telefone"
              outlined
            ></v-text-field>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="posto.sign"
                  label="Senha de pânico"
                  required
                  :rules="requiredField"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="posto.countersign"
                  label="Contra-senha de pânico"
                  required
                  :rules="requiredField"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-textarea
              v-model="posto.description"
              label="Descrição"
              outlined
            ></v-textarea>
          </v-form>
          <v-switch
            v-model="posto.enabled"
            label="Ativo"
            outlined
            class="mt-0 pt-0"
          ></v-switch>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancelar</v-btn>
          <v-btn color="success" @click="save"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="amber darken-1" dark absolute bottom right fab fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
        >
          <!--:to="{ name: 'cadPosto', params: { groupId } }" -->
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Posto</span>
    </v-tooltip>
  </div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import DataTable from "../../DataTableGeneric";
export default {
  name: "CliPosto",
  components: { DataTable },
  props: ["postos", "groupId"],
  data: () => {
    return {
      dialog: false,
      requiredField,
      posto: {},
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Endereço", value: "address", sortable: false  },
        { text: "Telefone", value: "phone", sortable: false  },
        { text: "Descrição", value: "description", sortable: false  },
        { text: "Ativo", value: "ativo", width: 80 },
        { text: "Ações", value: "actions", sortable: false, filterable: false, width: 70 },
      ],
    };
  },
  methods: {
    save() {
      if (!this.$refs.formPosto.validate())
        return;
      const metodo = this.posto.id ? "put" : "post";
      const finalUrl = this.posto.id ? `/${this.posto.id}` : "";
      this.$http[metodo](`/posto${finalUrl}`, this.posto).then(() => {
        this.$store.dispatch("closeSnack");
        this.$store.dispatch("showSuccessSnack", "Posto salvo com Sucesso");
        this.close();
        this.$emit("update");
      });
    },
    close() {
      this.$refs.formPosto.resetValidation();
      this.dialog = false;
      this.reset();
    },
    reset() {
      this.posto = {
        id: null,
        name: "",
        address: "",
        phone: "",
        description: "",
        groupId: this.groupId ? this.groupId : 0,
        enabled: true,
        createdAt: "",
        updatedAt: "",
      };
    },
    editar(item) {
      this.posto = { ...item };
      this.dialog = true;
    },
  },
  mounted() {
    this.reset();
  },
};
</script>

<style>
</style>