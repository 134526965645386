<template>
  <div>
    <v-card :disabled="disabled" flat>
      <v-card-title>Atendimento Realizado</v-card-title>
      <v-form ref="formTreatment">
        <v-textarea
          outlined
          label="Tratamento do Evento"
          v-model="treatment"
          counter
          required
          :rules="[
            requiredField[0],
          ]"
        ></v-textarea>
      </v-form>

      <!-- Atalhos de Preenchimento -->
      <div class="px-4 mb-5">
        <p class="mb-2">Tratamentos rápidos</p>
        <div class="d-flex justify-space-between">
          <span v-for="item in 15" :key="item">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  text
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="shortAppend(getShort([parseInt(item)]).message)"
                  @contextmenu.prevent="context(item)"
                  >{{ item }}</v-btn
                >
              </template>
              <span>{{ getShort([parseInt(item)]).message }}</span>
            </v-tooltip>
          </span>
        </div>
      </div>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-btn color="success" @click="tratar" :disabled="disabled">
          <v-icon class="mr-1">mdi-check-bold</v-icon>
          Fechar Evento
        </v-btn>
      </div>
    </v-card>

    <!-- usado para alterar dados dos atalhos -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="pb-4">Editar Atalho</v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="formShortcut">
            <v-text-field
              label="Digite o Novo Texto"
              v-model="dialogFields.message"
              counter="255"
              maxlength="255"
              required
              :rules="[
                requiredField[0],
                (v) => v.length <= 255 || 'Máximo de 255 caracteres',
              ]"
              @keydown.enter="changeShort"
              outlined
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="success" @click="changeShort(dialogFields)"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { requiredField } from "../../../Utils/InputValidation";
export default {
  data: () => ({
    dialogFields: {
      id: 0,
      text: "",
    },
    treatment: "",
    dialog: false,
    load: false,
    requiredField,
  }),
  props: ["disabled"],
  methods: {
    shortAppend(text) {
      this.treatment += `${text} `;
    },
    context(id) {
      this.dialogFields = { ...this.getShort(id) };
      this.dialog = true;
    },
    changeShort() {
      if (!this.$refs.formShortcut.validate()) return;
      this.$store.dispatch("saveShortcut", this.dialogFields);
      this.dialog = false;
    },
    tratar() {
      if (!this.$refs.formTreatment.validate()) return;
      let data = { id: this.getSelectedEvent.id, treatment: this.treatment };
      this.$store.dispatch("treatEvent", data);
    },
    getShort(id) {
      if (!this.getShortcuts) return id;

      let tl = this.getShortcuts.find((t) => t.shortId == id);
      if (tl) return tl;

      return {
        message: "Clique com o botão direito para cadastrar",
        shortId: id,
      };
    },
  },
  computed: {
    ...mapGetters(["getSelectedEvent", "getShortcuts", "getLoadingEvents"]),
  },
  watch: {
    getSelectedEvent() {
      this.treatment = "";
      if (this.getSelectedEvent.treatment) {
        this.treatment = this.getSelectedEvent.treatment;
      }
      this.$refs.formTreatment.resetValidation();
    },
  },
};
</script>

<style>
</style>