<template>
  <v-dialog v-model="dialog" width="600" scrollable>
    <v-card>
      <v-card-title>
        <span>Configuração do aplicativo</span>
        <v-spacer></v-spacer>
        <v-btn fab text @click="dialog = false" small>
          <v-icon>mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle>
        Dispositivo: <strong>{{ device.name }}</strong>
      </v-card-subtitle>
      <v-card-text>
        <v-row class="mt-1">
          <v-col class="pb-0">
            <v-text-field
              v-model.number="device.config.rondaTimeNotification"
              label="Tempo para notificação de ronda (Minutos)"
              hint="Disponivel apartir da versão 1.0.1+8"
              :rules="requiredField"
              outlined
              persistent-hint
              type="number"
              min="0"
              max="60"
            />
          </v-col>
          <v-col class="pb-0">
            <v-select
              v-model="device.config.rondaNotification"
              :items="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]"
              label="Notificação de ronda"
              hint="Disponivel apartir da versão 1.0.1+8"
              persistent-hint
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <v-select
              v-model="device.config.rondaSound"
              :items="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]"
              label="Som de notificação de ronda"
              hint="Disponivel apartir da versão 1.0.1+8"
              persistent-hint
              outlined
            ></v-select>
          </v-col>
          <v-col class="pb-0">
            <v-select
              v-model="device.config.rondaPassword"
              :items="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]"
              label="Solicitar senha para executar ronda"
              hint="Disponivel apartir da versão 1.0.1+8"
              persistent-hint
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col class="pb-0">
            <v-select
              v-model="device.config.jornadaPassword"
              :items="[
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
              ]"
              label="Solicitar senha para jornada"
              hint="Disponivel apartir da versão 1.0.1+8"
              persistent-hint
              outlined
            ></v-select>
          </v-col>
        </v-row> -->
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requiredField } from "../../Utils/InputValidation";
export default {
  name: "DialogConfigApp",
  data: () => {
    return {
      dialog: false,
      device: {
          name: "",
          config: {
            rondaTimeNotification: 1,
          },
        },
      requiredField,
    };
  },
  computed: {},
  methods: {
    open(device) {  
      this.device = device;
      this.dialog = true;
    },
    save() {
      this.device.callback = () => {
        this.dialog = false;
      };
      this.$store.dispatch("saveDispositivo", this.device);
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.device = {
          name: "",
          config: {
            rondaTimeNotification: 1,
          },
        };
      } else {
        if (typeof this.device.config != "object") {
          this.device.config = {};
        }
        if (typeof this.device.config.rondaTimeNotification != "number") {
          this.device.config.rondaTimeNotification = 1;
        }
        if (typeof this.device.config.rondaSound != "boolean") {
          this.device.config.rondaSound = false;
        }
        if (typeof this.device.config.rondaPassword != "boolean") {
          this.device.config.rondaPassword = true;
        }
        if (typeof this.device.config.rondaNotification != "boolean") {
          this.device.config.rondaNotification = true;
        }
      }
    },
  },
};
</script>

<style scoped></style>