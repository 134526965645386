<template>
  <div class="cliente">
    <ClienteCard :cliente="cliente" />
    <v-card>
      <v-tabs fixed-tabs background-color="success" show-arrows class="rounded-b-0">
        <v-tab><v-icon class="mr-1">mdi-human-greeting</v-icon>Visitas</v-tab>
        <v-tab-item>
          <!-- <RelVisitas :groupId="cliente.groupId" /> -->
          <RelVisitas :groupId="cliente.groupId" v-if="cliente.typeCli != 2" />
          <RelVisitasCond :groupId="cliente.groupId" v-else />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-car</v-icon>Veículos</v-tab>
        <v-tab-item>
          <RelVeiculos />
        </v-tab-item>
        <v-tab v-show="cliente.typeCli != 2"><v-icon class="mr-1">mdi-car-multiple</v-icon>Frota</v-tab>
        <v-tab-item>
          <RelFrota :cliente="cliente" />
        </v-tab-item>
        <v-tab v-show="cliente.typeCli != 2"><v-icon class="mr-1">mdi-car-multiple</v-icon>Frota Multi</v-tab>
        <v-tab-item>
          <RelFrotaMulti :cliente="cliente" />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-book-information-variant</v-icon>Ocorrências</v-tab>
        <v-tab-item>
          <RelatoriOcorrencias />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-flashlight</v-icon>Rondas</v-tab>
        <v-tab-item>
          <RelatorioRondas />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-leaf</v-icon>Tarefas</v-tab>
        <v-tab-item>
          <RelatorioTarefas />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-alert-rhombus</v-icon>Eventos</v-tab>
        <v-tab-item>
          <RelatorioEventos />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-briefcase-clock</v-icon>Jornadas</v-tab>
        <v-tab-item>
          <RelatorioJornadas />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-eye</v-icon>Rondas Virtuais</v-tab>
        <v-tab-item>
          <RelatorioRondasVirtuais />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-file-document-outline</v-icon>Vis. de Documentos</v-tab>
        <v-tab-item>
          <RelatorioDocumentos />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ClienteCard from "./components/ClienteCard";
import RelVisitas from "../Visitas/RelatorioVisitas";
import RelVisitasCond from "../Visitas/RelatorioVisitasCond";
import RelVeiculos from "../Veiculo/RelatorioVeiculos";
import RelFrota from "../Frota/RelatorioFleetSimple";
import RelFrotaMulti from "../Frota/RelatorioFleetMulti";
import RelatoriOcorrencias from '../Ocorrencias/RelatoriOcorrencias.vue';
import RelatorioRondas from '../Rondas/RelatorioRondas.vue';
import RelatorioTarefas from "../Tarefas/RelatorioTarefas.vue";
import RelatorioEventos from "../ReportEventos/RelatorioEventos.vue";
import RelatorioJornadas from "../Postos/RelatorioJornadas.vue";
import RelatorioRondasVirtuais from '../RondasVirtuais/RelatorioRondasVirtuais.vue';
import RelatorioDocumentos from '@/components/ReportDocs/RelDocument.vue';
//import RelVisitas from "../Relatorios/Visitantes";
// import RelVisitasCond from "../Relatorios/VisitantesCond2";
//import RelVeiculos from "../Relatorios/Veiculos";
//import RelFrota from "../Cliente/RelFrota";
export default {
  name: "ClienteRelatorios",
  components: {
    ClienteCard,
    RelVisitas,
    RelVisitasCond,
    RelVeiculos,
    RelFrota,
    RelFrotaMulti,
    RelatoriOcorrencias,
    RelatorioRondas,
    RelatorioTarefas,
    RelatorioEventos,
    RelatorioJornadas,
    RelatorioRondasVirtuais,
    RelatorioDocumentos
},
  computed: {
    cliente: {
      get() {
        return this.$store.getters.getCliente;
      },
    },
  },
  data: () => {
    return {
    };
  },
  mounted() {
    this.$store.dispatch("loadUsers");
  },
  watch: {

  },  
};
</script>

<style>
</style>