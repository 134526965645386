import axios from 'axios'
import { arrayBufferToBase64 } from '../../Utils/queryStringBuilder'

export default {
  state: {
    snapshots: [],
    cameras: [],
    snapshot: null
  },
  mutations: {
    setSnapshots(state, payload) {
      state.snapshots = payload
    },
    setCameras(state, payload) {
      state.cameras = payload
    },
    setSnapshot(state, payload) {
      state.snapshot = payload
    },
  },
  actions: {
    setSnapshots(context, payload) {
      context.commit('setSnapshots', payload)
    },
    setCameras(context, payload) {
      context.commit('setCameras', payload)
    },
    setSnapshot(context, payload) {
      context.commit('setSnapshot', payload)
    },
    loadSnapshots(context, payload) {
      let query = payload ? `?clientId=${payload}` : ''
      axios(`/snapshot${query}`).then((resp) => {
        context.dispatch('setSnapshots', resp.data);
      });
    },
    loadCameras(context) {
      axios.post(`/snapshot/getCameras`).then((resp) => {
        context.dispatch('setCameras', resp.data)
      });
    },
    saveSnap(context, payload) {
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/snapshot${finalUrl}`, payload).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Endereço salvo com Sucesso");
          context.dispatch("loadSnapshots", payload.clientId);
          if (typeof payload.callback == 'function')
            payload.callback(resp.data);
        }
      });
    },
    deleteSnap(context, payload) {
      axios.delete(`/snapshot/${payload.id}`).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Endereço excluído com Sucesso");
          context.dispatch("loadSnapshots", payload.clientId);
          if (typeof payload.callback == 'function')
            payload.callback(resp.data);
        }
      });
    },
    getSnapShot(context, payload) {
      context.commit('setSnapshot', null);
      axios(`/snapshot/` + payload.id, { responseType: "arraybuffer", timeout: 5000 }).then((resp) => {
        if (resp.status == 200) {
          let img = arrayBufferToBase64(resp.data, resp.headers["content-type"])
          if (typeof payload.callback == "function") {
            payload.callback(img);
          }
          context.commit('setSnapshot', img);
        }
      }).catch(() => {
        context.dispatch("resetSnack");
        context.dispatch("showErrorSnack", "Timeout ao buscar imagem");
      });
    },
    getSnapShotTest(context, payload) {
      context.commit('setSnapshot', null);
      axios.post(`/snapshot/testSnap`, payload, { responseType: "arraybuffer", timeout: 5000 }).then((resp) => {
        if (resp.status == 200) {
          let img = arrayBufferToBase64(resp.data, resp.headers["content-type"])
          if (typeof payload.callback == "function") {
            payload.callback(img);
          }
          context.commit('setSnapshot', img);
        }
      }).catch(() => {
        context.dispatch("resetSnack");
        context.dispatch("showErrorSnack", "Timeout ao buscar imagem");
      });
    },
  },
  getters: {
    getSnapshots(state) {
      return state.snapshots;
    },
    getCameras(state) {
      return state.cameras;
    },
    getSnapshot(state) {
      return state.snapshot;
    }
  }
}
