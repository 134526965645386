<template>
  <div>
    <template v-if="!report">
      <v-card-title class="pt-6">Frotas Iniciadas (Antiga)</v-card-title>
      <v-card-subtitle>Clique na tabela para mais detalhes</v-card-subtitle>
    </template>
    <v-card-text>
      <DataTable
        :headers="headers"
        :items="items"
        @rowClick="openFleetDetails($event)"
      >
        <template v-slot:item.picturesStart="{ item }">
          <CardImg
            :items="item.picturesStart"
            :width="70"
            :multiple="false"
          />
        </template>

        <template v-slot:item.picturesExit="{ item }">
          <CardImg
            :items="item.picturesExit"
            :width="70"
            :multiple="false"
          />
        </template>

        <template slot="item.driver" slot-scope="{ item }">
          <ItemMotorista :item="item.driver" class="px-0" />
        </template>
        
        <template slot="item.vehicle" slot-scope="{ item }">
          <ItemVeiculo :item="item.vehicle" class="px-0" />
        </template>

        <template v-slot:item.direction="{ item }">
          {{ `${item.direction ? "Entrada" : "Saída"}` }}
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ formataData(item.createdAt) }}
        </template>

        <template v-slot:item.updatedAt="{ item }">
          {{ formataData(item.updatedAt) }}
        </template>

        <template v-slot:item.timeDif="{ item }">
          {{ getIntervalo(item.createdAt, item.updatedAt) }}
        </template>

        <template v-slot:item.kmDif="{ item }">
          {{ Math.abs(item.kmEnd - item.kmStart) }}
        </template>

        <template v-slot:item.actions="{ item }" v-show="enableActions">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                large
                color="orange lighten-1"
                @click.stop="regOutFrota(item)"
              >
                <v-icon>mdi-exit-to-app</v-icon>
              </v-btn>
            </template>
            <span> <strong>Finalizar Frota</strong></span>
          </v-tooltip>
        </template>
      </DataTable>
    </v-card-text>
    <FleetDetails ref="modalFleetDetails" :actions="report ? false : actions" @out="regOutFrota($event)" />
    <DialogFrotaFinish v-if="!report" ref="dialogFleetFinish" @closeFleetDetails="closeFleetDetails" />
  </div>
</template>


<script>
import DataTable from "@/components/DataTableGeneric";
import CardImg from "@/components/Relatorios/CardImgList";
import ItemMotorista from "@/components/Motoristas/item_motorista.vue";
import ItemVeiculo from "@/components/Veiculo/item_veiculo.vue";
import DialogFrotaFinish from "@/components/Efetivo/components/dialog_frota_finish";
import FleetDetails from "./FleetSimpleDetails.vue";
import { mapGetters } from "vuex";
import { differenceDate } from "@/Utils/DateDiff";
export default {
  name: "TableFleet",
  components: {
    DataTable,
    CardImg,
    ItemVeiculo,
    ItemMotorista,
    FleetDetails,
    DialogFrotaFinish,
  },
  props: {
    items: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    report: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    formataData(dataUTC) {
      return new Date(dataUTC).toLocaleString();
    },
    regOutFrota(item) {
      this.$refs.dialogFleetFinish.open(item);
    },
    openFleetDetails(item) {
      this.$refs.modalFleetDetails.open(item);
    },
    closeFleetDetails() {
      this.$refs.modalFleetDetails.close();
    },
    getIntervalo(inicio, fim) {
      let dif = differenceDate(inicio, fim);
      return `${dif.day}d, ${dif.hour}h, ${dif.minute}m`;
    },
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(["getUsuario"]),
  },
};
</script>