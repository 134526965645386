var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pendentes"},[_c('div',{staticClass:"form"},[_c('v-card',{staticClass:"mt-1"},[_c('v-card-title',[_vm._v("Dispositivos Online")]),_c('v-card-text',[_c('DataTableGeneric',{attrs:{"headers":_vm.header,"items":_vm.online},scopedSlots:_vm._u([{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","small":"","text":"","color":"success","disabled":!item.user.deviceId},on:{"click":function($event){return _vm.start(item.user.deviceId)}}},[_c('v-icon',[_vm._v("mdi-cellphone-message")])],1),_c('v-btn',{attrs:{"fab":"","small":"","text":"","color":"success","disabled":!item.user.deviceId},on:{"click":function($event){return _vm.openCommands(item.user.deviceId)}}},[_c('v-icon',[_vm._v("mdi-powershell")])],1)]}},{key:"item.user.iat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.user.iat * 1000).toLocaleString())+" ")]}},{key:"item.user.groupId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$store.getters.getGroupNameById(item.user.groupId))+" ")]}},{key:"item.user.clientId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$store.getters.getClienteNameById(item.user.clientId))+" ")]}}])})],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":2000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('DialogMessage',{ref:"dialogMessage",attrs:{"deviceId":_vm.deviceId}}),_c('DialogCommands',{ref:"dialogCommand",attrs:{"deviceId":_vm.deviceId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }