<template>
  <div class="icon">
    <v-row>
      <v-col cols="1" class="d-flex align-content-center">
        <v-icon> {{ icon }}</v-icon>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="icon"
          label="Icone"
          hint="Selecione um Icone"
          :items="icons"
          persistent-hint
          @change="$emit('input', $event)"
        >
          <template v-slot:append-item>
            <v-slide-x-reverse-transition mode="out-in">
            </v-slide-x-reverse-transition>
          </template>
          <template v-slot:item="{ item }">
            <v-icon> {{ item }}</v-icon>
              {{ item }}
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SelectIcon",
  props:['value'],
  computed:{
    valor:{
      get(){
        return this.value
      },
      set(evento){
        this.$emit('input', evento)
      }
    },
    icons: {
      get() {
        return this.$store.getters.getIcones;
      },
    },
  },
  data: () => {
    return {
      icon: "",
    };
  },
  mounted(){
    this.$store.dispatch("loadIcones");
  }
};
</script>

<style>
</style>