var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.report)?[_c('v-card-title',[_vm._v("Visitantes Presentes")]),_c('v-card-subtitle',[_vm._v("Clique na tabela para mais detalhes")])]:_vm._e(),_c('v-card-text',[_c('DataTableGeneric',{attrs:{"headers":_vm.headers,"items":_vm.items},on:{"rowClick":_vm.openModalVisita,"headersChanged":function($event){return _vm.$emit('headersChanged', $event)}},scopedSlots:_vm._u([{key:"item.visitanteName",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-avatar',{staticClass:"my-2",attrs:{"width":"70","height":"70"}},[_c('v-avatar',{attrs:{"size":"70"}},[_c('v-img',{attrs:{"src":item.visitantePic,"lazy-src":"/img/nopic.jpg"}})],1)],1),_c('v-list-item-content',{staticClass:"pt-1 pb-0"},[_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(item.visitanteName))]),_c('v-list-item-subtitle',{staticClass:"mb-1"},[_vm._v("Documento: "+_vm._s(item.visitanteDoc))]),_c('v-list-item-subtitle',{staticClass:"mb-1"},[_vm._v("Referência: "+_vm._s(item.visitanteRef))])],1)],1)]}},{key:"item.visitadoName",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0"},[_c('v-list-item-avatar',{staticClass:"my-2",attrs:{"width":"70","height":"70"}},[_c('v-avatar',{attrs:{"size":"70"}},[_c('v-img',{attrs:{"src":item.visitadoPic,"lazy-src":"/img/nopic.jpg"}})],1)],1),_c('v-list-item-content',{staticClass:"pt-1 pb-0"},[_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(item.visitadoName))]),_c('v-list-item-subtitle',{staticClass:"mb-1"},[_vm._v("Local: "+_vm._s(item.visitadoPlace))]),_c('v-list-item-subtitle',{staticClass:"mb-1"},[_vm._v("OBS: "+_vm._s(item.visitadoObs))])],1)],1)]}},{key:"item.visitadoNameReport",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex my-2"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"70"}},[_c('v-img',{attrs:{"src":item.visitadoPic,"lazy-src":"/img/nopic.jpg"}})],1),_c('div',{staticClass:"d-flex align-center",staticStyle:{"max-width":"145px"}},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.visitadoName))])])],1)]}},{key:"item.visitanteNameReport",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex my-2"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"70"}},[_c('v-img',{attrs:{"src":item.visitantePic,"lazy-src":"/img/nopic.jpg"}})],1),_c('div',{staticClass:"d-flex align-center",staticStyle:{"max-width":"145px"}},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(item.visitanteName))])])],1)]}},{key:"item.createdAtReport",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formataData(item.createdAt))+" ")]}},{key:"item.updatedAtReport",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formataData(item.updatedAt))+" ")]}},{key:"item.aprovText",fn:function(ref){
var item = ref.item;
return [(item.aprovTime)?_c('span',[_vm._v(_vm._s(item.aprovText))]):_c('Dialog',{attrs:{"title":"Aprovar Visita?","btnText":"Aprovar","btnSendText":"Aprovar","lblInput":"Mensagem","required":true},on:{"emit":function($event){return _vm.$emit('aprovada', {texto: $event, id: item.id})}}})]}},{key:"item.perm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getIntervalo(item.createdAt, item.updatedAt, item.perm))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"orange lighten-1"},on:{"click":function($event){$event.stopPropagation();return _vm.regOut(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1)]}}],null,true)},[_c('strong',[_vm._v("Registrar Saída")])])]}}])})],1),_c('VisitaDetails',{ref:"modalVisitaDetails",attrs:{"actions":_vm.report ? false : _vm.actions},on:{"out":function($event){return _vm.regOut($event)}}}),(!_vm.report)?_c('ConfirmDialog',{ref:"confirmDialog"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }