<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="green" dark v-bind="attrs" v-on="on" @click="open">
        {{ btnText }}
      </v-btn>
    </template>

    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text class="pt-1 pb-0">
        <v-form ref="form">
          <v-text-field
            v-if="number"
            :label="lblInput"
            v-model="text"
            type="number"
            @keydown.enter="send"
            ref="textInput"
            outlined
            :required="required"
            :rules="required ? requiredField : []"
          ></v-text-field>
          <v-text-field
            v-else
            :label="lblInput"
            v-model="text"
            @keydown.enter="send"
            ref="textInput"
            outlined
            :required="required"
            :rules="required ? requiredField : []"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">Cancelar</v-btn>
        <v-btn color="success" @click="send">{{ btnSendText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requiredField } from "../../Utils/InputValidation";
export default {
  props: {
    btnText: {
      type: String,
    },
    lblInput: {
      type: String,
    },
    btnSendText: {
      type: String,
    },
    title: {
      type: String,
    },
    number: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  // props: ["btnText", "lblInput", "btnSendText", "title", 'number'],
  data() {
    return {
      dialog: false,
      text: "",
      requiredField,
    };
  },
  methods: {
    send() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.$emit("emit", this.text);
      this.dialog = false;
      this.text = "";
    },
    open() {
      // this.dialog = true;
      // // this.$refs.textInput.focus();
      // let btn = this.$refs.textInput
      // btn.focus();
    },
  },
  watch: {
    dialog(value) {
      if (value) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    },
  },
};
</script>

<style>
</style>