<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange lighten-1"
          dark
          v-bind="attrs"
          v-on="on"
          class="mb-4"
        >
          <v-icon class="mr-1">mdi-camera-outline</v-icon>Capturar Foto
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <v-icon class="mr-1">mdi-camera</v-icon> Tirar Foto
        </v-card-title>

        <v-card-text>
          <div class="d-flex justify-center">
            <div class="loader" v-show="isLoading"></div>
            <div class="camera" v-show="!isLoading">
              <video
                v-show="!isPhotoTaken"
                ref="camera"
                :width="wdth"
                :height="wdth * 0.75"
                autoplay
                class="d-block mx-auto"
              ></video>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="disableCamera"> Cancelar </v-btn>
          <v-btn color="success" @click="takePhoto">
            <v-icon class="mr-1">mdi-camera-iris</v-icon> Capturar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    wdth: {
      type: Number,
      default: 450,
    },
    defaultImage: {
      type: String,
    },
  },
  data() {
    return {
      dialog: false,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.enableCamera();
      } else {
        this.disableCamera();
      }
    },
  },
  methods: {
    enableCamera() {
      this.isCameraOpen = true;
      this.createCameraElement();
    },
    disableCamera() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
      this.dialog = false;
    },
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;

      // const constraints = (window.constraints = {
      //   audio: false,
      //   video: true,
      // });

      navigator.mediaDevices
        .getUserMedia({
          video: { width: { min: 640 }, height: { min: 480 } },
        })
        .then((stream) => {
          this.isLoading = false;

          //elemento camera
          this.$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.isLoading = false;
          this.dialog = false;
          alert("Seu navegador não suporta Webcam ou seu computador não possui uma.");
        });
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;
      this.$emit("image", this.$refs.camera);
      this.base64Gen();
      this.disableCamera();
    },
    base64Gen() {
      let canvas5 = document.createElement("canvas");
      // canvas5.width = this.$refs.camera.videoWidth;
      // canvas5.height = this.$refs.camera.videoHeight;
      canvas5.width = this.wdth;
      canvas5.height = this.wdth * 0.75;
      let ctx = canvas5.getContext("2d");
      ctx.drawImage(this.$refs.camera, 0, 0, canvas5.width, canvas5.height);

      let dataURI = canvas5.toDataURL("image/jpeg");

      this.isPhotoTaken = !this.isPhotoTaken;
      this.$emit("base64", dataURI);
      this.dataURItoBlob(dataURI);
    },
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      let file = new File(
        [ia],
        `WebCam ${new Date().toDateString()}.${mimeString.split("/")[1]}`
      );
      this.$emit("file", file);
      return file;
    },
  },
};
</script>

<style>

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #FFA726;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>