<template>
  <div class="userCRUD">
    <v-card class="mb-1">
      <v-card-text>
        <v-row>
          <v-col cols="auto">
            <p class="mt-5">Selecione o Tipo do Formulário</p>
          </v-col>
          <v-col>
            <v-radio-group row v-model="formType">
              <v-radio label="Empresas" value="empresa"></v-radio>
              <v-radio label="Condominios" value="condominio"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-1" v-if="formUsr || visitado.id">
      <v-card-title>
        {{ visitado.id ? "Edição Visitado" : "Cadastro de Visitado" }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="visitado.name"
          :counter="255"
          label="Nome Completo"
          required
        ></v-text-field>

        <v-text-field
          v-model="visitado.phone"
          :counter="255"
          label="Telefone fixo / Ramal"
          required
        ></v-text-field>

        <v-text-field
          v-model="visitado.celPhone"
          :counter="255"
          label="Telefone Celular"
        ></v-text-field>

        <v-text-field
          v-if="formType == 'empresa'"
          v-model="visitado.hierarchy"
          :counter="255"
          label="Função"
        ></v-text-field>

        <v-text-field
          v-model="visitado.place"
          :counter="255"
          :label="formType == 'empresa' ? 'Local' : 'AP/Unidade'"
        ></v-text-field>

        <v-text-field
          v-if="formType == 'condominio'"
          v-model="visitado.tipoMorador"
          :counter="255"
          label="Tipo Morador"
        ></v-text-field>

        <v-text-field
          v-if="formType == 'condominio'"
          v-model="visitado.tipoVeiculo"
          :counter="255"
          label="Tipo do Veículo"
        ></v-text-field>

        <v-text-field
          v-if="formType == 'condominio'"
          v-model="visitado.corVeiculo"
          :counter="255"
          label="Cor do Veículo"
        ></v-text-field>
        
        <v-text-field
          v-if="formType == 'condominio'"
          v-model="visitado.bloco"
          :counter="255"
          label="Bloco"
        ></v-text-field>


        <v-textarea
          v-model="visitado.obs"
          name="input-7-1"
          :counter="255"
          label="Observações"
        ></v-textarea>
        <SelectGroup v-model="visitado.groupId" />
        <v-checkbox
          v-model="visitado.enabled"
          label="Ativo"
          required
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" class="mr-4" @click="clearObj"
          >Limpar Campos</v-btn
        >
        <v-btn color="success" class="mr-4" @click="save(visitado)">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="div d-flex justify-end ma-2" v-else>
      <v-btn color="success" @click="formUsr = true">Cadastrar</v-btn>
    </div>
    <v-card>
      <v-card-text>
        <div class="tbl mt-1">
          <Table
            :items="lista"
            :headers="formType == 'empresa' ? headersEmp : headersCond"
            :enablePicture="true"
            @edit="editar($event)"
            @delete="delet($event)"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Table from "../../TableGeneric";
import SelectGroup from "../../Template/SelectGroupForm";

export default {
  data() {
    return {
      formUsr: false,
      visitado: {
        id: null,
        enabled: true,
      },
      formType: "empresa",
      headersCond: [
        { text: "Id", value: "id" },
        {
          text: "Imagem",
          align: "center",
          filterable: false,
          value: "picture",
        },
        { text: "AP", value: "place" },
        { text: "Bloco", value: "bloco" },
        { text: "Nome", value: "name" },
        { text: "Tipo", value: "tipoMorador" },
        { text: "Telefone", value: "phone" },
        { text: "Celular", value: "celPhone" },
        { text: "Carro", value: "tipoVeiculo" },
        { text: "Cor Carro", value: "corVeiculo" },
        { text: "Placa", value: "plate" },
        { text: "Box", value: "boxs" },
        { text: "Grupo", value: "groupName" },
        { text: "Observação", value: "obs" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      headersEmp: [
        { text: "Id", value: "id" },
        {
          text: "Imagem",
          align: "center",
          filterable: false,
          value: "picture",
        },
        { text: "Nome", value: "name" },
        { text: "Telefone", value: "phone" },
        { text: "Celular", value: "celPhone" },
        { text: "Local", value: "place" },
        { text: "Posição", value: "hierarchy" },
        { text: "Grupo", value: "groupName" },
        { text: "Observação", value: "obs" },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    lista: {
      get() {
        return this.$store.getters.getVisitados;
      },
    },
    headers: {
      get() {
        return this.$store.getters.getHeadersVisitados;
      },
    },
  },
  components: {
    Table,
    SelectGroup,
  },
  methods: {
    get() {
      this.edicao = false;
      this.$store.dispatch("loadVisitados");
    },
    save(objeto) {
      this.$store.dispatch("saveVisitado", objeto);
    },
    editar(event) {
      this.visitado = event;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
    },
    delet(event) {
      this.$store.dispatch("deleteVisitado", event);
      this.get();
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  created() {
    this.get();
  },
};
</script>

<style>
</style>