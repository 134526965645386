<template>
  <div class="userCRUD">
    <v-card class="mb-1" v-if="formUsr || posto.id">
      <v-card-title>
        {{ posto.id ? "Edição Posto" : "Cadastro de Posto" }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="posto.name"
          :counter="255"
          label="Nome"
          required
        ></v-text-field>
        <v-text-field
          v-model="posto.cnpj"
          :counter="255"
          label="CNPJ"
          required
        ></v-text-field>
        <v-text-field
          v-model="posto.address"
          :counter="255"
          label="Endereço"
          required
        ></v-text-field>
        <v-text-field
          v-model="posto.phone"
          :counter="255"
          label="Telefone"
          required
        ></v-text-field>

        <SelectGroup v-model="posto.groupId" />

        <v-textarea
          v-model="posto.description"
          name="input-7-1"
          :counter="255"
          label="Descrição"
        ></v-textarea>

        <v-checkbox v-model="posto.enabled" label="Ativo" required></v-checkbox>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" class="mr-4" @click="clearObj"
          >Limpar Campos</v-btn
        >
        <v-btn color="success" class="mr-4" @click="save(posto)">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="div d-flex justify-end ma-2" v-else>
      <v-btn color="success" @click="formUsr = true">Cadastrar</v-btn>
    </div>
    <v-card>
      <v-card-text>
        <div class="tbl mt-1">
          <Table
            :items="lista"
            :headers="headers"
            :enablePicture="true"
            @edit="editar($event)"
            @delete="delet($event)"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Table from "../../TableGeneric";
import SelectGroup from "../../Template/SelectGroupForm";

export default {
  data() {
    return {
      formUsr: false,
      posto: {
        id: null,
      },
    };
  },
  computed: {
    lista: {
      get() {
        return this.$store.getters.getPostos;
      },
    },
    headers: {
      get() {
        return this.$store.getters.getPostoHeaders;
      },
    },
  },
  components: {
    Table,
    SelectGroup,
  },
  methods: {
    get() {
      this.edicao = false;
      this.$store.dispatch("loadPostos");
    },
    save(objeto) {
      this.$store.dispatch("savePosto", objeto);
    },
    editar(event) {
      this.posto = event;
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
    delet(event) {
      this.$store.dispatch("deletPosto", event);
      this.get();
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  created() {
    this.get();
  },
};
</script>

<style>
</style>