<template>
  <v-card>
    <v-card-title>Efetivos Cadastrados</v-card-title>
    <v-card-text>
      <DataTable
        :headers="headers"
        :items="usuarios"
      >

      <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="editarU(item)"
                color="warning"
                class="mr-1"
              >mdi-pencil</v-icon>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
        </template>

      </DataTable>
    </v-card-text>

    <!-- dialog de cadastro -->
    <v-dialog v-model="dialog" persistent max-width="700" scrollable>
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2">{{ editar ? "Editar" : "Cadastrar" }} Efetivo<v-spacer></v-spacer>
          <v-btn icon @click="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-form ref="formEfetivo" :disabled="!usuario.enabled">
            <v-text-field
              v-model="usuario.name"
              label="Nome"
              :rules="reqFieldMinLength(4)"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="usuario.userName"
              :rules="reqFieldMinLength(4)"
              label="Usuário"
              required
              outlined
            ></v-text-field>

            <v-text-field
              v-show="usuario.id === null"
              v-model="usuario.password"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              @click:append="showPass = !showPass"
              :rules="reqFieldMinLength(4)"
              label="Senha"
              required
              outlined
            ></v-text-field>

            <v-text-field
              v-model="usuario.description"
              label="Descrição"
              outlined
            ></v-text-field>
          </v-form>
          <v-switch
            v-model="usuario.enabled"
            label="Ativo"
            required
            outlined
            class="mt-0 pt-0"
          ></v-switch>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="reset">Cancelar</v-btn>
          <v-btn color="success" @click="save">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber darken-1"
          dark
          absolute
          bottom
          right
          fab
          fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Efetivo</span>
    </v-tooltip>

  </v-card>
</template>

<script>
import { reqFieldMinLength } from "../../Utils/InputValidation";
import DataTable from "../../components/DataTableGeneric";
export default {
  props: ["editar", "groupId", "type", "clientName"],
  components: { DataTable },
  data: () => {
    return {
      dialog: false,
      showPass: false,
      usuarios: [],
      usuario: {
        id: null,
        groupId: 0,
        type: 3,
        userName: "",
        name: "",
        picture: "",
        description: "",
        enabled: true,
      },
      reqFieldMinLength,
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Usuário", value: "userName" },
        { text: "Descrição", value: "description" },
        { text: "Grupo", value: "grupo" },
        { text: "Ativo", value: "ativo" },
        { text: "Ações", value: "actions", align: "center", sortable: false, filterable: false, width: 70 },
      ],
    };
  },
  methods: {
    save() {
      if (!this.$refs.formEfetivo.validate())
        return;
      this.usuario.groupId = this.$store.state.user.groupId;
      const metodo = this.usuario.id ? "put" : "post";
      if (this.usuario.id) {
        if (this.usuario.password == "") {
          delete this.usuario.password;
        }
      }
      const finalUrl = this.usuario.id ? `/${this.usuario.id}` : "";
      this.$http[metodo](`/usuario${finalUrl}`, this.usuario).then(() => {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showSuccessSnack", "Usuário salvo com Sucesso");
        this.reset();
        this.get();
      });
    },
    reset() {
      this.dialog = false;
      this.usuario.name = "";
      this.usuario.userName = "";
      this.usuario.password = "";
      this.usuario.description = "";
      this.usuario.enabled = true;
      this.$refs.formEfetivo.resetValidation();
    },
    get() {
      this.$http(`usuario`).then((resp) => {
        resp.data.forEach((ele) => {
          ele.ativo = ele.enabled ? "Sim" : "Não";
          ele.grupo = this.$store.getters.getGroupNameById(ele.groupId)
        });
        this.usuarios = resp.data;
      });
    },
    editarU(item) {
      this.$router.push({ name: "cadUser", params: { editar: { ...item }, disableType: true } });
      this.$emit("get");
    }
  },
  mounted() {
    //caso venha o parametro seto ele
    if (this.editar) {
      this.usuario = { ...this.editar }
      this.dialog = true;
    }
    //caso venha o parametro seto ele
    this.groupId ? (this.usuario.groupId = this.groupId) : this.usuario.groupId;
    //caso venha o parametro seto ele
    this.type ? (this.usuario.type = this.type) : this.usuario.type;
    this.get();
  },
};
</script>

<style>
</style>