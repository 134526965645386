<template>
  <div class="AppCRUD">
    <v-card>
      <v-card-title class="pt-6">Importar Excel</v-card-title>
      <v-card-subtitle>Utilize para importar dados de Visitados para um novo cliente, <strong>somente se ainda não houverem Visitados cadastrados.</strong></v-card-subtitle>
      <v-card-text class="pt-2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              v-bind="attrs"
              v-on="on"
              class="mb-3"
            >
              <v-icon class="mr-1">mdi-file-download-outline</v-icon> Baixar Modelo
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item :href="$http.defaults.baseURL + 'downloads/MODELOEMPRESAS.xlsx'" target="_blank">
              <v-list-item-icon class="mr-1">
                <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Modelo Empresas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :href="$http.defaults.baseURL + 'downloads/MODELOCONDOMINIOS.xlsx'" target="_blank">
              <v-list-item-icon class="mr-1">
                <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Modelo Condomínios</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-row>
          <v-col>
            <v-radio-group v-model="modelToImport" row>
              <span class="mr-3">Modelo a importar:</span>
              <v-radio label="Empresas" value="1"></v-radio>
              <v-radio label="Condominos" value="2"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col>
            <v-file-input
              :disabled="!modelToImport"
              chips
              label="Selecione o arquivo Excel"
              :show-size="1000"
              v-model="excel"
              @change="lerArquivo"
              outlined
              hide-details
            ></v-file-input>
          </v-col>
        </v-row>
        <v-card outlined class="mt-5" v-if="table.length">
          <v-card-title class="d-block text-center">Dados recebidos da planilha</v-card-title>
          <v-card-text class="pb-0">
            <v-autocomplete
              v-model="tableSelectedId"
              :items="tableIndex"
              label="Selecione a Planilha"
              outlined
              hide-details
              @change="showTable"
              class="mb-3"
            >
            </v-autocomplete>
            <v-simple-table fixed-header height="500" class="mb-5">
              <thead>
                <tr class="menus">
                  <th></th>
                  <th
                    v-for="(row, index) in table[0]"
                    :key="index"
                    class="text-center"
                  >
                    {{ row.text }}
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th
                    v-for="(row, index) in table[0]"
                    :key="index"
                    class="text-center blue-grey lighten-4"
                    style="min-width: 100px"
                  >
                    <v-tooltip bottom v-if="index != 0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          x-small
                          @click="changePosition(index, false)"
                        >
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-arrow-collapse-left</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Mover para esquerda</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon x-small @click="removeCol(index)">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-delete</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Remover coluna</span>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-if="index != table[0].length - 1"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          x-small
                          @click="changePosition(index, true)"
                        >
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-arrow-collapse-right</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Mover para direita</span>
                    </v-tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, idxRow) in tableWork" :key="idxRow">
                  <td class="text-center blue-grey lighten-4">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon x-small @click="removeRow(idxRow)">
                          <v-icon v-bind="attrs" v-on="on">
                            mdi-delete</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Remover linha</span>
                    </v-tooltip>
                  </td>
                  <td v-for="(col, index) in row" :key="index">
                    {{ col }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-row>
              <v-col>
                <v-form ref="formGrupo">
                  <v-autocomplete
                    v-model="groupId"
                    label="Selecione o Grupo (deve ser do tipo Portaria Principal)"
                    :items="getGroupsSelect"
                    outlined
                    required
                    :rules="requiredField"
                  >
                  </v-autocomplete>
                </v-form>
              </v-col>
              <v-col class="flex-grow-0 d-flex align-center">
                <v-btn color="warning" @click="importar" class="mb-8">Adicionar Visitados/Moradores</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      formUsr: false,
      description: "",
      file: null,
      groupId: 0,
      excel: null,
      tableResponse: [],
      table: [],
      tableIndex: [],
      tableWork: [],
      tableSelectedId: 0,
      modelToImport: "",
      requiredField
    };
  },
  computed: {
    ...mapGetters(["getGroupsSelect"]),
  },

  methods: {
    get() {
      this.$store.dispatch("loadGrupos");
    },
    showTable(data) {
      let table = this.table.find((e) => e.id == data);
      this.tableWork = [...table.rows];
    },
    save() {
      let formData = new FormData();
      formData.append("groupId", this.groupId);
      formData.append("excel", this.excel);
      var config = {
        method: "post",
        url: "visitado/import",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      this.$http(config).then((resp) => {
        if (resp.status == 200) {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch("showSuccessSnack", "Importado com Sucesso");
        }
      });
    },
    lerArquivo() {
      // se não possuir arquivo, limpa o front
      if (!this.excel) {
        this.clearObj();
        return;
      }
      let formData = new FormData();
      formData.append("groupId", this.groupId);
      formData.append("excel", this.excel);
      formData.append("model", this.modelToImport);
      var config = {
        method: "post",
        url: "visitado/read",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      this.$http(config).then((resp) => {
        if (resp.status == 200) {
          this.table = resp.data;
          this.tableResponse = resp.data;
          this.table.forEach((e) => {
            if (e.id) {
              this.tableIndex.push({ value: e.id, text: e.name });
            }
          });
          this.$store.dispatch("resetSnack");
          this.$store.dispatch(
            "showSuccessSnack",
            "Excel carregado com Sucesso"
          );
        }
      });
    },
    removeRow(row) {
      this.tableWork.splice(row, 1);
    },
    removeCol(col) {
      this.tableWork.forEach((row) => {
        row.splice(col, 1);
      });
    },
    changePosition(col, right) {
      const changePositionS = (arr, from, to) => {
        arr.splice(to, 0, arr.splice(from, 1)[0]);
        return arr;
      };
      this.tableWork.forEach((row) => {
        changePositionS(row, col, right ? col + 1 : col - 1);
      });
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
    importar() {
      if (!this.$refs.formGrupo.validate()) {
        return;
      }
      let data = {
        rows: [...this.tableWork],
        groupId: this.groupId,
        model: this.modelToImport,
      };
      this.$http.post("visitado/write", data).then((resp) => {
        if (resp.status == 200) {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch("showSuccessSnack", "Importado com Sucesso");
        }
      });
    },
  },
  created() {
    this.get();
  },
};
</script>

<style scoped>
td {
  border: 1px solid rgb(227, 227, 227);
}
</style>