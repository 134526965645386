<template>
  <div>
    <div class="d-flex align-center mb-5" v-if="search">
      <v-text-field
        v-model="searchText"
        append-icon="mdi-magnify"
        label="Pesquisa"
        hide-details
        outlined
      ></v-text-field>
      <v-tooltip bottom v-if="headersFilter">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            x-large
            @click="openHeadersFilter = !openHeadersFilter"
            v-on="on"
            v-bind="attrs"
            class="ml-3"
          >
          mdi-view-column
        </v-icon>
        </template>
        <span>Colunas a exibir</span>
      </v-tooltip>
    </div>
    <v-fade-transition>
      <v-select
        v-if="headersFilter"
        v-show="openHeadersFilter"
        v-model="selectedHeaders"
        :items="headers"
        label="Colunas a exibir"
        outlined
        chips
        small-chips
        deletable-chips
        multiple
        @change="filterHeaders"
        hide-details
        class="mb-5"
      ></v-select>
    </v-fade-transition>
    <v-data-table
      v-model="selectedItems"
      :show-select="enableSelect"
      :single-select="singleSelect"
      :headers="headersFiltered"
      :items="items"
      :search="searchText"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :options="{ itemsPerPage: itemsPerPage }" 
      :footer-props="{'items-per-page-options': [15, 30, 50, 100, -1]}"
      :class="$listeners.hasOwnProperty('rowClick') && items.length ? 'row-clickable' : '' /* Se o componente estiver aguardando o evento rowClick, coloca cursor:pointer nas linhas quando há dados */"
      @click:row="rowClicked"
    >    
      <template v-for="(_, slotName) of $scopedSlots" v-slot:[slotName]="scope">
          <slot :name="slotName" v-bind="scope"></slot>
      </template>
    </v-data-table>
   </div>
</template>

<script>

export default {
  name: "DataTableGeneric",
  props: {
    headers: {
      type: Array,
    },
    items: {
      type: Array,
    },
    headersFilter: {
      type: Boolean,
      default: true,
    },
    search: {
      type: Boolean,
      default: true,
    },
    enableSelect: {
      type: Boolean,
      default: false,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    selectOnRowClick: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: { 
      type: Number,
      default: 15, // -1 para todos
    },
    sortBy: {
      type: Array,
    },
    sortDesc: {
      type: Array,
    },
  },
  emits: ['rowClick', 'update:selectedItems'],
  data: () => {
    return {
      searchText: "",
      openHeadersFilter: false,
      selectedHeaders: [],
      selectedItems: [],
    };
  },
  methods: {
    filterHeaders(){
      this.headersFiltered = this.headers.filter(col => this.selectedHeaders.includes(col.value)); //recarrega o headers filtrado no headersFiltered para manter a ordem original das colunas
      this.selectedHeaders = Object.values(this.headersFiltered); //recarrega os valores de headersFiltered no selectedHeaders para manter a ordem original das colunas no select também
      this.$emit('headersChanged', this.selectedHeaders);
    },
    rowClicked(item, row){  
      if (this.enableSelect && this.selectOnRowClick) row.select(!row.isSelected);
      this.$emit('rowClick', item);
    },
    clearSelectedItems(){
      this.selectedItems = [];
    }
  },
  created () {
    this.headersFiltered = this.headers.filter(col => col.show !== false);
    this.selectedHeaders = Object.values(this.headersFiltered);
  },
  watch: {
    selectedItems(){
      this.$emit('update:selectedItems', this.selectedItems);
    }
  }

};
</script>

<style>
  .row-clickable tbody tr {
    cursor: pointer;
  }
</style>