import axios from 'axios'
export default {
    state: {
        melhorias: [],
    },
    mutations: {
        setMelhorias(state, payload) {
            state.melhorias = payload
        },
    },
    actions: {
        setMelhorias(context, payload) {
            context.commit('setMelhorias', payload)
        },
        loadMelhorias(context, payload) {
            let gp = payload ? `?groupId${payload}` : ''
            axios(`melhoria${gp}`).then((resp) => {
                resp.data.forEach((ele) => {
                    ele.seguro = ele.secure ? "SIM" : "NÃO";
                    ele.text = `${ele.name} - ${ele.user}`
                    ele.value = ele.id
                });
                context.dispatch('setMelhorias', resp.data)
            });
        },
        saveMelhoria(context, payload) {
            const metodo = payload.id ? "put" : "post";
            // for (const iterator of payload) {
            //     console.log(iterator);
            // }
            let formData = new FormData()
            for (const key in payload) {
                if (Object.hasOwnProperty.call(payload, key)) {
                    const element = payload[key];
                    if (key != 'picture') {
                        console.log(key, element);
                        formData.append(key, element)
                    }
                }
            }
            if (payload.picture && payload.picture.length) {
                payload.picture.forEach(e => {
                    formData.append('picture', e)
                })

            }
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/melhoria${finalUrl}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((data) => {
                if (data.status == 200) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Melhoria salva com Sucesso");
                    context.dispatch('loadMelhorias')

                }
            });
        },
        deleteMelhoria(context, payload) {
            axios.delete(`/melhoria/${payload.id}`).then(() => {
                context.dispatch("resetSnack");
                context.dispatch(
                    "showSuccessSnack",
                    "Melhoria deletada com Sucesso!"
                );
                context.dispatch('loadMelhorias')
            });
        },
    },
    getters: {
        getMelhorias(state) {
            return state.melhorias
        },
    }
}