<template>
	<div>
		<v-dialog v-model="dialog" persistent max-width="600">
			<v-card>
				<v-card-title class="text-h5 pr-4 mb-2"
					>Editar checkpoint {{ checkpoint.id }}<v-spacer></v-spacer>
					<v-btn icon @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>
				<v-card-text class="pt-1 pb-0">
					<v-form ref="formQR">
						<v-text-field
							v-model="checkpoint.name"
							label="Nome"
							required
							outlined
							:rules="requiredField"
						></v-text-field>

						<!-- <v-switch
							class="mt-0 pt-0"
							v-model="checkpoint.enabled"
							label="Ativo"
						></v-switch> -->
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4">
					<v-spacer></v-spacer>
					<v-btn text @click="reset">Cancelar</v-btn>
					<v-btn color="success" @click="salvarQR">Salvar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import {
	reqFieldMinLength,
	requiredField,
} from "../../../Utils/InputValidation";
export default {
	name: "dialogCadCheckpoints",
	data: () => ({
		dialog: false,
		checkpoint: {
			id: null,
			name: "",
			enabled: true,
			clientId: null,
		},
		requiredField,
		reqFieldMinLength,
	}),
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		salvarQR() {
			if (this.$refs.formQR.validate()) {
				this.$store.dispatch("saveCheckpoint", this.checkpoint);
				this.dialog = false;
			}
		},
		edit(item) {
			this.checkpoint = item;
			this.dialog = true;
		},
		reset() {
			this.$refs.formQR.reset();
			this.dialog = false;
		},
	},
};
</script>