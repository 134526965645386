<template>
  <div class="cliUser">
    <v-card-title>Cadastros de Usuários</v-card-title>
    <v-card-text>
      <DataTable
        :items="usuarios"
        :headers="headers"
      >
    
      <template slot="item.picture" slot-scope="{ item }">
          <v-avatar size="70" class="my-2">
            <v-img
              :src="item.picture"
              lazy-src="/img/nopic.jpg"
            ></v-img>
          </v-avatar>
        </template>
        <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="editarU(item)"
                color="warning"
                class="mr-1"
              >mdi-pencil</v-icon>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
          <!-- botão excluir -->
          <!--v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="deletU(item)"
                color="red"
              >mdi-trash-can</v-icon>
            </template>
            <span>Excluir : {{ item.name }}</span>
          </v-tooltip-->
        </template>

      </DataTable>
    </v-card-text>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="amber darken-1" dark absolute bottom right fab fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          :to="{ name: 'cadUser', params: { groupId } }"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Usuário</span>
    </v-tooltip>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import DataTable from "../../DataTableGeneric";
import ConfirmDialog from '@/components/ConfirmDialog';
export default {
  name: "CliUser",
  components: { DataTable, ConfirmDialog },
  props: ["value", "groupId"],
  data: () => {
    return {
      headers: [
        { text: "ID", value: "id" },
        {
          text: "Imagem",
          align: "center",
          filterable: false,
          value: "picture",
        },
        { text: "Nome", value: "name" },
        { text: "Usuário", value: "userName" },
        { text: "Descrição", value: "description" },
        { text: "Tipo", value: "acessoName" },
        { text: "Grupo", value: "groupName" },
        { text: "Ativo", value: "ativo", width: 80 },
        { text: "Ações", value: "actions", sortable: false, width: 70 },
      ],
    };
  },
  computed: {
    usuarios: {
      get() {
        return this.$store.getters.getUsers;
      },
    },
  },
  methods: {
    cadU() {
      this.$router.push({ name: "cadUser", params: { groupId: this.groupId } });
    },
    editarU(item) {
      this.$router.push({ name: "cadUser", params: { editar: { ...item } } });
      this.$emit("get");
    },
    deletU(item) {
      this.$refs.confirmDialog.open({
        title: "Excluir Usuário",
        text: `Deseja excluir o registro de <strong>${item.name}</strong>?`,
        confirmCallback: () => {
          
          this.$http.delete(`/usuario/${item.id}`).then((resp) => {
              if (resp) this.$emit("get");
          });
          
        },
      });
      
    },
  },
};
</script>

<style>
</style>