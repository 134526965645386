<template>
	<div>
		<v-dialog v-model="dialogSnapshot" max-width="800" scrollable>
			<v-card class="py-0 my-0">
				<v-card-title class="text-h5 pr-4 mb-2"
					>Snapshot<v-spacer></v-spacer>
					<v-btn icon @click="closeSnapshot">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="py-0 my-0">
					<v-row>
						<v-col cols="12">
							<v-card
								outlined
								class="py-0 my-0"
								max-height="600"
								min-height="400"
							>
								<img
									class="py-0 my-0"
									:src="$store.getters.getSnapshot"
									:lazy-src="$store.getters.getSnapshot"
									aspect-ratio="1.77777"
									width="100%"
									contain
								/>
								<v-row v-show="carregando">
									<v-col cols="12" class="text-center">
										<v-progress-circular indeterminate></v-progress-circular>
									</v-col>
									<v-col cols="12" class="text-center" v-if="imgFail">
										<v-icon>mdi-emoticon-sad</v-icon
										><span>Não foi possível carregar imagem da camera</span>
									</v-col>
								</v-row>
							</v-card>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4">
					<v-spacer></v-spacer>
					<v-btn text @click="closeSnapshot">Fechar</v-btn>
					<v-btn
						text
						color="success"
						@click="download()"
						:disabled="!liberaSave"
						>Baixar</v-btn
					>
					<v-btn
						text
						v-if="btnSalvar"
						color="success"
						@click="saveImg()"
						:disabled="!liberaSave"
						>Salvar</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<img
			v-show="false"
			class="py-0 my-0"
			:src="$store.getters.getSnapImport"
			:lazy-src="$store.getters.getSnapImport"
			width="100%"
		/>
	</div>
</template>

<script>
import File from "@/Utils/File";

export default {
	name: "Snapshots",
	data() {
		return {
			snapshot: "",
			dialogSnapshot: false,
			btnSalvar: false,
			imgFail: false,
			liberaSave: false,
			carregando: true,
		};
	},
	methods: {
		closeSnapshot() {
			this.dialogSnapshot = false;
			this.btnSalvar = false;
			this.liberaSave = false;
			this.snapshot = "";
			this.carregando = true;
			this.imgFail = false;
		},
		open(tempQuery) {
			this.imgFail = false;
			setTimeout(() => {
				this.imgFail = true;
			}, 5000);
			this.dialogSnapshot = true;
			tempQuery.callback = (img) => {
				this.snapshot = img;
				this.liberaSave = true;
				this.carregando = false;
			};
			this.$store.dispatch("getSnapShot", tempQuery);
		},
		openTest(tempQuery) {
			this.imgFail = false;
			setTimeout(() => {
				this.imgFail = true;
			}, 5000);
			this.dialogSnapshot = true;
			tempQuery.callback = (img) => {
				this.snapshot = img;
				this.liberaSave = true;
				this.carregando = false;
			};
			this.$store.dispatch("getSnapShotTest", tempQuery);
		},
		download() {
			let blob = File.dataURItoFile(this.snapshot);
			File.downloadFile(blob, "snapshot.png");
			this.closeSnapshot();
		},
		saveImg() {
			let blob = File.dataURItoFile(this.snapshot);
			this.$emit("addImg", blob, this.snapshot);
			this.closeSnapshot();
		},
	},
	created() {},
};
</script>

<style>
</style>