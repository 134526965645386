export default {
    state: {
        permission: [],
    },
    mutations: {
        setPermission(state, payload) {
            state.permission = payload
        },
    },
    actions: {
        setPermission(context, payload) {
            context.commit('setPermission', payload)
        },
    },
    getters: {
        getPermission(state) {
            return state.permission
        },
        getPermissionByPath: (state) => (path) => {
            return state.permission.find(thing => thing.recursoPath === path)
        },
        getPermissionByPathMethod: (state) => (path) => {
            return state.permission.find(thing => thing.recursoPath === path)
        }
    }
}