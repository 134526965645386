<template>
	<div class="cliCameras">
		<v-card-title>Cadastros de Câmeras</v-card-title>
		<v-card-text>
			<DataTable :items="enderecos" :headers="headers">
				<template slot="item.typeId" slot-scope="{ item }">
					{{ softwares.find((s) => s.value === item.typeId).text }}
				</template>
				<template slot="item.actions" slot-scope="{ item }">
					<!-- botão de snapshot -->
					<TooltipIcon :text="`Mostrar Snapshot: ${item.name}`" icon="mdi-camera" left="true" color="primary"
						@click="takeSnapshot(item)" />
					<!-- botão editar -->
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on" @click="editar(item)" color="warning" class="mr-1">mdi-pencil</v-icon>
						</template>
						<span>Editar</span>
					</v-tooltip>
					<!-- botão excluir -->
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on" @click="excluir(item)" color="red" class="mr-1">mdi-trash-can</v-icon>
						</template>
						<span>Excluir</span>
					</v-tooltip>
					<!-- botão copiar -->
					<v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on" @click="copiar(item)" color="green"
								class="mr-1">mdi-content-copy</v-icon>
						</template>
						<span>Copiar</span>
					</v-tooltip>
				</template>
			</DataTable>
		</v-card-text>

		<!-- botão de cadastrar -->
		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="amber darken-1" dark absolute bottom right fab fixed class="mb-10" v-bind="attrs" v-on="on"
					@click="dialogCadastro = true">
					<v-icon color="black">mdi-plus</v-icon>
				</v-btn>
			</template>
			<span>Adicionar Câmeras</span>
		</v-tooltip>

		<!-- dialog de cadastro -->
		<v-dialog v-model="dialogCadastro" persistent max-width="800" scrollable>
			<v-card>
				<v-card-title class="text-h5 pr-4 mb-2">{{ endereco.id ? "Editar" : "Cadastrar" }} Câmeras<v-spacer></v-spacer>
					<v-btn icon @click="closeCadastro">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pt-1 pb-0">
					<v-form ref="formEndereco">
						<v-row>
							<v-col>
								<v-text-field v-model="endereco.address" label="Endereço" required :rules="requiredField"
									outlined></v-text-field>
							</v-col>
							<v-col>
								<v-text-field v-model="endereco.port" label="Porta" required :rules="requiredField"
									outlined></v-text-field>
							</v-col>
							<v-col>
								<v-select v-model="software" :items="[{ value: 1, text: 'Sigma Imagem' }, { value: 2, text: 'Dguard' }]"
									label="Software" outlined required :rules="requiredField">
								</v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field v-model="endereco.user" label="Usuário" required :rules="requiredField"
									outlined></v-text-field>
							</v-col>
							<v-col>
								<v-text-field v-model="endereco.password" label="Senha" type="password" required :rules="requiredField"
									outlined></v-text-field>
							</v-col>
							<v-col cols="auto">
								<v-btn v-if="!endereco.id" color="success" elevation="0" @click="buscaCameras(endereco)" size="x-large">
									<!-- <TooltipIcon text="Buscar Câmeras" icon="mdi-magnify" top="true" color="white" /> -->
									Buscar câmeras
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
					<v-divider v-if="!endereco.id"></v-divider>
					<v-card-text>
						<DataTable v-if="!endereco.id" :headers="camHeaders" :items="cameras" :items-per-page="5"
							class="elevation-0">
							<template v-slot:item.active="{ item }">
								<v-chip color="success" text-color="white" v-if="item.active">
									Ativo
								</v-chip>
								<v-chip color="red" text-color="white" v-else> Inativo </v-chip>
							</template>
							<template v-slot:item.actions="{ item }">
								<TooltipIcon :text="`Salvar: ${item.name}`" icon="mdi-content-save" top="true" color="success"
									@click="save(item)" />
								<TooltipIcon :text="`Mostrar Snapshot: ${item.name}`" icon="mdi-camera" left="true" color="primary"
									@click="takeSnapshotTest(item)" />
							</template>
						</DataTable>
					</v-card-text>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4">
					<v-spacer></v-spacer>
					<v-btn text @click="closeCadastro">Cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- dialog de Imagem -->
		<Snapshots ref="dialogSnapshot" />

		<ConfirmDialog ref="confirmDialog" />
	</div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import DataTable from "../../DataTableGeneric";
import ConfirmDialog from "@/components/ConfirmDialog";
import { mapGetters } from "vuex";
import TooltipIcon from "../../Template/TooltipIcon";
import Snapshots from "./Snapshots";

export default {
	name: "CliCameras",
	components: {
		DataTable,
		ConfirmDialog,
		TooltipIcon,
		Snapshots,
	},
	props: ["enderecos", "clientId"],
	data: () => {
		return {
			dialogCadastro: false,
			dialogSnapshot: false,
			snapshot: "",
			requiredField,
			cameras: [],
			softwares: [
				{ value: 1, text: "Sigma Imagem" },
				{ value: 2, text: "Dguard" },
			],
			software: null,
			endereco: {
				id: null,
				clientId: null,
				groupId: null,
				address: "",
				port: null,
				user: "",
				password: "",
			},
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Nome", value: "name" },
				{ text: "Endereço", value: "address" },
				{ text: "Porta", value: "port" },
				{ text: "Software", value: "typeId" },
				{
					text: "Ações",
					value: "actions",
					align: "center",
					sortable: false,
					filterable: false,
					width: 125,
				},
			],
			camHeaders: [
				{ text: "Camera", value: "name" },
				{ text: "Modelo", value: "model" },
				{ text: "Ativo", value: "active" },
				{
					text: "Ações",
					value: "actions",
					align: "center",
					sortable: false,
					filterable: false,
					width: 88,
				},
			],
		};
	},
	computed: {
		...mapGetters(["getAmbienteNameById"]),
	},
	methods: {
		reset() {
			this.endereco = {
				id: null,
				clientId: null,
				groupId: null,
				address: "",
				port: null,
				user: "",
				password: "",
			};
			this.cameras = [];
		},
		buscaCameras(endereco) {
			if (this.$refs.formEndereco.validate()) {
				this.$http.post("snapshot/getCameras", {
					address: endereco.address,
					port: endereco.port,
					user: endereco.user,
					password: endereco.password,
					typeId: this.software,
				}).then((response) => {
					this.cameras = response.data;
				});
			}
		},
		save(item) {
			if (!this.$refs.formEndereco.validate()) {
				return;
			}
			this.endereco.clientId = this.clientId;
			this.endereco.groupId = this.$store.state.user.groupId;
			this.endereco.typeId = this.software;
			this.endereco.urlsnap = item.url;
			this.endereco.callback = () => {
				this.$store.dispatch("loadSnapshots");
			};
			if (item) {
				this.endereco.name = item.name;
			}
			this.$store.dispatch("saveSnap", this.endereco);
			this.cameras.splice(this.cameras.indexOf(item), 1);
		},
		editar(item) {
			this.endereco = { ...item };
			this.dialogCadastro = true;
		},
		excluir(item) {
			this.$refs.confirmDialog.open({
				title: "Excluir Endereço",
				text: `Deseja excluir o endereço?`,
				confirmCallback: () => {
					this.$store.dispatch("deleteSnap", item);
				},
			});
		},
		copiar(item) {
			this.endereco = { ...item };
			this.endereco.id = null;
			this.software = item.typeId;
			this.dialogCadastro = true;
		},
		closeCadastro() {
			this.dialogCadastro = false;
			this.$refs.formEndereco.resetValidation();
			this.reset();
		},
		takeSnapshot(item) {
			this.snapshot = "";
			this.$refs.dialogSnapshot.open(item);
		},
		takeSnapshotTest(item) {
			this.snapshot = "";
			this.$refs.dialogSnapshot.openTest({ ...item, ...this.endereco, typeId: this.software, urlsnap: item.url });
		},
	},
	created() {
		this.reset();
	},
};
</script>

<style>
#table-activities .v-data-table__wrapper {
	max-height: 178px;
}
</style>