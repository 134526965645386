<template>
  <div class="d-flex justify-center align-center h-100">
    <v-card ref="form" max-width="400" id="cardLogin">
      <v-form ref="formLogin" @submit.prevent="login">
        <v-card-title> Login do Sistema </v-card-title>
        <v-card-text class="pb-0">
          <v-text-field
            ref="userName"
            v-model="user.userName"
            :rules="[() => !!user.userName || 'Campo obrigatório']"
            label="Usuário"
            placeholder="Usuário"
            required
            outlined
          ></v-text-field>
          <v-text-field
            ref="password"
            v-model="user.password"
            :rules="[() => !!user.password || 'Campo obrigatório']"
            label="Senha"
            placeholder="Senha"
            @keydown.enter="login"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            required
            outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="px-4 pb-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="success" type="submit"> Entrar </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show1: false,
      user: {
        userName: "",
        password: "",
      },
      users: [],
    };
  },
  methods: {
    login() {
      if (this.$refs.formLogin.validate()) {
        this.$http
          .post(`/login`, this.user)
          .then((resp) => {
            if (resp.data) {
              let token = resp.data.token;
              this.$store.commit("setToken", token);
              this.$router.push({ path: this.$store.getters.getDefaultRoute });
              this.getPermissions();
              this.getUser();
              this.$store.dispatch("loadGrupos");
              // console.log(this.$http.defaults.baseURL);
              this.$store.dispatch("setUrlAssets", this.$http.defaults.baseURL || window.location.origin);
            }
          })
          .catch(() => {});
      }
    },
    getUser() {
      this.$http(`/usuario/${this.$store.state.user.id}`)
        .then((resp) => {
          if (resp.data) {
            delete resp.data.password
            this.$store.commit("setUser", resp.data);
          }
        })
        .catch(() => {});
    },
    getPermissions() {
      this.$http("/permissao").then((resp) => {
        this.$store.dispatch("setPermission", resp.data);
      });
    },
  },

  created() {
    let token = localStorage.getItem("ca");
    if (token) {
      this.$store.commit("setToken", token);
      this.$store.commit("setLogged", true);
      this.$router.push({ path: this.$store.getters.getDefaultRoute });
      this.getPermissions();
      this.getUser();
      this.$store.dispatch("loadGrupos");
      this.$store.dispatch("setUrlAssets", this.$http.defaults.baseURL || window.location.origin);
    } else {
      if (!this.$router.history.current.path === "/login") {
        this.$router.push({ path: "/login" });
      }
    }
  },
};
</script>