<template>
	<div>
		<v-card>
			<v-card-title> Relatório de Ocorrências </v-card-title>
			<v-card-text>
				<!-- linha do filtro -->
				<v-card outlined>
					<v-card-text class="pb-0">
						<p class="text-subtitle-1 pt-1 pb-3">Filtros</p>
						<v-form ref="formReport">
							<v-row>
								<v-col class="py-0">
									<v-text-field
										v-model="relatorio.start"
										label="Data Inicial"
										required
										outlined
										type="datetime-local"
										@input="$refs.relatorioEnd.validate()"
									></v-text-field>
								</v-col>
								<v-col class="py-0">
									<v-text-field
										ref="relatorioEnd"
										v-model="relatorio.end"
										label="Data Final"
										required
										outlined
										type="datetime-local"
										:rules="[
											(v) =>
												validaDataFinal(v) ||
												'A data final deve ser maior que a inicial',
										]"
									></v-text-field>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-card-actions class="px-4">
						<MenuExportReport
							:disabled="!getRelOcorrencias.length"
							:items="geraitensRel(getRelOcorrencias, true)"
							module="ocorrencias"
							title="Relatório de Ocorrencias"
							filename="relatorio_de_ocorrencias"
						/>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="loadReport">
							<v-icon class="mr-1">mdi-magnify</v-icon> Buscar Relatório
						</v-btn>
					</v-card-actions>
				</v-card>
				<!-- linha da tabela -->
			</v-card-text>
			<TableOcorrencias
				:headers="headers"
				:items="geraitensRel(getRelOcorrencias, false)"
				:report="true"
			/>
		</v-card>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuExportReport from "@/components/Relatorios/MenuExportReport.vue";
import TableOcorrencias from "./TableOcorrencias.vue";

export default {
	name: "RelatoriOcorrencias",
	components: {
		MenuExportReport,
		TableOcorrencias,
	},
	computed: {
		...mapGetters([
			"getRelOcorrencias",
			"getOcorrencias",
			"getClientes",
			"getUserNameById",
			"getClienteNameById",
		]),
	},
	data() {
		return {
			ocorrencia: {},
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Ocorrência", value: "name" },
				{ text: "Prioridade", value: "priority" },
				{ text: "Funcionário", value: "userId" },
				{ text: "Comunicante", value: "communicant" },
				{ text: "Data Fato", value: "factDate" },
				{ text: "Descrição", value: "description" },
				{ text: "Cliente", value: "clientId" },
				{ text: "Data Comunicação", value: "createdAt" },
				{ text: "Tipo", value: "type" },
				{ text: "Mídias", value: "media" },
			],
			relatorio: {},
			prioridade: [
				{ text: "Alta", value: 1 },
				{ text: "Média", value: 2 },
				{ text: "Baixa", value: 3},
				{ text: "Somente Registro", value: 4 },
			],
		};
	},
	methods: {
		loadReport() {
			if (this.$refs.formReport.validate()) {
				this.relatorio.groupId = this.$store.getters.getCliente.groupId;
				this.$store.dispatch("loadReportOcorrencia", this.relatorio);
			}
		},
		geraitensRel(itens, exportar) {
			let itensRel = [];
			itens.forEach((item) => {
				let itemRel = { ...item };
				itemRel.userId = this.getUserNameById(item.userId).split(" - ")[1];
				itemRel.clientId = this.getClienteNameById(item.clientId).split(
					" - "
				)[1];
				if (exportar) {
					itemRel.priority = this.prioridade.find(
						(p) => p.value == item.priority
					).text;
					itemRel.type = item.type == "ronda" ? "Ronda" : "Registro";
				}
				itensRel.push(itemRel);
			});
			return itensRel;
		},
		validaDataFinal(dataFinal) {
			// função auxiliar pois este teste direto no rules não funciona corretamente
			return new Date(dataFinal) > new Date(this.relatorio.start);
		},
	},
	created() {
		this.$store.dispatch("setRelOcorrencias", []);
		this.$store.dispatch(
			"loadOcorrencias",
			this.$store.getters.getCliente.clientId
		);
		this.$store.dispatch(
			"loadClientes",
			this.$store.getters.getCliente.groupId
		);
		// pega a data atual e seta no relatorio
		let now = new Date();
		let onlyDate = now.toISOString().substring(0, 11);
		this.relatorio.start = onlyDate + "00:00";
		this.relatorio.end = onlyDate + "23:59";
	},
};
</script>

<style>
</style>