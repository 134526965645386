export default {
    state: {
        online: [],
    },
    mutations: {
        setOnline(state, payload) {
            state.online = payload
        },
    },
    actions: {
        setOnline(context, payload) {
            context.commit('setOnline', payload)
        },
    },
    getters: {
        getOnline(state) {
            return state.online
        },
    },
}