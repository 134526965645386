<template>
  <v-tooltip
    :left="left.length > 0"
    :bottom="bottom.length > 0"
    :top="top.length > 0"
    :right="right.length > 0"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn :x-small="xsmall" icon v-bind="attrs" v-on="on" :disabled="disabled" @click="$emit('click')">
        <v-icon :color="color" v-if="icon">{{ icon }}</v-icon>
        <span v-else>infome o icone do tooltip via props :icon='mdi-xxx'</span>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>
  
  <script>
export default {
  name: "ToolTipIcon",
  props: {
    color: {
      type: String,
      required: false,
      default: "grey darken-4",
    },
    icon: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
      default: "informe o texto do tooltip via props :text='texto'",
    },
    top: {
      required: false,
      default: "",
    },
    bottom: {
      required: false,
      default: "",
    },
    left: {
      required: false,
      default: "",
    },
    right: {
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    xsmall: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
  
  <style>
</style>