<template>
  <v-card>
    <v-card-title>Visitados Cadastrados</v-card-title>
    <v-card-text>
      <DataTable :headers="headers" :items="getVisitados">
        <!-- formata a data -->
        <template slot="item.createdAt" slot-scope="{ item }">
          {{ new Date(item.createdAt).toLocaleString() }}
        </template>
        <template slot="item.updatedAt" slot-scope="{ item }">
          {{ new Date(item.updatedAt).toLocaleString() }}
        </template>
        <!-- imagem do avatar -->
        <template slot="item.picture" slot-scope="{ item }">
          <v-avatar size="70" class="my-2">
            <v-img
              :src="item.picture"
              lazy-src="/img/nopic.jpg"
            ></v-img>
          </v-avatar>
        </template>
      </DataTable>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import DataTable from "@/components/DataTableGeneric.vue";
export default {
  name: "visitados",
  components: {
    DataTable,
  },
  computed: {
    ...mapGetters(["getVisitados"]),
  },
  data() {
    return {
      dialogForm: false,
      headers: [
      { text: "Foto", value: "picture", sortable: false, filterable: false, width: 70 },
        { text: "Nome", value: "name" },
        { text: "Telefone / Ramal", value: "phone", sortable: false },
        { text: "Celular", value: "celPhone", sortable: false },
        { text: "Função", value: "place" },
        { text: "Hierarquia", value: "hierarchy", show: false },
        { text: "Observações", value: "obs" },
        { text: "Cadastrado", value: "createdAt" },
        { text: "Atualizado", value: "updatedAt", show: false },
      ],
      item: {},
    };
  },
  created() {
    this.$store.dispatch("loadVisitados");
  },
};
</script>

<style></style>