<template>
  <v-menu
    ref="menu"
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="title"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="menu2"
      v-model="time"
      full-width
      @click:minute="$refs.menu.save(time)"
      format="24hr"
    ></v-time-picker>
  </v-menu>
</template>

<script>
  export default {
    props:{
      title: {
        type: String,
        default: "Titulo"
      },
      timeStart:{
        type: String,
        default: "00:00"
      }
    },
    data () {
      return {
        time: "",
        menu2: false,
        modal2: false,
      }
    },
    watch:{
        time(){
            this.$emit('timePic', this.time)
            // console.log(this.time);
        },
        timeStart(){
          this.time = this.timeStart
        }
    },
    mounted(){
      this.time = this.timeStart
    },
    methods: {
      clear(){
        this.time = this.timeStart;
      }
    }
  }
</script>