import axios from 'axios'
import { queryStringBuilder } from '../../Utils/queryStringBuilder'

export default {
  state: {
    rondasVirtuais: [],
    rondasVirtuaisHorarios: [],
    relRondasVirtuais: [],
  },
  mutations: {
    setRondasVirtuais(state, payload) {
      state.rondasVirtuais = payload
    },
    setRelRondasVirtuais(state, payload) {
      state.relRondasVirtuais = payload
    }
  },
  actions: {
    setRondasVirtuais(context, payload) {
      context.commit('setRondasVirtuais', payload)
    },
    setRondasVirtuaisHorarios(context, payload) {
      context.commit('setRondasVirtuaisHorarios', payload)
    },
    setRelRondasVirtuais(context, payload) {
      context.commit('setRelRondasVirtuais', payload)
    },
    loadRondasVirtuais(context, payload) {
      let gp = payload ? `?clientId=${payload}` : ''
      axios(`/ronda-virtual${gp}`).then((resp) => {
        context.dispatch('setRondasVirtuais', resp.data)
      });
    },
    loadRondasVirtuaisHorarios(context, payload) {
      axios(`/ronda-virtual/horarios/${payload}`).then((resp) => {
        context.dispatch('setRondasVirtuaisHorarios', resp.data)
      });
    },
    saveRondaVirtual(context, payload) {
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/ronda-virtual${finalUrl}`, payload).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Ronda salva com Sucesso");
          context.dispatch("loadRondasVirtuais", payload.clientId);
          if (typeof payload.callback == 'function')
            payload.callback(resp.data);
          
        }
      });
    },
    loadReportRondaVirtual(context, payload) {
      let url = `/ronda-virtual/run/report?`;
      // gerador de query para relatorio
      url = queryStringBuilder(url, payload);
      axios(url).then((resp) => {
        context.commit("setRelRondasVirtuais", resp.data);
        if (resp.data.length == 0) {
          context.dispatch("resetSnack");
          context.dispatch("showErrorSnack", "Nenhum registro encontrado de acordo com o filtro");
        }
      });
    },
    saveRondaVirtualRun(context, payload) {
      let formData = new FormData();
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          const element = payload[key];
          if (Array.isArray(element)) {
            element.forEach((e) => {
              formData.append(key, e);
            });
          } else
            formData.append(key, element);
        }
      }
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/ronda-virtual/run${finalUrl}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Ronda concluída com Sucesso");
          if (typeof payload.callback == 'function')
            payload.callback(resp.data);
        }
      });
    },
    deleteRondaVirtual(context, payload) {
      axios.delete(`/ronda-virtual/${payload.id}`).then(() => {
        context.dispatch("resetSnack");
        context.dispatch("showSuccessSnack", "Ronda Deletada com Sucesso");
        context.dispatch("loadRondasVirtuais", payload.clientId);
      });
    },
  },
  getters: {
    getRondasVirtuais(state) {
      return state.rondasVirtuais
    },
    getRelRondasVirtuais(state) {
      return state.relRondasVirtuais
    },
    getRondasVirtuaisHorarios(state) {
      return state.rondasVirtuaisHorarios
    },
    getRondaVirtualNameById: (state) => (id) => {
      if (!state.rondasVirtuais) {
        return id
      }
      let gp = state.rondasVirtuais.find(g =>
        g.id == id
      )
      return gp ? `${gp.id} - ${gp.name}` : ''
    }
  },
}