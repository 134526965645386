<template>
  <v-expansion-panel flat>
    <v-expansion-panel-header>Cliente: {{ cliente.name }}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" sm="4">
          <strong>Tipo:</strong>
          {{ cliente.tipo }}
          <br>
          <strong>Grupo:</strong>
          {{ cliente.groupName }}
        </v-col>
        <v-col cols="4">
          <strong>Endereço:</strong>
          {{ cliente.address }}
        </v-col>
        <v-col cols="4">
          <strong>Telefone:</strong>
          {{ cliente.phone }}
          <br>
          <strong>CNPJ:</strong>
          {{ cliente.cnpj }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <span><strong>Descrição:</strong> {{ cliente.description }}</span>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "clientCard",
  props: {
    cliente: {
      type: Object,
    },
  },
};
</script>

<style>
</style>