<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2">{{ ambiente.id ? 'Editar' : 'Cadastrar' }} Ambiente<v-spacer></v-spacer>
          <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-form ref="formAmbiente">
            <v-text-field
              v-model="ambiente.name"
              label="Nome"
              outlined
              counter="40"
              maxlength="40"
              :rules="requiredField"
            ></v-text-field>
            <v-textarea
              v-model="ambiente.description"
              label="Descrição"
              outlined
            ></v-textarea>
            <!-- <v-autocomplete
              v-model="ambiente.checkPointId"
              :items="getCheckpoints"
              item-text="name"
              item-value="id"
              label="Vincular QR Code"
              :disabled="!!ambiente.id"
              outlined
              hide-details
            >
              <template v-slot:selection="{ item }">{{ item.id }} - {{ item.name }}</template>
              <template v-slot:item="{ item }" >
                <span><strong class="mr-3">{{ item.id }}</strong> {{ item.name }}</span>
              </template>
            </v-autocomplete> -->
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancelar</v-btn>
          <v-btn color="success" @click="save">Salvar 
            <v-menu open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                >mdi-arrow-down-drop-circle-outline</v-icon>
              </template>
              <v-btn @click="save({print: true})" text color="success" class="btn-dropdown"><v-icon class="mr-1">mdi-qrcode</v-icon>SALVAR e gerar QR Code</v-btn>
            </v-menu></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <checkpoints-dialog ref="checkpointsDialog" />
  </div>
</template>
<script>
import {
	reqFieldMinLength,
	requiredField,
} from "../../../Utils/InputValidation";
import CheckpointsDialog from "./CheckpointsDialog.vue";
import { mapGetters } from "vuex";

export default {
  name: "cadAmbientes",
  components: {
    CheckpointsDialog,
  },
  data: () => ({
    dialog: false,
    ambiente: {
      id: null,
      name: "",
      enabled: true,
      clientId: null,
      description: "",
      checkPointId: null,
    },
    requiredField,
    reqFieldMinLength,
  }),
  computed: {
    ...mapGetters(["getCheckpoints", "getAmbientes",]),
  },
  props: {
    item: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    save(options) {
      if (this.$refs.formAmbiente.validate()) {
        this.ambiente.clientId = this.$store.getters.getCliente.id;
        this.ambiente.callback = (resp) => {
          this.$emit("onSave", resp);
          if (options.print){
            this.$refs.checkpointsDialog.open({
              checkpoints: [{id: resp.checkPointId, name: resp.name}],
              isFacilities: true,
            });
          }
          this.close();
        };
        this.$store.dispatch("saveAmbiente", this.ambiente);
      }
    },
    reset() {
      this.$refs.formAmbiente.reset();
    },
    open() {
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.formAmbiente.resetValidation();
      });
    },
    close() {
      this.dialog = false;
      this.reset();
    },
  },
  created() {
    this.$store.dispatch("loadCheckpoints", this.$store.getters.getCliente.id);
  }
}
</script>