<template>
  <div>
    <v-virtual-scroll
      :items="items"
      bench="1"
      item-height="78"
      height="78"
      class="my-1"
    >
      <template v-slot:default="{ item }">
        <v-list-item class="px-0">
          <v-list-item-avatar width="70" height="70" class="my-1">
            <v-avatar size="70">
              <v-img :src="item.picture" lazy-src="/img/nopic.jpg"></v-img>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content class="py-1">
            <v-list-item-title class="mb-1">{{ item.plate.toUpperCase() }}</v-list-item-title>
            <v-list-item-subtitle class="text-truncate">Prefixo: {{ item.prefix }}</v-list-item-subtitle>
            <v-list-item-subtitle class="text-truncate">Descrição: {{ item.description }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
export default {
  name: "ListaVeiculo",
  props: {
    items: {
      type: Array,
    },
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>