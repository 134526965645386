var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cliente"},[_c('ClienteCard',{attrs:{"cliente":_vm.cliente},on:{"refresh":_vm.refresh}}),_c('v-card',[_c('v-tabs',{staticClass:"rounded-b-0",attrs:{"fixed-tabs":"","background-color":"success","show-arrows":""}},[_c('v-tab',{staticClass:"d-print-none"},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-email-arrow-right")]),_vm._v("Avisos de Eventos ("+_vm._s(_vm.avisos.length)+") ")],1),_c('v-tab-item',{staticClass:"px-2 mt-2"},[_c('DataTable',{attrs:{"items":_vm.avisos,"headers":_vm.headersAvisos,"actions":false},scopedSlots:_vm._u([{key:"item.eventId",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getEventoById(item.eventId).color}},[_vm._v("mdi-information")]),_vm._v(" "+_vm._s(_vm.getEventoById(item.eventId).name)+" ")]}},{key:"item.emails",fn:function(ref){
var item = ref.item;
return _vm._l((item.emails),function(email){return _c('p',{key:email,staticClass:"my-0"},[_vm._v(" "+_vm._s(email)+" ")])})}},{key:"item.sendWithoutTreatment",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sendWithoutTreatment ? "Sim" : "Não")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('TooltipIcon',{attrs:{"color":"warning","text":"Editar","icon":"mdi-pencil","top":"true"},on:{"click":function($event){return _vm.openDialogCadastro(item)}}}),_c('TooltipIcon',{attrs:{"color":"red","text":"Excluir","icon":"mdi-trash-can","top":"true"},on:{"click":function($event){return _vm.delAvisoEvento(item)}}})]}}])})],1)],1)],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-10",attrs:{"color":"amber darken-1","dark":"","absolute":"","bottom":"","right":"","fab":"","fixed":""},on:{"click":_vm.openDialogCadastro}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Adicionar Aviso")])]),_c('CadAvisoEventos',{ref:"dialogCadastro",on:{"refresh":_vm.refresh}}),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }