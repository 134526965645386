<template>
	<div>
		<v-card>
			<v-card-title> Relatório de Rondas Virtuais </v-card-title>
			<v-card-text>
				<!-- linha do filtro -->
				<v-card outlined>
					<v-card-text class="pb-0">
						<p class="text-subtitle-1 pt-1 pb-3">Filtros</p>
						<v-form ref="formReport">
							<v-row>
								<v-col class="py-0">
									<v-text-field
										v-model="relatorio.start"
										label="Data Inicial"
										required
										outlined
										type="datetime-local"
										@input="$refs.relatorioEnd.validate()"
									></v-text-field>
								</v-col>
								<v-col class="py-0">
									<v-text-field
										ref="relatorioEnd"
										v-model="relatorio.end"
										label="Data Final"
										required
										outlined
										type="datetime-local"
										:rules="[
											(v) =>
												validaDataFinal(v) ||
												'A data final deve ser maior que a inicial',
										]"
									></v-text-field>
								</v-col>
								<v-col class="py-0" cols="3">
									<v-switch v-model="relatorio.showNulls" class="d-inline-block" label="Mostrar não finalizados"/>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-card-actions class="px-4">
						<MenuExportReport
							:disabled="!getRelRondasVirtuais.length"
							:items="geraitensRel(getRelRondasVirtuais)"
							module="rondasVirtuais"
							title="Relatório de Rondas Virtuais"
							filename="relatorio_de_rondas_virtuais"
						/>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="loadReport">
							<v-icon class="mr-1">mdi-magnify</v-icon> Buscar Relatório
						</v-btn>
					</v-card-actions>
				</v-card>
				<!-- linha da tabela -->
			</v-card-text>
			<TableRondasVirtuais
				:headers="headers"
				:items="getRelRondasVirtuais"
				:report="true"
			/>
		</v-card>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuExportReport from "@/components/Relatorios/MenuExportReport.vue";
import TableRondasVirtuais from "./TableRondasVirtuais.vue";

export default {
	name: "RelatorioRondasVirtuais",
	components: {
		MenuExportReport,
		TableRondasVirtuais,
	},
	computed: {
		...mapGetters([
			"getRelRondasVirtuais",
			"getUserNameById",
			"getClienteNameById",
		]),
	},
	data() {
		return {
			ocorrencia: {},
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Ronda", value: "name" },
				{ text: "Data", value: "createdAt"},
				{ text: "Horário", value: "horario" },
				{ text: "Usuário", value: "userId" },
				{ text: "Cliente", value: "clientId" },
				{ text: "Finalizada", value: "finished" },
				{ text: "Observação", value: "payload.observacao" },
				{ text: "Questões", value: "payload.questions" },
				{ text: "Tipo", value: "tipo" }
			],
			relatorio: {},
		};
	},
	methods: {
		loadReport() {
			if (this.$refs.formReport.validate()) {
				this.relatorio.clientId = this.$store.getters.getCliente.id;
				this.$store.dispatch("loadReportRondaVirtual", this.relatorio);
			}
		},
		getDia(data) {
			if (data) {
				let dataFormatada = new Date(data);
				return dataFormatada.toLocaleDateString();
			}
		},
		geraitensRel(itens) {
			let itensRel = [];
			if (itens) {
				itens.forEach((item) => {
					let itemRel = { ...item };
					itemRel.userId = this.getUserNameById(item.userId).split(" - ")[1];
					itemRel.clientId = this.getClienteNameById(item.clientId).split(
						" - "
					)[1];
					itemRel.data = itemRel.finishedAt? this.getDia(itemRel.finishedAt) : this.getDia(itemRel.createdAt);
					itemRel.horario = itemRel.finishedAt? itemRel.finishedAt.substring(11, 16) : itemRel.createdAt.substring(11, 16);
					itensRel.push(itemRel);
				});
			}
			return itensRel;
		},
		validaDataFinal(dataFinal) {
			// função auxiliar pois este teste direto no rules não funciona corretamente
			return new Date(dataFinal) > new Date(this.relatorio.start);
		},
	},
	created() {
		this.$store.dispatch("setRelRondasVirtuais", []);
		this.$store.dispatch(
			"loadClientes",
			this.$store.getters.getCliente.groupId || null
		);
		// pega a data atual e seta no relatorio
		let now = new Date();
		let onlyDate = now.toISOString().substring(0, 11);
		this.relatorio.start = onlyDate + "00:00";
		this.relatorio.end = onlyDate + "23:59";
	},
};
</script>

<style>
</style>