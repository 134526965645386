var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cliUser"},[_c('v-card-title',[_vm._v("Cadastros de Usuários")]),_c('v-card-text',[_c('DataTable',{attrs:{"items":_vm.usuarios,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-2",attrs:{"size":"70"}},[_c('v-img',{attrs:{"src":item.picture,"lazy-src":"/img/nopic.jpg"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.editarU(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar : "+_vm._s(item.name))])])]}}])})],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-10",attrs:{"color":"amber darken-1","dark":"","absolute":"","bottom":"","right":"","fab":"","fixed":"","to":{ name: 'cadUser', params: { groupId: _vm.groupId } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Adicionar Usuário")])]),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }