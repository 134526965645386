<template>
  <div class="recursos">
    <div class="form">
      <v-card class="mx-auto py-2" elevation="5" outlined>
        <v-card-title> Cadastro de Recurso </v-card-title>
        <v-form>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="2">
                <v-checkbox
                  v-model="recurso.enabled"
                  label="Ativo"
                ></v-checkbox>
                <v-checkbox
                  v-model="recurso.public"
                  label="Público"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="recurso.name"
                  :counter="50"
                  label="Nome do Recurso"
                  hint="Digite algum nome para este recurso"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete
                  v-if="recAvailable.length"
                  v-model="recurso.path"
                  label="Path"
                  hint="Path do recurso EX: /recursoMuitoLegal"
                  :items="recAvailable"
                  persistent-hint
                >
                </v-autocomplete>
                <v-text-field
                  v-else
                  v-model="recurso.path"
                  :counter="50"
                  label="Path"
                  hint="Path do recurso EX: /recursoMuitoLegal"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-textarea
                  v-model="recurso.description"
                  rows="1"
                  label="Descrição"
                  hint="Digite uma descrição do Recurso"
                  :counter="255"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed color="warning" class="mr-2" @click="clear">
              Cancelar
            </v-btn>
            <v-btn depressed color="success" @click="save(recurso)">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-card class="my-1">
        <v-card-text>
          <Table
            :headers="header"
            :items="recursos"
            @edit="edit($event)"
            :rows="15"
            @delete="delet($event.id)"
          />
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import Table from "../TableGeneric";
export default {
  name: "Recursos",
  components: {
    Table,
  },
  data: () => {
    return {
      text: "",
      snackbar: false,
      recurso: {
        id: null,
        enabled: true,
      },
      recursos: [],
      recAvailable: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Path", value: "path" },
        { text: "Descrição", value: "description" },
        { text: "Ativo", value: "ativo" },
        { text: "Público", value: "publico" },
        { text: "Ações", value: "actions", width: "85px" },
      ],
    };
  },
  methods: {
    get() {
      this.$http("nvrecurso").then((resp) => {
        resp.data.forEach((element) => {
          element.ativo = element.enabled ? "Sim" : "Não";
          element.publico = element.public ? "Sim" : "Não";
        });
        this.recursos = resp.data;
      });
    },
    getRecAvailable() {
      this.$http("rotas").then((resp) => {
        this.recAvailable = resp.data;
      });
    },
    save(recurso) {
      const metodo = recurso.id ? "put" : "post";
      const finalUrl = recurso.id ? `/${recurso.id}` : "";
      this.$http[metodo](`/nvrecurso${finalUrl}`, recurso).then(() => {
        this.get();
        this.clear();
        this.snackbar = true;
        this.text = "Salvo com Sucesso";
      });
    },
    edit(rec) {
      this.recurso = rec;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
    },
    delet(id) {
      this.$http.delete(`/nvrecurso/${id}`).then(() => {
        this.get();
        this.snackbar = true;
        this.text = "Deletado com Sucesso";
      });
    },
    clear() {
      this.recurso = {
        id: null,
        enabled: true,
      };
    },
  },
  mounted() {
    this.get();
    this.getRecAvailable();
  },
};
</script>

<style>
</style>