var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.report)?[_c('v-card-title',[_vm._v("Veículos Presentes")]),_c('v-card-subtitle',[_vm._v("Clique na tabela para mais detalhes")])]:_vm._e(),_c('v-card-text',[_c('DataTable',{attrs:{"headers":_vm.headers,"items":_vm.items},on:{"rowClick":_vm.openModalVeiculo},scopedSlots:_vm._u([{key:"item.picturesEnter",fn:function(ref){
var item = ref.item;
return [_c('CardImgList',{attrs:{"items":item.picturesEnter,"width":70,"multiple":false}})]}},{key:"item.picturesExit",fn:function(ref){
var item = ref.item;
return [_c('CardImgList',{attrs:{"items":item.picturesExit,"width":70,"multiple":false}})]}},{key:"item.placa",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(item.placa.toUpperCase()))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formataData(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formataData(item.updatedAt))+" ")]}},{key:"item.visitaId",fn:function(ref){
var item = ref.item;
return [_c('CardVisita',{model:{value:(item.visitaId),callback:function ($$v) {_vm.$set(item, "visitaId", $$v)},expression:"item.visitaId"}})]}},{key:"item.visitanteName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"70"}},[_c('v-img',{attrs:{"src":item.visitantePic,"lazy-src":"/img/nopic.jpg"}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(item.visitanteName))])])],1)]}},{key:"item.visitadoName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"70"}},[_c('v-img',{attrs:{"src":item.visitadoPic,"lazy-src":"/img/nopic.jpg"}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(item.visitadoName))])])],1)]}},{key:"item.timeDif",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getIntervalo(item.createdAt, item.updatedAt, item.perm))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"orange lighten-1"},on:{"click":function($event){$event.stopPropagation();return _vm.regOutVehicle(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1)]}}],null,true)},[_c('span',[_c('strong',[_vm._v("Registrar Saida")]),_vm._v(" "+_vm._s(item.placa))])])]}}])})],1),_c('VeiculoDetails',{ref:"modalVeiculoDetails",attrs:{"actions":_vm.report ? false : _vm.actions},on:{"out":function($event){return _vm.regOutVehicle($event)}}}),(!_vm.report)?_c('DialogVeiculoExit',{ref:"dialogVehicleExit",on:{"closeVehicleDetails":_vm.closeVehicleDetails}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }