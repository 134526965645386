<template>
	<div>
		<v-dialog v-model="dialog" persistent max-width="700">
			<v-card>
				<v-card-title
					>{{ ronda.horarioId ? "Execução programada" : "Execução Extra"
					}}<v-spacer />
					<TooltipIcon
						:text="
							images.length ? `${images.length} imagens adicionadas` : 'Nenhuma imagem adicionada'
						"
						left="true"
						:icon="images.length ? 'mdi-image-check' : 'mdi-image-off'"
						:color="images.length ? 'success' : 'red'"
					/>
					<v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
				</v-card-title>
				<v-card-title class="py-2 intermed-title"
					>Ronda Virtual: {{ ronda.name }}</v-card-title
				>
				<v-card-text>
					<v-card outlined class="overflow-y-auto" max-height="500">
						<span class="text-h6 mx-3 my-3"><strong>Questões:</strong></span>
						<v-form class="mx-3 my-3" ref="formRondaVirtual">
							<v-row v-for="(respT, idxText) in this.respText" :key="idxText">
								<v-col cols="12" class="my-0 py-0">
									<v-text-field
										class="my-0 py-0"
										v-model="text[idxText]"
										:label="respT.name"
										outlined
										:rules="[(v) => !!v || 'Obrigatório']"
									>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col
									cols="6"
									class="my-0 py-0"
									v-for="(respN, idxNumber) in this.respNumber"
									:key="'A' + idxNumber"
								>
									<v-text-field
										class="my-0 py-0"
										v-model="number[idxNumber]"
										min="0"
										:label="respN.name"
										outlined
										type="number"
										:rules="[(v) => !!v || 'Obrigatório']"
									>
									</v-text-field>
								</v-col>
							</v-row>
							<v-row
								v-for="(respB, idxBoolean) in this.respBoolean"
								:key="'B' + idxBoolean"
							>
								<v-col cols="12" class="my-0 py-0">
									<v-radio-group
										v-model="boolean[idxBoolean]"
										:label="`${respB.name}:`"
										outlined
										row
										class="my-0 py-0"
										required
										:rules="ruleBoolean"
									>
										<v-radio label="Sim" :value="true"></v-radio>
										<v-radio label="Não" :value="false"></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" class="mt-2 mb-0 py-0">
									<v-textarea
										class="my-0 py-0"
										v-model="ronda.observacao"
										label="Observação (Opcional)"
										outlined
									></v-textarea>
								</v-col>
							</v-row>
							<v-row v-show="!!images.length">
								<v-col cols="12" class="mt-2 mb-0 py-0 text-center">
									<div
										v-for="(img, idx) in images"
										:key="idx"
										class="snapshot mb-3"
									>
										<img
											:src="img"
											aspect-ratio="1.77777"
											width="450px"
											contain
										/>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													v-bind="attrs"
													v-on="on"
													fab
													x-small
													@click="removeImg(idx)"
													class="btn-remove error"
												>
													<v-icon>mdi-trash-can</v-icon>
												</v-btn>
											</template>
											<span>Remover Snapshot</span>
										</v-tooltip>
									</div>
								</v-col>
							</v-row>
						</v-form>
					</v-card>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-speed-dial
						v-model="btn"
						direction="top"
						transition="slide-y-reverse-transition"
					>
						<template v-slot:activator>
							<v-btn v-show="getSnapshots.length" color="success" text v-model="btn">
								<v-icon v-if="!btn">mdi-video</v-icon>
								<v-icon v-else>mdi-close</v-icon>
								Adicionar Snapshot
							</v-btn>
						</template>
						<v-btn
							v-for="(item, idx) in getSnapshots"
							:key="'C' + idx"
							@click="takeSnapshot(item)"
							color="success"
							class="mx-1 text-right"
							right
							align-self="flex-end"
						>
							Camera {{ item.name }}
						</v-btn>
					</v-speed-dial>

					<v-btn color="primary" text @click="close">Fechar</v-btn>
					<v-btn color="success" text @click="executaRonda">Executar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<Snapshots ref="dialogSnapshot" v-on:addImg="addImg" />
	</div>
</template>

<script>
import Snapshots from "./Snapshots";
import { mapGetters } from "vuex";
import TooltipIcon from "../../Template/TooltipIcon.vue";

export default {
	name: "execRondaVirtual",
	components: {
		Snapshots,
		TooltipIcon,
	},
	computed: {
		...mapGetters(["getSnapshots"]),
	},
	data: () => ({
		images: [],
		btn: false,
		dialog: false,
		ronda: {
			payload: {},
		},
		respNumber: 0,
		respBoolean: 0,
		respText: 0,
		text: [],
		boolean: [],
		number: [],
		ruleBoolean: [(v) => v !== null || "Obrigatório"],
	}),
	methods: {
		close() {
			this.dialog = false;
			this.$refs.formRondaVirtual.reset();
		},
		open(item) {
			this.images = [];
			this.ronda = item;
			this.ronda.images = [];
			this.ronda.startedAt = new Date();
			this.getRespostas();
			this.dialog = true;
		},
		pegaNumeroCam(name) {
			let num = name.split(" ");
			return num[num.length - 1];
		},
		takeSnapshot(item) {
			this.$refs.dialogSnapshot.open(item);
			this.$refs.dialogSnapshot.btnSalvar = true;
		},
		addImg(img, base64) {
			this.ronda.images.push(img);
			this.images.push(base64);
		},
		removeImg(idx){
			this.ronda.images.splice(idx, 1);
			this.images.splice(idx, 1);
		},
		executaRonda() {
			if (!this.$refs.formRondaVirtual.validate()) {
				return;
			}
			
			let execucao = {
				rondaId: this.ronda.id,
				images: this.ronda.images,
				payload: {
					observacao: this.ronda.observacao,
					horario: {},
					questions: [
						...this.respNumber.map((r, idx) => {
							return {
								name: r.name,
								type: r.type,
								value: this.number[idx],
							};
						}),
						...this.respBoolean.map((r, idx) => {
							return {
								name: r.name,
								type: r.type,
								value: this.boolean[idx],
							};
						}),
						...this.respText.map((r, idx) => {
							return {
								name: r.name,
								type: r.type,
								value: this.text[idx],
							};
						}),
					],
				},
			};
			if (this.ronda.horarioId) {
				execucao.horarioId = this.ronda.horarioId;
				execucao.id = this.ronda.id;
				execucao.rondaId = this.ronda.rondaId;
			}
			execucao.payload = JSON.stringify(execucao.payload);
			this.$store.dispatch("saveRondaVirtualRun", execucao);
			if (this.ronda.id) {
				this.$emit("concluiRonda");
			}
			this.close();
		},
		getRespostas() {
			this.respNumber = this.ronda.questions.filter((r) => r.type === "number");
			this.respBoolean = this.ronda.questions.filter(
				(r) => r.type === "boolean"
			);
			this.respText = this.ronda.questions.filter((r) => r.type === "text");
		},
	},
	created() {
		this.$store.dispatch("loadSnapshots", this.$store.getters.getCliente.id);
	},
};
</script>

<style >
.snapshot {
	position: relative;
	display: inline-block;
}
.snapshot img{
	display: block;
}
.snapshot .btn-remove {
	position: absolute;
	bottom: 15px;
	right: 15px;
	z-index: 1;
}
</style>