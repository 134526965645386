<template>
  <v-navigation-drawer
    app
    permanent
    expand-on-hover
    class="d-print-none menu"
    v-if="showMenu"
    dark
  >
    <v-list nav dense class="over">
      <v-list-item
        link
        :to="menuItem.to"
        v-for="menuItem in menus"
        :key="menuItem.title"
      >
        <v-list-item-icon >
          <v-icon
            :color="menuItem.color"
            >{{ menuItem.icon }}</v-icon
          >
        </v-list-item-icon>
        <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
      </v-list-item>  
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data: () => {
    return {
      showMenu: false,
      menus: [],
    };
  },
  methods: {
    ...mapActions(["logOff","setParameters"]),
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getPermission", "getUsuario", "getLogged"]),
  },
  watch: {
    user() {
      let param = []
      if (this.user) {
        this.$http("nvmenu/user").then((resp) => {
          this.menus = resp.data;
          if(this.menus.length){
            this.showMenu = true;
          }
          resp.data.forEach(element => {
            param.push({to: element.to, parameters:element.parameters})
          });
          this.setParameters(param)
        });
      } else {
        this.menus = [];
        this.showMenu = false;
      }

    },
    getLogged(){
      if(this.getLogged){
        if(this.menus.length){
            this.showMenu = true;
          }
      }else{
        this.showMenu = false;
      }
    }
  },
};
</script>

<style scoped>
.over {
  overflow: auto;
  height: 100vh;
}
* {
  scrollbar-width: thin;
  scrollbar-color: gray rgb(82, 82, 82);
}
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: #005f36;
}
*::-webkit-scrollbar-thumb {
  background-color: #005f36;
  border-radius: 20px;
  border: 5px solid rgb(82, 82, 82);
}
</style>