var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Grupos de Pontos de Ronda")]),_c('v-card-text',[_c('DataTable',{attrs:{"headers":_vm.headers,"items":_vm.checkpointGroups},scopedSlots:_vm._u([{key:"item.checkpoints",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(item.checkpoints.length)+" Pontos de ronda")]),(item.checkpoints.length > 0)?_c('v-menu',{attrs:{"open-on-hover":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('v-list',{staticClass:"dropdown-list superdense",attrs:{"dense":""}},_vm._l((item.checkpoints),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_c('strong',{staticClass:"mr-2"},[_vm._v(_vm._s(item.id))]),_vm._v(_vm._s(item.name))])],1)],1)}),1)],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('TooltipIcon',{attrs:{"text":("Editar: " + (item.name)),"icon":"mdi-pencil","top":"true","color":"warning"},on:{"click":function($event){return _vm.editarGrupoPontos(Object.assign({}, item))}}}),_c('TooltipIcon',{attrs:{"text":("Excluir: " + (item.name)),"icon":"mdi-trash-can","top":"true","color":"red"},on:{"click":function($event){return _vm.excluirGrupoPontos(item)}}})]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600","scrollable":""},model:{value:(_vm.dialogCadastro),callback:function ($$v) {_vm.dialogCadastro=$$v},expression:"dialogCadastro"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 pr-4 mb-2"},[_vm._v(_vm._s(_vm.checkpointGroup.id ? "Editar" : "Cadastrar")+" Grupo de Pontos de Ronda "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){_vm.dialogCadastro = false}}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-1 pb-0",attrs:{"outlined":""}},[_c('v-form',{ref:"formGroup"},[_c('v-text-field',{attrs:{"label":"Nome","required":"","rules":_vm.requiredField,"outlined":""},model:{value:(_vm.checkpointGroup.name),callback:function ($$v) {_vm.$set(_vm.checkpointGroup, "name", $$v)},expression:"checkpointGroup.name"}}),_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-card-subtitle',[_vm._v("Pontos de ronda")]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-autocomplete',{attrs:{"items":_vm.checkpoints,"hide-selected":"","label":"Selecionar Pontos de Ronda","multiple":"","outlined":"","item-text":"name","return-object":"","rules":[
										_vm.checkpointGroup.checkpoints.length > 0 ||
											'Adicione pelo menos um Ponto de Ronda' ]},scopedSlots:_vm._u([{key:"selection",fn:function(){return undefined},proxy:true},{key:"item",fn:function(ref){
									var item = ref.item;
return [_c('span',[_c('strong',{staticClass:"mr-3"},[_vm._v(_vm._s(item.id))]),_vm._v(" "+_vm._s(item.name))])]}}]),model:{value:(_vm.checkpointGroup.checkpoints),callback:function ($$v) {_vm.$set(_vm.checkpointGroup, "checkpoints", $$v)},expression:"checkpointGroup.checkpoints"}}),(_vm.checkpointGroup.checkpoints.length)?_c('div',[_c('v-data-table',{staticClass:"fixed-header-table draggable mt--1 mb-4",attrs:{"hide-default-footer":"","hide-default-header":"","id":"table-checkpointsGroup"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"px-0",attrs:{"width":"24"}}),_c('th',{staticClass:"text-center",attrs:{"width":"38"}},[_vm._v("Ordem")]),_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Nome")]),_c('th',{staticClass:"text-center",attrs:{"width":"70"}},[_vm._v("Ações")])])])]},proxy:true},{key:"body",fn:function(){return [_c('draggable',{attrs:{"list":_vm.checkpointGroup.checkpoints,"tag":"tbody"}},_vm._l((_vm.checkpointGroup.checkpoints),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"px-0",attrs:{"width":"24"}},[_c('v-icon',[_vm._v("mdi-drag-vertical")])],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var on = ref.on;
									var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.checkpointGroup.checkpoints.splice(
																			index,
																			1
																		)}}},'v-icon',attrs,false),on),[_vm._v("mdi-trash-can")])]}}],null,true)},[_c('span',[_vm._v("Excluir: "+_vm._s(item.name))])])],1)])}),0)]},proxy:true}],null,false,3316591851)}),_c('p',{staticClass:"text-caption"},[_vm._v(" Arraste os itens da tabela caso deseje alterar a ordem dos pontos. ")])],1):_vm._e()],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.closeCadastro()}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.salvarGrupoPontos}},[_vm._v("Salvar")])],1)],1)],1)],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
																		var on = ref.on;
																		var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-10",attrs:{"color":"amber darken-1","dark":"","absolute":"","bottom":"","right":"","fab":"","fixed":""},on:{"click":function($event){_vm.dialogCadastro = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Adicionar Grupo de pontos")])]),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }