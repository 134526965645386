<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Pesquisa"
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selected"
        :show-select="enableSelec"
        :single-select="enableSelec"
        :options="{ itemsPerPage: rows }"
        :headers="headers"
        :items="items"
        :search="search"
      >
        <template v-slot:item.actions="{ item }" v-show="enableActions">
          <v-icon small class="mr-2" @click="editar(item)">mdi-pencil</v-icon>
          <v-icon small @click="deletar(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:item.get="{ item }">
          <span>{{ item.get ? "S" : "N"}}</span>        
        </template>
        <template v-slot:item.getParam="{ item }">
          <span>{{ item.getParam ? "S" : "N"}}</span>
        </template>
        <template v-slot:item.getQuery="{ item }">
          <span>{{ item.getQuery ? "S" : "N"}}</span>
        </template>
        <template v-slot:item.post="{ item }">
          <span>{{ item.post ? "S" : "N"}}</span>
        </template>
        <template v-slot:item.postParam="{ item }">
          <span>{{ item.postParam ? "S" : "N"}}</span>
        </template>
        <template v-slot:item.postQuery="{ item }">
          <span>{{ item.postQuery ? "S" : "N"}}</span>
        </template>
        <template v-slot:item.put="{ item }">
          <span>{{ item.put ? "S" : "N"}}</span>
        </template>
        <template v-slot:item.putParam="{ item }">
          <span>{{ item.putParam ? "S" : "N"}}</span>
        </template>
        <template v-slot:item.putQuery="{ item }">
          <span>{{ item.putQuery ? "S" : "N"}}</span>
        </template>
        <template v-slot:item.delete="{ item }">
          <span>{{ item.delete ? "S" : "N"}}</span>
        </template>
        <template v-slot:item.deleteParam="{ item }">
          <span>{{ item.deleteParam ? "S" : "N"}}</span>
        </template>
        <template v-slot:item.deleteQuery="{ item }">
          <span>{{ item.deleteQuery ? "S" : "N"}}</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TableDialog",
  data: () => {
    return {
      search: "",
      selected: [],
    };
  },
  props: {
    items: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    enableSelec: {
      type: Boolean,
      default: false,
    },
    enableActions: {
      type: Boolean,
      default: false,
    },
    enablePicture: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 5,
    },
  },
  methods: {
    editar(item) {
      this.$emit("edit", item);
    },
    deletar(item) {
      let del = confirm("Tem Certeza?\nEsta Operção é Irreversivel");
      if (del) {
        this.$emit("delete", item);
      }
    },
  },
  watch: {
    selected() {
      this.$emit("selected", this.selected[0]);
    },
  },
};
</script>

<style>
</style>