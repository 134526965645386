<template>
  <v-tabs fixed-tabs background-color="primary" dark>
    <v-tab> Usuários </v-tab>
    <v-tab-item> <Usuarios/> </v-tab-item>
    <v-tab> Visitados </v-tab>
    <v-tab-item> <Visitados/> </v-tab-item>
    <v-tab> Visitantes </v-tab>
    <v-tab-item> <Visitantes/> </v-tab-item>
    <v-tab> Importar </v-tab>
    <v-tab-item> <Importar/> </v-tab-item>
  </v-tabs>
</template>

<script>
import Usuarios from './PageUAdm'
import Visitados from './PageVTOAdm'
import Visitantes from './PageVTEAdm'
import Importar from './Importar'
export default {
  name: "AdminForms",
  components: {
    Usuarios,
    Visitados,
    Visitantes,
    Importar
  },
  created(){
      this.$store.dispatch('loadGrupos')
  }
};
</script>

<style>
</style>