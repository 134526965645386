<template>
	<div>
		<v-dialog
			v-model="dialog"
			max-width="800"
			@click:outside="closeRonda"
			scrollable
		>
			<v-card>
				<v-card-title class="text-h5 pb-4">Detalhes da Ronda</v-card-title>
				<v-card-text class="py-0">
					<v-card outlined>
						<v-card-title class="py-2 intermed-title"
							>Ronda: {{ verificaNomeRonda(ronda.rondaId) }}</v-card-title
						>
						<v-card-text>
							<v-row class="mb-3">
								<v-col class="py-2">
									<span
										><strong>Cliente:</strong>
										{{
											ronda.clientId
												? getClienteNameById(ronda.clientId).split(" - ")[1]
												: ""
										}}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Dispositivo:</strong>
										{{ verificaNomeDispositivo(ronda.deviceId) }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Funcionário:</strong>
										{{
											ronda.userId
												? getUserNameById(ronda.userId).split(" - ")[1]
												: ""
										}}</span
									>
								</v-col>
							</v-row>
							<v-row class="mb-3">
								<v-col class="py-2">
									<span
										><strong>Data:</strong> {{ getDia(ronda.createdAt) }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Inicio:</strong>
										{{
											ronda.payload.horario.start
												? ronda.payload.horario.start
												: ""
										}}
									</span>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Fim: </strong>
										{{
											ronda.payload.horario.end ? ronda.payload.horario.end : ""
										}}
									</span>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Finalizada: </strong>
										{{ ronda.finished ? "Sim" : "Não" }}
									</span>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-card-title class="py-2 mb-2 intermed-title"
								>Pontos de Ronda Registrados</v-card-title
							>
							<v-row v-show="ronda.payload.checkpoints.length == 0">
								<v-col cols="12" class="py-3 text-center">
									<span>Nenhum ponto registrado</span>
								</v-col>
							</v-row>
							<v-row>
								<v-col
									cols="12"
									md="6"
									lg="4"
									v-for="(item, index) in ronda.payload.checkpoints"
									:key="index"
								>
									<v-card outlined>
										<v-card-title class="py-2">
											{{ item.name }}
										</v-card-title>
										<v-card-text>
											<v-row>
												<v-col cols="12">
													<span
														><strong>Registrado em:</strong>
														{{
															item.checkedAt ? formataData(item.checkedAt) : ""
														}}</span
													>
												</v-col>
												<v-col cols="12">
													<span
														><strong>Intervalo entre pontos:</strong>
														{{
															item.checkedAt ? calculaIntervalos(index) : ""
														}}</span
													>
												</v-col>
												<v-col cols="12">
													<span
														><strong>Imagem:</strong>
														<v-img
															max-height="150"
															max-width="250"
															:src="item.picture"
															@click="openImg(item.picture)"
														/>
													</span>
												</v-col>
											</v-row>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
							<v-divider></v-divider>
						</v-card-text>
					</v-card>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="gerarRelatorioSolo(ronda)">
						Exportar Relatório
					</v-btn>
					<v-btn color="success" text @click="closeRonda">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Modal Imagem Grande -->
		<v-dialog v-model="dialogImg" max-width="800px" scrollable>
			<v-card>
				<v-card-title>
					<v-icon class="mr-1">mdi-image</v-icon>
					Imagem: {{ ronda.name }}
				</v-card-title>

				<v-card-text>
					<v-img :src="image" contain height="500"/>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="downloadImg(image)">
						<v-icon class="mr-1">mdi-download</v-icon> Baixar
					</v-btn>
					<v-btn color="success" text @click="dialogImg = false">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import PDF from "@/Utils/PDF";
import File from "@/Utils/File";
import { mapGetters } from "vuex";
import { differenceDate } from "@/Utils/DateDiff";

export default {
	name: "DialogRonda",
	components: {},
	computed: {
		...mapGetters(["getUserNameById", "getClienteNameById", "getRondas"]),
	},
	data: () => ({
		dialog: false,
		imgs: [],
		img: "",
		dialogImg: false,
		dispositivos: [],
		ronda: {
			payload: {
				checkpoints: [],
				horario: {
					start: "",
					end: "",
				},
			},
		},
		ultimoHorario: "",
		image: "",
	}),
	methods: {
		verificaNomeRonda(id) {
			let ronda = this.getRondas.find((ronda) => ronda.id === id);
			if (ronda) {
				return ronda.name;
			}
			return "Ronda não encontrada";
		},
		verificaNomeDispositivo(id) {
			let dispositivo = this.dispositivos.find(
				(dispositivo) => dispositivo.id === id
			);
			if (dispositivo) {
				return dispositivo.name;
			}
			return "Dispositivo não encontrado";
		},
		openRonda(ronda) {
			this.ronda = { ...ronda };
			if (ronda.payload.checkpoints.length > 0) {
				this.ultimoHorario = ronda.payload.checkpoints[0].checkedAt;
			}
			this.dialog = true;
		},
		closeRonda() {
			this.dialog = false;
		},
		openImg(img) {
			this.image = img;
			this.dialogImg = true;
		},
		calculaIntervalos(idx) {
			let horario = this.ronda.payload.checkpoints[idx].checkedAt;
			if (idx === 0) {
				return `0m`;
			} else {
				let dif = differenceDate(
					this.ronda.payload.checkpoints[idx - 1].checkedAt,
					horario, true
				);
				return dif;
			}
		},
		downloadImg(img) {
			let imag = new Image();
			imag.onload = function () {
				let canvas = document.createElement("canvas");
				let context = canvas.getContext("2d");
				canvas.width = this.width;
				canvas.height = this.height;
				context.drawImage(this, 0, 0);
				let imageData = canvas.toDataURL();
				let blob = File.dataURItoBlob(imageData);
				File.downloadFile(blob, "ImageName.png");
			};
			imag.crossOrigin = "anonymous";
			imag.src = img;
		},
		formataData(data) {
			if (data) {
				let dataFormatada = new Date(data);
				return dataFormatada.toLocaleString();
			}
		},
		getDia(data) {
			if (data) {
				let dataFormatada = new Date(data);
				return dataFormatada.toLocaleDateString();
			}
		},
		gerarRelatorioSolo(item) {
			this.$store.dispatch("setTitleDialog", "Gerando o Relátorio");
			this.$store.dispatch("setShowDialog", true);
			let ronda = { ...item };
			let itens = [];
			ronda.name = this.verificaNomeRonda(ronda.rondaId);
			ronda.clientId = this.getClienteNameById(ronda.clientId).split(" - ")[1];
			ronda.deviceId = this.verificaNomeDispositivo(ronda.deviceId);
			ronda.userId = this.getUserNameById(ronda.userId).split(" - ")[1];
			ronda.createdAt = this.getDia(ronda.createdAt);
			for (let i = 0; i < ronda.payload.checkpoints.length; i++) {
				ronda.payload.checkpoints[i].interval = this.calculaIntervalos(i);
			}
			itens.push(ronda);
			PDF["rondaSolo"](itens, "Relatório de Ronda", "relatorio_de_ronda");
		},
	},
	created() {
		this.$store.dispatch("loadRondas", this.$store.getters.getCliente.id);
		this.$store.dispatch(
			"loadClientes",
			this.$store.getters.getCliente.groupId
		);
		// this.$http(
		// 	`dispositivo?groupId=${this.$store.getters.getCliente.groupId}`
		// ).then((resp) => {
		// 	this.dispositivos = resp.data.filter((item) => item.enabled);
		// });
	},
};
</script>

<style>
</style>