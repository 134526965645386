<template >
  <v-card class="cinza h-100vh d-flex">
    <div class="flex-grow-0">
      <v-toolbar flat color="primary" dark dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="getLoadingEvents"
      ></v-progress-linear>
    </div>
    <div class="flex-grow-1 overflow-hidden cinza">
      <v-virtual-scroll :items="events" :item-height="100" bench="2" class="colToScroll pb-1">
        <template v-slot="{ item }">
          <v-list-item v-if="item" :key="item.id" class="px-3 pt-1">
            <EVTCARD :event="item" :btn="btn" @eventSelected="$emit('eventSelected', $event)"/>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import EVTCARD from "./EventoCard";
export default {
  name: "colunasEvento",
   data: () => ({
   }),
  components: {
    EVTCARD,
  },
  props: {
    title: {
      type: String,
    },
    events: {
      type: Array,
    },
    btn: {
      type: Boolean,
    },
  },
  computed:{
    ...mapGetters(["getLoadingEvents"])
  },
  methods:{
  },
};
</script>

<style scoped>
.cinza {
  background-color: #c2c0c0 !important;
  color: rgb(73, 73, 73) !important;
}
.overflow {
  height: 100%;
  overflow-y: auto;
}
</style>