import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeCliente from '../components/Cliente/Home'
import Login from '../views/Login'
import VisitadosCliente from '../components/Cliente/Visitados'
import VisitantesClientes from '../components/Cliente/Visitantes'
import ChangePassword2 from '../components/Usuarios/MyUser'
import ChangePasswordAdm from '../components/Usuarios/ChangePasswordAdm'
import CadUser from '../components/Usuarios/CadUser'
import CadEfe from '../components/Usuarios/CadEfe'
import Clientes from '../components/Clientes/Page'
import ConfigCliente from '../components/Clientes/ConfigCliente'
import AvisoEventos from '../components/Clientes/AvisoEventos'
import ClienteRelatorios from '../components/Clientes/Relatorios'
import ClienteCadastros from '../components/Clientes/Cadastros'
import ClienteFacilities from '../components/Clientes/Facilities'
import ClienteRondas from '../components/Clientes/Rondas'
import ClienteJornadas from '../components/Clientes/Jornadas'
import AdminPage from '../components/Admin/AdminPage'
import AdminForms from '../components/Admin/forms/AdminForms'
import SupervisorForms from '../components/Admin/forms/SupervisorForms'
import RelatoriosVisitas from '../components/Visitas/RelatorioVisitas'
import RelatoriosVeiculos from '../components/Veiculo/RelatorioVeiculos'
import RelatoriosFrotas from '../components/Frota/RelatorioFleetSimple.vue'
import RelatoriosFrotasMulti from '../components/Frota/RelatorioFleetMulti.vue'
import Dispositivos from '../components/Dispositivos/Dispositivos'
import DispositivosWB from '../components/Dispositivos/DispositivosWB'
import Documentos from '../components/Documentos/Documentos'
import Monitoramento from '../views/Monitoramento'
import Logout from '../views/Logout'
import OnlyApp from '../views/OnlyApp'
import Log from '../components/Log/Log'
import Agenda from '../components/Agenda/Agenda'
import DashBoard from '../views/DashBoard'
import Cron from '../views/Cron'
import Request from '../components/Admin/Request'
Vue.use(VueRouter)

const routes = [{
            path: '/',
            name: 'Home',
            component: Home,
            meta: { requiresAuth: true }
        },
        {
            path: '/dashboard',
            name: 'DashBoard',
            component: DashBoard,
            meta: { requiresAuth: true }
        },
        {
            path: '/efetivo/home',
            name: 'EfetivoHome',
            props: true,
            component: () =>
                import ( /* webpackChunkName: "EfetivoHome" */ '@/components/Efetivo/Home/Efetivo.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/efetivo/visitados',
            name: 'EfetivoVisitados',
            props: true,
            component: () =>
                import ( /* webpackChunkName: "EfetivoVisitados" */ '@/components/Visitados/Visitados.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/efetivo/visitantes',
            name: 'EfetivoVisitantes',
            props: true,
            component: () =>
                import ( /* webpackChunkName: "EfetivoVisitantes" */ '@/components/Visitantes/Visitantes.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/efetivo/motoristas',
            name: 'EfetivoMotoristas',
            props: true,
            component: () =>
                import ( /* webpackChunkName: "EfetivoMotoristas" */ '@/components/Efetivo/Motorista/motorista.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/efetivo/veiculosFrota',
            name: 'EfetivoVeiculosFrota',
            props: true,
            component: () =>
                import ( /* webpackChunkName: "EfetivoVeiculosFrota" */ '@/components/Efetivo/VeiculosFrota/vei_frota.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/efetivo/relatorioVisitas',
            name: 'EfetivoRelatorioVisitas',
            component: RelatoriosVisitas,
            meta: { requiresAuth: true }
        },
        {
            path: '/efetivo/relatorioVeiculos',
            name: 'EfetivoRelatorioVeiculos',
            component: RelatoriosVeiculos,
            meta: { requiresAuth: true }
        },
        {
            path: '/efetivo/relatorioFrotas',
            name: 'EfetivoRelatorioFrotas',
            props: true,
            component: RelatoriosFrotas,
            meta: { requiresAuth: true }
        },
        {
            path: '/efetivo/relatorioFrotasMulti',
            name: 'EfetivoRelatorioFrotasMulti',
            props: true,
            component: RelatoriosFrotasMulti,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/home',
            name: 'HomeCliente',
            component: HomeCliente,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/visitados',
            name: 'VisitadosCliente',
            component: VisitadosCliente,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/visitantes',
            name: 'VisitantesClientes',
            component: VisitantesClientes,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/relatorioVisitas',
            name: 'ClienteRelatorioVisitas',
            component: RelatoriosVisitas,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/relatorioVisitantes',
            name: 'ClienteRelatorioVisitantes',
            component: RelatoriosVisitas,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/relatorioVeiculos',
            name: 'ClienteRelatorioVeiculos',
            component: RelatoriosVeiculos,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/relatoriosFrotas',
            name: 'ClienteRelatorioFrotas',
            props: true,
            component: RelatoriosFrotasMulti,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/relatorioFrotasMulti',
            name: 'ClienteRelatorioFrotasMulti',
            props: true,
            component: RelatoriosFrotasMulti,
            meta: { requiresAuth: true }
        },
        {
            path: '/clientes/avisoeventos',
            name: 'ClienteAvisoEventos',
            props: true,
            component: AvisoEventos,
            meta: { requiresAuth: true }
        },
        {
            path: '/home',
            name: 'AdminUnidadeHome',
            component: () =>
                import ('@/components/Admin/HomeAdminUnidade'),
            meta: { requiresAuth: true }
        },
        {
            path: '/relatoriogerencial',
            name: 'RelatorioGerencial',
            component: () =>
                import ( /* webpackChunkName: "relGerencial" */ '@/components/Relatorios/RelatorioGerencial'),
            meta: { requiresAuth: true }
        },
        {
            path: '/cadUser',
            name: 'cadUser',
            component: CadUser,
            props: true,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/request',
            name: 'request',
            component: Request,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/cadEfe',
            name: 'cadEfe',
            component: CadEfe,
            props: true,
            meta: {
                requiresAuth: true,
                configuration: {
                    efetivo: {
                        userType: Number,
                    },
                }
            }
        },
        {
            path: '/clientes',
            name: 'Clientes',
            component: Clientes,
            meta: {
                requiresAuth: true,
                configuration: {
                    cliente: {
                        userType: Number,
                    },
                }
            }
        },
        {
            path: '/clientes/cadastros',
            name: 'ClienteCadastros',
            component: ClienteCadastros,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/clientes/rondas',
            name: 'ClienteRondas',
            component: ClienteRondas,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/clientes/facilities',
            name: 'ClienteFacilities',
            component: ClienteFacilities,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/clientes/jornadas',
            name: 'ClienteJornadas',
            component: ClienteJornadas,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/clientes/relatorios',
            name: 'ClienteRelatorios',
            component: ClienteRelatorios,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/clientes/app',
            name: 'ClienteApp',
            component: () =>
            import ( /* webpackChunkName: "ClienteApp" */ '@/components/Clientes/App.vue'),
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/configCliente',
            name: 'configCliente',
            component: ConfigCliente,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/agenda',
            name: 'Agenda',
            component: Agenda,
            meta: { requiresAuth: true }
        },
        {
            path: '/documentos',
            name: 'Documentos',
            component: Documentos,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/monitoramento',
            name: 'Monitoramento',
            component: Monitoramento,
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/adm',
            name: 'Admin',
            component: AdminPage,
            meta: { requiresAuth: true }
        },
        {
            path: '/admForms',
            name: 'AdminForms',
            component: AdminForms,
            meta: { requiresAuth: true }
        },
        {
            path: '/supervisorForms',
            name: 'SupervisorForms',
            component: SupervisorForms,
            meta: { requiresAuth: true }
        },
        {
            path: '/dispositivos',
            name: 'Dispositivos',
            component: Dispositivos,
            meta: { requiresAuth: true }
        },
        {
            path: '/dispositivosWB',
            name: 'DispositivosWB',
            component: DispositivosWB,
            meta: { requiresAuth: true }
        },
        {
            path: '/log',
            name: 'Log',
            component: Log,
            meta: { requiresAuth: true }
        },
        {
            path: '/myUser',
            name: 'MyUser',
            component: ChangePassword2,
            meta: { requiresAuth: true }
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
            meta: { requiresAuth: true }
        },
        {
            path: '/somenteApp',
            name: 'Somente APP',
            component: OnlyApp,
            meta: { requiresAuth: true }
        },
        {
            path: '/changePass',
            name: 'changePass',
            props: true,
            component: ChangePasswordAdm,
            meta: { requiresAuth: true }
        },
        {
            path: '/cron',
            name: 'Cron',
            props: true,
            component: Cron,
            meta: { requiresAuth: true }
        },
        {
            path: '/importar',
            name: 'Importar',
            props: true,
            component: () =>
                import ( /* webpackChunkName: "melhorias" */ '@/components/Admin/forms/Importar.vue'),
            meta: { requiresAuth: true }
        },
        {
            path: '/teste',
            name: 'Teste',
            component: () =>
                import ( /* webpackChunkName: "teste" */ '@/views/Teste.vue'),
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/eventType',
            name: 'EventType',
            component: () =>
                import ( /* webpackChunkName: "Event" */ '@/components/EventType/Event.vue'),
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/rel-ocorrencia',
            name: 'Rel-Ocorrencia',
            component: () =>
                import ( /* webpackChunkName: "RelatoriOcorrencias" */ '@/components/Ocorrencias/RelatoriOcorrencias.vue'),
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/rel-ronda',
            name: 'Rel-Ronda',
            component: () =>
                import ( /* webpackChunkName: "RelatorioRondas" */ '@/components/Rondas/RelatorioRondas.vue'),
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/rel-tarefas',
            name: 'Rel-Tarefas',
            component: () =>
                import ( /* webpackChunkName: "RelatorioTarefas" */ '@/components/Tarefas/RelatorioTarefas.vue'),
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/rel-eventos',
            name: 'Rel-Eventos',
            component: () =>
                import ( /* webpackChunkName: "RelatorioEventos" */ '@/components/ReportEventos/RelatorioEventos.vue'),
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/rel-jornada',
            name: 'Rel-Jornada',
            component: () =>
                import ( /* webpackChunkName: "RelatorioJornadas" */ '@/components/Postos/RelatorioJornadas.vue'),
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/rel-ronda-virtual',
            name: 'Rel-Ronda-Virtual',
            component: () =>
                import ( /* webpackChunkName: "RelatorioRondasVirtuais" */ '@/components/RondasVirtuais/RelatorioRondasVirtuais.vue'),
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/cliente/rel-documentos',
            name: 'Rel-Documentos',
            component: () =>
                import ( /* webpackChunkName: "RelDocument" */ '@/components/ReportDocs/RelDocument.vue'),
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '/relatorioeventos',
            name: 'Relatorio-Eventos',
            component: () =>
                import ( /* webpackChunkName: "RelatorioEventosTodos" */ '@/components/ReportEventos/RelatorioEventosTodos.vue'),
            props: true,
            meta: { requiresAuth: true }
        },
        {
            path: '*',
            redirect: '/'
        },
        {
            path: '/melhorias',
            name: 'Melhorias',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
                import ( /* webpackChunkName: "melhorias" */ '@/components/Melhorias/Melhorias.vue')
        },
    ]
    // router.replace({ path: '*', redirect: '/' })
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    let logged = store.getters.getLogged
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // user && user.admin ? next() : next({ path: '/' })
        logged ? next() : next({ path: '/login' })
    } else {
        next()
    }

})

export default router