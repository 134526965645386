<template>
  <v-select
    :items="getNiveis"
    label="Niveis"
    v-model="valor"
    required
    :outlined="outlined"
    :disabled="disabled"
  ></v-select>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NiveisPicker",
  props:['value', 'outlined', 'disabled'],
  computed: {
    ...mapGetters(["getNiveis"]),
    nivel: {
      get() {
        return this.$store.state.niveis.nivel;
      },
      set(value) {
        this.$store.dispatch("setNivel", value);
        this.$emit('nivel', value)
        this.$emit('input', value)
      },
    },
    valor:{
      get(){
        return this.value
      },
      set(evento){
        this.$emit('input', evento)
      },
    }
  },
  created() {
    if (!this.getNiveis.length) {
      this.$http("nvacesso").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name}`;
          element.value = element.id;
        });
        this.$store.commit("setNiveis", resp.data);
      });
    }
  },
};
</script>

<style>
</style>