<template>
  <div>
    <v-alert
      v-if="Object.keys(attributes).length"
      :color="selectedEvent.eventColor"
      colored-border
      border="left"
      prominent
      text
      class="alert-details py-3 mb-5"
    >
      <div class="grey--text text--darken-4">
        <template v-if="selectedEvent.type == 'ronda_fail'">
          Ronda: <strong>{{ attributes.rondaName }}</strong><br />
          Intervalo: <strong>{{ attributes.payload.horario.start }} - {{ attributes.payload.horario.end }}</strong><br />
          Posto: <strong>{{ attributes.postoName || 'Posto desconhecido' }}</strong><br>
          <span v-if="posto.sign">Senha: <strong>{{ posto.sign }}</strong> - Contrasenha: <strong>{{ posto.countersign }}</strong></span>
        </template>

        <template v-else-if="selectedEvent.type == 'tarefa_fail'">
          Tarefa: <strong>{{ attributes.tarefaName }}</strong><br />
          Intervalo: <strong>{{ attributes.payload.horario.start }} - {{ attributes.payload.horario.end }}</strong><br />
          Posto: <strong>{{ attributes.postoName || 'Posto desconhecido' }}</strong><br>
          <span v-if="posto.sign">Senha: <strong>{{ posto.sign }}</strong> - Contrasenha: <strong>{{ posto.countersign }}</strong></span>
        </template>

        <template v-else-if="['ocorrencia1','ocorrencia2', 'ocorrencia3', 'ocorrencia4'].includes(selectedEvent.type)">
          <div class="d-flex align-center">
            <div>
              Prioridade: <v-icon :color="occurPriority.color" class="mr-1 mb-1">mdi-alert-circle</v-icon><strong>{{ occurPriority.text }}</strong><br />
              Ocorrência: <strong>{{ attributes.name }}</strong><br />
              Descrição: <strong>{{ attributes.description }}</strong><br />
              Data do fato: <strong>{{ new Date(attributes.factDate).toLocaleString() }}</strong><br />
              Comunicante: <strong>{{ attributes.communicant }}</strong><br />
              Posto: <strong>{{ attributes.postoName }}</strong><br>
              <span v-if="posto.sign">Senha: <strong>{{ posto.sign }}</strong> - Contrasenha: <strong>{{ posto.countersign }}</strong></span>
            </div>
            <v-spacer></v-spacer>
            <v-btn color="warning" @click="viewOccurrence(attributes)">Ver ocorrência</v-btn>
          </div>
          <DialogOcorrencia ref="dialogOcorrencia" />
        </template>

        <template v-else-if="['turno_covered','turno_uncovered','turno_start','turno_finished'].includes(selectedEvent.type)">
          Turno: <strong>{{ attributes.turnoName }}</strong><br />
          Horário: <strong>{{ attributes.payload.horario.start }} - {{ attributes.payload.horario.end }}</strong><br />
          Posto: <strong>{{ attributes.payload.postoName }}</strong><br>
          <span v-if="posto.sign">Senha: <strong>{{ posto.sign }}</strong> - Contrasenha: <strong>{{ posto.countersign }}</strong></span>
        </template>

        <template v-else-if="selectedEvent.type == 'checklist'">
          <div class="d-flex align-center">
            <div>
              Ronda Virtual: <strong>{{ attributes.name }}</strong><br />
              Horário: <strong>{{ attributes.payload.horario.start }}</strong>
            </div>
            <v-spacer></v-spacer>
            <v-btn color="warning" @click="execChecklist(attributes)">Executar Ronda Virtual</v-btn>
          </div>
          <ExecRondaVirtual ref="execRondaVirtual" @concluiRonda="autoCloseEvent" />
        </template>

        <template v-else-if="['turno_interval_start', 'turno_interval_start_before', 'turno_interval_start_after'].includes(selectedEvent.type)">
          Turno: <strong>{{ attributes.turnoName }}</strong><br />
          Início realizado: <strong>{{ new Date(attributes.intervalStart).toLocaleString() }}</strong><br />
          Horário do intervalo cadastrado: <strong>{{ attributes.payload.horario.startInterval }} - {{ attributes.payload.horario.endInterval }}</strong><br />
          Posto: <strong>{{ attributes.payload.postoName }}</strong>
        </template>
        
        <template v-else-if="['turno_interval_end', 'turno_interval_end_before', 'turno_interval_end_after'].includes(selectedEvent.type)">
          Turno: <strong>{{ attributes.turnoName }}</strong><br />
          Intervalo realizado: <strong>{{ new Date(attributes.intervalStart).toLocaleString() }} - {{ new Date(attributes.intervalEnd).toLocaleString() }}</strong><br />
          Horário do intervalo cadastrado: <strong>{{ attributes.payload.horario.startInterval }} - {{ attributes.payload.horario.endInterval }}</strong><br />
          Posto: <strong>{{ attributes.payload.postoName }}</strong><br>
          <span v-if="posto.sign">Senha: <strong>{{ posto.sign }}</strong> - Contrasenha: <strong>{{ posto.countersign }}</strong></span>
        </template>
        
        <template v-else-if="['charging_device', 'offline_device', 'low_battery'].includes(selectedEvent.type)">
          <div >
            Dispositivo: <strong>{{ device.name }}</strong><br />
            Bateria: <strong>{{ attributes.status ? attributes.status.batteryLevel : '' }}%</strong><br />
            Última sincronização: <strong>{{ new Date(attributes.status.date).toLocaleString() || ''}}</strong><br />
            <span v-if="posto.sign">Senha: <strong>{{ posto.sign }}</strong> - Contrasenha: <strong>{{ posto.countersign }}</strong></span>
          </div>
        </template>

        <template v-else-if="selectedEvent.type == 'panico'">
          Posto: <strong>{{ attributes.name }}</strong><br />
          Senha: <strong>{{ attributes.sign ? attributes.sign.toUpperCase() : '-' }}</strong><br />
          Contra-senha: <strong>{{ attributes.countersign ? attributes.countersign.toUpperCase() : '-' }}</strong>
        </template>

      </div>
    </v-alert>
  </div>
</template>

<script>
import DialogOcorrencia from "@/components/Ocorrencias/DialogOcorrencia.vue";
import ExecRondaVirtual from "@/components/Clientes/components/ExecRondaVirtual.vue";
export default {
  name: "EventDetails",
  components: {
    DialogOcorrencia,
    ExecRondaVirtual,
  },
  props: {
    selectedEvent: {
      type: Object,
    },
    device: {
      type: Object,
    },
  },
  data: () => ({
    attributes: {},
    posto: {},
  }),
  computed: {
    occurPriority() {
      switch (this.attributes.priority) {
        case 1:
          return { text: "Alta", color: "red" };
        case 2:
          return { text: "Média", color: "orange" };
        case 3:
          return { text: "Baixa", color: "green" };
        default:
          return { text: "Somente Registro", color: "blue" };
      }
    },
  },
  methods: {
    viewOccurrence(item) {
      this.$refs.dialogOcorrencia.openOcorrencia(item);
    },
    execChecklist(item) {
			this.$refs.execRondaVirtual.open(item);
		},
    autoCloseEvent() {
      let data = { id: this.selectedEvent.id, treatment: "Fechado pelo sistema" };
      this.$store.dispatch("treatEvent", data);
    },
    loadAttributes() {
      this.attributes = {};
      this.posto = {};
      if (this.selectedEvent.id) {
        let urlAttr = null;
        let urlPosto = null;
        switch (this.selectedEvent.type) {
          case "ronda_fail":
            urlAttr = `ronda/run/${this.selectedEvent.idType}`;
            urlPosto = `posto/device/${this.selectedEvent.deviceId}`;
            break;
          case "tarefa_fail":
            urlAttr = `tarefa/run/${this.selectedEvent.idType}`;
            urlPosto = `posto/device/${this.selectedEvent.deviceId}`;
            break;
          case "ocorrencia1":
          case "ocorrencia2":
          case "ocorrencia3":
          case "ocorrencia4":
            urlAttr = `ocorrencia/${this.selectedEvent.idType}`;
            urlPosto = `posto/device/${this.selectedEvent.deviceId}`;
            break;
          case "turno_covered":
          case "turno_uncovered":
          case "turno_started":
          case "turno_finished":
          case "turno_interval_start":
          case "turno_interval_start_before":
          case "turno_interval_start_after":
          case "turno_interval_end_before":
          case "turno_interval_end_after":
            urlAttr = `jornada/run/${this.selectedEvent.idType}`;
            break;
          case "checklist":
            urlAttr = `ronda-virtual/run/${this.selectedEvent.idType}`;
            this.$emit("disableTreatment");
            break;
          case "panico":
            urlAttr = `posto/device/${this.selectedEvent.deviceId}`;
            break;
          case "charging_device":
          case "offline_device":
          case "low_battery":
            urlAttr = `dispositivo/status/${this.device.identificador}`;
            urlPosto = `posto/device/${this.device.id}`;
            break;
        }

        this.$emit("setLoader", true);

        if (urlAttr) {
          this.$http(urlAttr).then((resp) => {
            this.attributes = resp.data;
            this.$emit("setLoader", false);
          });
        } else {
          this.$emit("setLoader", false);
        }

        if(urlPosto) {
          this.$http(urlPosto).then((resp) => {
            this.posto = resp.data;
          });
        }
      }
    }
  },
  created() {
    this.loadAttributes();
  },
  watch: {
    selectedEvent() {
      this.loadAttributes();
    },
    attributes() {
      if(this.attributes.postoId) {
        this.$http(`posto/${this.attributes.postoId}`).then((resp) => {
          this.posto = resp.data;
        });
      }
    },
  },
};
</script>

<style scoped>
.alert-details {
  line-height: 1.65em;
}
.alert-details::before {
  opacity: 0.06;
}
</style>