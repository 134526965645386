<template>
  <v-card class="mx-auto text-center" color="green" dark>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select
            :items="tipo"
            label="Gráfico de:"
            v-model="tipoSel"
            required
            @change="loadGraphData"
          ></v-select>
        </v-col>
        <v-col class="d-flex align-end">
          <v-slider
            v-model="slidder.val"
            :label="slidder.label"
            :thumb-color="slidder.color"
            thumb-label="always"
            min="2"
            max="60"
            @change="loadGraphData"
          ></v-slider>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-sheet color="rgba(0, 0, 0, .12)">
        <v-sparkline
          auto-draw
          :value="data"
          :labels="labels"
          color="rgb(0, 95, 54)"
          height="100"
          padding="8"
          stroke-linecap="round"          
          smooth="1"
          label-size="3"
          :auto-line-width="true"
        >
          <template v-slot:label="item">
            {{ item.value }}
          </template>
        </v-sparkline>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SparkLine",
  data: () => {
    return {
      data: [],
      labels: [],
      title: "Titulo",
      tipo: [
        { value: 0, text: "Visitas (Pessoas)" },
        { value: 1, text: "Visitas (Veiculos)" },
        { value: 2, text: "Visitantes" },
        { value: 3, text: "Visitados" },
        { value: 4, text: "Clientes" },
        { value: 5, text: "Usuários" },
        { value: 6, text: "Agenda" },
      ],
      tipoSel: 0,
      slidder: { label: "Dias", val: 7, color: "blue" },
    };
  },
  props: {
    value: {
      type: Array,
    },
  },
  methods: {
    loadGraphData() {
      this.$http
        .get(`dashboard/days?days=${this.slidder.val}&type=${this.tipoSel}`)
        .then((resp) => {
          if (resp.status != 200) {
            return;
          }
          this.data = [];
          this.labels = [];
          resp.data.forEach((element) => {
            // this.labels.push(Object.keys(element)[0])
            this.labels.push(
              Object.values(element)[0]
                ? `${Object.keys(element)[0]}(${Object.values(element)[0]})`
                : "-"
            );
            this.data.push(Object.values(element)[0]);
          });
        });
    },
  },
  created() {
    this.loadGraphData();
  },
};
</script>

<style>
</style>