<template>
  <div>
    <v-card>
      <v-card-title> Relatório de Jornadas de Trabalho </v-card-title>
      <v-card-text>
        <!-- linha do filtro -->
        <v-card outlined>
          <v-card-text class="pb-0">
            <p class="text-subtitle-1 pt-1 pb-3">Filtros</p>
            <v-form ref="formReport">
              <!-- linha dos input -->
              <v-row>
                <v-col class="py-0">
                  <v-text-field v-model="relatorio.start" label="Data Inicial" required outlined type="datetime-local"
                    @input="$refs.relatorioEnd.validate()"></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field ref="relatorioEnd" v-model="relatorio.end" label="Data Final" required outlined
                    type="datetime-local"
                    :rules="[v => validaDataFinal(v) || 'A data final deve ser maior que a inicial']"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-4">
            <MenuExportReport :disabled="!getReportJornadas.length" :items="reportJornadas" module="jornadas"
              title="Relatório de Jornadas de Trabalho" filename="relatorio_de_jornadas" />
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="loadReport">
              <v-icon class="mr-1">mdi-magnify</v-icon> Buscar Relatório
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
      <!-- linha da tabela -->
      <v-card flat>
        <v-card-text>
          <DataTableGeneric :headers="headers" :items="reportJornadas">
            <template v-slot:item.picturesStart="{ item }">
              <v-avatar size="70" class="my-2">
                <v-img :src="item.picturesStart ? item.picturesStart[0] : '/img/nopic.jpg'"
                  lazy-src="/img/nopic.jpg"></v-img>
              </v-avatar>
            </template>
            <template v-slot:item.picturesEnd="{ item }">
              <v-avatar size="70" class="my-2">
                <v-img :src="item.picturesEnd ? item.picturesEnd[0] : '/img/nopic.jpg'"
                  lazy-src="/img/nopic.jpg"></v-img>
              </v-avatar>
            </template>
          </DataTableGeneric>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuExportReport from "@/components/Relatorios/MenuExportReport.vue";
import DataTableGeneric from "../DataTableGeneric";
export default {
  name: "RelatorioJornadas",
  components: {
    MenuExportReport,
    DataTableGeneric,
  },
  computed: {
    ...mapGetters(["getReportJornadas", "getUsers", "getDispositivos", "getUsuario"]),
    reportJornadas() {
      return this.getReportJornadas.map(item => {
        return {
          ...item,
          postoName: item.payload.postoName,
          schedule: `${item.payload.horario.start} - ${item.payload.horario.end}`,
          intervalTurno: item.turnoStartInterval ? `${item.turnoStartInterval} - ${item.turnoEndInterval}` : "-",
          interval: item.intervalStart ? `${new Date(item.intervalStart).toLocaleString().split(', ')[1]} - ${new Date(item.intervalEnd).toLocaleString().split(', ')[1]}` : '-',
          createdAt: new Date(item.createdAt).toLocaleString(),
          updatedAt: new Date(item.updatedAt).toLocaleString(),
        };
      })
    },
  },
  data() {
    return {
      headers: [
        { text: "Turno", value: "turnoName" },
        { text: "Imagem Inicial", value: "picturesStart" },
        { text: "Início", value: "createdAt" },
        { text: "Fim", value: "updatedAt" },
        { text: "Intervalo", value: "interval", align: "center" },
        { text: "Imagem Final", value: "picturesEnd" },
        { text: "Horário da jornada", value: "schedule" },
        { text: "Intervalo da jornada", value: "intervalTurno" },
        { text: "Tolerância (min)", value: "turnoTolerance", show: false },
        { text: "Posto", value: "postoName" },
        { text: "Funcionário", value: "userName" },
        { text: "Dispositivo", value: "deviceName", show: false },
      ],
      relatorio: {},
    };
  },
  methods: {
    async loadReport() {
      // se possui cliente selecionado usa ele senão pega o primeiro da lista
      if (this.$store.getters.getCliente.id) {
        if (this.$refs.formReport.validate()) {
          this.relatorio.clientId = this.$store.getters.getCliente.id;
          this.$store.dispatch("loadReportJornadas", this.relatorio);
        }
      } else {
        try {
          let cli = await this.$http.get('/cliente');
          if (cli.data.length > 0 && this.$refs.formReport.validate()) {
            this.relatorio.clientId = cli.data[0].id;
            this.$store.dispatch("loadReportJornadas", this.relatorio);
          }
        } catch (error) {
          console.log(error);
        }
      }
      // if (this.$refs.formReport.validate()){
      //   this.relatorio.clientId = this.$store.getters.getCliente.id;
      //   this.$store.dispatch("loadReportJornadas", this.relatorio);
      // }
    },
    validaDataFinal(dataFinal) { // função auxiliar pois este teste direto no rules não funciona corretamente
      return new Date(dataFinal) > new Date(this.relatorio.start);
    },
  },
  created() {
    this.$store.dispatch("setReportJornadas", []);
    // pega a data atual e seta no relatorio
    let now = new Date();
    let onlyDate = now.toISOString().substring(0, 11);
    this.relatorio.start = onlyDate + "00:00";
    this.relatorio.end = onlyDate + "23:59";
  },
};
</script>

<style></style>