<template>
  <v-tabs fixed-tabs background-color="primary" dark>
    <v-tab> Usuários </v-tab>
    <v-tab-item> <Usuarios/> </v-tab-item>
    <v-tab> Visitados </v-tab>
    <v-tab-item> <Visitados/> </v-tab-item>
    <v-tab> Visitantes </v-tab>
    <v-tab-item> <Visitantes/> </v-tab-item>
    <v-tab> Grupos </v-tab>
    <v-tab-item> <Grupos/> </v-tab-item>
    <v-tab> Clientes </v-tab>
    <v-tab-item> <Cliente/> </v-tab-item>
    <v-tab> Tipos </v-tab>
    <v-tab-item> <Tipos/> </v-tab-item>
    <v-tab> Postos </v-tab>
    <v-tab-item> <Posto/> </v-tab-item>
    <v-tab> Emails </v-tab>
    <v-tab-item> <Email/> </v-tab-item>
    <v-tab> App </v-tab>
    <v-tab-item> <App/> </v-tab-item>
    <v-tab> Importar </v-tab>
    <v-tab-item> <Importar/> </v-tab-item>
    <v-tab> Eventos </v-tab>
    <v-tab-item> <Eventos/> </v-tab-item>
  </v-tabs>
</template>

<script>
import Usuarios from './PageUAdm'
import Visitados from './PageVTOAdm'
import Visitantes from './PageVTEAdm'
import Grupos from './PageGPAdm'
import Tipos from './PageCLITypeAdm.vue'
import Cliente from './PageCLIAdm'
import Posto from './PagePSTAdm'
import App from './App'
import Importar from './Importar'
import Eventos from './PageEVTAdm.vue'
export default {
  name: "AdminForms",
  components: {
    Usuarios,
    Visitados,
    Visitantes,
    Grupos,
    Cliente,
    Posto,
    App,
    Email: () => import('./PageEmaildm.vue'),
    Tipos,
    Importar,
    Eventos
  },
  created(){
      this.$store.dispatch('loadGrupos')
  }
};
</script>

<style>
</style>