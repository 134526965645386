<template v-if="imagens.length">
  <div class="py-2">
    <v-carousel
      v-if="multiple && imagens.length > 1"
      hide-delimiters
      :width="width"
      :height="width"
      class="my-2 rounded"
    >
      <v-carousel-item v-for="(img, index) in imagens" :key="index">
        <v-avatar :size="width">
          <v-img v-if="img.hasOwnProperty('path')" :src="img.path" lazy-src="/img/nopic.jpg"></v-img>
          <v-img v-else :src="img" lazy-src="/img/nopic.jpg"></v-img>
        </v-avatar>
      </v-carousel-item>
    </v-carousel>
    <v-badge
      v-else-if="showBadge" 
      color="grey"
      overlap
      :content="`${imagens.length}`"
      class="badgeImgs"
    >
      <v-avatar :size="width">
        <v-img :src="imagens[0]" lazy-src="/img/nopic.jpg"></v-img>
      </v-avatar>
    </v-badge>
    <v-avatar
      v-else
      :size="width"
    >
      <v-img :src="imagens[0]" lazy-src="/img/nopic.jpg"></v-img>
    </v-avatar>
</div>
</template>

<script>
export default {
  name: "CardImgList",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    width: {
      type: Number,
      default: 140,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    showBadge: {
      type: Boolean,
      default: true,
    },
  },
  data: () => {
    return {
      imagens: [],
    };
  },
  created() {
    if (this.items)
      this.imagens = this.items;
  },

};
</script>

<style>
.badgeImgs span {
  bottom: 0 !important;
}
</style>