<template>
  <v-dialog v-model="dialogAvisos" max-width="500px" persistent>
    <!-- Avisos de ronda concluída -->
    <v-card>
      <v-card-title>
        Avisos de ronda concluída
        <v-spacer></v-spacer>
        <v-btn icon @click="dialogAvisos = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
        <v-card-text class="pb-1">
          <v-form ref="formAviso">
            <v-text-field label="Adicionar Email" outlined dense class="d-flex align-start mb-0" v-model="tempEmail"
              :rules="[verificaEmailDuplicado, rules.email]">
              <template v-slot:append-outer>
                <div class="d-flex">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="mt-0 pointer" color="success"
                        @click="addEmail">mdi-plus</v-icon>
                    </template>
                    <span>Adicionar Email</span>
                  </v-tooltip>
                </div>
              </template>
            </v-text-field>
            <v-card outlined>
              <v-simple-table dense height="350px" fixed-header>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Email</th>
                      <th class="text-right">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(email, index) in emailsAviso" :key="index">
                      <td>{{ email }}</td>
                      <td class="text-right">
                        <v-btn icon small color="red" @click="delEmail(index)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="dialogAvisos = false">Cancelar</v-btn>
          <v-btn color="success" @click="saveAvisos">Salvar</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'CadAvisoRondas',
  data() {
    return {
      dialogAvisos: false,
      avisoRonda: {},
      rondaId: null,
      emailsAviso: [],
      tempEmail: "",
      clienteId: null,
      rules: {
        email: (value) => {
          const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if(value == '') {
            return true;
          }
          return pattern.test(value) || "E-mail inválido";
        },
      },
      ruleEmail: "",
    }
  },
  methods: {
    addEmail() {
      if (!this.tempEmail) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showErrorSnack", "Adicione um e-mail");
        return;
      }
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!pattern.test(this.tempEmail)) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showErrorSnack", "E-mail inválido");
        return;
      }
      if (this.emailsAviso.indexOf(this.tempEmail) < 0) {
        this.emailsAviso.push(this.tempEmail);
        this.tempEmail = "";
        this.$refs.formAviso.resetValidation();
      }
    },
    verificaEmailDuplicado() {
      if (!this.emailsAviso) {
        return true;
      }
      return (
        this.emailsAviso.indexOf(this.tempEmail) < 0 || "E-mail já adicionado"
      );
    },
    openDialogAvisos(avisos, rondaId, clienteId) {    
      this.avisoRonda = {...avisos};
      if(avisos && avisos.emails) {
        this.emailsAviso = [...avisos.emails];
      } else {
        this.emailsAviso = [];
      }
      this.rondaId = rondaId;
      this.clienteId = clienteId;      
      this.dialogAvisos = true;
    },
    saveAvisos() {
      this.avisoRonda.emails = this.emailsAviso;
      this.avisoRonda.rondaId = this.rondaId;
      this.avisoRonda.clienteId = this.clienteId;
      this.$store.dispatch("saveAvisosRonda", this.avisoRonda);
      this.dialogAvisos = false;
    },
    delEmail(index) {
      this.emailsAviso.splice(index, 1);
    },
  },
  computed: {

  },
  watch: {
    tempEmail(val) {
      if (val && val.length > 0) {
        this.ruleEmail = this.rules.email(val);
      } else {
        this.ruleEmail = true;
        this.$refs.formAviso.resetValidation();
      }
    },
  },
  mounted() {

  },
  created() {

  },
  unmounted() {

  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>