import axios from 'axios'
import { queryStringBuilder } from '../../Utils/queryStringBuilder'
export default {
    state: {
        visitas: [],
        relVisitas: [],
    },
    mutations: {
        setVisitas(state, payload) {
            state.visitas = payload
        },
        setRelVisitas(state, payload) {
            state.relVisitas = payload
        },
        addVisitas(state, payload) {
            state.visitas.push(payload)
        },
        delVisitas(state, payload) {
            state.visitas.splice(payload, 1)
        },
    },
    actions: {
        setVisitas(context, payload) {
            context.commit('setVisitas', payload)
        },
        setRelVisitas(context, payload) {
            context.commit('setRelVisitas', payload)
        },
        loadVisitas(context) {
            axios(`visita/in`).then((resp) => {
                resp.data.forEach((element) => {
                    element.createdAt = new Date(element.createdAt).toLocaleString();
                    element.groupName = context.getters.getGroupNameById(element.groupId)
                });
                context.commit("setVisitas", resp.data);
            });
        },
        loadReportVisit(context, payload) {
            let url = `relatorios/visita?`;
            // gerador de query para relatorio
            url = queryStringBuilder(url, payload);

            axios(url).then((resp) => {
                context.commit("setRelVisitas", resp.data);
                if (resp.data.length == 0) {
                    context.dispatch("resetSnack");
                    context.dispatch("showErrorSnack", "Nenhum registro encontrado de acordo com o filtro");
                }
            });
        },
        addVisitas(context, payload) {
            axios(`visita/${payload.id}`).then((resp) => {
                resp.data.createdAt = new Date(resp.data.createdAt).toLocaleString();
                context.commit('addVisitas', resp.data)
            })
        },
        delVisitas(context, payload) {
            let index = context.state.visitas.findIndex((ele) => {
                return ele.id == payload
            })
            context.commit('delVisitas', index)
        },
        registrarEntrada(context, payload) {
            axios.post(`/visita`, payload).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch('loadVisitas');
                    if (typeof payload.callback == 'function') {
                        payload.callback()
                    }
                }
            });
        },
        registrarSaida(context, payload) {
            axios.put(`/visita/exit/${payload.id}`).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch('loadVisitas');
                    if (typeof payload.callback == 'function') {
                        payload.callback()
                    }
                }
            });
        },
    },
    getters: {
        getVisitas(state) {
            return state.visitas
        },
        getRelVisitas(state) {
            return state.relVisitas
        },
        getVisitasLen(state) {
            return state.visitas.length
        },
        getVisita: (state) => (id) => {
            if (!state.visitas) {
                return id
            }
            let vst = state.visitas.find(g =>
                g.id == id
            )
            return vst ? `${vst.id} - ${vst.name}` : ''
        },
    },
}