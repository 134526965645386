<template>
  <v-card class="h-100vh">
    <v-toolbar flat color="primary" dark dense class="flex-grow-0">
      <v-toolbar-title>
        {{ getSelectedEvent.id ? `Evento em Tratamento #${getSelectedEvent.id}` : "Selecione um Evento" }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="getSelectedEvent.finishedUserId && getSelectedEvent.finishedUserId != getUsuario.id"
        color="warning"
        @click="assume(getSelectedEvent)"
        >Assumir Evento
      </v-btn>
      <v-btn
        v-if="getSelectedEvent.id && !getSelectedEvent.finishedUserId"
        color="success"
        @click="assume(getSelectedEvent)"
        >Atender Evento
      </v-btn>
      <v-spacer></v-spacer>
      <v-icon color="warning" v-if="getIconAct">mdi-rewind</v-icon>
      <v-spacer></v-spacer>
      <span>Status da Conexão:</span>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar
            v-bind="attrs"
            v-on="on"
            class="ml-2"
            :class="socketStatus.class"
            size="36"
            :color="socketStatus.color"
          >
            <v-icon dense>{{ socketStatus.icon }}</v-icon>
          </v-avatar>
        </template>
        <span>{{ socketStatus.text }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>
    <v-card
      v-if="getSelectedEvent.id"
      class="flex-grow-1 rounded-0 overflow-y-auto"
      :disabled="enableAttend()"
      flat
    >
      <v-card-text class="pt-0 px-5">
        <div class="d-flex mb-1">
          <div>
            <v-card-title class="pl-0">{{ getSelectedEvent.eventName || getSelectedEvent.type }}</v-card-title>
            <v-card-subtitle class="pl-0">Evento gerado em: {{ new Date(getSelectedEvent.createdAt).toLocaleString() }}</v-card-subtitle>
          </div>
          <v-spacer></v-spacer>
          <div v-if="getSelectedEvent.createdUserId" class="pt-5 pb-4 pl-5">
            <v-avatar size="50" class="mr-2">
              <img :src="getEventUser.picture" lazy-src="/img/nopic.jpg" />
            </v-avatar>
            <span class="text-body-2">{{ getEventUser.name }}</span>
          </div>
        </div>
        <v-expansion-panels accordion class="mb-6">
          <ClientCard :cliente="getEventClient" />
        </v-expansion-panels>

        <Detalhes :selectedEvent="getSelectedEvent" :device="device" @disableTreatment="treatmentDisabled = true" @setLoader="loading = $event" />

        <div class="d-flex">
          <v-tabs v-model="tabPosition">
            <v-tab><v-icon left>mdi-pencil</v-icon>Tratar</v-tab>
            <v-tab><v-icon left>mdi-message</v-icon>Notas</v-tab>
            <v-tab><v-icon left>mdi-history</v-icon>Historico</v-tab>
          </v-tabs>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                :value="!!getSelectedEvent.deviceId"
                dot
                :color="isDeviceOnline ? 'success' : 'error'"
                offset-x="30"
                offset-y="14"
              >
                <v-btn
                  v-if="getSelectedEvent.deviceId"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  class="mx-4 pr-6 two-lines"
                  @click="startChat"
                  ><v-icon class="mr-2">mdi-cellphone-message</v-icon>Chat com o<br />dispositivo
                </v-btn>
              </v-badge>
            </template>
            <span>Dispositivo {{ isDeviceOnline ? 'online' : 'offline' }}</span>
          </v-tooltip>
        </div>
        <v-divider></v-divider>
        <v-tabs-items v-model="tabPosition">
          <v-tab-item>
            <Tratamento :disabled="treatmentDisabled"/>
          </v-tab-item>
          <v-tab-item>
            <Observacoes :clientId="getSelectedEvent.clientId" />
          </v-tab-item>
          <v-tab-item>
            <Historico :clientId="getSelectedEvent.clientId" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-card v-else class="d-flex flex-grow-1 align-center justify-center" flat>
      <v-alert
        icon="mdi-exclamation-thick"
        class="d-inline-block"
        type="warning"
        text
        prominent
        >Nenhum evento selecionado
      </v-alert>
    </v-card>
  </v-card>
</template>

<script>
import Tratamento from "./Atendimento/Tratamento";
import Historico from "./Atendimento/Historico";
import Observacoes from "./Atendimento/Observacoes.vue";
import ClientCard from "./Atendimento/ClientCard.vue";
import Detalhes from "./Atendimento/Detalhes.vue";
import { mapGetters } from "vuex";
export default {
  name: "Atendimento",
  components: {
    Tratamento,
    Historico,
    Observacoes,
    ClientCard,
    Detalhes,
  },
  data: () => ({
    loading: false,
    attributes: null,
    tabPosition: 0,
    socket: null,
    deviceToChat: null,
    treatmentDisabled: false,
    device: null,
  }),
  computed: {
    ...mapGetters([
      "getSelectedEvent",
      "getUsuario",
      "getUsers",
      "getClientes",
      "getSocketStatus",
      "getIconAct",
      "getDispositivos",
      "getOnline",
    ]),
    getEventClient() {
      return this.getClientes.find((c) => c.id == this.getSelectedEvent.clientId);
    },
    getEventUser() {
      let user = this.getUsers.find((u) => u.id == this.getSelectedEvent.createdUserId);
      if (!user)
        user = { name: "Usuário Desconhecido", picture: "/img/nopic.jpg" };
      return user;
    },
    socketStatus() {
      if (this.getSocketStatus) {
        return {
          color: "success",
          icon: "mdi-server-network",
          text: "Conectado",
          class: "",
        };
      }
      return {
        color: "error",
        icon: "mdi-server-network-off",
        text: "Desconectado",
        class: "blink",
      };
    },
    isDeviceOnline() {
      let online = false;
      if (this.getSelectedEvent.deviceId)
        online = this.getOnline.find((item) => item.user.deviceId == this.deviceToChat);
      return online;
    },
  },
  methods: {
    assume(item) {
      this.$store.dispatch("assumeEvent", item);
    },
    enableAttend() {
      return this.getSelectedEvent.finishedUserId != this.getUsuario.id;
    },
    reset() {
      this.tabPosition = 0;
      this.treatmentDisabled = false;      
      this.deviceToChat = null;

      if (this.getSelectedEvent.deviceId){
        let device = this.getDispositivos.find( d => d.id == this.getSelectedEvent.deviceId );
        if (device)
          this.deviceToChat = device.identificador;
          this.device = device;
      }
    },
    startChat() {
      this.$emit("startChat", { deviceId: this.deviceToChat });
    },
  },
  created() {
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("loadDispositivos");
    this.reset();
  },
  watch: {
    getSelectedEvent() {
      this.reset();
    },
  },
};
</script>

<style scoped>
.two-lines {
  font-size: 0.6rem;
  text-align: center;
}
.blink {
  animation: blinker 1s step-start infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>