<template>
  <div class="cliTarefas">
    <v-card-title>Cadastros de Tarefas</v-card-title>
    <v-card-text>
      <DataTable
        :items="tarefas"
        :headers="headers"
      >
        <template slot="item.ambientId" slot-scope="{ item }">
          {{ getAmbienteNameById(item.ambientId) }}
        </template>

        <template slot="item.daysOfWeek" slot-scope="{ item }">
          {{ daysToString(item.daysOfWeek) }}
        </template>
        
        <template slot="item.activities" slot-scope="{ item }">
          <span class="mr-1">{{ item.activities.length }} Atividades</span>
          <v-menu
            open-on-hover
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >mdi-eye</v-icon>
            </template>
            <v-list dense class="dropdown-list superdense">
              <v-list-item v-for="(item, index) in item.activities" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>  
        </template>
        
        <template slot="item.deviceId" slot-scope="{ item }">
          {{ getDeviceNameById(item.deviceId) }}
        </template>

        <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="editar(item)"
                color="warning"
                class="mr-1"
              >mdi-pencil</v-icon>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
          <!-- botão excluir -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="excluir(item)"
                color="red"
                class="mr-1"
              >mdi-trash-can</v-icon>
            </template>
            <span>Excluir : {{ item.name }}</span>
          </v-tooltip>
        </template>

      </DataTable>
    </v-card-text>

<!-- dialog de cadastro -->
    <v-dialog v-model="dialogCadastro" persistent max-width="800" scrollable>
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2">{{ tarefa.id ? "Editar" : "Cadastrar" }} Tarefa<v-spacer></v-spacer>
          <v-btn icon @click="closeCadastro">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-form ref="formTarefa">
            <v-row>
              <v-col class="pb-0">
                <v-text-field
                  v-model="tarefa.name"
                  label="Nome da Tarefa"
                  required
                  :rules="requiredField"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="pb-0">
                <v-autocomplete
                  v-model="tarefa.deviceId"
                  :items="dispositivos"
                  hide-selected
                  label="Dispositivo"
                  outlined
                  item-text="name"
                  item-value="id"
                  :rules="requiredField"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <v-autocomplete
                  v-model="tarefa.ambientId"
                  :items="ambientes"
                  hide-selected
                  label="Ambiente"
                  outlined
                  item-text="name"
                  item-value="id"
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }"
                  :rules="requiredField"
                  ref="tarefaAmbiente"
                >
                  <template v-slot:prepend-item>
                    <v-list-item link @click="$refs.cadAmbientes.dialog = true;">
                      <v-list-item-title class="font-weight-medium">Cadastrar Ambiente</v-list-item-title>
                      <v-list-item-icon class="my-2"><v-icon large color="amber lighten-1">mdi-plus</v-icon></v-list-item-icon>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col class="pt-0" cols="3">
                <v-text-field
                  v-model="tarefa.start"
                  label="Hora inicial"
                  type="time"
                  required
                  :rules="requiredField"
                  @input="$refs.tarefaEnd.validate()"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="pt-0" cols="3">
                <v-text-field
                  v-model="tarefa.end"
                  ref="tarefaEnd"
                  label="Hora Final"
                  type="time"
                  required
                  :rules="[requiredField[0], v => validaHoraFinal(tarefa.start, v) || 'A hora final deve ser maior que a inicial']"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- select dias da semana -->
            <v-select
              v-model="tarefa.daysOfWeek"
              :items="daysOfWeek"
              label="Dias da semana"
              multiple
              chips
              deletable-chips
              small-chips
              outlined
              :rules="[v => v.length > 0 || 'Selecione pelo menos um dia']"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="selAllSelectSemana"
                >
                  <v-list-item-action>
                    <v-icon>{{ iconSelectSemana }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Todos os dias</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-form>
<!-- atividades -->
          <v-card class="mb-4" outlined>
            <v-card-subtitle>Atividades</v-card-subtitle>
            <v-card-text class="pb-0">
              <v-form ref="formActivities">
                <div class="d-flex">
                  <v-text-field
                    v-model="tmpActivity.name"
                    label="Nome da Atividade"
                    required
                    :rules="activityNameRules"
                    outlined
                    class="mr-4"
                    ref="activityName"
                    @keyup.enter="addActivity"
                    @focus="activityNameRules = []"
                    @blur="activityNameRules = defaultActivityNameRules"
                  ></v-text-field>
                  <v-text-field
                    v-model="tmpActivity.description"
                    label="Descrição da Atividade (opcional)"
                    outlined
                    class="mr-4"
                    @keyup.enter="addActivity"
                  ></v-text-field>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="success"
                        dark
                        fab
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                        @click="addActivity"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Adicionar esta atividade</span>
                  </v-tooltip>
                </div>
              </v-form>
<!-- tabela de atividades -->
              <div v-if="tarefa.activities.length">
                <v-data-table
                  class="fixed-header-table draggable mt--1 mb-4"
                  hide-default-footer
                  hide-default-header
                  id="table-activities"
                >
                  <template v-slot:header>
                    <thead>
                      <tr>
                        <th class="px-0" width="24"><!-- vazio --></th>
                        <th class="text-center" width="38">Ordem</th>
                        <th>Nome</th>
                        <th width="250">Descrição</th>
                        <th class="text-center" width="70">Ações</th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:body>
                    <draggable :list="tarefa.activities" tag="tbody">
                      <tr v-for="(item, index) in tarefa.activities" :key="index">
                        <td class="px-0" width="24"><v-icon>mdi-drag-vertical</v-icon></td>
                        <td class="text-center">{{ index + 1 }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td class="text-center">
                          <!-- botão excluir -->
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteActivity(index)"
                                color="red"
                              >mdi-trash-can</v-icon>
                            </template>
                            <span>Excluir : {{ item.name }}</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </draggable>
                  </template>
                </v-data-table>
                <p class="text-caption">Arraste os itens da tabela caso deseje alterar a ordem das atividades.</p>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="closeCadastro">Cancelar</v-btn>
          <v-btn
            color="success"
            @click="save()"
          > Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="amber darken-1" dark absolute bottom right fab fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialogCadastro = true"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Tarefa</span>
    </v-tooltip>
    <CadAmbientes ref="cadAmbientes" @onSave="tarefa.ambientId = $event.id ? $event.id : ''" />
    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import DataTable from "../../DataTableGeneric";
import CadAmbientes from "./CadAmbientes.vue";
import ConfirmDialog from '@/components/ConfirmDialog';
import draggable from 'vuedraggable';
import { mapGetters } from "vuex";

export default {
  name: "CliTarefas",
  components: {
    DataTable,
    CadAmbientes,
    ConfirmDialog,
    draggable,
  },
  props: ["tarefas", "ambientes"],
  data: () => {
    return {
      dialogCadastro: false,
      dialogAmbiente: false,
      requiredField,
      tarefa: {},
      tmpActivity: {},
      activityNameRules: [],
      defaultActivityNameRules: [],
      timer: 0,
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome da Tarefa", value: "name" },
        { text: "Ambiente", value: "ambientId" },
        { text: "Horário Inicial", value: "start" },
        { text: "Horário Final", value: "end" },
        { text: "Dispositivo", value: "deviceId" },
        { text: "Dias da Semana", value: "daysOfWeek", sortable: false },
        { text: "Atividades", value: "activities", sortable: false },
        { text: "Ações", value: "actions", align: "center", sortable: false, filterable: false, width: 88 },
      ],
      daysOfWeek: [
        { text: "domingo", value: 0, short: "dom" },
        { text: "segunda-feira", value: 1, short: "seg" },
        { text: "terça-feira", value: 2, short: "ter" },
        { text: "quarta-feira", value: 3, short: "qua" },
        { text: "quinta-feira", value: 4, short: "qui" },
        { text: "sexta-feira", value: 5, short: "sex" },
        { text: "sábado", value: 6, short: "sab" }
      ],
    };
  },
  computed: {
    ...mapGetters(["getAmbienteNameById", "getDispositivos"]),
    iconSelectSemana() {
      if (this.tarefa.daysOfWeek.length == this.daysOfWeek.length) return 'mdi-close-box';
      else if (this.tarefa.daysOfWeek.length > 0) return 'mdi-minus-box';
      else return 'mdi-checkbox-blank-outline';
    },
    dispositivos() {
      return this.getDispositivos.filter((item) => item.enabled);
    },
  },
  methods: {
    reset() {
      this.tarefa = {
        id: null,
        name: "",
        ambientId: null,
        start: "",
        end: "",
        activities: [],
        daysOfWeek: [],
        clientId: null,
        deviceId: null,
      };
      this.tmpActivity = {
        name: "",
        description: ""
      };

    },
    save() {
      this.activityNameRules = this.defaultActivityNameRules;
      this.$nextTick(() => {
        let valForm1 = this.$refs.formTarefa.validate();
        let valForm2 = this.$refs.formActivities.validate();
        if (valForm1 && valForm2) {
          this.tarefa.clientId = this.$store.getters.getCliente.id;
          this.tarefa.groupId = this.$store.getters.getCliente.groupId;
          this.$store.dispatch("saveTarefa", this.tarefa);
          this.closeCadastro();
        }
      });
    },
    editar(item) {
      this.tarefa = {...item};
      this.dialogCadastro = true;
    },
    excluir(item) {
      this.$refs.confirmDialog.open({
        title: 'Excluir Tarefa',
        text: `Deseja excluir a tarefa <strong>${item.name}</strong>?`,
        confirmCallback: () => {
          this.$store.dispatch("deleteTarefa", item);
        }
      });
    },
    closeCadastro() {
      this.dialogCadastro = false;
      this.$refs.formTarefa.resetValidation();
      this.$refs.formActivities.resetValidation();
      this.reset();
    },
    addActivity() {
      clearTimeout(this.timer);

      this.activityNameRules = this.requiredField;
      this.$nextTick(() => {
        
        if (this.$refs.formActivities.validate()){
          this.tarefa.activities.push(this.tmpActivity);
          this.tmpActivity = {name: "", description: ""};
          this.activityNameRules = [];
          this.scrollTableActivities();
        } else {
          let self = this;
          this.timer = setTimeout(() => {
            self.activityNameRules = [];
          }, 2500);
        }
      });
    },
    deleteActivity(index) {
      if (this.tarefa.id){ // se a tarefa já foi salva, abre o dialog de confirmação
        this.$refs.confirmDialog.open({
          title: "Excluir Atividade",
          text: `Deseja excluir a atividade <strong>${this.tarefa.activities[index].name}</strong>?`,
          confirmCallback: () => {
            this.tarefa.activities.splice(index, 1);
          },
        });
      } else {
        this.tarefa.activities.splice(index, 1);
      }
    },
    getDeviceNameById(id) {
      let device = this.dispositivos.find(item => item.id == id);
      return device ? device.name : "";
    },
    selAllSelectSemana() {
      if (this.tarefa.daysOfWeek.length == this.daysOfWeek.length)
        this.tarefa.daysOfWeek = [];
      else
        this.tarefa.daysOfWeek = this.daysOfWeek.map(dia => dia.value)
    },
    validaHoraFinal(horaInicial, horaFinal) { // função auxiliar pois este teste direto no rules não funciona corretamente
      if (!horaInicial || !horaFinal) return;
      const minutes = (h, m) => (h*60+m);
      const secInicial = minutes(...horaInicial.split(":"));
      const secFinal = minutes(...horaFinal.split(":"));
      return new Date(secFinal) > new Date(secInicial);
    },
    daysToString(days) {
      let daysShort = this.daysOfWeek.filter(dia => days.includes(dia.value)).map(dia => dia.short);
      return daysShort.toString().replaceAll(",", ", ");
    },
    scrollTableActivities() {
      setTimeout(() => {
        document.querySelector("#table-activities .v-data-table__wrapper").scroll({
          top: 10000,
          behavior: "smooth"
        });
      }, 1);
    },
  },
  created() {
    this.reset();
    this.defaultActivityNameRules = [() => {return this.tarefa.activities.length > 0 || 'Adicione pelo menos uma atividade'}];
    this.$store.dispatch("loadDispositivos", this.$store.getters.getCliente.groupId );
  },
};
</script>

<style>
#table-activities .v-data-table__wrapper {
  max-height: 178px;
}
</style>