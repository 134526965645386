import axios from 'axios'
import { queryStringBuilder } from '../../Utils/queryStringBuilder'
export default {
  state: {
    turnos: [],
    reportJornadas: [],
    turnosExec: [],
  },
  mutations: {
    setTurnos(state, payload) {
      state.turnos = payload
    },
    setReportJornadas(state, payload) {
      state.reportJornadas = payload
    },
    setTurnosExec(state, payload) {
      state.turnosExec = payload
    },
  },
  actions: {
    setTurnos(context, payload) {
      context.commit('setTurnos', payload)
    },
    setTurnosExec(context, payload) {
      context.commit('setTurnosExec', payload)
    },
    loadTurnos(context, payload) {
      let gp = payload ? `?clientId=${payload}` : ''
      axios(`/turno${gp}`).then((resp) => {
        context.dispatch('setTurnos', resp.data)
      });
    },
    loadExecTurnos(context, payload) {
      let gp = payload ? `?groupId=${payload}` : ''
      axios(`/jornada/run/executed${gp}`).then((resp) => {
        context.dispatch('setTurnosExec', resp.data)
      });
    },
    saveTurno(context, payload) {
      const metodo = payload.id ? "put" : "post";
      const finalUrl = payload.id ? `/${payload.id}` : "";
      axios[metodo](`/turno${finalUrl}`, payload).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Turno salvo com Sucesso");
          context.dispatch("loadTurnos", payload.clientId);
          context.dispatch("loadPostos", payload.clientId);
          if (typeof payload.callback == 'function')
            payload.callback(resp.data);
        }
      });
    },
    forceFinishTurno(context, payload) {
      axios.put(`/jornada/run/executed/${payload.id}`, payload).then((resp) => {
        if (resp.status == 200) {
          context.dispatch("resetSnack");
          context.dispatch("showSuccessSnack", "Turno finalizado com Sucesso");
          context.dispatch("loadExecTurnos", payload.groupId);
        }
      });
    },
    deleteTurno(context, payload) {
      axios.delete(`/turno/${payload.id}`).then(() => {
        context.dispatch("resetSnack");
        context.dispatch("showSuccessSnack", "Turno deletado com Sucesso");
        context.dispatch("loadTurnos", payload.clientId);
      });
    },
    setReportJornadas(context, payload) {
      context.commit('setReportJornadas', payload)
    },
    loadReportJornadas(context, payload) {
      let url = `/jornada/run/report?`;
      // gerador de query para relatorio
      url = queryStringBuilder(url, payload);
      axios(url).then((resp) => {
        context.dispatch("setReportJornadas", resp.data);
        if (resp.data.length == 0) {
          context.dispatch("resetSnack");
          context.dispatch("showErrorSnack", "Nenhum registro encontrado de acordo com o filtro");
        }
      });
    },
  },
  getters: {
    getTurnos(state) {
      return state.turnos
    },
    getTurnosExec(state) {
      return state.turnosExec
    },
    getReportJornadas(state) {
      return state.reportJornadas
    },
    getTurnoNameById: (state) => (id) => {
      if (!state.turnos) {
        return id
      }
      let gp = state.turnos.find(g =>
        g.id == id
      )
      if (!gp) return id
      return `${gp.id} - ${gp.name}` || id
    },
  },
}