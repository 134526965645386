<template>
  <div class="recursos">
    <div class="form">
      <v-card class="mx-auto py-2" elevation="5" outlined>
        <v-card-title> Cadastro de Niveis </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-checkbox
                v-model="nivel.enabled"
                label="Ativo"
                hint="Nivel ativo"
                persistent-hint
              ></v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                v-model="nivel.onlyApp"
                label="Somente APP "
                hint="(Header 'deviceId')"
                persistent-hint
              ></v-checkbox>
            </v-col>
            
            <v-col>
              <v-text-field
                v-model="nivel.name"
                :counter="50"
                label="Nome do Nivel"
                hint="Digite algum nome para este nivel"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-text-field
                v-model="nivel.orderLvl"
                label="Ordem de Nivel"
                hint="Ordem que sera seguido pelas permissões"
                persistent-hint
                required
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-autocomplete
                :items="routes"
                v-model="nivel.route"
                label="Rota Padrão deste nivel (WEB)"
                hint="Selecione a rota"
                persistent-hint
              >
              </v-autocomplete>
              
            </v-col>
            <v-col cols="5">
              <v-autocomplete
                :items="routesApp"
                v-model="nivel.routeApp"
                label="Rota Padrão deste nivel (APP)"
                hint="Selecione a rota"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="nivel.description"
                rows="1"
                label="Descrição"
                hint="Digite uma descrição do Nivel"
                :counter="255"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="warning" class="mr-2" @click="clear">
            Cancelar
          </v-btn>
          <v-btn depressed color="success" @click="save(nivel)">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="my-1">
        <v-card-text>
          <Table
            :headers="header"
            :items="niveis"
            @edit="edit($event)"
            :rows="15"
            @delete="delet($event.id)"
          />
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import Table from "../TableGeneric";
export default {
  name: "Recursos",
  components: {
    Table,
  },
  data: () => {
    return {
      text: "",
      snackbar: false,
      nivel: {
        id: null,
        enabled: true,
        onlyApp: false,
        orderLvl: 1
      },
      niveis: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Ordem da Permissão", value: "orderLvl" },
        { text: "Nome", value: "name" },
        { text: "Descrição", value: "description" },
        { text: "Somente APP", value: "soApp" },
        { text: "Rota Padrão (WEB)", value: "route" },
        { text: "Rota Padrão (APP)", value: "routeApp" },
        { text: "Ativo", value: "ativo" },
        { text: "Ações", value: "actions", width: "85px" },
      ],
      routes: [],
      routesApp:[]
    };
  },
  methods: {
    get() {
      this.$http("nvacesso").then((resp) => {
        resp.data.forEach((element) => {
          element.ativo = element.enabled ? "Sim" : "Não";
          element.soApp = element.onlyApp ? "Sim" : "Não";
        });
        this.niveis = resp.data;
      });
    },
    save(nivel) {
      const metodo = nivel.id ? "put" : "post";
      const finalUrl = nivel.id ? `/${nivel.id}` : "";
      this.$http[metodo](`/nvacesso${finalUrl}`, nivel).then(() => {
        this.get();
        this.clear();
        (this.snackbar = true), (this.text = "Salvo com Sucesso");
      });
    },
    edit(rec) {
      this.nivel = rec;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
    },
    delet(id) {
      this.$http.delete(`/nvacesso/${id}`).then(() => {
        this.get();
        (this.snackbar = true), (this.text = "Deletado com Sucesso");
      });
    },
    getRoutesApp() {
      this.$http("approutes").then((resp) => {
        resp.data.forEach((element) => {
          element.text = `${element.id} - ${element.name} - (${element.description})`;
          element.value = element.path;
        });
        this.routesApp = resp.data;
      });
    },
    clear() {
      this.nivel = {
        id: null,
        enabled: true,
      };
    },
  },
  mounted() {
    this.get();
    this.getRoutesApp();
    let routes = this.$router.options.routes;
    this.routes = routes.map((ele) => {
      return { text: `${ele.name} - (${ele.path})`, value: ele.path };
    });
  },
};
</script>

<style>
</style>