var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cliTurno"},[_c('v-card-title',[_vm._v("Cadastros de Turnos de Trabalho")]),_c('v-card-text',[_c('DataTable',{attrs:{"items":_vm.turnos,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('TextTooltip',{attrs:{"text":item.name,"maxWidth":'100'}})]}},{key:"item.daysOfWeek",fn:function(ref){
var item = ref.item;
return [_c('TextTooltip',{attrs:{"text":_vm.daysToString(item.daysOfWeek),"maxWidth":'180'}})]}},{key:"item.interval",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.mostraHora(item.startInterval, item.endInterval)))])]}},{key:"item.horario",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.mostraHora(item.start, item.end)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('TextTooltip',{attrs:{"text":item.description,"maxWidth":'200'}})]}},{key:"item.tolerance",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.tolerance ? item.tolerance + " min" : "Sem tolerância"))])]}},{key:"item.postoId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPostoNameById(item.postoId))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('TooltipIcon',{attrs:{"color":"warning","text":("Editar: " + (item.name)),"icon":"mdi-pencil","left":"true"},on:{"click":function($event){return _vm.editTurno(Object.assign({}, item))}}}),_c('TooltipIcon',{attrs:{"color":"red","text":("Excluir: " + (item.name)),"icon":"mdi-trash-can","left":"true"},on:{"click":function($event){return _vm.delTurno(item)}}})]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.dialogCadastro),callback:function ($$v) {_vm.dialogCadastro=$$v},expression:"dialogCadastro"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 pr-4 mb-2"},[_vm._v(_vm._s(_vm.turno.id ? "Editar" : "Cadastrar")+" Turno de Trabalho"),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeCadastro}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pt-1 pb-0"},[_c('v-form',{ref:"formTurno"},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('v-text-field',{attrs:{"label":"Nome do Turno","required":"","rules":_vm.requiredField,"outlined":""},model:{value:(_vm.turno.name),callback:function ($$v) {_vm.$set(_vm.turno, "name", $$v)},expression:"turno.name"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-select',{attrs:{"items":_vm.daysOfWeek,"menu-props":{ offsetY: true },"label":"Dias da semana","multiple":"","chips":"","deletable-chips":"","small-chips":"","outlined":"","rules":[
									function (v) { return v.length > 0 || 'Selecione pelo menos um dia'; } ]},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.selAllSelectSemana}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(_vm.iconSelectSemana))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Todos os dias")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.turno.daysOfWeek),callback:function ($$v) {_vm.$set(_vm.turno, "daysOfWeek", $$v)},expression:"turno.daysOfWeek"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-text-field',{attrs:{"label":"Hora inicial","type":"time","required":"","rules":_vm.requiredField,"outlined":""},on:{"input":function($event){return _vm.$refs.turnoEnd.validate()}},model:{value:(_vm.turno.start),callback:function ($$v) {_vm.$set(_vm.turno, "start", $$v)},expression:"turno.start"}})],1),_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-text-field',{ref:"turnoEnd",attrs:{"label":"Hora Final","type":"time","required":"","rules":_vm.requiredField,"outlined":""},model:{value:(_vm.turno.end),callback:function ($$v) {_vm.$set(_vm.turno, "end", $$v)},expression:"turno.end"}})],1),_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-text-field',{attrs:{"label":"Inicio do Intervalo","type":"time","outlined":"","clearable":"","hint":"Deixe em branco se não houver intervalo","rules":[
									function (v) { return _vm.testaPreenchimento(v, _vm.turno.endInterval) ||
										'Preencha o início do intervalo'; } ]},on:{"input":function($event){return _vm.$refs.intervaloEnd.validate()}},model:{value:(_vm.turno.startInterval),callback:function ($$v) {_vm.$set(_vm.turno, "startInterval", $$v)},expression:"turno.startInterval"}})],1),_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-text-field',{ref:"intervaloEnd",attrs:{"label":"Fim do Intervalo","type":"time","clearable":"","rules":[
									function (v) { return _vm.testaPreenchimento(v, _vm.turno.startInterval) ||
										'Preencha o fim do intervalo'; },
									function (v) { return _vm.validaIntervalo(
											_vm.turno.startInterval,
											v,
											_vm.turno.start,
											_vm.turno.end
										) || _vm.mensagem; } ],"outlined":""},model:{value:(_vm.turno.endInterval),callback:function ($$v) {_vm.$set(_vm.turno, "endInterval", $$v)},expression:"turno.endInterval"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"sm-9"}},[_c('v-autocomplete',{attrs:{"items":_vm.opPostos,"hide-selected":"","label":"Posto de Trabalho","outlined":"","item-text":"name","item-value":"id","rules":_vm.requiredField},scopedSlots:_vm._u([{key:"item",fn:function(ref){
								var item = ref.item;
return [_c('span',{staticClass:"flex-grow-1"},[_vm._v(_vm._s(item.name))]),(!item.enabled)?_c('v-chip',{attrs:{"x-small":"","color":"warning"}},[_vm._v("Posto inativo")]):_vm._e()]}}]),model:{value:(_vm.turno.postoId),callback:function ($$v) {_vm.$set(_vm.turno, "postoId", $$v)},expression:"turno.postoId"}})],1),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"sm-3"}},[_c('v-text-field',{attrs:{"label":"Tolerância de Atraso","type":"number","min":"0","outlined":"","suffix":"min","hint":"Deixe em branco para usar a tolerância padrão"},model:{value:(_vm.turno.tolerance),callback:function ($$v) {_vm.$set(_vm.turno, "tolerance", $$v)},expression:"turno.tolerance"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('v-textarea',{attrs:{"label":"Observação","outlined":""},model:{value:(_vm.turno.description),callback:function ($$v) {_vm.$set(_vm.turno, "description", $$v)},expression:"turno.description"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeCadastro}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.salvarTurno()}}},[_vm._v(" Salvar ")])],1)],1)],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-10",attrs:{"color":"amber darken-1","dark":"","absolute":"","bottom":"","right":"","fab":"","fixed":""},on:{"click":function($event){_vm.dialogCadastro = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Adicionar Turno de Trabalho")])]),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }