<template>
  <v-dialog v-model="dialog" persistent max-width="825" scrollable>
    <v-card>
      <v-card-title class="text-h5 pr-4 mb-2">Gerar QR Codes<v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-1 pb-0">
        <v-select v-model="checkpointsToPrint" :items="checkpoints" chips deletable-chips label="QR Codes a imprimir"
          multiple item-text="id" return-object outlined>
          <template v-slot:selection="data">
            <v-chip v-bind="data.attrs" :input-value="data.selected" close :color="data.item.new ? 'success' : ''"
              @click:close="removeCheckpoint(data.item.id)">
              <strong class="mr-2">{{ data.item.id }}</strong>{{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <v-list-item-action>
                <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="d-flex">
                  <span class="flex-grow-1"><strong class="mr-3">{{ item.id }}</strong> {{ item.name }}</span>
                  <v-chip v-if="item.new" x-small color="success">Novo</v-chip>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
        <template v-if="checkpoints && checkpoints.length">
          <div class="checkpoints-container">
            <div class="qrCheckpoint" :style="`background-image: url(${background})`" v-for="(item, index) in checkpointsToPrint" :key="index">
              <p class="mb-2">{{ item.id }}</p>
              <qrcode-vue size="150" :value="item.id.toString()" :name="item.name" class="myqr"></qrcode-vue>
              <p>{{ item.name }}</p>
            </div>
          </div>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">Cancelar</v-btn>
        <v-btn color="success" @click="generatePdf('download')"><v-icon class="mr-1">mdi-file-download-outline</v-icon>Salvar PDF</v-btn>
        <v-btn color="success" @click="generatePdf('print')"><v-icon class="mr-1">mdi-printer-outline</v-icon>Imprimir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import PDF from "@/Utils/PDF";
export default {
  name: "CheckpointsDialog",
  components: {
    QrcodeVue,
  },
  data: () => {
    return {
      dialog: false,
      checkpoints: [],
      checkpointsToPrint: [],
      filename: "",
      itemsPerPage: 6,
      background: "ronda",
    };
  },
  methods: {
    open(options) {
      this.dialog = true;
      this.checkpoints = options.checkpoints;
      this.checkpointsToPrint = options.checkpoints;
      this.background = options.isFacilities ? require("@/assets/facilities.png") : require("@/assets/ronda.png");
      this.filename = options.rondaName ? this.toFilename(options.rondaName) + "_QRCodes" : "QRCodes";
    },
    toFilename(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", "_");
    },
    removeCheckpoint(id) {
      this.checkpointsToPrint = this.checkpointsToPrint.filter(item => item.id !== id);
    },
    generatePdf(method) {
      let checkPoints = [];
      let divs = document.getElementsByClassName("myqr");
      let background = new Image();
      background.src = this.background;

      for (let i = 0; i < divs.length; i++) {
        // cria o canvas
        let canvas = document.createElement("canvas");
        let cvw = 250;
        let cvh = 428;
        // define o tamanho do canvas
        canvas.width = cvw;
        canvas.height = cvh;
        // calcula a posição do qrcode, para centralizar
        let x = canvas.width / 2 - 75;
        let y = canvas.height / 2 - 65;
        let ctx = canvas.getContext("2d");
        // insere o fundo
        ctx.drawImage(background, 0, 0, cvw, cvh);
        // insere o qrcode
        ctx.drawImage(divs[i].children[0], x, y, 150, 150);
        // insere a borda pontilhada
        ctx.setLineDash([4, 4]);
        ctx.lineDashOffset = 0;
        ctx.strokeRect(0, 0, cvw, cvh);
        // insere o texto
        ctx.fillStyle = "rgba(0, 0, 0, 1)";
        ctx.font = "bold 20px Arial";
        ctx.textAlign = "center";
        ctx.fillText(
          divs[i].getAttribute("value"),
          cvw / 2,
          y - 15,
        );
        fillTextWrap(
          ctx,
          divs[i].getAttribute("name"),
          cvw / 2,
          y + 180,
          20,
          cvw - 20,
        );

        checkPoints.push(canvas.toDataURL("image/png"));
      }
      PDF.qrcode(checkPoints, {
        filename: this.filename,
        method: method,
      });

      // escreve o texto quebrando as linhas, caso necessário
      function fillTextWrap( context , text, x, y, lineHeight, fitWidth){
        fitWidth = fitWidth || 0;
        
        if (fitWidth <= 0){
          context.fillText( text, x, y );
          return;
        }
        var words = text.split(' ');
        var currentLine = 0;
        var idx = 1;
        while (words.length > 0 && idx <= words.length){
          var str = words.slice(0, idx).join(' ');
          var w = context.measureText(str).width;
          if ( w > fitWidth ){
            if (idx == 1) idx = 2;
            context.fillText( words.slice(0, idx - 1).join(' '), x, y + (lineHeight*currentLine) - 4 );
            currentLine++;
            words = words.splice(idx - 1);
            idx = 1;
          }
          else
            idx++;
        }
        if (idx > 0)
          context.fillText( words.join(' '), x, y + (lineHeight * currentLine) );
      }
      
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.checkpoints-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 5px;
}

.qrCheckpoint {
  width: 250px;
  height: 428px;
  padding: 115px 15px 0;
  box-sizing: border-box;
  border: 1px dashed #CCC;
  background-position: center;
  background-repeat: no-repeat;
}

.qrCheckpoint p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  line-height: 1.5rem;
}

.qrCheckpoint .myqr {
  width: 150px;
  margin: 0 auto;
}
</style>