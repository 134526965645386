<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="700">
    <v-card>
      <v-card-title class="text-h5 pr-4 mb-2">Finalização de Frota<v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-card outlined class="mb-3 pb-2" color="bg-gray">
          <v-card-title class="py-2">Veículo</v-card-title>
          <ItemVeiculo
            :item="item.vehicle"
          />
        </v-card>
        <v-card outlined class="mb-3 pb-2" color="bg-gray">
          <v-card-title class="py-2">Motorista</v-card-title>
          <ItemMotorista
            :item="item.driver"
          />
        </v-card>
        <!-- card com as imagens -->
        <v-card outlined class="mb-7">
        <v-card-title class="py-2">Imagens</v-card-title>
        <v-card-text class="pa-3 pt-0">
          <Webcam @file="addFile" @base64="addBase64" />
          <div class="d-flex flex-wrap">
            <div class="pa-1" style="width:25%" v-for="(img, idx) in veiculo.base64" :key="idx">
              <v-card color="orange lighten-1 transition">
                <v-btn text block @click="removeImage(idx, $event)">
                  <v-icon color="red">mdi-trash-can</v-icon>
                </v-btn>
                <v-img :src="img" width="100%"></v-img>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </v-card>
        <v-form ref="formFrota">
          <v-text-field
            v-model.number="veiculo.kmEnd"
            label="Km final"
            required
            outlined
            :rules="requiredField"
            type="number"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <!-- ações do card -->
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text @click="close"> Cancelar </v-btn>
        <v-btn color="success" @click="registrarSaida"> Registrar Saída </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import Webcam from "@/components/Webcam/WbDialog.vue";
import ItemMotorista from "@/components/Motoristas/item_motorista.vue";
import ItemVeiculo from "@/components/Veiculo/item_veiculo.vue";
export default {
  name: "DialogFrotaFinish",
  components: {
    Webcam,
    ItemMotorista,
    ItemVeiculo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      requiredField,
      item: {},
      veiculo: {
        images: [],
        base64: [],
        kmEnd: null,
        description: null,
      },
    };
  },
  methods: {
    open(item){
      this.item = item;
      this.$nextTick(() => {
        this.$refs.formFrota.resetValidation();
      });
      this.dialog = true;
    },
    close() {
      this.veiculo = {
        kmEnd: null,
        images: [],
        base64: [],
      };
      this.dialog = false;
    },
    registrarSaida() {
      if (!this.$refs.formFrota.validate()) {
        return;
      }
      this.veiculo.id = this.item.id;
      this.veiculo.callback = () => {
        this.close();
        this.$emit("closeFleetDetails"); // fecha o dialog de detalhes da frota pois a saída pode ter sido chamada por lá
      };
      // this.$store.dispatch("registrarEntradaVeiculo", this.veiculo);
      this.$store.dispatch("regFimFrota", this.veiculo);
    },
    addFile(file) {
      this.veiculo.images.push(file);
    },
    addBase64(base64) {
      this.veiculo.base64.push(base64);
    },
    removeImage(idx, event) {
      // adicionando efeito de transição ao remover a imagem
      let card = event.srcElement.closest(".transition");
      card.style.opacity = 0;
      setTimeout(() => {
        this.veiculo.base64.splice(idx, 1);
        this.veiculo.images.splice(idx, 1);
        card.style.opacity = 1;
      }, 200);
    },
  },
};
</script>

<style>
.finishScroll {
  overflow-y: auto;
  min-height: 300px;
}
</style>