<template>
	<div>
		<v-card-text>
			<DataTableGeneric
				:headers="headers"
				:items="items"
			>
				<template v-slot:item.createdAt="{ item }">
					<span>{{ formataData(item.createdAt) }}</span>
				</template>
				<template v-slot:item.updatedAt="{ item }">
					<span>{{ formataData(item.updatedAt) }}</span>
				</template>
				
			</DataTableGeneric>
		</v-card-text>
	</div>
</template>

<script>
import DataTableGeneric from "../DataTableGeneric";
import { mapGetters } from "vuex";

export default {
	name: "TableDocuments",
	computed: {
		...mapGetters([
			"getClienteNameById",
			"getUserNameById",
			"getTarefas",
			"getAmbienteNameById",
			"getDispositivos",
		]),
		dispositivos() {
      return this.getDispositivos.filter((item) => item.enabled);
    },
	},
	components: {
		DataTableGeneric,
	},
	data: () => {
		return {
			search: "",
			selected: [],
		};
	},
	props: {
		items: {
			type: Array,
		},
		headers: {
			type: Array,
		},
	},
	methods: {
		formataData(dataUTC) {
			return new Date(dataUTC).toLocaleString();
		},
	},
	created() {
		this.$store.dispatch("loadClientes", this.$store.getters.getCliente.groupId);
		this.$store.dispatch("loadDispositivos", this.$store.getters.getCliente.groupId );
	},
	watch: {},
};
</script>

<style scoped>
.intermed-title {
	font-size: 1.2rem;
	font-weight: bold;
}
</style>