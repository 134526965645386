<template>
  <div>
    <v-card>
      <v-card-title class="d-block text-center">
        <v-btn color="warning" @click="$store.dispatch('updateIcones', urlRefresh)">Atualizar Icones</v-btn>
      </v-card-title>
      <v-card-text class="d-flex flex-wrap justify-center">
        <v-card
          v-for="(icon, index) in icons"
          :key="index"
          class="ma-1"
          @click="copy(icon)"
        >
          <v-card-text class="d-flex justify-center">
            <v-icon class="">{{ icon }}</v-icon>
          </v-card-text>
          <v-card-actions>
            {{ icon }}
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "icons",
  data: () => {
    return {
      icon: "",
      urlRefresh: "",
    };
  },
  methods: {
    copy(i) {
      console.log(i);
      navigator.clipboard.writeText(i);
    },
  },
  computed:{
    icons: {
      get() {
        return this.$store.getters.getIcones;
      },
    },
  },
  mounted() {
    let links = document.getElementsByTagName("link");
    links.forEach((element) => {
      if (element.rel == "stylesheet" && element.href.includes("icons")) {
        this.urlRefresh = element.href;
      }
    });
    this.$store.dispatch("loadIcones");
  },
};
</script>

<style>
</style>