var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticClass:"pb-0"},[_vm._v("Notas vinculadas ao cliente")]),_c('v-data-table',{staticClass:"mb-2",attrs:{"headers":_vm.headers,"items":_vm.notes,"loading":_vm.load,"disable-sort":"","no-data-text":_vm.load ? '' : 'Nenhuma Nota cadastrada',"footer-props":{'items-per-page-options': [15, 30, 50, 100, -1]}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString())+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.editar(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar : "+_vm._s(item.name))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.excluir(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-trash-can")])]}}],null,true)},[_c('span',[_vm._v("Excluir : "+_vm._s(item.name))])])]}}])}),_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.add}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v(" Nova Nota ")],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.dialogFields.id ? "Editar Nota" : "Cadastrar Nota"))]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"formNote"},[_c('v-textarea',{attrs:{"outlined":"","label":"Nota","counter":"255","maxlength":"255","required":"","rules":_vm.requiredField},model:{value:(_vm.dialogFields.note),callback:function ($$v) {_vm.$set(_vm.dialogFields, "note", $$v)},expression:"dialogFields.note"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"py-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.save(_vm.dialogFields)}}},[_vm._v("Salvar")])],1)],1)],1),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }