import axios from 'axios'
import { queryStringBuilder } from '../../Utils/queryStringBuilder'
export default {
    state: {
        veiculos: [],
        reportVeiculos: [],

    },
    mutations: {
        setVeiculos(state, payload) {
            state.veiculos = payload
        },
        setReportVeiculos(state, payload) {
            state.reportVeiculos = payload
        },
        addVeiculo(state, payload) {
            state.veiculos.push(payload)
        },
        delVeiculo(state, payload) {
            state.veiculos = state.veiculos.splice(payload, 1)
        },

    },
    actions: {
        setVeiculos(context, payload) {
            context.commit('setVeiculos', payload)
        },
        setReportVeiculos(context, payload) {
            context.commit('setReportVeiculos', payload)
        },
        addVeiculo(context, payload) {
            axios(`veiculo/${payload.id}`).then((resp) => {
                resp.data.createdAt = new Date(resp.data.createdAt).toLocaleString();
                context.commit('addVeiculo', resp.data)
            })
        },
        delVeiculo(context, payload) {
            let index = context.state.veiculos.findIndex((ele) => {
                return ele.id == payload
            })
            context.commit('delVeiculo', index)
        },
        loadVeiculos(context) {
            axios(`veiculo/in`).then((resp) => {
                context.dispatch("setVeiculos", resp.data);
            });
        },
        loadVeiculos2(context) {
            axios(`veiculo2/in`).then((resp) => {
                resp.data.forEach((element) => {
                    element.groupName = context.getters.getGroupNameById(element.groupId)
                });
                context.dispatch("setVeiculos", resp.data);
            });
        },
        loadReportVeiculos(context, payload) {
            let url = `relatorios/veiculo?`;
            // gerador de query para relatorio
            url = queryStringBuilder(url, payload);

            axios(url).then((resp) => {
                context.commit("setReportVeiculos", resp.data);
                if (resp.data.length == 0) {
                    context.dispatch("resetSnack");
                    context.dispatch("showErrorSnack", "Nenhum registro encontrado de acordo com o filtro");
                }
            });
        },
        registrarEntradaVeiculo(context, payload) {
            let data = payload;
            // removendo os dados do base64
            delete data.base64;
            let formData = new FormData();
            // adicionando os arquivos se existirem
            if (Array.isArray(data.images) && data.images.length > 0) {
                data.images.forEach((ele, index) => {
                    formData.append(`file${index}`, ele);
                });
            }
            // removendo os arquivos do data
            delete data.images;
            // adicionando os dados do veiculo
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const element = data[key];
                    formData.append(key, element);
                }
            }

            axios.post(`veiculo2`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch('loadVeiculos');
                    if (typeof payload.callback == 'function') {
                        payload.callback();
                    }
                }
            });
        },
        registrarSaidaVeiculo(context, payload) {
            let data = payload;
            // removendo os dados do base64
            delete data.base64;
            let formData = new FormData();
            // adicionando os arquivos se existirem
            if (Array.isArray(data.images) && data.images.length > 0) {
                data.images.forEach((ele, index) => {
                    formData.append(`file${index}`, ele);
                });
            }
            // removendo os arquivos do data
            delete data.images;
            // adicionando os dados do veiculo
            for (const key in data) {
                if (Object.hasOwnProperty.call(data, key)) {
                    const element = data[key];
                    formData.append(key, element);
                }
            }

            axios.put(`veiculo/exit/${payload.id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch('loadVeiculos');
                    if (typeof payload.callback == 'function') {
                        payload.callback();
                    }
                }
            });
            // axios.put(`veiculo/exit/${payload.id}`, payload).then((resp) => {
            //     if (resp.status < 299) {
            //         context.dispatch('loadVeiculos');
            //         if (typeof payload.callback == 'function') {
            //             payload.callback();
            //         }
            //     }
            // });
        },
    },
    getters: {
        getVeiculos(state) {
            return state.veiculos
        },
        getReportVeiculos(state) {
            return state.reportVeiculos
        },
        getVeiculosCount(state) {
            return state.veiculos.lenth
        },
    },
}