<template>
	<div>
		<v-dialog
			v-model="dialog"
			max-width="800"
			max-height="800"
			@click:outside="closeRonda"
			scrollable
		>
			<v-card>
				<v-card-title class="text-h5 pb-4"
					>Detalhes da Ronda Virtual</v-card-title
				>
				<v-card-text class="py-0">
					<v-card outlined>
						<v-card-title class="py-2 intermed-title"
							>Ronda Virtual: {{ ronda.name }}</v-card-title
						>
						<v-card-text>
							<v-row class="mb-3">
								<v-col class="py-2">
									<span
										><strong>Cliente:</strong>
										{{
											ronda.clientId
												? getClienteNameById(ronda.clientId).split(" - ")[1]
												: ""
										}}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Funcionário:</strong>
										{{
											ronda.userId
												? getUserNameById(ronda.userId).split(" - ")[1]
												: ""
										}}</span
									>
								</v-col>
							</v-row>
							<v-row class="mb-3">
								<v-col class="py-2">
									<span
										><strong>Data:</strong>
										{{
											ronda.finishedAt
												? formataData(ronda.finishedAt)
												: formataData(ronda.createdAt)
										}}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Finalizada: </strong>
										{{ ronda.finished ? "Sim" : "Não" }}
									</span>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Tipo:</strong>
										{{
											ronda.horarioId ? "Execução agendada" : "Execução Extra"
										}}</span
									>
								</v-col>
							</v-row>
							<v-divider class="mb-3"></v-divider>
							<v-card-title class="intermed-title">Questões</v-card-title>
							<v-row v-show="!ronda.payload.questions">
								<v-col cols="12" class="py-3 my-3 text-center">
									<span>Nenhuma questão registrada</span>
								</v-col>
							</v-row>

							<v-card
								outlined
								v-for="(item, index) in ronda.payload.questions"
								:key="index"
								class="mb-4"
							>
								<v-card-title class="py-2 text-h6">
									{{ item.name }}
								</v-card-title>
								<v-card-text>
									<v-row>
										<v-col cols="12">
											<span
												><strong>Resposta:</strong> {{ verificaResposta(item) }}</span
											>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
							<v-card outlined v-if="ronda.payload.observacao">
								<v-card-title class="py-2 text-h6">
									<strong>Observação:</strong>
								</v-card-title>
								<v-card-text>
									<v-row>
										<v-col cols="12">
											<span> {{ ronda.payload.observacao }}</span>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
							<template v-if="ronda.images && ronda.images.length">
								<v-card-title class="intermed-title">Imagens</v-card-title>
								<!-- Carousel customizado para múltiplas colunas-->
								<v-carousel
									v-if="ronda.images && ronda.images.length"
									v-model="carouselPosition"
									height="200"
									hide-delimiters
									>
									<template v-for="(img, index) in ronda.images">
										<v-carousel-item v-if="(index + 1) % carouselColumns === 1 || carouselColumns === 1" :key="index"> 
										<v-row class="flex-nowrap" style="height:100%; margin:-4px"> 
											<template v-for="(col, i) in carouselColumns"> 
											<template v-if="(+index + i) < ronda.images.length"> 
												<v-col :key="i" :cols="12 / carouselColumns" class="pa-1">
												<v-img :src="ronda.images[+index + i]" width="100%" height="100%" @click="openImg(img)" class="d-block rounded"></v-img>
												</v-col> 
											</template> 
											</template> 
										</v-row> 
										</v-carousel-item> 
									</template> 
								</v-carousel>
							</template>
						</v-card-text>
					</v-card>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="gerarRelatorioSolo(ronda)">
						Exportar Relatório
					</v-btn>
					<v-btn color="success" text @click="closeRonda">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Modal Imagem Grande -->
		<v-dialog v-model="dialogImg" max-width="800px" scrollable>
			<v-card>
				<v-card-title>
					<v-icon class="mr-1">mdi-image</v-icon>
					Imagem: {{ ronda.name }}
				</v-card-title>

				<v-card-text>
					<v-img :src="image" contain height="500" />
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="downloadImg(image)">
						<v-icon class="mr-1">mdi-download</v-icon> Baixar
					</v-btn>
					<v-btn color="success" text @click="dialogImg = false">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import PDF from "@/Utils/PDF";
import File from "@/Utils/File";
import { mapGetters } from "vuex";

export default {
	name: "DialogRondaVirtual",
	components: {},
	computed: {
		...mapGetters(["getUserNameById", "getClienteNameById"]),
	},
	data: () => ({
		dialog: false,
		imgs: [],
		img: "",
		dialogImg: false,
		dispositivos: [],
		ronda: {
			payload: {
				questions: [],
				horario: {
					start: "",
					end: "",
				},
			},
		},
		image: "",
		carouselColumns: 3,
		carouselPosition: 0,
	}),
	methods: {
		openRonda(ronda) {
			this.ronda = { ...ronda };
			this.dialog = true;
			this.carouselPosition = 0;

		},
		closeRonda() {
			this.dialog = false;
		},
		openImg(img) {
			this.image = img;
			this.dialogImg = true;
		},
		downloadImg(img) {
			let imag = new Image();
			imag.onload = function () {
				let canvas = document.createElement("canvas");
				let context = canvas.getContext("2d");
				canvas.width = this.width;
				canvas.height = this.height;
				context.drawImage(this, 0, 0);
				let imageData = canvas.toDataURL();
				let blob = File.dataURItoBlob(imageData);
				File.downloadFile(blob, "ImageName.png");
			};
			imag.crossOrigin = "anonymous";
			imag.src = img;
		},
		formataData(data) {
			if (data) {
				let dataFormatada = new Date(data);
				return dataFormatada.toLocaleString();
			}
		},
		verificaResposta(question) {
			if (question.type == "boolean") {
				return question.value ? "Sim" : "Não";
			} else return question.value;
		},
		gerarRelatorioSolo(item) {
			this.$store.dispatch("setTitleDialog", "Gerando o Relátorio");
			this.$store.dispatch("setShowDialog", true);
			let ronda = { ...item };
			let itens = [];
			ronda.clientId = this.getClienteNameById(ronda.clientId).split(" - ")[1];
			ronda.userId = this.getUserNameById(ronda.userId).split(" - ")[1];
			ronda.data = ronda.finishedAt
				? this.formataData(ronda.finishedAt)
				: this.formataData(ronda.createdAt);
			ronda.tipo = ronda.horarioId ? "Execução Agendada" : "Execução Extra";
			itens.push(ronda);
			PDF["rondaVirtualSolo"](
				itens,
				"Relatório de Ronda Virtual",
				"relatorio_de_ronda_virtual.pdf"
			);
		},
	},
	created() {
		// this.$store.dispatch("loadRondas", this.$store.getters.getCliente.id);
		// this.$store.dispatch(
		// 	"loadClientes",
		// 	this.$store.getters.getCliente.groupId || null
		// );
		let complemento = this.$store.getters.getCliente.groupId
			? `?groupId=${this.$store.getters.getCliente.groupId}`
			: "";
		this.$http(
			`dispositivo${complemento}`
		).then((resp) => {
			this.dispositivos = resp.data.filter((item) => item.enabled);
		});
	},
};
</script>

<style>
</style>