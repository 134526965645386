<template>
  <div class="cliente">
    <ClienteCard :cliente="cliente" @refresh="refresh" />
    <v-card>
      <v-tabs
        fixed-tabs
        background-color="success"
        show-arrows
        class="rounded-b-0"
      >
        <v-tab class="d-print-none">
          <v-icon class="mr-1">mdi-email-arrow-right</v-icon>Avisos de Eventos
          ({{ avisos.length }})
        </v-tab>
        <v-tab-item class="px-2 mt-2">
          <DataTable :items="avisos" :headers="headersAvisos" :actions="false">
            <template v-slot:item.eventId="{ item }">
              <v-icon :color="getEventoById(item.eventId).color"
                >mdi-information</v-icon
              >
              {{ getEventoById(item.eventId).name }}
            </template>
            <template v-slot:item.emails="{ item }">
              <p v-for="email in item.emails" :key="email" class="my-0">
                {{ email }}
              </p>
            </template>
            <template v-slot:item.sendWithoutTreatment="{ item }">
              {{ item.sendWithoutTreatment ? "Sim" : "Não" }}
            </template>
            <template v-slot:item.actions="{ item }">
              <TooltipIcon
                color="warning"
                :text="`Editar`"
                icon="mdi-pencil"
                top="true"
                @click="openDialogCadastro(item)"
              />
              <TooltipIcon
                color="red"
                :text="`Excluir`"
                icon="mdi-trash-can"
                top="true"
                @click="delAvisoEvento(item)"
              />
            </template>
          </DataTable>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="amber darken-1"
          dark
          absolute
          bottom
          right
          fab
          fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="openDialogCadastro"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Aviso</span>
    </v-tooltip>
    <CadAvisoEventos ref="dialogCadastro" @refresh="refresh" />
    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import DataTable from "../DataTableGeneric.vue";
import TooltipIcon from "../Template/TooltipIcon.vue";
import ClienteCard from "./components/ClienteCard";
import CadAvisoEventos from "./components/CadAvisoEventos";
import ConfirmDialog from "../ConfirmDialog.vue";

export default {
  name: "AvisoEventos",
  components: {
    ClienteCard,
    DataTable,
    TooltipIcon,
    CadAvisoEventos,
    ConfirmDialog,
  },
  data: () => {
    return {
      avisos: [],
      eventos: [],
      headersAvisos: [
        { text: "Evento", value: "eventId" },
        { text: "E-mails", value: "emails" },
        { text: "Enviar sem Tratamento", value: "sendWithoutTreatment"},
        {
          text: "Ações",
          value: "actions",
          sortable: false,
          filterable: false,
          align: "right",
        },
      ],
    };
  },
  computed: {
    cliente: {
      get() {
        return this.$store.getters.getCliente;
      },
    },
  },
  methods: {
    getAvisos() {
      this.$http.get(`/avisoeventos/${this.cliente.id}`).then((res) => {
        this.avisos = res.data;
      });
    },
    getEventos() {
      this.$http.get("/eventType").then((res) => {
        this.eventos = res.data;
      });
    },
    refresh() {
      console.log("refresh");
      this.getAvisos();
    },
    delAvisoEvento(item) {
      this.$refs.confirmDialog.open({
        title: "Excluir Aviso de Eventos",
        text: `Deseja realmente excluir o aviso?`,
        confirmCallback: () => {
          this.$http.delete(`/avisoeventos/${item.id}`).then(() => {
            this.getAvisos();
          });
        },
      });
    },
    getEventoById(id) {
      const evento = this.eventos.find((e) => e.id === id);
      return evento ? evento : { name: "Evento não encontrado", color: "red" };
    },
    openDialogCadastro(evento) {
      this.$refs.dialogCadastro.open(this.cliente, this.eventos, evento);
    },
  },
  mounted() {
    this.getAvisos();
    this.getEventos();
  },
};
</script>

<style>
.theme--light.v-tabs-items {
  background-color: transparent !important;
}
</style>