<template>
  <div class="cliRonda">
    <v-card-title>Cadastros de Rondas</v-card-title>
    <v-card-text>
      <DataTable
        :items="rondas"
        :headers="headers"
      >
        <template slot="item.daysOfWeek" slot-scope="{ item }">
          {{ daysToString(item.daysOfWeek) }}
        </template>
        
        <template slot="item.checkpoints" slot-scope="{ item }">
          <span class="mr-1">{{ item.checkpoints.length }} Pontos</span>
          <v-menu
            open-on-hover
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
              >mdi-eye</v-icon>
            </template>
            <v-list dense class="dropdown-list superdense">
              <v-list-item v-for="(item, index) in item.checkpoints" :key="index">
                <v-list-item-content>
                  <v-list-item-title><strong class="mr-2">{{ item.id }}</strong>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          
        </template>

        <template slot="item.deviceId" slot-scope="{ item }">
          {{ getDeviceNameById(item.deviceId) }}
        </template>

        <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="editar(item)"
                color="warning"
                class="mr-1"
              >mdi-pencil</v-icon>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
          <!-- botão excluir -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="excluir(item)"
                color="red"
                class="mr-1"
              >mdi-trash-can</v-icon>
            </template>
            <span>Excluir : {{ item.name }}</span>
          </v-tooltip>
          <!-- botão qrcodes -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click="print(item)"
                color="black"
              >mdi-qrcode</v-icon>
            </template>
            <span>Gerar QR Codes</span>
          </v-tooltip>
        </template>

      </DataTable>
    </v-card-text>

<!-- dialog de cadastro -->
    <v-dialog v-model="dialogCadastro" persistent max-width="815" scrollable>
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2">{{ ronda.id ? "Editar" : "Cadastrar" }} Ronda<v-spacer></v-spacer>
          <v-btn icon @click="closeCadastro">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-form ref="formRonda">
            <v-row>
              <v-col class="pb-0">
                <v-text-field
                  v-model="ronda.name"
                  label="Nome da Ronda"
                  required
                  :rules="requiredField"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="pb-0">
                <v-autocomplete
                    v-model="ronda.deviceId"
                    :items="dispositivos"
                    hide-selected
                    label="Dispositivo"
                    outlined
                    item-text="name"
                    item-value="id"
                    :rules="requiredField"
                  ></v-autocomplete>
              </v-col>
              <v-col class="pb-0" v-if="ronda.id">
                <v-btn @click="openDialogAvisos" x-large block  outlined text>Emails de aviso ({{ emailsAvisos.emails ? emailsAvisos.emails.length : 0 }})</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0">
                <v-text-field
                  v-model="ronda.start"
                  label="Hora Inicial"
                  type="time"
                  required
                  :rules="requiredField"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="pt-0">
                <v-text-field
                  v-model="ronda.end"
                  ref="rondaEnd"
                  label="Hora Final"
                  type="time"
                  required
                  :rules="[requiredField[0], ronda.end !== ronda.start || 'Hora final deve ser diferente da inicial']"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="pt-0">
                <v-text-field
                  v-model.number="ronda.interval"
                  label="Intervalo"
                  hint="Intervalo entre Rondas (minutos)"
                  type="number"
                  :min="minInterval"
                  required
                  :rules="[requiredField[0], validaInterval || 'Intervalo maior que o tempo total'  , v => v >= minInterval || `Mínimo ${minInterval} minutos`]"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card outlined class="mb-8">
              <v-card-subtitle>
                <span>Horários das rondas</span>
                <span class="text-caption d-inline-block ml-2">
                  <template v-if="rondaRepeats.length">({{ rondaRepeats.length }} {{ rondaRepeats.length > 1 ? 'repetições' : 'repetição' }})</template>
                  <template v-else>(Preencha os campos <strong>Hora Inicial</strong>, <strong>Hora Final</strong> e <strong>Intervalo</strong> para visualizar)</template>
                </span>
              </v-card-subtitle>
              <v-card-text class="pt-0 pb-4 px-4" v-if="rondaRepeats.length">
                <div id="card-repeats">
                  <v-chip
                    v-for="(repeat, index) in rondaRepeats" :key="index"
                    small
                  >
                    {{ `${repeat.start} - ${repeat.end}` }}
                  </v-chip>
                </div>
              </v-card-text>
            </v-card>

            <!-- select dias da semana -->
            <v-select
              v-model="ronda.daysOfWeek"
              :items="daysOfWeek"
              label="Dias da semana"
              multiple
              chips
              deletable-chips
              small-chips
              outlined
              :rules="[v => v.length > 0 || 'Selecione pelo menos um dia']"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @mousedown.prevent
                  @click="selAllSelectSemana"
                >
                  <v-list-item-action>
                    <v-icon>{{ iconSelectSemana }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Todos os dias</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>

<!-- checkpoints -->
            <v-card class="mb-4" outlined>
              <v-card-subtitle class="d-flex justify-space-between">
                <span>Pontos de ronda</span>
                <div class="d-flex">
                  <v-switch
                    v-model="ronda.orderRequired"
                    label="Ordem obrigatória"
                    hide-details
                    class="d-inline-flex mt-0 pt-0 mr-1"
                  ></v-switch>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        class="mt-0"
                      >mdi-help-circle-outline</v-icon>
                    </template>
                    <span>Quando ativo, o efetivo deve efetuar a<br />ronda seguindo a ordem dos Pontos</span>
                  </v-tooltip>
                </div>
              </v-card-subtitle>
              <v-card-text class="pb-0">
                <div class="d-flex">
                  <v-autocomplete
                    v-model="ronda.checkpoints"
                    :items="checkpoints"
                    :search-input.sync="searchCheckpoint"
                    hide-selected
                    label="Adicionar Pontos de Ronda"
                    multiple
                    outlined
                    item-text="name"
                    return-object
                    @change="scrollDown('#table-checkpoints .v-data-table__wrapper')"
                    :rules="[(ronda.checkpoints.length > 0) || 'Adicione pelo menos um Ponto de Ronda']"
                    class="mr-4"
                  >
                    <template v-slot:selection><!-- vazio para não mostrar os items selecionados no combo --></template>

                    <template v-slot:item="{ item }" >
                      <span class="flex-grow-1"><strong class="mr-3">{{ item.id }}</strong> {{ item.name }}</span>
                      <v-chip v-if="!item.rondas.length" x-small color="warning">Sem vínculo</v-chip>
                    </template>

                    <template v-slot:prepend-item>
                      <v-list-item link @click="openDialogCheckpoint">
                        <v-list-item-title class="font-weight-medium">Novo Ponto de Ronda</v-list-item-title>
                        <v-list-item-icon class="my-2"><v-icon large color="amber lighten-1">mdi-plus</v-icon></v-list-item-icon>
                      </v-list-item>
                      <v-divider></v-divider>
                    </template>
                  </v-autocomplete>

                  <v-autocomplete
                    v-model="selectedGroup"
                    :items="checkpointGroups"
                    label="Adicionar Grupos de Pontos"
                    multiple
                    outlined
                    item-value="checkpoints"
                    :menu-props="{
                      closeOnClick: true,
                      closeOnContentClick: true,
                    }"
                    @change="selectGroup"
                  >
                    <template v-slot:selection><!-- vazio para não mostrar os items selecionados no combo --></template>

                    <template v-slot:item="{ item }" >
                        <v-list-item-content>
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                          <v-list dense class="superdense pb-0">
                            <v-list-item v-for="(subitem, index) in item.checkpoints" :key="index">
                              <v-list-item-content>
                                <v-list-item-subtitle :class="checkAlreadySel(subitem.id) ? 'text-decoration-line-through' : ''">{{ subitem.id }} - {{ subitem.name }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>  
                        </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </div>
<!-- tabela de checkpoints -->
                <div v-if="ronda.checkpoints.length">
                  <v-data-table
                    class="fixed-header-table draggable mt--1 mb-4"
                    hide-default-footer
                    hide-default-header
                    id="table-checkpoints"
                  >
                    <template v-slot:header>
                      <thead>
                        <tr>
                          <th class="px-0" width="24"><!-- vazio --></th>
                          <th class="text-center" width="38">Ordem</th>
                          <th>ID</th>
                          <th>Nome</th>
                          <th width="250">
                            Tempo obrigatório no Ponto
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  small
                                >mdi-help-circle-outline</v-icon>
                              </template>
                              <span>Quando ativo, o efetivo deve permanecer<br />no Ponto pelo tempo estipulado</span>
                            </v-tooltip>
                          </th>
                          <th class="text-center" width="70">Ações</th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:body>
                      <draggable :list="ronda.checkpoints" tag="tbody">
                        <tr v-for="(item, index) in ronda.checkpoints" :key="index">
                          <td class="px-0" width="24"><v-icon>mdi-drag-vertical</v-icon></td>
                          <td class="text-center">{{ index + 1 }}</td>
                          <td>
                            <span v-if="item.id">{{ item.id }}</span>
                            <v-chip v-else x-small color="success">Novo</v-chip>
                          </td>
                          <td>{{ item.name }}</td>
                          <td>
                            <div class="d-flex">
                              <v-switch
                                v-model="item.timeRequired"
                                hide-details
                                class="mt-0 pt-2 mr-1"
                              ></v-switch>
                              <v-text-field
                                v-model.number="item.minTime"
                                label="Mín."
                                type="number"
                                min="0"
                                :disabled="!item.timeRequired"
                                :rules="[item.timeRequired && !item.maxTime ? v => v > 0 || 'Tempo mínimo deve ser maior que zero' : true]"
                                outlined
                                dense
                                hide-details
                                class="mr-3"
                              ></v-text-field>
                              <v-text-field
                                v-model.number="item.maxTime"
                                label="Máx."
                                type="number"
                                min="0"
                                :disabled="!item.timeRequired"
                                :rules="[item.timeRequired && !item.minTime ? v => v > 0 || 'Tempo máximo deve ser maior que zero' : true]"
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                            </div>
                          </td>
                          <td class="text-center">
                            <!-- botão excluir -->
                            <v-tooltip left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="deleteCheckpoint(index)"
                                  color="red"
                                >mdi-trash-can</v-icon>
                              </template>
                              <span>Excluir : {{ item.name }}</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </draggable>
                    </template>
                  </v-data-table>
                  <p class="text-caption">Arraste os itens da tabela caso deseje alterar a ordem dos Pontos de Ronda.</p>
                </div>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="closeCadastro">Cancelar</v-btn>
          <v-btn
            color="success"
            @click="save({print: false})"
          > Salvar 
            <v-menu open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                >mdi-arrow-down-drop-circle-outline</v-icon>
              </template>
              <v-btn @click="save({print: true})" text color="success" class="btn-dropdown"><v-icon class="mr-1">mdi-qrcode</v-icon>SALVAR e gerar QR Codes</v-btn>
            </v-menu>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }" in>
        <v-btn color="amber darken-1" dark absolute bottom right fab fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialogCadastro = true"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Ronda</span>
    </v-tooltip>

    <v-dialog v-model="dialogCheckpoint" max-width="500px">
      <v-card>
        <v-card-title>Adicionar Ponto de Ronda</v-card-title>
        <v-card-text class="pb-0 pt-2">
          <v-form ref="formCheckpoint" @submit.prevent="addCheckpoint">
            <v-text-field
              v-model="tmpCheckpoint"
              ref="inputCheckpoint"
              label="Nome do Ponto de Ronda"
              required
              counter="40"
              maxlength="40"
              :rules="[requiredField[0], checkpointNotExist || 'Já existe um Ponto com esse nome']"
              outlined
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="dialogCheckpoint = false">Cancelar</v-btn>
          <v-btn
            color="success"
            @click="addCheckpoint"
          >
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CheckpointsDialog ref="checkpointsDialog" />

    <ConfirmDialog ref="confirmDialog" />

    <CadAvisoRondas ref="dialogAvisosRondas"/>
  </div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import { mapGetters } from "vuex";
import DataTable from "../../DataTableGeneric";
import ConfirmDialog from '@/components/ConfirmDialog';
import CheckpointsDialog from "./CheckpointsDialog.vue";
import draggable from 'vuedraggable';
import CadAvisoRondas from "./CadAvisoRondas.vue";

export default {
  name: "CliRondas",
  components: {
    DataTable,
    ConfirmDialog,
    draggable,
    CheckpointsDialog,
    CadAvisoRondas
  },
  props: ["rondas", "checkpoints", "checkpointGroups", "dispositivos"],
  data: () => {
    return {
      dialogCadastro: false,
      dialogCheckpoint: false,
      requiredField,
      ronda: {},
      searchCheckpoint: "",
      tmpCheckpoint: "",
      minInterval: 30,
      selectedGroup: [],
      devices: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Nome da Ronda", value: "name" },
        { text: "Início", value: "start" },
        { text: "Fim", value: "end"  },
        { text: "Intervalo", value: "interval" },
        { text: "Dispositivo", value: "deviceId" },
        { text: "Dias da Semana", value: "daysOfWeek", sortable: false  },
        { text: "Pontos de Ronda", value: "checkpoints", sortable: false  },
        { text: "Ações", value: "actions", align: "center", sortable: false, filterable: false, width: 112 },
      ],
      daysOfWeek: [
        { text: "domingo", value: 0, short: "dom" },
        { text: "segunda-feira", value: 1, short: "seg" },
        { text: "terça-feira", value: 2, short: "ter" },
        { text: "quarta-feira", value: 3, short: "qua" },
        { text: "quinta-feira", value: 4, short: "qui" },
        { text: "sexta-feira", value: 5, short: "sex" },
        { text: "sábado", value: 6, short: "sab" }
      ],
      emailsAvisos: [],
    };
  },
  computed: {
    iconSelectSemana () {
      if (this.ronda.daysOfWeek.length == this.daysOfWeek.length) return 'mdi-close-box';
      else if (this.ronda.daysOfWeek.length > 0) return 'mdi-minus-box';
      else return 'mdi-checkbox-blank-outline';
    },
    validaInterval() { // verifica se o intervalo é menor que o tempo total
      if (!this.ronda.start || !this.ronda.end) return true;
      return this.ronda.interval <= this.getTimeDiff;
    },
    checkpointNotExist() {
      return (
        this.checkpoints.filter(item => item.name.toLowerCase() == this.tmpCheckpoint.trim().toLowerCase()).length == 0 &&
        this.ronda.checkpoints.filter(item => item.name.toLowerCase() == this.tmpCheckpoint.trim().toLowerCase()).length == 0
      );
    },
    rondaRepeats() {
      if (!this.ronda.interval || !this.ronda.start || !this.ronda.end) return [];

      const interval = this.ronda.interval;
      const minsStart = this.timeToMinutes(this.ronda.start);
      const repeats = Math.floor((this.getTimeDiff) / interval);
      let rondaRepeat = [];
      for (let i = 0; i < repeats; i++) {
        rondaRepeat.push({
          start: this.minutesToTime(minsStart + interval * i ),
          end: this.minutesToTime(minsStart + interval * (i + 1))
        });
      }
      this.scrollDown("#card-repeats");
      return rondaRepeat;
    },
    getTimeDiff() { // retorna a diferença entre o horário inicial e final, utilizado em 2 lugares
      let dif = this.timeToMinutes(this.ronda.end) - this.timeToMinutes(this.ronda.start);
      return dif < 0 ? dif += (24 * 60) : dif;
    },
    ...mapGetters(["getAvisosRondas"]),
  },
  methods: {
    reset() {
      this.ronda = {
        id: null,
        name: "",
        start: "",
        end: "",
        interval: this.minInterval,
        daysOfWeek: [],
        checkpoints: []
      }
    },
    save(options) {
      if (!this.$refs.formRonda.validate())
        return;
      this.ronda.repeats = this.rondaRepeats;
      this.ronda.clientId = this.$store.getters.getCliente.id;
      this.ronda.groupId = this.$store.getters.getCliente.groupId;
      this.ronda.callback = (ronda) => {
        if (options.print){
          this.$refs.checkpointsDialog.open({
            checkpoints: ronda.checkpoints,
            rondaName: ronda.name
          });
        }
        this.closeCadastro();
      };
      this.$store.dispatch("saveRonda", this.ronda);
    },
    editar(item) {
      this.ronda = {...item};
      this.$store.dispatch("loadAvisosRondas", item.id);
      this.dialogCadastro = true;
    },
    excluir(item) {
      this.$refs.confirmDialog.open({
        title: 'Excluir Ronda',
        text: `Deseja excluir a ronda <strong>${item.name}</strong>?`,
        confirmCallback: () => {
          this.$store.dispatch("deleteRonda", item);
        }
      });
    },
    print(item){
      this.$refs.checkpointsDialog.open({
        checkpoints: item.checkpoints, //checkpoints e name são passados separados pois o componente é reutilizável
        rondaName: item.name
      });
    },
    closeCadastro() {
      this.dialogCadastro = false;
      this.$refs.formRonda.resetValidation();
      this.reset();
    },
    openDialogCheckpoint() {
      this.tmpCheckpoint = "";
      this.dialogCheckpoint = true;
      this.$nextTick(() => {
        this.$refs.formCheckpoint.resetValidation();
      });
      setTimeout(() => this.$refs.inputCheckpoint.focus(), 1);
    },
    addCheckpoint() {
      if (!this.$refs.formCheckpoint.validate())
        return;
      this.ronda.checkpoints.push({
        name: this.tmpCheckpoint,
        id: null
      });
      this.dialogCheckpoint = false;
      this.scrollTableCheckpoints();
    },
    deleteCheckpoint(index) {
      if (this.ronda.id){ // se a ronda já foi salva, abre o dialog de confirmação
        this.$refs.confirmDialog.open({
          title: "Excluir Checkpoint",
          text: `Deseja excluir o checkpoint <strong>${this.ronda.checkpoints[index].name}</strong>?`,
          confirmCallback: () => {
            this.ronda.checkpoints.splice(index, 1);
          },
        });
      } else {
        this.ronda.checkpoints.splice(index, 1);
      }
    },
    selectGroup(){
      this.selectedGroup[0].forEach(item => { // adiciona somente os itens do grupo selecionado que ainda não estão na ronda
        if (!this.ronda.checkpoints.filter(checkpoint => checkpoint.id == item.id).length)
          this.ronda.checkpoints.push(item);
      });
      this.selectedGroup = [];
      this.scrollDown("#table-checkpoints .v-data-table__wrapper");
    },
    selAllSelectSemana() {
      if (this.ronda.daysOfWeek.length == this.daysOfWeek.length)
        this.ronda.daysOfWeek = [];
      else
        this.ronda.daysOfWeek = this.daysOfWeek.map(dia => dia.value)
    },
    daysToString(days) {
      let daysShort = this.daysOfWeek.filter(dia => days.includes(dia.value)).map(dia => dia.short);
      return daysShort.join(", ");
    },
    checkAlreadySel(checkId) {
      return this.ronda.checkpoints.filter(item => item.id == checkId).length > 0;
    },
    scrollDown(selector) {
      setTimeout(() => {
        document.querySelector(selector).scroll({
          top: 10000,
          behavior: "smooth"
        });
      }, 1);
    },
    getDeviceNameById(id) {
      let device = this.dispositivos.find(item => item.id == id);
      return device ? device.name : "";
    },
    timeToMinutes(time) {
      const minutes = (h, m) => (parseInt(h) * 60 + parseInt(m));
      return minutes(...time.split(":"));
    },
    minutesToTime(min) {
      let h = Math.floor(min / 60);
      let m = min % 60;
      if (h >= 24) h -= 24;
      if (h < 10) h = "0" + h;
      if (m < 10) m = "0" + m;
      return `${h}:${m}`;
    },
    openDialogAvisos() {
      this.$refs.dialogAvisosRondas.openDialogAvisos(this.emailsAvisos, this.ronda.id, this.$store.getters.getCliente.id);
    },
  },
  watch: {
    getAvisosRondas() {
      this.emailsAvisos = this.getAvisosRondas;
    }
  },
  created() {
    this.reset();
  },
};
</script>

<style scoped>
#table-checkpoints td{
  padding-top: 8px;
  padding-bottom: 8px;
}
#card-repeats {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  max-height: 88px;
  overflow-y: auto;
}
</style>

<style>
#table-checkpoints .v-data-table__wrapper {
  max-height: 210px;
}
</style>