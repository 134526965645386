<template>
	<v-card outlined class="py-2 px-2">
		<v-card elevation="1">
			<v-card-text>
				<v-row>
					<v-col cols="sm-1">
						<v-btn icon small @click="playPause">
							<v-icon>{{ icon }}</v-icon>
						</v-btn>
					</v-col>
					<v-col class="text-center">
						<v-row>
							<v-progress-linear
								height="10"
								rounded
								color="success"
								:value="audioProgress"
								class="mt-5 mr-3"
							></v-progress-linear>
						</v-row>
						<v-row>
							<v-col class="py-0 mb-0 mt-1">
								<v-btn
									text
									small
									@click="mostrarTranscricao = !mostrarTranscricao"
									color="grey"
								>
									{{ mostrarTranscricao ? "Ocultar" : "Mostrar" }} transcrição
									<v-icon>mdi-text-box-outline</v-icon>
								</v-btn>
							</v-col>
							<v-col class="py-0 mb-0 mt-2">
								<span>
									<strong>Tempo decorrido: </strong
									>{{ formatTime(this.audioCurrentTime) }}
								</span>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card-text v-if="mostrarTranscricao" class="text-justify">
			<span><strong>Transcrição: </strong>{{ transcricao != ""? transcricao: "Não foi possivel gerar a transcrição do áudio." }}</span>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	name: "AudioPlayer",
	props: {
		audio: {
			type: String,
			required: true,
		},
		transcricao: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
      duration: 0,
			audioPlayer: null,
			audioDuration: 0,
			audioCurrentTime: 0,
			audioProgress: 0,
			audioMuted: false,
			audioPlaying: false,
			audioEnded: false,
			icon: "mdi-play",
			mostrarTranscricao: false,
		};
	},
	methods: {
		formatTime(time) {
			const minutes = Math.floor(time / 60);
			const seconds = Math.floor(time % 60);
			return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
		},
		playPause() {
			if (this.audioPlaying) {
				this.audioPlayer.pause();
				this.audioPlaying = false;
				this.icon = "mdi-play";
			} else if (this.audioCurrentTime !== 0) {
				this.audioPlay();
				this.audioPlayer.currentTime = this.audioCurrentTime;
				this.icon = "mdi-pause";
			} else {
				this.audioPlay();
				this.icon = "mdi-pause";
			}
		},
		audioPlay() {
			this.audioPlayer = new Audio(this.audio);
			this.audioPlayer.play();
			this.audioPlaying = true;
			this.audioEnded = false;
			this.audioPlayer.addEventListener("loadedmetadata", () => {
				this.audioDuration = this.audioPlayer.duration;
			});
			this.audioPlayer.addEventListener("timeupdate", () => {
				this.audioCurrentTime = this.audioPlayer.currentTime;
				this.audioProgress = (this.audioCurrentTime / this.audioDuration) * 100;
			});
			this.audioPlayer.addEventListener("ended", () => {
				this.audioEnded = true;
				this.audioPlaying = false;
				delete this.audioPlayer;
				this.audioDuration = 0;
				this.audioCurrentTime = 0;
				this.audioProgress = 0;
				this.icon = "mdi-play";
			});
		},
	},
	computed: {},
	created() {
    
  },
};
</script>

<style>
</style>