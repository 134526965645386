import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './plugins/axios/index'
import './plugins/formValidation/index'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import { VueMaskDirective } from 'v-mask'

Vue.directive('mask', VueMaskDirective);
Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')