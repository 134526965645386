<template>
  <v-dialog v-model="value" persistent max-width="700" scrollable>
    <v-card>
      <v-card-title class="text-h5 pr-4 mb-2">{{ item.id ? "Editar" : "Cadastrar" }} Visitante<v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-1 pb-0">
        <v-form ref="formVisitante">
          <v-row>
            <v-col cols="7">
              <v-text-field
                v-model="item.name"
                label="Nome"
                :rules="requiredField"
                required
                outlined
              ></v-text-field>
              
              <v-text-field
                v-model="item.phone"
                label="Telefone / Celular"
                :rules="requiredField"
                v-mask="phoneMask"
                required
                outlined
                name="phone"
              ></v-text-field>
              <v-text-field
                v-model="item.document"
                :rules="requiredField"
                label="Documento"
                required
                outlined
              ></v-text-field>
              <v-text-field
                v-model="item.reference"
                label="Referência"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="text-center">
              <v-avatar size="250" class="mb-7">
                <v-img
                  :src="tempPicture"
                  lazy-src="/img/nopic.jpg"
                ></v-img>
              </v-avatar>
              <Webcam
                @base64="tempPicture = $event"
                @file="item.picFile = $event"
              />
            </v-col>
          </v-row>
          <v-textarea
            v-model="item.obs"
            :counter="255"
            label="Observações"
            outlined
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancelar</v-btn>
        <v-btn color="success" @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Webcam from "@/components/Webcam/WbDialog.vue";
import { requiredField } from "@/Utils/InputValidation";
export default {
  name: "DialogVisitado",
  components: {
    Webcam,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      requiredField,
      tempPicture: "",
      phoneMask: "",
      webcamDialog: false,
    };
  },
  methods: {
    close() {
      this.$emit("input", false);
      this.$emit("close");
    },
    save(){
      if (!this.$refs.formVisitante.validate()) {
        return;
      }
      this.picture = this.tempPicture;
      this.$emit("save", this.item);
    },
  },
  watch: {
    value(value) {
      if (value){
        if (this.item.id)
          this.tempPicture = this.item.picture; // Utiliza a imagem em uma variável temporária para não alterar o estado antes de salvar
        else
          this.phoneMask = "(##) ####-#####"; // Cria a máscara na abertura do dialog p/ contornar bug do v-mask
        
        this.$nextTick(() => {
          this.$refs.formVisitante.resetValidation();
        });  
      } else {
        this.tempPicture = "../img/nopic.jpg";
        this.phoneMask = "";
      }
    }
  },
};
</script>

<style>
</style>