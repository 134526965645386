<template>
  <div class="userCRUD">
    <v-card class="mb-1" v-if="formUsr || visitante.id">
      <v-card-title>
        {{ visitante.id ? "Edição Visitante" : "Cadastro de Visitante" }}
      </v-card-title>
      <v-card-text>
      <v-text-field
        v-model="visitante.name"
        :counter="255"
        label="Nome"
        required
      ></v-text-field>

      <v-text-field
        v-model="visitante.document"
        :counter="255"
        label="Documento"
        required
      ></v-text-field>

      <v-text-field
        v-model="visitante.phone"
        :counter="255"
        label="Telefone"
      ></v-text-field>

      
        <SelectGroup v-model="visitante.groupId" />

      <v-text-field
        v-model="visitante.reference"
        :counter="255"
        label="Referencia / De onde vem"
      ></v-text-field>

      <v-textarea
        v-model="visitante.obs"
        name="input-7-1"
        :counter="255"
        label="Observações"
      ></v-textarea>

        <v-checkbox
          v-model="visitante.enabled"
          label="Ativo"
          required
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" class="mr-4" @click="clearObj"
          >Limpar Campos</v-btn
        >
        <v-btn color="success" class="mr-4" @click="save(visitante)">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="div d-flex justify-end ma-2" v-else>
      <v-btn color="success" @click="formUsr = true">Cadastrar</v-btn>
    </div>
    <v-card>
      <v-card-text>
        <div class="tbl mt-1">
          <Table
            :items="lista"
            :headers="headers"
            :enablePicture="true"
            @edit="editar($event)"
            @delete="delet($event)"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Table from "../../TableGeneric";
import SelectGroup from "../../Template/SelectGroupForm";

export default {
  data() {
    return {
      formUsr: false,
      visitante: {
        id: null,
        enabled: true,
      },
    };
  },
  computed: {
    lista: {
      get() {
        return this.$store.getters.getVisitantes;
      },
    },
    headers: {
      get() {
        return this.$store.getters.getHeadersVisitantes;
      },
    },
  },
  components: {
    Table,
    SelectGroup,
  },
  methods: {
    get() {
      this.edicao = false;
      this.$store.dispatch("loadVisitantes");
      this.clearObj()
    },
    save(objeto) {
      this.$store.dispatch("saveVisitante", objeto);
      this.get()
    },
    editar(event) {
      this.visitante = event;
    },
    delet(event) {
      this.$store.dispatch('deleteVisitante', event)
      this.get();
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  created() {
    this.get();
  },
};
</script>

<style>
</style>