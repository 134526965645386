<template>
  <v-menu
    v-model="menu2"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormated"
        :label="title"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @input="menu2 = false"
      locale="pt-br"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    // date: new Date().toLocaleDateString().substr(0, 10),
    dateFormated: new Date().toLocaleDateString(),
    menu: false,
    modal: false,
    menu2: false,
  }),
  props: {
    title: {
      type: String,
      default: "Título",
    },
  },
  watch: {
    date() {
      let dt = new Date(this.date)
      this.$emit("date", this.date);
      this.dateFormated = `${dt.getUTCDate()}/${dt.getUTCMonth()+1}/${dt.getUTCFullYear()}`
    },
  },
  mounted() {
    this.$emit("date", this.date);
  },
  methods: {
    clear(){
      this.date = new Date().toISOString().substr(0, 10);
    }
  }
};
</script>


<style>
</style>