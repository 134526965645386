<template>
  <v-dialog v-model="value" persistent max-width="700" scrollable>
    <v-card>
      <v-card-title class="text-h5 pr-4 mb-2">{{ item.id ? "Editar" : "Cadastrar" }} {{ typeCli == 1 ? "Visitado" : "Morador" }}<v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-form ref="formVisitado" class="mt-1">
          <!-- Formulário Empresas -->
          <template v-if="typeCli == 1">
            <v-row>
              <v-col cols="7">
                <v-text-field
                  v-model="item.name"
                  label="Nome"
                  :rules="requiredField"
                  required
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="item.phone"
                  label="Telefone / Ramal"
                  :rules="requiredField"
                  required
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="item.celPhone"
                  label="Celular"
                  v-mask="celMask"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="item.place"
                  label="Função"
                  :rules="requiredField"
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="5" class="text-center">
                <v-avatar size="250" class="mb-7">
                  <v-img
                    :src="tempPicture"
                    lazy-src="/img/nopic.jpg"
                  ></v-img>
                </v-avatar>
                <Webcam
                  @base64="tempPicture = $event"
                  @file="item.picFile = $event"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="item.hierarchy"
              label="Hierarquia"
              required
              outlined
            ></v-text-field>
            <v-textarea
              v-model="item.obs"
              :counter="255"
              label="Observações"
              outlined
            ></v-textarea>
          </template>

          <!-- Formulário Condomínios -->
          <template v-else>
            <v-row>
              <v-col cols="7" class="pb-0">
                <v-text-field
                  v-model="item.name"
                  label="Nome"
                  :rules="requiredField"
                  required
                  outlined
                ></v-text-field>
                <v-row>
                  <v-col class="pb-0">
                    <v-text-field
                      v-model="item.phone"
                      label="Telefone"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="pb-0">
                    <v-text-field
                      v-model="item.celPhone"
                      label="Celular"
                      v-mask="celMask"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-text-field
                      v-model="item.place"
                      label="Apartamento"
                      :rules="requiredField"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0">
                    <v-text-field
                      v-model="item.bloco"
                      label="Bloco"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="pt-0">
                    <v-text-field
                      v-model="item.tipoMorador"
                      label="Tipo de Morador"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="pt-0">
                    <v-text-field
                      v-model="item.boxs"
                      label="Boxes"
                      v-mask="celMask"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="5" class="text-center pb-0">
                <v-avatar size="250" class="mb-7">
                  <v-img
                    :src="tempPicture"
                    lazy-src="/img/nopic.jpg"
                  ></v-img>
                </v-avatar>
                <Webcam
                  @base64="tempPicture = $event"
                  @file="item.picFile = $event"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  v-model="item.tipoVeiculo"
                  label="Veículo"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  v-model="item.corVeiculo"
                  label="Cor do Veículo"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col class="pt-0">
                <v-text-field
                  v-model="item.plate"
                  label="Placa do Veículo"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-textarea
              v-model="item.obs"
              :counter="255"
              label="Observações"
              outlined
            ></v-textarea>
          </template>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn text @click="close">Cancelar</v-btn>
        <v-btn color="success" @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Webcam from "@/components/Webcam/WbDialog.vue";
import { requiredField } from "@/Utils/InputValidation";
export default {
  name: "DialogVisitado",
  components: {
    Webcam,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    typeCli: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      requiredField,
      tempPicture: "",
      celMask: "",
      webcamDialog: false,
    };
  },
  methods: {
    close() {
      this.$emit("input", false);
      this.$emit("close");
    },
    save(){
      if (!this.$refs.formVisitado.validate()) {
        return;
      }
      this.picture = this.tempPicture;
      this.$emit("save", this.item);
    },
  },
  watch: {
    value(value) {
      if (value){
        if (this.item.id)
          this.tempPicture = this.item.picture; // Utiliza a imagem em uma variável temporária para não alterar o estado antes de salvar
        else
          this.celMask = "(##) ####-#####"; // Cria a máscara na abertura do dialog p/ contornar bug do v-mask
        
        this.$nextTick(() => {
          this.$refs.formVisitado.resetValidation();
        });  
      } else {
        this.tempPicture = "../img/nopic.jpg";
        this.celMask = "";
      }
    }
  },
};
</script>

<style>
</style>