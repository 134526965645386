<template>
  <v-card>
    <v-card-title>Documentos</v-card-title>
    <v-card-text>
      <DataTable
        :items="getDocumentos"
        :headers="headers"
        @rowClick="viewFile"
      >
        <template slot="item.type" slot-scope="{ item }">
          <v-icon large :color="getRestriction(item).color">{{ item.type == ".pdf" ? "mdi-file-pdf-box" : "mdi-video-box" }}</v-icon>
        </template>

        <template slot="item.restriction" slot-scope="{ item }">
          <v-chip :color="getRestriction(item).color" small>{{ getRestriction(item).text }}</v-chip>
        </template>

        <template slot="item.groupId" slot-scope="{ item }" v-if="!groupId">
          {{ getClientNameByGroupId(item.groupId) }}
        </template>

        <template slot="item.groups" slot-scope="{ item }">
          <template v-if="!item.public && item.groups.length">
            <span class="mr-1">{{ item.groups.length }} postos</span>
            <v-menu
              open-on-hover
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >mdi-eye</v-icon>
              </template>
              <v-list dense class="dropdown-list superdense">
                <v-list-item v-for="(groupId, index) in item.groups" :key="index">
                  <v-list-item-content>
                    <v-list-item-title>{{ getPostoNameByGroupId(groupId) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </template>
        
        <template slot="item.actions" slot-scope="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  @click.stop="editar(item)"
                  color="warning"
                  class="mr-1"
                >mdi-pencil</v-icon>
              </span>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
          <!-- botão excluir -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click.stop="excluir(item)"
                color="red"
                class="mr-1"
                :disabled="groupId && item.groupId != groupId"
              >mdi-trash-can</v-icon>
            </template>
            <span>Excluir : {{ item.name }}</span>
          </v-tooltip>
        </template>

      </DataTable>
    </v-card-text>

<!-- dialog de cadastro -->
    <v-dialog v-model="dialogCadastro" persistent max-width="700" scrollable>
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2">{{ document.id ? "Editar" : "Cadastrar" }} Documento<v-spacer></v-spacer>
          <v-btn icon @click="closeCadastro">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-alert
            v-if="docIsLinked"
            type="info"
            text
            class="text-caption mb-8"
            >Este documento está apenas vinculado ao cliente, portanto, apenas os postos podem ser editados.
          </v-alert>
          <v-form ref="formDocument">
            <v-text-field
              v-model="document.name"
              label="Nome do Documento"
              required
              :rules="requiredField"
              :disabled="docIsLinked"
              outlined
            ></v-text-field>
            <v-file-input
              v-model="document.file"
              label="Arquivo"
              :prepend-icon="null"
              prepend-inner-icon="mdi-paperclip"
              truncate-length="80"
              :rules="!document.id ? requiredField : []"
              :disabled="docIsLinked"
              accept=".pdf, .mp4"
              outlined
            ></v-file-input>
            <div class="d-flex">
              <v-switch
                v-model="document.public"
                label="Público"
                class="pt-0 mr-6 d-inline-block"
                :disabled="docIsLinked"
              ></v-switch>
              <v-autocomplete
                v-model="document.groups"
                :items="postosList"
                :disabled="document.public > 0"
                :loading="!postosList.length"
                multiple
                small-chips
                deletable-chips
                clearable
                label="Restrito aos postos"
                outlined
                item-text="name"
                item-value="groupId"
              ></v-autocomplete>
            </div>
            <v-textarea
              v-model="document.description"
              label="Descrição"
              :disabled="docIsLinked"
              outlined
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="closeCadastro">Cancelar</v-btn>
          <v-btn
            color="success"
            @click="save()"
          > Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="amber darken-1" dark absolute bottom right fab fixed
          class="mb-10"
          v-bind="attrs"
          v-on="on"
          @click="dialogCadastro = true"
        >
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Documento</span>
    </v-tooltip>
    <ConfirmDialog ref="confirmDialog" />

    <!-- dialog de visualização -->
    <v-dialog v-model="dialogView" max-width="700" scrollable>
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2">{{ view.name }}<v-spacer></v-spacer>
          <v-btn icon @click="dialogView = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="d-flex justify-center">
          <video v-if="view.type == '.mp4'" width="100%" height="auto" controls autoplay>
            <source :src="view.file" type="video/mp4">
            <p>Não foi possível reproduzir o vídeo. Tente abrir em <a :href="view.file" target="_blank">nova aba</a>.</p>
          </video>
          <object v-else-if="view.type == '.pdf'" :data="`${view.file}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0`" type="application/pdf" width="100%" height="500">
            <p>Não foi possível abrir o PDF. Tente abrir em <a :href="view.file" target="_blank">nova aba</a>.</p>
          </object>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="dialogView = false"
          > Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { requiredField } from "../../Utils/InputValidation";
import DataTable from "../DataTableGeneric";
import ConfirmDialog from '@/components/ConfirmDialog';
import { mapGetters } from "vuex";

export default {
  name: "Documents",
  components: {
    DataTable,
    ConfirmDialog,
  },
  props: ["groupId"],
  data: () => {
    return {
      dialogCadastro: false,
      dialogView: false,
      requiredField,
      document: {},
      docIsLinked: false,
      view: {},
      headers: [
        { text: "ID", value: "id", show: false },
        { text: "Tipo", value: "type" },
        { text: "Nome do Documento", value: "name" },
        { text: "Descrição", value: "description" },
        { text: "Disponibilidade", value: "restriction", align: "center", sortable: false, filterable: false },
        { text: "Cliente", value: "groupId" },
        { text: "Postos", value: "groups" },
        { text: "Ações", value: "actions", align: "center", sortable: false, filterable: false, width: 112 },
      ],
    };
  },
  computed: {
    ...mapGetters(["getDocumentos", "getClientes", "getPostos"]),
    postosList() {
      
      if (!this.getPostos) return [];
      return this.getPostos.filter((item) => item.enabled);
    },
  },
  methods: {
    save() {
      if (!this.$refs.formDocument.validate()) return;
      
      this.document.groupId = this.groupId;

      this.document.callback = () => {
        this.closeCadastro();
        this.$store.dispatch("loadDocumentos", this.groupId);
      };

      this.$store.dispatch("saveDocumento", this.document);
    },
    editar(item) {
      this.document = {...item};
      this.docIsLinked = (this.groupId && item.groupId != this.groupId);
      delete this.document.file;
      this.dialogCadastro = true;
      if (!this.groupId){
        this.$store.commit("setPostos", []);
        this.$store.dispatch("loadPostos", item.groupId);
      }
    },
    excluir(item) {
      this.$refs.confirmDialog.open({
        title: 'Excluir Documento',
        text: `Deseja excluir o documento <strong>${item.name}</strong>?`,
        confirmCallback: () => {
          item.groupId = this.groupId;
          this.$store.dispatch("deleteDocumento", item);
        }
      });
    },
    closeCadastro() {
      this.dialogCadastro = false;
      this.$refs.formDocument.resetValidation();
      this.document = {};
    },
    viewFile(item) {
      this.view = item;
      this.dialogView = true;
    },
    getPostoNameByGroupId(groupId) {
      const posto = this.getPostos.find(item => item.groupId === groupId);
      return posto ? posto.name : '';
    },
    getClientNameByGroupId(groupId) {
      const client = this.getClientes.find(item => item.groupId == groupId);
      return client ? client.name : '';
    },
    getRestriction(item) {
      if (this.groupId && item.groupId != this.groupId)
        return {color: "error", text: "Vinculado"};
      if (item.public)
        return {color: "success", text: "Público"};
      if (!item.public && item.groups.length)
        return {color: "warning", text: "Restrito"};

      return {color: "gray", text: "Sem vínculo"};
    },
  },
  created() {
    this.$store.dispatch("setDocumentos", []);
    this.$store.dispatch("loadPostos", this.groupId);
    if (!this.groupId){
      this.$store.dispatch("loadDocumentos");
      this.$store.dispatch("loadClientes");
    } else
      this.headers.splice(5, 1);
  },
  watch: {
    dialogView(value) {
      if (!value)
          this.view = {};
    },
  },
};
</script>

<style>
</style>