<template>
  <v-app>
    <Menu />
    <AppBar />
    <v-main>
      <v-container fluid class="pt-5 px-10">
        <div class="bg" :class="ifLoginPgClass"></div>
        <router-view></router-view>
        <v-snackbar
          v-model="snck.snackbar"
          :color="snck.color"
          :timeout="snck.timeout"
        >
          {{ snck.text }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import Menu from "./components/MenuBanco";
import AppBar from "./components/AppBar2";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    AppBar,
    Menu,
  },
  computed: {
    ...mapGetters(["snackbar"]),
    ...mapGetters(["getLogged"]),
    ifLoginPgClass() {
      return (this.$route.path == "/login") ? 'opacity-1' : '';
    },
  },
  watch: {
    // ...mapGetters(["getLooged"]),
    snackbar() {
      this.snck = this.snackbar;
    },
    getLogged() {
      if (this.getLogged) {
        // if(!this.$store.getters.getUsuario.clientId){
        //   return;
        // }
        this.$store.dispatch("connect", this.$store.getters.getToken);
        let skt = this.$store.getters.getSocket;
        
        // sockets do chat
        skt.on("users", (message) => {
          this.$store.dispatch("setOnline", message);
        });
        skt.on("replyOnLine", (message) => {
          this.$store.dispatch("setOnline", message);
        });
        skt.emit("getOnLine");

        skt.on("connect", () => {
          this.$store.dispatch("setStatus", true);
        });
        skt.on("disconnect", () => {
          this.$store.dispatch("setStatus", false);
        });
        skt.on("connect_erro", () => {
          this.$store.dispatch("setStatus", false);
        });
        skt.on("visitas", (message) => {
          switch (message.op) {
            case "entrou":
              this.play();
              this.$store.dispatch("resetSnack");
              this.$store.dispatch("showSnack", "Visitante Entrou");
              this.$store.dispatch("addVisitas", message.visita);
              break;
            case "saiu":
              this.$store.dispatch("resetSnack");
              this.$store.dispatch("showSnack", "Visitante Saiu");
              this.$store.dispatch("delVisitas", message.visita);
              break;
            case "aprov":
              this.$store.dispatch("resetSnack");
              this.$store.dispatch("showSnack", "Visita Aprovada");
              this.$store.dispatch("loadVisitas");
              break;
          }
        });
        skt.on("veiculos", (message) => {
          switch (message.op) {
            case "entrou":
              this.play();
              this.$store.dispatch("resetSnack");
              this.$store.dispatch("showSnack", "Veiculo Entrou");
              // this.$store.dispatch("addVeiculo", message.veiculo);
              this.$store.dispatch("loadVeiculos");

              break;
            case "saiu":
              this.$store.dispatch("resetSnack");
              this.$store.dispatch("showSnack", "Veiculo Saiu");
              this.$store.dispatch("delVeiculo", message.veiculo);
              break;
          }
        });
        skt.on("frota", (message) => {
          switch (message.op) {
            case "start":
              this.$store.dispatch("resetSnack");
              this.$store.dispatch("showSnack", "Frota Iniciou");
              this.$store.dispatch("loadFleetsStarted2");
              break;
            case "end":
              this.$store.dispatch("resetSnack");
              this.$store.dispatch("showSnack", "Frota Finalizou");
              this.$store.dispatch("loadFleetsStarted2");
              break;
          }
        });
        skt.on("frotaM", (message) => {
          switch (message.op) {
            case "start":
              this.$store.dispatch("resetSnack");
              this.$store.dispatch("showSnack", "Frota Iniciou");
              this.$store.dispatch("loadFleetsMultiStarted");
              break;
            case "end":
              this.$store.dispatch("resetSnack");
              this.$store.dispatch("showSnack", "Frota Finalizou");
              this.$store.dispatch("loadFleetsMultiStarted");
              break;
          }
        });
      } else {
        this.$store.dispatch("disconnect");
      }
    },
  },
  data: () => ({
    snck: {},
    title: "Controle de Acesso",
  }),
  methods: {
    play() {
      let audio = new Audio("@/assets/toque.mp3"); // path to file
      audio.play();
    },
  },
  async created() {
    document.title = this.title;
  },
};
</script>

<style src="./assets/css/customStyles.css"></style>