<template>
  <v-row >
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Adicionar Previsão
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="headline">Nova previsão de visita</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="agenda.name"
                  label="Nome da Pessoa"
                  hint="Digite o nome da Pessoa"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="agenda.document"
                  label="Documento"
                  hint="Digite o documento da Pessoa"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="agenda.plate"
                  label="Placa"
                  hint="Digite a placa do veiculo"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-card>
              <v-card-text>
                <strong>Inicio</strong>
                <v-row class="d-flex align-center">
                  <v-col cols="6">
                    <DateTime @date="dataInicial = $event" title="Data" />
                  </v-col>
                  <v-col cols="6">
                    <Time
                      @timePic="horaInicial = $event"
                      class="ml-1"
                      title="Hora"
                      timeStart="08:00"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card class="mt-1">
              <v-card-text>
                <strong>Final</strong>
                <v-row class="d-flex align-center">
                  <v-col cols="6">
                    <DateTime @date="dataFinal = $event" title="Data" />
                  </v-col>
                  <v-col cols="6">
                    <Time
                      @timePic="horaFinal = $event"
                      class="ml-1"
                      title="Hora"
                      timeStart="18:00"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="agenda.description"
                  :counter="255"
                  label="Descrição da visita"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save">
            Cadastrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DateTime from "../Template/DatePicker";
import Time from "../Template/TimePicker2";
export default {
  name: "CadPrevi",
  components: { DateTime, Time },
  data: () => ({
    dialog: false,
    dataFinal: "",
    horaFinal: "",
    dataInicial: "",
    horaInicial: "",
    agenda: {
      id:null,
      name: "",
      document: "",
      plate:"",
      start: "",
      end: "",
      description: "",
      color: "blue",
    },
  }),
  methods:{
    save() {
      // let inicio = `${this.dataInicial} ${this.horaInicial}`;
      // let final = `${this.dataFinal} ${this.horaFinal}`;
      // this.agenda.start = this.remove3Hours(inicio)
      // this.agenda.end = this.remove3Hours(final)
      this.agenda.start = `${this.dataInicial} ${this.horaInicial}`;
      this.agenda.end = `${this.dataFinal} ${this.horaFinal}`;
      this.$http.post(`/agenda`, this.agenda).then(() => {
        this.dialog = false
        this.$emit('refresh')
      });
    },
    remove3Hours(data){
      let m3 = new Date(data)
      m3.setHours(m3.getHours() -3)
      return m3.toISOString();
    }
  }
};
</script>

<style>
</style>