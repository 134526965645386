<template>
  <v-card>
    <v-card-title class="pt-6">Clientes</v-card-title>
    <v-card-subtitle>Selecione um cliente na lista para mais ações.</v-card-subtitle>
    <v-card-text>
      <DataTable
        :headers="headers"
        :items="clientes"
        @rowClick="config($event)"
      >
        <template v-slot:item.tipo="{ item }">
          <v-icon large v-if="item.tipo == '1 - Empresa'">mdi-domain</v-icon>
          <v-icon large v-if="item.tipo == '2 - Condominio'">mdi-home-city</v-icon>
        </template>
        <template v-slot:item.address="{ item }">
          <span class="long-text-col">{{ item.address }}</span>
        </template>
        <template v-slot:item.description="{ item }">
          <span class="long-text-col">{{ item.description }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- botão editar -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click.stop="editar(item)"
                color="warning"
                class="mr-1"
              >mdi-pencil</v-icon>
            </template>
            <span>Editar : {{ item.name }}</span>
          </v-tooltip>
          <!-- botão excluir -->
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                @click.stop="excluir(item)"
                color="red"
              >mdi-trash-can</v-icon>
            </template>
            <span>Excluir : {{ item.name }}</span>
          </v-tooltip>
        </template>
      </DataTable>
    </v-card-text>

    <v-dialog v-model="dialog" persistent max-width="700" scrollable>
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2">{{ cliente.id ? "Editar" : "Cadastrar" }} Cliente<v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0 pt-1">
          <v-form ref="formCliente" :disabled="!cliente.enabled">
            <v-text-field
            v-model="cliente.name"
            label="Nome"
            required
            :rules="requiredField"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="cliente.cnpj"
            label="CNPJ"
            v-mask="cnpjMask"
            required
            :rules="requiredField"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="cliente.address"
            label="Endereço"
            required
            outlined
          ></v-text-field>
          <v-row>
            <v-col>
              <v-text-field
                v-model="cliente.phone"
                label="Telefone"
                v-mask="celMask"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="cliente.typeCli"
                label="Tipo do Cliente"
                :items="getClientTypesSelect"
                required
                :rules="requiredField"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-textarea
            v-model="cliente.description"
            name="input-7-1"
            label="Descrição"
            outlined
          ></v-textarea>
          </v-form>
          <v-switch
            class="pt-0 mt-0"
            v-model="cliente.enabled"
            label="Ativo"
            required
          ></v-switch>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancelar</v-btn>
          <v-btn color="success" @click="save">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- botão de cadastrar -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="amber darken-1" dark absolute bottom right fab fixed class="mb-10" v-bind="attrs" v-on="on"
          @click="dialog = true">
          <v-icon color="black">mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar Cliente</span>
    </v-tooltip>
  </v-card>
</template>

<script>
import { mapGetters} from 'vuex'
import DataTable from "../DataTableGeneric";
import { requiredField } from "../../Utils/InputValidation";
export default {
  data() {
    return {
      dialog: false,
      celMask: "",
      cnpjMask: "",
      requiredField,
      clientes: [],
      cliente: {},
      headers: [        
        { text: "Tipo", value: "tipo", width: 75 },
        { text: "ID", value: "id", show: false },
        { text: "Nome", value: "name" },
        { text: "Grupo", value: "groupName" },
        { text: "CNPJ", value: "cnpj", sortable: false, show: false },
        { text: "Endereço", value: "address", sortable: false },
        { text: "Telefone", value: "phone", sortable: false, show: false },
        { text: "Descrição", value: "description", sortable: false },
        { text: 'Ações', value: 'actions', sortable: false, width: 84 },
      ],
    };
  },
  components: {
    // Expansible,
    // Form,
    // List,
    // GroupPicker,
    DataTable,
  },
  computed:{
    ...mapGetters(["getClientTypesSelect","getClientTypeNameById"]),
    //para buscar somente o necessario
    workGroup: {
      get() {
        return this.$store.getters.workGroup;
      },
    },
  },
  methods: {
    get() {
      this.$store.dispatch("loadClientTypes");
      this.$http(`cliente`).then((resp) => {
        this.edicao = false;
        resp.data.forEach((ele) => {
          ele.ativo = ele.enabled ? "SIM" : "NÃO";
          ele.tipo = this.$store.getters.getClientTypeNameById(ele.typeCli)
        });
        this.clientes = resp.data;
      });
    },
    save() {
      if (!this.$refs.formCliente.validate())
        return;
      const metodo = this.cliente.id ? "put" : "post";
      const finalUrl = this.cliente.id ? `/${this.cliente.id}` : "";
      this.$http[metodo](`/cliente${finalUrl}`, this.cliente).then(() => {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showSuccessSnack", "Cliente salvo com Sucesso");
        this.get();
        this.close();
      });
    },
    close() {
      this.dialog = false;
      this.resetCliente();
    },
    resetCliente() {
      this.cliente = {
        id: null,
        name: "",
        cnpj: "",
        address: "",
        phone: "",
        groupId: 0,
        description: "",
        enabled: true,
        createdAt: "",
        updatedAt: "",
      };
    },
    editar(item) {
      this.cliente = item;
      this.dialog = true;
    },
    excluir(event) {
      this.$http.delete(`/cliente/${event.id}`).then(() => {
        this.get();
      });
    },
    config(cliente) {
      this.$store.dispatch("setCliente", cliente);
      this.$router.push({
        name: "configCliente",
      });
    },
    groupSelected() {
      this.get();
    },
  },
  created() {
    this.get();
    this.$store.dispatch("setWorkGroup", this.$store.state.groupId);
  },
  mounted() {
    //caso venha o parametro seto ele
    this.editar ? (this.cliente = { ...this.editar }) : this.cliente;
    //caso venha o parametro seto ele
    this.groupId ? (this.cliente.groupId = this.groupId) : this.cliente.groupId;
    //caso venha o parametro seto ele
    this.type ? (this.cliente.type = this.type) : this.cliente.type;

    this.$store.dispatch("loadClientTypes");
    this.resetCliente();
  },
  watch: {
    dialog(value) {
      if (value){
        if (!this.cliente.id){
          this.celMask = "(##) ####-#####"; // Cria a máscara na abertura do dialog p/ contornar bug do v-mask
          this.cnpjMask = "##.###.###/####-##";
        }
        this.$nextTick(() => {
          this.$refs.formCliente.resetValidation();
        });        
      } else {
        this.celMask = "";
        this.cnpjMask = "";
      }
    }
  },
};
</script>

<style>
</style>