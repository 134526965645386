import axios from 'axios'
export default {
    state: {
        dispositivos: [],
    },
    mutations: {
        setDispositivos(state, payload) {
            state.dispositivos = payload
        },
    },
    actions: {
        setDispositivos(context, payload) {
            context.commit('setDispositivos', payload)
        },
        loadDispositivos(context, payload) {
            const finalUrl = payload ? `?groupId=${payload}` : "";
            axios(`/dispositivo${finalUrl}`).then((resp) => {
                context.dispatch('setDispositivos', resp.data)
            });
        },
        saveDispositivo(context, payload) {
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/dispositivo${finalUrl}`, payload).then(() => {
                if (typeof payload.callback == 'function') {
                    payload.callback();
                }
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Dispositivo salvo com Sucesso");
                context.dispatch('loadDispositivos')
            });
        },
        deletDispositivo(context, payload) {
            axios.delete(`/dispositivo/${payload.id}`).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Dispositivo Deletado com Sucesso");
                context.dispatch('loadDispositivos')
            });
        },
    },
    getters: {
        getDispositivos(state) {
            return state.dispositivos
        },
        getDispositivoNameById: (state) => (id) => {
            if (!state.dispositivos) {
                return id
            }
            let gp = state.dispositivos.find(g =>
                g.id == id
            )
            if (!gp) return id
            return `${gp.id} - ${gp.name}` || id
        }
    },
}