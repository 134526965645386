<template>
  <div>
    <v-card>
      <v-card-title> Relatório de Veículos </v-card-title>
      <v-card-text>
        <!-- linha do filtro -->
        <v-card outlined>
          <v-card-text class="pb-0">
            <p class="text-subtitle-1 pt-1 pb-3">Filtros</p>
            <v-form ref="formReport">
              <!-- linha dos input -->
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model="relatorio.placa"
                    label="Placa"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field
                    v-model="relatorio.start"
                    label="Data Inicial"
                    required
                    outlined
                    type="datetime-local"
                    @input="$refs.relatorioEnd.validate()"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field
                    ref="relatorioEnd"
                    v-model="relatorio.end"
                    label="Data Final"
                    required
                    outlined
                    type="datetime-local"
                    :rules="[v => validaDataFinal(v) || 'A data final deve ser maior que a inicial']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-4">
            <MenuExportReport
              :disabled="!getReportVeiculos.length"
              :items="getReportVeiculos"
              module="veiculos"
              title="Relatório de Veículos"
              filename="relatorio_de_veiculos"
            />
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="loadReport">
              <v-icon class="mr-1">mdi-magnify</v-icon> Buscar Relatório
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
      <!-- linha da tabela -->
      <TableReportVeiculos
        :headers="headers"
        :items="getReportVeiculos"
        :report="true"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableReportVeiculos from "./TableReportVeiculos.vue";
import MenuExportReport from "@/components/Relatorios/MenuExportReport.vue";
export default {
  name: "RelatorioVeiculo",
  components: {
    TableReportVeiculos,
    MenuExportReport,
  },
  computed: {
    ...mapGetters(["getReportVeiculos"]),
  },
  data() {
    return { 
      headers: [
        { text: "Img. Entrada", value: "picturesEnter", sortable: false, filterable: false, align: "center", width: 70, },
        { text: "Placa", value: "placa" },
        { text: "Entrada", value: "createdAt" },
        { text: "Descrição Inicial", value: "description" },
        { text: "Img. Saída", value: "picturesExit", sortable: false, filterable: false, align: "center", width: 70, },
        { text: "Saída", value: "updatedAt" },
        { text: "Descrição Final", value: "descriptionExit" },
        { text: "Visitante", value: "visitanteName" },
        { text: "Doc. Visitante", value: "visitanteDoc" },
        { text: "Visitado", value: "visitadoName" },
        { text: "Permanência", value: "timeDif" },
      ],
      relatorio: {
        withPictures: true,
      },
    };
  },
  methods: {
    loadReport() {
      if (this.$refs.formReport.validate()){
        this.relatorio.groupId = this.$store.getters.getCliente.groupId;
        this.$store.dispatch("loadReportVeiculos", this.relatorio);
      }
    },
    validaDataFinal(dataFinal) { // função auxiliar pois este teste direto no rules não funciona corretamente
      return new Date(dataFinal) > new Date(this.relatorio.start);
    },
  },
  created() {
    this.$store.dispatch("setReportVeiculos", []);
    // pega a data atual e seta no relatorio
    let now = new Date();
    let onlyDate = now.toISOString().substring(0, 11);
    this.relatorio.start = onlyDate + "00:00";
    this.relatorio.end = onlyDate + "23:59";
  },
};
</script>

<style>
</style>