<template>
  <div class="cliente">
    <ClienteCard :cliente="cliente" @refresh="refresh" />
    <v-card>
      <v-tabs fixed-tabs background-color="success" show-arrows class="rounded-b-0">
        <v-tab><v-icon class="mr-1">mdi-boom-gate-up</v-icon>Postos ({{ postos.length }})</v-tab>
        <v-tab-item>
          <CliPosto :postos="postos" :groupId="cliente.groupId" @update="getPosto" />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-account-clock</v-icon>Turnos de Trabalho ({{ getTurnos.length }})</v-tab>
        <v-tab-item>
          <CliTurnos :cliente="cliente" :turnos="getTurnos" :postos="postos" @update="getPosto"/>
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-account-check</v-icon>Turnos em execução ({{ getTurnosExec.length }})</v-tab>
        <v-tab-item>
          <CliTurnosExec :cliente="cliente" :turnos="getTurnosExec" :postos="postos" @update="getPosto"/>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ClienteCard from "./components/ClienteCard";
import CliPosto from './components/CliPosto.vue';
import CliTurnos from './components/CliTurnos.vue';
import CliTurnosExec from "./components/CliTurnosExec.vue";
import { mapGetters } from "vuex";

export default {
  name: "ClienteJornadas",
  components: {
    ClienteCard,
    CliPosto,
    CliTurnos,
    CliTurnosExec,
},
  computed: {
    ...mapGetters(["getTurnos", "getTurnosExec"]),
    cliente: {
      get() {
        return this.$store.getters.getCliente;
      },
    },
  },
  data: () => {
    return {
      dispositivos: [],
      postos: [],
    };
  },
  methods: {
    getPosto() {
      this.$http(`posto2?groupId=${this.cliente.groupId}`).then((resp) => {
        resp.data.forEach((ele) => {
          ele.ativo = ele.enabled ? "Sim" : "Não";
        });
        this.postos = resp.data;
      });
    },
    refresh() {
      this.getPosto();
      this.$store.dispatch("loadTurnos", this.$store.getters.getCliente.id);
      this.$store.dispatch("loadExecTurnos", this.$store.getters.getCliente.groupId);
    },
  },
  created() {
    this.refresh();
  },
};
</script>