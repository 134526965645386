<template>
	<div>
		<v-dialog
			v-model="dialog"
			max-width="800"
			@click:outside="closeEvento"
			scrollable
		>
			<v-card>
				<v-card-title class="text-h5 pb-4">Detalhes do Evento</v-card-title>
				<v-card-text class="py-0">
					<v-card outlined>
						<v-card-title class="py-2 intermed-title"
							>Evento: {{ evento.eventName }}</v-card-title
						>
						<v-card-text>
							<v-row class="mb-3">
								<v-col class="py-2">
									<span
										><strong>Cliente:</strong>
										{{
											evento.clientId
												? getClienteNameById(evento.clientId).split(" - ")[1]
												: ""
										}}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Dispositivo:</strong>
										{{ verificaNomeDispositivo(evento.deviceId) }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Origem:</strong>
										{{ verificaUsuarios(evento.createdUserId) }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Responsável:</strong>
										{{
											getUserNameById(evento.finishedUserId).split(" - ")[1]
										}}</span
									>
								</v-col>
							</v-row>
							<v-row class="mb-3">
								<v-col class="py-2">
									<span
										><strong>Data:</strong>
										{{ formataData(evento.createdAt) }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Atendimento:</strong>
										{{ evento.startedAt ? formataData(evento.startedAt) : "" }}
									</span>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Finalização: </strong>
										{{
											evento.finishedAt ? formataData(evento.finishedAt) : ""
										}}
									</span>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Duração: </strong>
										{{
											calculaIntervalo()
										}}
									</span>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-card-title class="py-2 mb-2 intermed-title"
								>Resolução</v-card-title
							>
							<v-row>
								<v-col cols="12">
									<v-card outlined>
										<v-card-text>
											<v-row>
												<v-col cols="12">
													<span>
														{{
															evento.treatment
																? evento.treatment
																: "Sem resolução"
														}}</span
													>
												</v-col>
											</v-row>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
							<v-divider></v-divider>
						</v-card-text>
					</v-card>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="gerarRelatorioSolo(evento)">
						Exportar Relatório
					</v-btn>
					<v-btn color="success" text @click="closeEvento">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import PDF from "@/Utils/PDF";
import { mapGetters } from "vuex";
import { differenceDate } from "@/Utils/DateDiff";

export default {
	name: "DialogEvento",
	components: {},
	computed: {
		...mapGetters(["getUserNameById", "getClienteNameById"]),
	},
	data: () => ({
		dialog: false,
		dispositivos: [],
		evento: {
		},
	}),
	methods: {
		verificaUsuarios(id) {
			if (id == null || id == 0) {
				return "Gerado automaticamente";
			} else {
				return this.getUserNameById(id);
			}
		},
		verificaNomeDispositivo(id) {
			let dispositivo = this.dispositivos.find(
				(dispositivo) => dispositivo.id === id
			);
			if (dispositivo) {
				return dispositivo.name;
			}
			return "Dispositivo não encontrado";
		},
		openEvento(evento) {
			this.evento = { ...evento };
			this.dialog = true;
		},
		closeEvento() {
			this.dialog = false;
		},
		calculaIntervalo() {
			let intervalo = "";
			let now = new Date();
			if (this.evento.finishedAt) {
				now = this.evento.finishedAt;
			}
			intervalo = differenceDate(
				this.evento.createdAt, now
			);
			let format = `${intervalo.minute} minutos`
			if (intervalo.hour > 0) {
				format = `${intervalo.hour} horas e ${intervalo.minute} minutos`
			}
			if (intervalo.day > 0) {
				format = `${intervalo.day} dias, ${intervalo.hour} horas e ${intervalo.minute} minutos`
			}
			return format;
		},
		formataData(data) {
			if (data) {
				let dataFormatada = new Date(data);
				return dataFormatada.toLocaleString();
			}
		},
		gerarRelatorioSolo(item) {
			this.$store.dispatch("setTitleDialog", "Gerando o Relátorio");
			this.$store.dispatch("setShowDialog", true);
			let evento = { ...item };
			evento.clientId = this.getClienteNameById(evento.clientId).split(" - ")[1];
			evento.deviceId = this.verificaNomeDispositivo(evento.deviceId);
			evento.createdUserId = this.verificaUsuarios(evento.createdUserId);
			evento.finishedUserId = this.getUserNameById(evento.finishedUserId).split(" - ")[1];
			evento.duration = this.calculaIntervalo();
			let itens = [];
			itens.push(evento);
			PDF["eventoSolo"](itens, "Relatório de evento", "relatorio_de_evento.pdf");
		},
	},
	created() {
		// this.$store.dispatch(
		// 	"loadClientes",
		// 	this.$store.getters.getCliente.groupId ||
		// );
		// this.$http(
		// 	`dispositivo?groupId=${this.$store.getters.getCliente.groupId}`
		// ).then((resp) => {
		// 	this.dispositivos = resp.data.filter((item) => item.enabled);
		// });
	},
};
</script>

<style>
</style>