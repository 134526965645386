<template>
	<v-card>
		<v-card-title>Grupos de Pontos de Ronda</v-card-title>
		<v-card-text>
			<DataTable :headers="headers" :items="checkpointGroups">
				<template slot="item.checkpoints" slot-scope="{ item }">
					<span class="mr-1"
						>{{ item.checkpoints.length }} Pontos de ronda</span
					>
					<v-menu v-if="item.checkpoints.length > 0" open-on-hover top>
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
						</template>
						<v-list dense class="dropdown-list superdense">
							<v-list-item
								v-for="(item, index) in item.checkpoints"
								:key="index"
							>
								<v-list-item-content>
									<v-list-item-title
										><strong class="mr-2">{{ item.id }}</strong
										>{{ item.name }}</v-list-item-title
									>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>
				<template slot="item.actions" slot-scope="{ item }">
					<!--botão editar Grupo de Pontos -->
					<!-- <v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								@click="editarGrupoPontos({ ...item })"
								color="warning"
								class="mr-1"
								>mdi-pencil</v-icon
							>
						</template>
						<span>Editar : {{ item.name }}</span>
					</v-tooltip> -->
					<TooltipIcon
						:text="`Editar: ${item.name}`"
						icon="mdi-pencil"
						top="true"
						color="warning"
						@click="editarGrupoPontos({...item})"
					/>

					<!--botão excluir Grupo de Pontos -->
					<!-- <v-tooltip left>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-bind="attrs"
								v-on="on"
								@click="excluirGrupoPontos(item)"
								color="red"
								class="mr-1"
								>mdi-trash-can</v-icon
							>
						</template>
						<span>Excluir : {{ item.name }}</span>
					</v-tooltip> -->
					<TooltipIcon
						:text="`Excluir: ${item.name}`"
						icon="mdi-trash-can"
						top="true"
						color="red"
						@click="excluirGrupoPontos(item)"
					/>
				</template>
			</DataTable>

			<!--Dialog de cadastro de grupo de pontos-->
			<v-dialog v-model="dialogCadastro" persistent max-width="600" scrollable>
				<v-card>
					<v-card-title class="text-h5 pr-4 mb-2"
						>{{ checkpointGroup.id ? "Editar" : "Cadastrar" }} Grupo de Pontos
						de Ronda
						<v-spacer></v-spacer>
						<v-btn icon>
							<v-icon @click="dialogCadastro = false">mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text outlined class="pt-1 pb-0">
						<v-form ref="formGroup">
							<v-text-field
								v-model="checkpointGroup.name"
								label="Nome"
								required
								:rules="requiredField"
								outlined
							></v-text-field>

							<v-card outlined class="mb-4">
								<v-card-subtitle>Pontos de ronda</v-card-subtitle>
								<v-card-text class="pb-0">
									<v-autocomplete
										v-model="checkpointGroup.checkpoints"
										:items="checkpoints"
										hide-selected
										label="Selecionar Pontos de Ronda"
										multiple
										outlined
										item-text="name"
										return-object
										:rules="[
											checkpointGroup.checkpoints.length > 0 ||
												'Adicione pelo menos um Ponto de Ronda',
										]"
									>
										<template v-slot:selection
											><!-- vazio para não mostrar os items selecionados no combo --></template
										>
										<template v-slot:item="{ item }">
											<span
												><strong class="mr-3">{{ item.id }}</strong>
												{{ item.name }}</span
											>
										</template>
									</v-autocomplete>

									<div v-if="checkpointGroup.checkpoints.length">
										<v-data-table
											class="fixed-header-table draggable mt--1 mb-4"
											hide-default-footer
											hide-default-header
											id="table-checkpointsGroup"
										>
											<template v-slot:header>
												<thead>
													<tr>
														<th class="px-0" width="24"><!-- vazio --></th>
														<th class="text-center" width="38">Ordem</th>
														<th>ID</th>
														<th>Nome</th>
														<th class="text-center" width="70">Ações</th>
													</tr>
												</thead>
											</template>
											<template v-slot:body>
												<draggable
													:list="checkpointGroup.checkpoints"
													tag="tbody"
												>
													<tr
														v-for="(item, index) in checkpointGroup.checkpoints"
														:key="index"
													>
														<td class="px-0" width="24">
															<v-icon>mdi-drag-vertical</v-icon>
														</td>
														<td class="text-center">{{ index + 1 }}</td>
														<td>{{ item.id }}</td>
														<td>{{ item.name }}</td>
														<td class="text-center">
															<!-- botão excluir -->
															<v-tooltip left>
																<template v-slot:activator="{ on, attrs }">
																	<v-icon
																		v-bind="attrs"
																		v-on="on"
																		@click="
																			checkpointGroup.checkpoints.splice(
																				index,
																				1
																			)
																		"
																		color="red"
																		>mdi-trash-can</v-icon
																	>
																</template>
																<span>Excluir: {{ item.name }}</span>
															</v-tooltip>
														</td>
													</tr>
												</draggable>
											</template>
										</v-data-table>
										<p class="text-caption">
											Arraste os itens da tabela caso deseje alterar a ordem dos
											pontos.
										</p>
									</div>
								</v-card-text>
							</v-card>
						</v-form>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions class="py-4">
						<v-spacer></v-spacer>
						<v-btn text @click="closeCadastro()">Cancelar</v-btn>
						<v-btn color="success" @click="salvarGrupoPontos">Salvar</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-card-text>
		<!--Botão de cadastrar grupo de pontos-->
		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="amber darken-1"
					dark
					absolute
					bottom
					right
					fab
					fixed
					class="mb-10"
					v-bind="attrs"
					v-on="on"
					@click="dialogCadastro = true"
				>
					<v-icon color="black">mdi-plus</v-icon>
				</v-btn>
			</template>
			<span>Adicionar Grupo de pontos</span>
		</v-tooltip>
		<ConfirmDialog ref="confirmDialog" />
	</v-card>
</template>

<script>
import DataTable from "../../DataTableGeneric";
import { mapGetters } from "vuex";
import { requiredField } from "../../../Utils/InputValidation";
import ConfirmDialog from "../../ConfirmDialog.vue";
import draggable from "vuedraggable";
import TooltipIcon from '../../Template/TooltipIcon.vue';

export default {
	name: "cliCheckpointsGroup",
	components: { DataTable, ConfirmDialog, draggable, TooltipIcon },
	computed: {
		...mapGetters(["getCheckpointNameById"]),
	},
	props: {
		checkpointGroups: {
			type: Array,
			default: () => [],
		},
		checkpoints: {
			type: Array,
			default: () => [],
		},
	},
	data: () => {
		return {
			dialogCadastro: false,
			checkpointGroup: {
				id: null,
				name: "",
				checkpoints: [],
			},
			requiredField,
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Nome", value: "name" },
				{ text: "Pontos vinculados:", value: "checkpoints" },
				{
					text: "Ações",
					value: "actions",
					align: "center",
					sortable: false,
					filterable: false,
					width: 100,
				},
			],
		};
	},
	methods: {
		salvarGrupoPontos() {
			if (this.$refs.formGroup.validate()) {
				this.checkpointGroup.checkpointsIds =
					this.checkpointGroup.checkpoints.map((item) => item.id);
				this.$store.dispatch("saveCheckpointGroup", this.checkpointGroup);
				this.dialogCadastro = false;
				this.reset();
			}
		},
		editarGrupoPontos(item) {
			this.checkpointGroup = { ...item };
			this.dialogCadastro = true;
		},
		excluirGrupoPontos(item) {
			this.$refs.confirmDialog.open({
				title: "Excluir Grupo de Pontos",
				text: `Deseja excluir o grupo <strong>${item.name}</strong>?`,
				confirmCallback: () => {
					this.$store.dispatch("deleteCheckpointGroup", item);
				},
			});
		},
		reset() {
			this.checkpointGroup = {
				id: null,
				name: "",
				clientId: this.checkpointGroup.clientId,
				checkpoints: [],
			};
			this.$refs.formGroup.reset();
		},
		closeCadastro() {
			this.reset();
			this.dialogCadastro = false;
		},
	},
	mounted() {
		this.checkpointGroup.clientId = this.$store.getters.getCliente.id;
	},
};
</script>

<style>
#table-checkpointsGroup .v-data-table__wrapper {
	max-height: 232px;
}
</style>