<template>
  <div>
    <template v-if="!report">
      <v-card-title>Veículos Presentes</v-card-title>
      <v-card-subtitle>Clique na tabela para mais detalhes</v-card-subtitle>
    </template>
    <v-card-text>
      <DataTable
        :headers="headers"
        :items="items"
        @rowClick="openModalVeiculo"
      >

        <template v-slot:item.picturesEnter="{ item }">
          <CardImgList
            :items="item.picturesEnter"
            :width="70"
            :multiple="false"
          />
        </template>
        
        <template v-slot:item.picturesExit="{ item }">
          <CardImgList
            :items="item.picturesExit"
            :width="70"
            :multiple="false"
          />
        </template>

        <template v-slot:item.placa="{ item }">
          <span class="text-subtitle-1">{{ item.placa.toUpperCase() }}</span>
        </template>

        <template v-slot:item.createdAt="{ item }">
          {{ formataData(item.createdAt) }}
        </template>

        <template v-slot:item.updatedAt="{ item }">
          {{ formataData(item.updatedAt) }}
        </template>

        <template v-slot:item.visitaId="{ item }">
          <CardVisita v-model="item.visitaId" />
        </template>
        
        <template v-slot:item.visitanteName="{ item }">
          <div class="d-flex">
            <v-avatar size="70" class="mr-3">
              <v-img :src="item.visitantePic" lazy-src="/img/nopic.jpg"></v-img>
            </v-avatar>
            <div class="d-flex align-center">
              <span>{{ item.visitanteName }}</span>
            </div>
          </div>
        </template>
        
        <template v-slot:item.visitadoName="{ item }">
          <div class="d-flex">
            <v-avatar size="70" class="mr-3">
              <v-img :src="item.visitadoPic" lazy-src="/img/nopic.jpg"></v-img>
            </v-avatar>
            <div class="d-flex align-center">
              <span>{{ item.visitadoName }}</span>
            </div>
          </div>
        </template>
        
        <template v-slot:item.timeDif="{ item }">
          {{ getIntervalo(item.createdAt, item.updatedAt, item.perm) }}
        </template>

        <template v-slot:item.actions="{ item }" v-show="enableActions">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                large
                color="orange lighten-1"
                @click.stop="regOutVehicle(item)"
              >
                <v-icon>mdi-exit-to-app</v-icon>
              </v-btn>
            </template>
            <span> <strong>Registrar Saida</strong> {{ item.placa }}</span>
          </v-tooltip>
        </template>

      </DataTable>
    </v-card-text>
    <VeiculoDetails ref="modalVeiculoDetails" :actions="report ? false : actions" @out="regOutVehicle($event)" />
    <DialogVeiculoExit v-if="!report" ref="dialogVehicleExit" @closeVehicleDetails="closeVehicleDetails" />
  </div>
</template>

<script>
import CardVisita from "../Relatorios/CardVisita";
import CardImgList from "../Relatorios/CardImgList";
import DataTable from "../DataTableGeneric.vue";
import VeiculoDetails from "./VeiculoDetails.vue";
import DialogVeiculoExit from "../Efetivo/components/dialog_veiculo_exit.vue";
import { mapGetters } from "vuex";
import { differenceDate } from "@/Utils/DateDiff";

export default {
  name: "TableReportVeiculos",
  components: {
    CardVisita,
    CardImgList,
    DataTable,
    VeiculoDetails,
    DialogVeiculoExit,
  },
  props: {
    items: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    report: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    formataData(dataUTC) {
      return new Date(dataUTC).toLocaleString();
    },
    openModalVeiculo(item) {
      this.$refs.modalVeiculoDetails.open(item);
    },
    regOutVehicle(item) {
      this.$refs.dialogVehicleExit.open(item);
    },
    closeVehicleDetails() {
      this.$refs.modalVeiculoDetails.close();
    },
    getIntervalo(inicio, fim, perm) {
      let dif = differenceDate(inicio, fim);
      if (dif.day > 0)
        return `${dif.day}d, ${dif.hour}h, ${dif.minute}m`;
      else
        return perm;
    },
  },
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters(["getUsuario"]),
  },
};
</script>

<style>
</style>