const ExcelJS = require('exceljs');
const File = require('./File');
const dateDiff = require("@/Utils/DateDiff");

exports.visitasCond = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Morador", key: "visitadoName", width: 32 },
    { header: "Visitante", key: "visitanteName", width: 32 },
    { header: "Documento", key: "visitanteDoc", width: 32 },
    { header: "Emp. Visitante", key: "visitanteRef", width: 32 },
    { header: "Tipo da Visita", key: "visitaMotivo", width: 32 },
    { header: "Entrada", key: "FcreatedAt", width: 32 },
    { header: "Saída", key: "FupdatedAt", width: 32 },
    { header: "Descrição", key: "description", width: 32 },
    { header: "Permanencia", key: "perm", width: 32 },
  ];

  let rows = [...dataS];

  rows.forEach(e => {
    e.FcreatedAt = new Date(e.createdAt).toLocaleString();
    e.FupdatedAt = new Date(e.updatedAt).toLocaleString();
  });

  createExcel(rows, columns, title, filename);
}

exports.ocorrencias = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Ocorrencia", key: "name", width: 32 },
    { header: "Prioridade", key: "priority", width: 20 },
    { header: "Tipo", key: "type", width: 32 },
    { header: "Funcionário", key: "userId", width: 32 },
    { header: "Comunicante", key: "communicant", width: 32 },
    { header: "Cliente", key: "clientId", width: 32 },
    { header: "Data Fato", key: "factDate", width: 32 },
    { header: "Data Comunicação", key: "createdAt", width: 32 },
    { header: "Descrição", key: "description", width: 32 },
    { header: "Transcrição dos áudios", key: "transcriptions", width: 32 },
  ];

  let rows = [...dataS];

  rows.forEach(e => {
    e.createdAt = new Date(e.createdAt).toLocaleString();
    e.factDate = new Date(e.updatedAt).toLocaleString();
    e.transcriptions = e.transcriptions ? e.transcriptions.map(e => e.text).join('\n') : '';
  });

  createExcel(rows, columns, title, filename);
}

exports.rondas = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Id", key: "id", width: 32 },
    { header: "Ronda", key: "name", width: 20 },
    { header: "Data", key: "createdAt", width: 32 },
    { header: "Inicio", key: "start", width: 32 },
    { header: "Fim", key: "end", width: 32 },
    { header: "Usuário", key: "userId", width: 32 },
    { header: "Dispositivo", key: "deviceId", width: 32 },
    { header: "Cliente", key: "clientId", width: 32 },
    { header: "Finalizada", key: "finished", width: 32 },
    { header: "Checkpoints", key: "checkpoints", width: 32 },
  ];

  let rows = [...dataS];
  rows.forEach(e => {
    e.finished = e.finished ? "Sim" : "Não";
  });

  createExcel(rows, columns, title, filename);
}

exports.tarefas = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Id", key: "id", width: 32 },
    { header: "Tarefa", key: "tarefaId", width: 20 },
    { header: "Ambiente", key: "ambientId", width: 32 },
    { header: "Data", key: "createdAt", width: 32 },
    { header: "Inicio", key: "payload.horario.start", width: 32 },
    { header: "Fim", key: "payload.horario.end", width: 32 },
    { header: "Usuário", key: "userId", width: 32 },
    { header: "Dispositivo", key: "deviceId", width: 32 },
    { header: "Cliente", key: "clientId", width: 32 },
    { header: "Finalizada", key: "finished", width: 32 },
    { header: "Atividades", key: "activities", width: 32 },
  ];

  let rows = [...dataS];
  rows.forEach(e => {
    e.finished = e.finished ? "Sim" : "Não";
    e.activities = e.payload.activities.map(e => `${e.name} - ${new Date(e.checkedAt).toLocaleString()}`).join(',\n');
  });

  createExcel(rows, columns, title, filename);
}

exports.eventos = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Id", key: "id", width: 10 },
    { header: "Evento", key: "eventName", width: 20 },
    { header: "Cliente", key: "clientId", width: 32 },
    { header: "Origem", key: "createdUserId", width: 32 },
    { header: "Dispositivo", key: "deviceId", width: 32 },
    { header: "Responsável", key: "finishedUserId", width: 32 },
    { header: "Data", key: "createdAt", width: 32 },
    { header: "Atendimento", key: "startedAt", width: 32 },
    { header: "Finalização", key: "finishedAt", width: 32 },
    { header: "Resolução", key: "treatment", width: 32 },
    { header: "Duração", key: "duration", width: 32 },
  ];

  let rows = [...dataS];
  rows.forEach(e => {
    e.createdAt = new Date(e.createdAt).toLocaleString();
    e.startedAt = new Date(e.startedAt).toLocaleString();
    e.finishedAt = new Date(e.finishedAt).toLocaleString();
  });

  createExcel(rows, columns, title, filename);
}

exports.visitasEmp = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Visitado", key: "visitadoName", width: 32 },
    { header: "Visitante", key: "visitanteName", width: 32 },
    { header: "Documento", key: "visitanteDoc", width: 32 },
    { header: "Entrada", key: "FcreatedAt", width: 32 },
    { header: "Saída", key: "FupdatedAt", width: 32 },
    { header: "Descrição", key: "description", width: 32 },
    { header: "Permanencia", key: "perm", width: 32 },
  ];

  let rows = [...dataS];

  rows.forEach(e => {
    e.FcreatedAt = new Date(e.createdAt).toLocaleString();
    e.FupdatedAt = new Date(e.updatedAt).toLocaleString();
  });

  createExcel(rows, columns, title, filename);
}

exports.veiculos = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Placa", key: "placa", width: 16 },
    { header: "Entrada", key: "FcreatedAt", width: 32 },
    { header: "Descr. Inicial", key: "description", width: 32 },
    { header: "Saída", key: "FupdatedAt", width: 32 },
    { header: "Descr. Final", key: "descriptionExit", width: 32 },
    { header: "Visitante", key: "visitanteName", width: 32 },
    { header: "Doc. Visitante", key: "visitanteDoc", width: 32 },
    { header: "Visitado", key: "visitadoName", width: 32 },
    { header: "Permanência", key: "Fperm", width: 16 },
  ];

  let rows = [...dataS];

  rows.forEach(e => {
    e.FcreatedAt = new Date(e.createdAt).toLocaleString();
    e.FupdatedAt = new Date(e.updatedAt).toLocaleString();
    e.Fperm = getIntervalo(e.createdAt, e.updatedAt);
  });

  createExcel(rows, columns, title, filename);
}

exports.rondasVirtuais = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "ID", key: "id", width: 32 },
    { header: "Ronda", key: "name", width: 32 },
    { header: "Data", key: "createdAt", width: 32 },
    { header: "Horário", key: "horario", width: 32 },
    { header: "Usuário", key: "userId", width: 32 },
    { header: "Cliente", key: "clientId", width: 32 },
    { header: "Finalizada", key: "finished", width: 32 },
    { header: "Observação", key: "observacao", width: 32 },
    { header: "Questões", key: "questions", width: 32 },
    { header: "Tipo", key: "tipo", width: 32 },
  ];

  let rows = [...dataS];

  function verificaResposta(question) {
    if (question.type == 'boolean') {
      return question.value ? 'Sim' : 'Não';
    }
    else return question.value;
  }

  rows.forEach(e => {
    e.observacao = e.payload.observacao ? e.payload.observacao : '-';
    e.createdAt = new Date(e.createdAt).toLocaleString().split(',')[0];
    e.questions = e.payload.questions ? e.payload.questions.map(item => `${item.name}: ${verificaResposta(item)}`).join(`, `) : '-';
    e.finished = e.finished ? "Sim" : "Não";
    e.tipo = e.horarioId ? 'Execução agendada' : 'Execução Extra';
  });
  createExcel(rows, columns, title, filename);
}

exports.documents = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Nome do Documento", key: "docName", width: 32 },
    { header: "Cliente", key: "clientName", width: 32 },
    { header: "Grupo", key: "groupName", width: 32 },
    { header: "Usuário", key: "userName", width: 32 },
    { header: "Primeira Visualização", key: "createdAt2", width: 32 },
    { header: "Ultima Visualização", key: "updatedAt2", width: 32 },
  ];

  let rows = [...dataS];

  rows.forEach(e => {
    e.createdAt2 = new Date(e.createdAt).toLocaleString();
    e.updatedAt2 = new Date(e.updatedAt).toLocaleString();
  });

  createExcel(rows, columns, title, filename);
}

exports.jornadas = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Turno", key: "turnoName", width: 32 },
    { header: "Início", key: "createdAt", width: 32 },
    { header: "Fim", key: "updatedAt", width: 32 },
    { header: "Intervalo", key: "interval", width: 32 },
    { header: "Horário da jornada", key: "schedule", width: 32 },
    { header: "Intervalo da jornada", key: "intervalTurno", width: 32 },
    { header: "Tolerância (min)", key: "turnoTolerance", width: 32 },
    { header: "Posto", key: "postoName", width: 32 },
    { header: "Funcionário", key: "userName", width: 32 },
    { header: "Dispositivo", key: "deviceName", width: 32 },
  ];

  let rows = [...dataS];

  createExcel(rows, columns, title, filename);
}

exports.fleetSimple = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Motorista", key: "Fdriver", width: 32 },
    { header: "Placa", key: "Fplate", width: 16 },
    { header: "Modelo", key: "Fmodel", width: 32 },
    { header: "Descrição", key: "description", width: 32 },
    { header: "Sentido", key: "Fdirection", width: 16 },
    { header: "Início", key: "FcreatedAt", width: 32 },
    { header: "Fim", key: "FupdatedAt", width: 32 },
    { header: "Tempo", key: "Fperm", width: 16 },
    { header: "km Inicial", key: "kmStart", width: 16 },
    { header: "km Final", key: "kmEnd", width: 16 },
    { header: "km Percorrido", key: "fKmDiff", width: 16 },
  ];

  let rows = [...dataS];

  rows.forEach(e => {
    e.Fdriver = e.driver.name;
    e.Fplate = e.vehicle.plate;
    e.Fmodel = e.vehicle.model;
    e.Fdirection = e.direction ? "Entrada" : "Saída";
    e.FcreatedAt = new Date(e.createdAt).toLocaleString();
    e.FupdatedAt = new Date(e.updatedAt).toLocaleString();
    e.Fperm = getIntervalo(e.createdAt, e.updatedAt);
    e.fKmDiff = e.kmEnd - e.kmStart;
  });

  createExcel(rows, columns, title, filename);
}

exports.fleetSimpleConv = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Motorista", key: "Fdriver", width: 32 },
    { header: "Placa", key: "Fplate", width: 16 },
    { header: "Modelo", key: "Fmodel", width: 32 },
    { header: "Descrição", key: "description", width: 32 },
    { header: "Sentido", key: "Fdirection", width: 16 },
    { header: "Início", key: "FcreatedAt", width: 32 },
    { header: "Fim", key: "FupdatedAt", width: 32 },
    { header: "Tempo", key: "Fperm", width: 16 },
    { header: "km Inicial", key: "kmStart", width: 16 },
    { header: "km Final", key: "kmEnd", width: 16 },
    { header: "km Percorrido", key: "fKmDiff", width: 16 },
  ];

  let rows = [...dataS];

  rows.forEach(e => {
    e.Fdriver = e.drivers[0] ? e.drivers[0].name : "Sem Motorista";
    e.Fplate = e.fleetVehicles[0] ? e.fleetVehicles[0].plate : "Sem Veículo";
    e.Fmodel = e.fleetVehicles[0] ? e.fleetVehicles[0].model : "Sem Modelo";
    e.Fdirection = e.direction ? "Entrada" : "Saída";
    e.FcreatedAt = new Date(e.createdAt).toLocaleString();
    e.FupdatedAt = new Date(e.updatedAt).toLocaleString();
    e.Fperm = getIntervalo(e.createdAt, e.updatedAt);
    e.fKmDiff = e.kmEnd - e.kmStart;
  });

  createExcel(rows, columns, title, filename);
}

exports.fleetMulti = async (dataS = [], title = "", filename = "") => {
  let columns = [
    { header: "Motoristas Iniciais", key: "Fdrivers", width: 32 },
    { header: "Veículos Iniciais", key: "Fvehicles", width: 32 },
    { header: "Descrição Inicial", key: "Fmodel", width: 32 },
    { header: "Motoristas Finais", key: "FfinalDrivers", width: 32 },
    { header: "Veículos Finais", key: "FfinalVehicles", width: 32 },
    { header: "Descrição Final", key: "description", width: 32 },
    { header: "Sentido", key: "Fdirection", width: 16 },
    { header: "Início", key: "FcreatedAt", width: 32 },
    { header: "Fim", key: "FupdatedAt", width: 32 },
    { header: "Tempo", key: "Fperm", width: 16 },
    { header: "km Inicial", key: "kmStart", width: 16 },
    { header: "km Final", key: "kmEnd", width: 16 },
    { header: "km Percorrido", key: "fKmDiff", width: 16 },
  ];

  let rows = [...dataS];

  rows.forEach(e => {
    e.Fdrivers = e.drivers.map(e => e.name).join(',\n');
    e.Fvehicles = e.fleetVehicles.map(e => `${e.plate} - ${e.model}`).join(',\n');
    e.FfinalDrivers = e.finalDrivers.map(e => e.name).join(',\n');
    e.FfinalVehicles = e.finalFleetVehicles.map(e => `${e.plate} - ${e.model}`).join(',\n');
    e.Fdirection = e.direction ? "Entrada" : "Saída";
    e.FcreatedAt = new Date(e.createdAt).toLocaleString();
    e.FupdatedAt = new Date(e.updatedAt).toLocaleString();
    e.Fperm = getIntervalo(e.createdAt, e.updatedAt);
    e.fKmDiff = e.kmEnd - e.kmStart;
  });

  createExcel(rows, columns, title, filename);
}

async function createExcel(rows, columns, title, filename) {
  let data = [{ columns, rows, title }]


  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'STV Segurança';
  workbook.created = new Date();

  try {
    if (!Array.isArray(data)) {
      throw 'data not correct'
    }
    let contaisData = false
    data.forEach(e => {
      if (!e.rows.length) {
        return
      }
      contaisData = true
      const worksheet = workbook.addWorksheet(e.nameSheet);

      worksheet.columns = e.columns

      if (Array.isArray(e.rows)) {
        e.rows.forEach(row => {
          let rowD = {}
          let maxLines = 0;
          for (let valor in row) {
            rowD[valor] = row[valor];
            // obtém o número máximo de linhas de texto das rows p/ setar a altura da row
            if (typeof row[valor] === 'string') {
              let lineBreaks = row[valor].split(/\r\n|\r|\n/).length;
              if (lineBreaks > maxLines)
                maxLines = lineBreaks;
            }
          }
          worksheet.addRow(rowD).height = maxLines > 1 ? maxLines * 15 : undefined;
        });
      }
      worksheet.getRow(1).font = { size: 12, bold: true };
      worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center' };
    })


    if (contaisData) {
      let buffer = await workbook.xlsx.writeBuffer();
      let blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
      File.downloadFile(blob, filename);
    } else {
      return null
    }

  } catch (error) {
    console.log(error);
    return null
  }
}

function getIntervalo(inicio, fim) {
  let dif = dateDiff.differenceDate(inicio, fim);
  let res;
  if (dif.day > 0)
    res = `${dif.day}d, ${dif.hour}h, ${dif.minute}m`;
  else
    res = `${dif.hour < 10 ? "0" + dif.hour : dif.hour}:${dif.minute < 10 ? "0" + dif.minute : dif.minute}:${dif.second < 10 ? "0" + dif.second : dif.second}`;
  return res;
}