exports.ipField = [
  value => !!value || 'Obrigatório',
  value => (value || '').length <= 15 || 'Máx. 15 caracteres',
  value => {
    const pattern = /^((1?\d{1,2}|2([0-4]\d|5[0-5]))\.){3}(1?\d{1,2}|2([0-4]\d|5[0-5]))$|^$/
    return pattern.test(value) || 'IP inválido'
  },
];

exports.portField = [
  value => !!value || 'Obrigatório',
  value => (value || '').length <= 5 || 'Máx. 5 numeros',
  value => (value || '').length > 1 || 'Mín. 2 numeros',
  value => {
    const pattern = /^[0-9]+$/
    return pattern.test(value) || 'Porta inválida'
  },
];

exports.requiredField = [
  value => !!value || 'Obrigatório',
];

exports.reqFieldMinLength = (len) => [
  value => !!value || 'Obrigatório',
  value => (value || '').length >= len || `Mín. ${len} caracteres`,
];

exports.reqFieldMinMaxLength = (min, max) => [
  value => !!value || 'Obrigatório',
  value => (value || '').length >= min || `Mín. ${min} caracteres`,
  value => (value || '').length <= max || `Max. ${max} caracteres`,
];

exports.portFieldNotRequired = [
  value => {
    const pattern = /^[0-9]+$/
    return pattern.test(value) || 'Porta inválida'
  },
];