<template>
	<div>
		<template v-if="!report">
			<v-card-title class="pt-6">Rondas</v-card-title>
			<v-card-subtitle>Clique na tabela para mais detalhes</v-card-subtitle>
		</template>
		<v-card-text>
			<DataTableGeneric
				:headers="headers"
				:items="items"
				@headersChanged="$emit('headersChanged', $event)"
				@rowClick="openModalRondas"
			>
				<template v-slot:item.finished="{ item }">
					<span>{{ item.finished ? "Sim" : "Não" }}</span>
				</template>
				
				<template v-slot:item.rondaId="{ item }">
					<span>{{ verificaNomeRonda(item.rondaId)}}</span>
				</template>

				<template v-slot:item.createdAt="{ item }">
					<span>{{ getDia(item.createdAt) }}</span>
				</template>
				<template v-slot:item.payload.checkpoints="{ item }">
					<span class="mr-1"
						>{{ verificaPonto(item.payload.checkpoints) }}</span
					>
					<v-menu open-on-hover top offset-y v-if="item.payload.checkpoints.length > 1">
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on">mdi-eye</v-icon>
						</template>
						<v-list dense>
							<v-list-item
								v-for="(item, index) in item.payload.checkpoints"
								:key="index"
							>
								<v-list-item-content>
									<v-list-item-title>{{ item.name }}</v-list-item-title>
									<v-list-item-subtitle>{{
										formataData(item.checkedAt)
									}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</template>

				<template v-slot:item.userId="{ item }">
					<span>{{ getUserNameById(item.userId).split(' - ')[1] }}</span>
				</template>

				<template v-slot:item.syncAt="{ item }">
					<span>{{ item.payload.syncAt ? new Date(item.payload.syncAt).toLocaleString() : '' }}</span>
				</template>

				<template v-slot:item.clientId="{ item }">
					<span>{{ getClienteNameById(item.clientId).split(' - ')[1] }}</span>
				</template>
			</DataTableGeneric>
		</v-card-text>

		<DialogRonda ref="dialogRonda" />
		<ConfirmDialog v-if="!report" ref="confirmDialog" />
	</div>
</template>

<script>
import DataTableGeneric from "../DataTableGeneric";
import ConfirmDialog from "../ConfirmDialog";
//import { differenceDate } from "@/Utils/DateDiff";
import DialogRonda from "./DialogRonda.vue";
//import PDF from "@/Utils/PDF";
import { mapGetters } from "vuex";

export default {
	name: "TableRondas",
	computed: {
		...mapGetters(["getClienteNameById", "getUserNameById", "getRondas"]),
	},
	components: {
		DataTableGeneric,
		ConfirmDialog,
		DialogRonda,
	},
	data: () => {
		return {
			search: "",
			selected: [],	
		};
	},
	props: {
		items: {
			type: Array,
		},
		headers: {
			type: Array,
		},
		report: {
			type: Boolean,
			default: false,
		},
		actions: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		formataData(dataUTC) {
			return new Date(dataUTC).toLocaleString();
		},
		getDia(dataUTC) {
			return new Date(dataUTC).toLocaleDateString();
		},
		openModalRondas(item) {
			this.$refs.dialogRonda.openRonda(item);
		},
		verificaNomeRonda(id){
			let ronda = this.getRondas.find(ronda => ronda.id == id)
			return ronda ? ronda.name : 'Ronda não encontrada'
		},
		verificaPonto(pontos){
			let name = ''
			if(pontos){
				switch (pontos.length) {
					case 1:
						name = pontos[0].name
						break;
				
					default:
						name = pontos.length + ' Pontos Registrados'
						break;
				}
			}
			return name
		}
	},
	created() {
		// this.$store.dispatch("loadRondas", this.$store.getters.getCliente.id);
		// this.$store.dispatch(
		// 	"loadClientes",
		// 	this.$store.getters.getCliente.groupId
		// );
	},
	watch: {},
};
</script>

<style scoped>
.intermed-title {
	font-size: 1.2rem;
	font-weight: bold;
}
</style>