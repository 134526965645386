<template>
  <div class="cliente">
    <ClienteCard :cliente="cliente" @refresh="refresh" />
    <v-card>
      <v-tabs fixed-tabs background-color="success" show-arrows class="rounded-b-0">
        <v-tab><v-icon class="mr-1">mdi-flashlight</v-icon>Rondas ({{ getRondas.length }})</v-tab>
        <v-tab-item>
          <CliRondas :rondas="getRondas" :checkpoints="getCheckpoints" :checkpointGroups="getCheckpointGroups" :dispositivos="dispositivos" />
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-map-marker</v-icon>Pontos de Ronda ({{ getCheckpoints.length }})</v-tab>
        <v-tab-item>
          <CliCheckpoints :checkpoints="getCheckpoints"/>
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-map-marker-multiple</v-icon>Grupos de Pontos ({{ getCheckpointGroups.length }})</v-tab>
        <v-tab-item>
          <CliCheckpointsGroup :checkpointGroups="getCheckpointGroups" :checkpoints="getCheckpoints"/>
        </v-tab-item>
        <v-tab><v-icon class="mr-1">mdi-eye</v-icon>Rondas Virtuais ({{ getRondasVirtuais.length }})</v-tab>
        <v-tab-item>
          <CliRondasVirtuais :rondas="getRondasVirtuais"/>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import ClienteCard from "./components/ClienteCard";
import CliRondas from "./components/CliRondas";
import CliCheckpoints from "./components/CliCheckpoints.vue";
import CliCheckpointsGroup from "./components/CliCheckpointsGroup.vue";
import { mapGetters } from "vuex";
import CliRondasVirtuais from './components/CliRondasVirtuais.vue';

export default {
  name: "ClienteRondas",
  components: {
    ClienteCard,
    CliRondas,
    CliCheckpoints,
    CliCheckpointsGroup,
    CliRondasVirtuais,
},
  computed: {
    ...mapGetters(["getRondas", "getCheckpoints", "getCheckpointGroups", "getDispositivos", "getRondasVirtuais"]),
    cliente() {
        return this.$store.getters.getCliente;
    },
    dispositivos() {
      return this.getDispositivos.filter((item) => item.enabled);
    },
  },
  data: () => {
    return {
    };
  },
  methods: {
    refresh() {
      this.$store.dispatch("loadRondas", this.$store.getters.getCliente.id);
      this.$store.dispatch("loadCheckpoints", this.$store.getters.getCliente.id);
      this.$store.dispatch("loadCheckpointGroups", this.$store.getters.getCliente.id);
      this.$store.dispatch("loadDispositivos", this.$store.getters.getCliente.groupId );
      this.$store.dispatch("loadRondasVirtuais", this.$store.getters.getCliente.id);
    },
  },
  created() {
    this.refresh();
  },
};
</script>