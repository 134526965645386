<template>
  <div class="userCRUD">
    <v-card class="mb-1" v-if="formUsr || grupo.id">
      <v-card-title>
        {{ grupo.id ? "Edição Grupo" : "Cadastro de Grupo" }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="grupo.name"
          :counter="255"
          label="Nome"
          required
        ></v-text-field>

        <SelectGroup v-model="grupo.groupId" />

        <v-textarea
          v-model="grupo.description"
          name="input-7-1"
          :counter="255"
          label="Descrição"
        ></v-textarea>

        <v-checkbox v-model="grupo.enabled" label="Ativo" required></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" class="mr-4" @click="clearObj"
          >Limpar Campos</v-btn
        >
        <v-btn color="success" class="mr-4" @click="save(grupo)">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
    <div class="div d-flex justify-end ma-2" v-else>
      <v-btn color="success" @click="formUsr = true">Cadastrar</v-btn>
    </div>
    <v-card>
      <v-card-text>
        <div class="tbl mt-1">
          <Table
            :items="lista"
            :headers="headers"
            :enablePicture="true"
            @edit="editar($event)"
            @delete="delet($event)"
          />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Table from "../../TableGeneric";
import SelectGroup from "../../Template/SelectGroupForm";

export default {
  data() {
    return {
      formUsr: false,
      grupo: {
        id: null,
      },
    };
  },
  computed: {
    lista: {
      get() {
        let groups = this.$store.getters.getGroups;
        return groups.map((e) => {
          return {
            ...e,
            groupName: this.findGroupName(e.groupId),
          };
        });
      },
    },
    headers: {
      get() {
        return this.$store.getters.getGrupoHeaders;
      },
    },
  },
  components: {
    Table,
    SelectGroup,
  },
  methods: {
    get() {
      this.edicao = false;
      this.$store.dispatch("loadAllGrupos");
    },
    save(objeto) {
      this.$store.dispatch("saveGrupo", objeto);
      setTimeout(() => {
        this.$store.dispatch("loadAllGrupos");
      }, 500);
    },
    editar(event) {
      this.grupo = event;
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
    delet(event) {
      this.$store.dispatch("deletGrupo", event);
      this.get();
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
    findGroupName(id) {
      let groups = this.$store.getters.getGroups;
      let gp = groups.find((e) => e.id == id);
      return gp ? `${gp.id} - ${gp.name}` : "";
    },
  },
  created() {
    this.get();
  },
};
</script>

<style>
</style>