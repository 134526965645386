const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
const axios = require('axios');
const store = require('../store/index');
const dateDiff = require("@/Utils/DateDiff");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// import store from '../store/index'

// steps para gerar o pdf
let steps = 1;
let sAtual = 0;

exports.qrcode = async (imgs = [], options) => {
  // pegando a quantidade de linhas
  let lines = Math.ceil(imgs.length / 3);
  // criando o corpo do pdf
  let body = [];
  // iterando sobre as linhas
  for (let index = 0; index < lines; index++) {
    // criando a linha
    let line = [];
    // iterando sobre os itens da linha
    for (let indexS = 0; indexS < 3; indexS++) {
      let subIndex = indexS + index * 3;
      let img = imgs[subIndex];
      if (img) {
        line.push({
          image: img,
          width: 180, // empírico, ficando exatamente do tamanho dos antigos
          alignment: 'center',
          marginBottom: 5
        });
      } else {
        line.push({});
      }
    }
    // adicionando a linha no corpo do pdf
    body.push(line);
  }

  let dd = {
    content: [
      {
        table: {
          widths: ['*', '*', '*'],
          //heights: 308,
          body: [
            ...body
          ],
        },
        layout: 'noBorders'
      },
    ],
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 20, 20, 20],
  }
  let pdf = pdfMake.createPdf(dd);
  switch (options.method) {
    case 'download': pdf.download(`${options.filename || "QRcodes"}.pdf`); break;
    case 'print': pdf.print(); break;
    default: pdf.open();
  }
}

exports.visitasCond = async (data = [], title = "", filename = "", img = false) => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "Morador", style: "tableHeader", alignment: "center", },
    { text: "Visitante", style: "tableHeader", alignment: "center", },
    { text: "Documento", style: "tableHeader", alignment: "center" },
    { text: "Emp. Visitante", style: "tableHeader", alignment: "center" },
    { text: "Tipo da Visita", style: "tableHeader", alignment: "center" },
    { text: "Entrada", style: "tableHeader", alignment: "center" },
    { text: "Saída", style: "tableHeader", alignment: "center" },
    { text: "Descrição", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Permanencia", style: "tableHeader", alignment: "center" },
  ];

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  // montando o corpo
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      body.push([
        {
          columns: [
            img ? { image: await getImgBase64(e.visitadoPic), width: 50 } : { text: '', width: 'auto' },
            { text: e.visitadoName, alignment: "center", style: "tableBody" },
          ]
        },
        {
          columns: [
            img ? { image: await getImgBase64(e.visitantePic), width: 50 } : { text: '', width: 'auto' },
            { text: e.visitanteName, alignment: "center", style: "tableBody" },
          ]
        },
        { text: e.visitanteDoc, alignment: "center", style: "tableBody" },
        { text: e.visitanteRef, alignment: "center", style: "tableBody" },
        { text: e.visitaMotivo, alignment: "center", style: "tableBody" },
        { text: new Date(e.createdAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: new Date(e.updatedAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: e.description, alignment: "center", style: "tableBody" },
        { text: e.perm, alignment: "center", style: "tableBody" },
      ]);
      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }
  }
  createReportPDF(body, title, filename);
};

exports.ocorrenciaSolo = async (data = [], title = "", filename = "") => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  let ocorrencia = data[0];
  // inserindo o header
  const headers = [
    { text: ocorrencia.name, style: "Header", alignment: "center", },
  ]

  let body = [];
  let transcricoes = "";
  for (let i = 0; i < ocorrencia.audios.length; i++) {
    let text = ocorrencia.transcriptions ? ocorrencia.transcriptions[i].text : "Não foi possível transcrever o áudio";
    if (text == '') text = "Não foi possível transcrever o áudio";
    transcricoes += "Transcrição do áudio " + (i + 1) + ": " + text + "\n\n";
  }
  let divisor = "_________________________________________________________________________________________________________________";

  // montando o corpo
  body.push([
    { text: ocorrencia.type, alignment: "center", style: "Body" },
    { text: divisor, alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    headers,
    { text: " ", alignment: "center", style: "Body" },
    {
      columns: [
        { text: "Cliente: " + ocorrencia.clientId, style: "Body" },
        { text: "Funcionário: " + ocorrencia.userId, style: "Body" },
        { text: "Data do Fato: " + new Date(ocorrencia.factDate).toLocaleString(), style: "Body", width: "auto" },
      ]
    },
    { text: " ", alignment: "center", style: "Body" },
    {
      columns: [
        { text: "Prioridade: " + ocorrencia.priority, style: "Body" },
        { text: "Comunicante: " + ocorrencia.communicant, style: "Body" },
        { text: "Data Comunicação: " + new Date(ocorrencia.createdAt).toLocaleString(), style: "Body", width: "auto" },
      ]
    },
    { text: divisor, alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    { text: "Descrição: ", alignment: "center", style: "Subtitle" },
    { text: " ", alignment: "center", style: "Body" },
    { text: ocorrencia.description, style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    { text: divisor, alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    { text: "Transcrição dos áudios", alignment: "center", style: "Subtitle" },
    { text: " ", alignment: "center", style: "Body" },
    { text: transcricoes, style: "Body" },
    { text: divisor, alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    { text: "Imagens", alignment: "center", style: "Subtitle" }
  ]);

  for (let i = 0; i < ocorrencia.pictures.length; i++) {
    body.push([
      { text: " ", alignment: "center", style: "Body" },
      { image: await getImgBase64(ocorrencia.pictures[i]), width: 500, alignment: "center", style: "Imagem" },
      { text: " ", alignment: "center", style: "Body" },
    ]);
  }
  createReportSolo(body, title, filename, 'portrait', ocorrencia.id, 'Ocorrência');
};

exports.eventoSolo = async (data = [], title = "", filename = "") => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  let evento = data[0];
  // inserindo o header
  const headers = [
    { text: evento.eventName, style: "Header", alignment: "center", },
  ]

  let body = [];

  let divisor = "_________________________________________________________________________________________________________________";

  // montando o corpo
  body.push([
    { text: divisor, alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    headers,
    { text: " ", alignment: "center", style: "Body" },
    {
      columns: [
        { text: "Cliente: " + evento.clientId, style: "Body" },
        { text: "Dispositivo: " + evento.deviceId, style: "Body" },
        { text: "Origem: " + evento.createdUserId, style: "Body" },
        { text: "Responsável: " + evento.finishedUserId, style: "Body", width: "auto" },
      ]
    },
    { text: " ", alignment: "center", style: "Body" },
    {
      columns: [
        { text: "Data: " + new Date(evento.createdAt).toLocaleString(), style: "Body" },
        { text: "Atendimento: " + new Date(evento.startedAt).toLocaleString(), style: "Body" },
        { text: "Finalização: " + new Date(evento.finishedAt).toLocaleString(), style: "Body", width: "auto" },
        { text: "Duração: " + evento.duration, style: "Body", width: "auto" },
      ]
    },
    { text: divisor, alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    { text: "Descrição: ", alignment: "center", style: "Subtitle" },
    { text: " ", alignment: "center", style: "Body" },
    { text: evento.treatment, style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
  ]);

  createReportSolo(body, title, filename, 'portrait', evento.id, 'Evento');
};

exports.rondaSolo = async (data = [], title = "", filename = "") => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  let ronda = data[0];
  // inserindo o header
  const headers = [
    { text: ronda.name, style: "Header", alignment: "center", },
  ]

  let body = [];
  let divisor = "_________________________________________________________________________________________________________________";

  // montando o corpo
  body.push([
    { text: divisor, alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    headers,
    { text: " ", alignment: "center", style: "Body" },
    {
      columns: [
        { text: "Cliente: " + ronda.clientId, style: "Body" },
        { text: "Dispositivo: " + ronda.deviceId, style: "Body" },
        { text: "Funcionário: " + ronda.userId, style: "Body", width: "auto" },
      ]
    },
    { text: " ", alignment: "center", style: "Body" },
    {
      columns: [
        { text: "Data: " + ronda.createdAt, style: "Body" },
        { text: "Inicio: " + ronda.payload.horario.start, style: "Body" },
        { text: "Fim: " + ronda.payload.horario.end, style: "Body" },
        { text: ronda.finished ? 'Ronda finalizada' : 'Ronda não finalizada', style: "Body", width: "auto" },
      ]
    },
    { text: " ", alignment: "center", style: "Body" },
    { text: divisor, alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    { text: "Pontos de Ronda", alignment: "center", style: "Subtitle" },
    { text: " ", alignment: "center", style: "Body" },
  ]);

  for (let i = 0; i < ronda.payload.checkpoints.length; i++) {
    let texto = `${ronda.payload.checkpoints[i].name}\n
    Registrado em: ${new Date(ronda.payload.checkpoints[i].checkedAt).toLocaleString()}\n
    Intervalo entre pontos: ${ronda.payload.checkpoints[i].interval}\n`;
    body.push([
      { text: " ", alignment: "center", style: "Body" },
      {
        columns: [
          { text: texto, alignment: "left", style: "Body" },
          { image: await getImgBase64(ronda.payload.checkpoints[i].picture), width: 150, alignment: "left", style: "Imagem" },
        ]
      },
      { text: divisor, alignment: "center", style: "Body" },
    ]);
  }
  createReportSolo(body, title, filename, 'portrait', ronda.id, 'Ronda');
};

exports.tarefaSolo = async (data = [], title = "", filename = "") => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  let tarefa = { ...data[0] };
  // inserindo o header
  const headers = [
    { text: tarefa.name, style: "Header", alignment: "center", },
  ]

  let body = [];
  let divisor = "_________________________________________________________________________________________________________________";

  // montando o corpo
  body.push([
    { text: divisor, alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    headers,
    { text: " ", alignment: "center", style: "Body" },
    {
      columns: [
        { text: "Cliente: " + tarefa.clientId, style: "Body" },
        { text: "Dispositivo: " + tarefa.deviceId, style: "Body" },
        { text: "Funcionário: " + tarefa.userId, style: "Body", width: "auto" },
      ]
    },
    { text: " ", alignment: "center", style: "Body" },
    {
      columns: [
        { text: "Data: " + tarefa.createdAt, style: "Body" },
        { text: "Inicio: " + tarefa.payload.horario.start, style: "Body" },
        { text: "Fim: " + tarefa.payload.horario.end, style: "Body" },
        { text: tarefa.finished ? 'Tarefa finalizada' : 'Tarefa não finalizada', style: "Body", width: "auto" },
      ]
    },
    { text: " ", alignment: "center", style: "Body" },
    { text: divisor, alignment: "center", style: "Body" },
  ]);
  let colunasImgIni = [{ text: "Iniciais", alignment: "center", style: "Subtitle" }];
  let colunasImgFim = [{ text: "Finais", alignment: "center", style: "Subtitle" }];
  for (let i = 0; i < tarefa.picturesStart.length; i++) {
    colunasImgIni.push([
      { image: await getImgBase64(tarefa.picturesStart[i]), width: 200, alignment: "center", style: "Imagem" },
      { text: " ", alignment: "center", style: "Body" },
    ]);
  }

  for (let i = 0; i < tarefa.picturesEnd.length; i++) {
    colunasImgFim.push([
      { image: await getImgBase64(tarefa.picturesEnd[i]), width: 200, alignment: "center", style: "Imagem" },
      { text: " ", alignment: "center", style: "Body" },
    ]);
  }
  body.push([
    { text: " ", alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    { text: "Atividades", alignment: "center", style: "Subtitle" },
  ]);

  for (let i = 0; i < tarefa.payload.activities.length; i++) {
    let texto = `${tarefa.payload.activities[i].name}\n
    Registrado em: ${new Date(tarefa.payload.activities[i].checkedAt).toLocaleString()}\n
    Intervalo entre atividades: ${tarefa.payload.activities[i].interval}\n`;
    body.push([
      { text: " ", alignment: "center", style: "Body" },
      {
        columns: [
          { text: texto, alignment: "left", style: "Body" },
        ]
      },
      { text: divisor, alignment: "center", style: "Body" },
    ]);
  }
  body.push([
    { text: " ", alignment: "center", style: "Body" },
    { text: "Imagens", alignment: "center", style: "Subtitle" },
    { text: "", alignment: "center", style: "Body" },
    {
      columns: [
        colunasImgIni,
        colunasImgFim,
      ]
    },
  ]);
  createReportSolo(body, title, filename, 'portrait', tarefa.id, 'Tarefa');
};

exports.rondaVirtualSolo = async (data = [], title = "", filename = "") => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  let ronda = { ...data[0] };

  // inserindo o header
  const headers = [
    { text: ronda.name, style: "Header", alignment: "center", },
  ]

  let body = [];
  let divisor = "_________________________________________________________________________________________________________________";

  function verificaResposta(question) {
    if (question.type == 'boolean') {
      return question.value ? 'Sim' : 'Não';
    }
    else return question.value;
  }

  // montando o corpo
  body.push([
    { text: divisor, alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    headers,
    { text: " ", alignment: "center", style: "Body" },
    {
      columns: [
        { text: "Cliente: " + ronda.clientId, style: "Body" },
        ronda.userId ? { text: "Funcionário: " + ronda.userId, style: "Body" } : {},
      ]
    },
    { text: " ", alignment: "center", style: "Body" },
    {
      columns: [
        { text: "Data: " + ronda.data, style: "Body" },
        { text: ronda.finished ? 'Ronda Virtual finalizada' : 'Ronda Virtual não finalizada', style: "Body" },
        { text: "Tipo: " + ronda.tipo, style: "Body" },
      ]
    },
    { text: " ", alignment: "center", style: "Body" },
    { text: divisor, alignment: "center", style: "Body" },
    { text: " ", alignment: "center", style: "Body" },
    { text: "Questões", alignment: "center", style: "Subtitle" },
    { text: " ", alignment: "center", style: "Body" },
  ]);
  if (ronda.payload && ronda.payload.questions && ronda.payload.questions.length > 0) {
    for (let i = 0; i < ronda.payload.questions.length; i++) {
      let titulo = `${ronda.payload.questions[i].name}: \n\n`
      let texto = `${verificaResposta(ronda.payload.questions[i])}\n`;
      body.push([
        { text: " ", alignment: "center", style: "Body" },
        { text: titulo, alignment: "left", style: "Subtitle" },
        { text: texto, alignment: "left", style: "Body" },
        { text: divisor, alignment: "center", style: "Body" },
      ]);
    }
  }

  if (ronda.payload.observacao) {
    body.push([
      { text: " ", alignment: "center", style: "Body" },
      { text: "Observação: ", alignment: "left", style: "Subtitle" },
      { text: " ", alignment: "center", style: "Body" },
      { text: ronda.payload.observacao, alignment: "left", style: "Body" },
      { text: divisor, alignment: "center", style: "Body" },
      { text: " ", alignment: "center", style: "Body" },
    ]);
  }

  if (ronda.images && ronda.images.length) {
    body.push({ text: "Imagens", alignment: "center", style: "Subtitle", marginBottom: 10 });
    for (const key in ronda.images) {
      if (Object.hasOwnProperty.call(ronda.images, key)) {
        const element = ronda.images[key];
        body.push([
          { image: await getImgBase64(element), alignment: "center", width: 550, marginBottom: 10 },
        ]);

      }
    }
  }
  createReportSolo(body, title, filename, 'portrait', ronda.id, 'Ronda Virtual');
};

exports.rondas = async (data = [], title = "", filename = "", img = false) => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "ID", style: "tableHeader", alignment: "center", width: "auto" },
    { text: "Ronda", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Data", style: "tableHeader", alignment: "center" },
    { text: "Início", style: "tableHeader", alignment: "center" },
    { text: "Fim", style: "tableHeader", alignment: "center" },
    { text: "Usuário", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Dispositivo", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Cliente", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Finalizada", style: "tableHeader", alignment: "center", starSizedCol: true },
  ];
  if (!img) {
    headers.push({ text: "Checkpoints", style: "tableHeader", alignment: "center", starSizedCol: true })
  }

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  // montando o corpo
  let linhaPar = false;
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      linhaPar = !linhaPar;
      const e = data[key];
      let pontos = "";
      let images = [];
      let pontosArray = [];
      for (let i = 0; i < e.payload.checkpoints.length; i++) {
        pontos += `${e.payload.checkpoints[i].name}\n`;
        images.push(e.payload.checkpoints[i].picture);
        pontosArray.push(e.payload.checkpoints[i].name);
      }
      let tempBody = [
        { text: e.id, style: "tableBody" },
        { text: e.name, alignment: "center", style: "tableBody" },
        { text: e.createdAt, alignment: "center", style: "tableBody" },
        { text: e.payload.horario ? e.payload.horario.start : '-', alignment: "center", style: "tableBody" },
        { text: e.payload.horario ? e.payload.horario.end : '-', alignment: "center", style: "tableBody" },
        { text: e.userName, alignment: "center", style: "tableBody" },
        { text: e.deviceName, alignment: "center", style: "tableBody" },
        { text: e.clientName, alignment: "center", style: "tableBody" },
        { text: e.finished ? 'Sim' : 'Não', alignment: "center", style: "tableBody" },
      ];
      if (!img) {
        tempBody.push({ text: pontos, alignment: "center", style: "tableBody" });
      }
      body.push(tempBody);
      if (img && images != '') {
        const style = linhaPar ? "tableBodyPerson" : "tableBodyPerson2";
        body.push([
          { text: "", alignment: "center", style: style, colSpan: 9 },
        ]);
        let linhas = Math.ceil(e.payload.checkpoints.length / 9);
        for (let i = 0; i < linhas; i++) {
          let tempNomes = [];
          let temp = [];
          for (let j = 0; j < 9; j++) {
            images[i * 9 + j] ? temp.push({ image: await getImgBase64(images[i * 9 + j]), width: 50, alignment: "center", style: style }) : temp.push({ text: '', alignment: "center", style: style });
            tempNomes.push({ text: pontosArray[i * 9 + j], alignment: "center", style: style });
          }
          body.push(tempNomes);
          body.push(temp);
        }
        body.push([
          { text: "", alignment: "center", style: style, colSpan: 9 },
        ]);
      }

      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }

  }
  createReportPDF(body, title, filename);
};

exports.tarefas = async (data = [], title = "", filename = "", img = false) => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "ID", style: "tableHeader", alignment: "center", width: "auto" },
    { text: "Tarefa", style: "tableHeader", alignment: "center" },
    { text: "Ambiente", style: "tableHeader", alignment: "center" },
    { text: "Data", style: "tableHeader", alignment: "center" },
    { text: "Inicio", style: "tableHeader", alignment: "center" },
    { text: "Fim", style: "tableHeader", alignment: "center" },
    { text: "Usuário", style: "tableHeader", alignment: "center" },
    { text: "Dispositivo", style: "tableHeader", alignment: "center" },
    { text: "Cliente", style: "tableHeader", alignment: "center" },
    { text: "Finalizada", style: "tableHeader", alignment: "center" },
    { text: "Atividades", style: "tableHeader", alignment: "center", starSizedCol: true },
  ];

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  // montando o corpo
  let linhaPar = false;
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      linhaPar = !linhaPar;
      const e = data[key];
      if (e.payload.horario == null) {
        e.payload.horario = {
          start: "Não iniciada",
          end: "Não finalizada"
        }
      }
      let atividades = "";
      for (let i = 0; i < e.payload.activities.length; i++) {
        atividades += `${e.payload.activities[i].name}\n`;
      }
      let tempBody = [
        { text: e.id, style: "tableBody" },
        { text: e.tarefaId, alignment: "center", style: "tableBody" },
        { text: e.ambientId ? e.ambientId : "Não informado", alignment: "center", style: "tableBody" },
        { text: e.createdAt, alignment: "center", style: "tableBody" },
        { text: e.payload.horario.start, alignment: "center", style: "tableBody" },
        { text: e.payload.horario.end, alignment: "center", style: "tableBody" },
        { text: e.userId, alignment: "center", style: "tableBody" },
        { text: e.deviceId, alignment: "center", style: "tableBody" },
        { text: e.clientId, alignment: "center", style: "tableBody" },
        { text: e.finished ? 'Sim' : 'Não', alignment: "center", style: "tableBody" },
        { text: atividades, alignment: "center", style: "tableBody" },
      ];


      body.push(tempBody);
      let style = linhaPar ? "tableBodyPerson" : "tableBodyPerson2";
      if (img && e.picturesStart != '') {
        body.push([{ text: "Imagens de Início", alignment: "center", style: style, colSpan: 11 }]);

        let linhas = Math.ceil(e.picturesStart.length / 11);
        for (let i = 0; i < linhas; i++) {
          let temp = [];
          for (let j = 0; j < 11; j++) {
            e.picturesStart[i * 11 + j] ? temp.push({ image: await getImgBase64(e.picturesStart[i * 11 + j]), width: 50, alignment: "center", style: style }) : temp.push({ text: '', alignment: "center", style: style });
          }
          body.push(temp);
        }
      }
      if (img && e.picturesEnd != '') {
        body.push([{ text: "Imagens de Fim", alignment: "center", style: style, colSpan: 11 }]);

        let linhas = Math.ceil(e.picturesEnd.length / 11);
        for (let i = 0; i < linhas; i++) {
          let temp = [];
          for (let j = 0; j < 11; j++) {
            e.picturesEnd[i * 11 + j] ? temp.push({ image: await getImgBase64(e.picturesEnd[i * 11 + j]), width: 50, alignment: "center", style: style }) : temp.push({ text: '', alignment: "center", style: 'tableBody' });
          }
          body.push(temp);
        }
      }
      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }

  }
  createReportPDF(body, title, filename);
};
exports.eventos = async (data = [], title = "", filename = "", img = false) => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "ID", style: "tableHeader", alignment: "center", width: "auto" },
    { text: "Evento", style: "tableHeader", alignment: "center" },
    { text: "Cliente", style: "tableHeader", alignment: "center" },
    { text: "Origem", style: "tableHeader", alignment: "center" },
    { text: "Dispositivo", style: "tableHeader", alignment: "center" },
    { text: "Responsável", style: "tableHeader", alignment: "center" },
    { text: "Data", style: "tableHeader", alignment: "center" },
    { text: "Atendimento", style: "tableHeader", alignment: "center" },
    { text: "Finalização", style: "tableHeader", alignment: "center" },
    { text: "Resolução", style: "tableHeader", alignment: "center", width: "auto" },
  ];

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  // montando o corpo
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      let tempBody = [
        { text: e.id, style: "tableBody" },
        { text: e.eventName, alignment: "center", style: "tableBody" },
        { text: e.clientId, alignment: "center", style: "tableBody" },
        { text: e.createdUserId, alignment: "center", style: "tableBody" },
        { text: e.deviceId, alignment: "center", style: "tableBody" },
        { text: e.finishedUserId, alignment: "center", style: "tableBody" },
        { text: new Date(e.createdAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: new Date(e.startedAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: new Date(e.finishedAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: e.treatment, alignment: "center", style: "tableBody" },
      ];
      body.push(img ? tempBody : tempBody.filter(item => !Object.hasOwnProperty.call(item, 'image')));
      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }

  }
  createReportPDF(body, title, filename);
};
exports.rondasVirtuais = async (data = [], title = "", filename = "", img = false) => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "Ronda", style: "tableHeader", alignment: "center", width: "auto" },
    { text: "Data", style: "tableHeader", alignment: "center", },
    { text: "Horário", style: "tableHeader", alignment: "center" },
    { text: "Usuário", style: "tableHeader", alignment: "center" },
    { text: "Cliente", style: "tableHeader", alignment: "center" },
    { text: "Finalizada", style: "tableHeader", alignment: "center" },
    { text: "Observação", style: "tableHeader", alignment: "center" },
    { text: "Questões", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Imagem", style: "tableHeader", alignment: "center", isImg: true },
  ];

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  function verificaResposta(question) {
    if (question.type == 'boolean') {
      return question.value ? 'Sim' : 'Não';
    }
    else return question.value;
  }

  // montando o corpo
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];

      let tempBody = [
        { text: e.name, alignment: "center", style: "tableBody" },
        { text: e.data, alignment: "center", style: "tableBody" },
        { text: e.horario, alignment: "center", style: "tableBody" },
        { text: e.userId, alignment: "center", style: "tableBody" },
        { text: e.clientId, alignment: "center", style: "tableBody" },
        { text: e.finished ? "Sim" : "Não", alignment: "center", style: "tableBody" },
        { text: e.payload.observacao, alignment: "center", style: "tableBody" },
        { text: e.payload.questions ? e.payload.questions.map(item => `${item.name}: ${verificaResposta(item)}`).join(`, `) : "", alignment: "center", style: "tableBody" },
      ];
      // mostrando as imagens
      if (img && e.images) {
        let imagescol = [];
        for (const key in e.images) {
          if (Object.hasOwnProperty.call(e.images, key)) {
            const element = e.images[key];
            imagescol.push({ image: await getImgBase64(element), width: 100, margin: [0, 1] });
          }
        }
        tempBody.push(imagescol.length ? imagescol : { text: "Sem imagens", alignment: "center" });
      }
      // inserindo no corpo
      body.push(img ? tempBody : tempBody.filter(item => !Object.hasOwnProperty.call(item, 'image')));
      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }

  }
  createReportPDF(body, title, filename);
};
exports.ocorrencias = async (data = [], title = "", filename = "", img = false) => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "Ocorrência", style: "tableHeader", alignment: "center", width: "auto" },
    { text: "Tipo", style: "tableHeader", alignment: "center", },
    { text: "Prioridade", style: "tableHeader", alignment: "center", },
    { text: "Funcionário", style: "tableHeader", alignment: "center" },
    { text: "Comunicante", style: "tableHeader", alignment: "center" },
    { text: "Cliente", style: "tableHeader", alignment: "center" },
    { text: "Data Comunicação", style: "tableHeader", alignment: "center" },
    { text: "Data do Fato", style: "tableHeader", alignment: "center" },
    { text: "Descrição", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Transcrição dos áudios", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Imagens", style: "tableHeader", alignment: "center", isImg: true },
  ];

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  // montando o corpo
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      let tempBody = [
        { text: e.id + " - " + e.name, style: "tableBody" },
        { text: e.type, alignment: "center", style: "tableBody" },
        { text: e.priority, alignment: "center", style: "tableBody" },
        { text: e.userId, alignment: "center", style: "tableBody" },
        { text: e.communicant, alignment: "center", style: "tableBody" },
        { text: e.clientId, alignment: "center", style: "tableBody" },
        { text: new Date(e.createdAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: new Date(e.factDate).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: e.description, alignment: "center", style: "tableBody" },
        { text: e.transcriptions ? e.transcriptions.map(item => item.text).join("\n\n") : "Não foi possível transcrever o áudio", alignment: "center", style: "tableBody" },
        { image: await getImgBase64(e.pictures[0]), width: 50 }
      ];
      body.push(img ? tempBody : tempBody.filter(item => !Object.hasOwnProperty.call(item, 'image')));
      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }

  }
  createReportPDF(body, title, filename);
};

exports.visitasEmp = async (data = [], title = "", filename = "", img = false) => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "Visitado", style: "tableHeader", alignment: "center", },
    { text: "Visitante", style: "tableHeader", alignment: "center", },
    { text: "Documento", style: "tableHeader", alignment: "center" },
    { text: "Entrada", style: "tableHeader", alignment: "center" },
    { text: "Saída", style: "tableHeader", alignment: "center" },
    { text: "Descrição", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Permanencia", style: "tableHeader", alignment: "center" },
  ];

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  // montando o corpo
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      body.push([
        {
          columns: [
            img ? { image: await getImgBase64(e.visitadoPic), width: 50 } : { text: '', width: 'auto' },
            { text: e.visitadoName, alignment: "center", style: "tableBody" },
          ]
        },
        {
          columns: [
            img ? { image: await getImgBase64(e.visitantePic), width: 50 } : { text: '', width: 'auto' },
            { text: e.visitanteName, alignment: "center", style: "tableBody" },
          ]
        },
        { text: e.visitanteDoc, alignment: "center", style: "tableBody" },
        { text: new Date(e.createdAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: new Date(e.updatedAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: e.description, alignment: "center", style: "tableBody" },
        { text: e.perm, alignment: "center", style: "tableBody" },
      ]);
      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }
  }
  createReportPDF(body, title, filename);
};

exports.veiculos = async (data = [], title = "", filename = "", img = false) => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "Img. Entrada", style: "tableHeader", alignment: "center", isImg: true },
    { text: "Placa", style: "tableHeader", alignment: "center", },
    { text: "Entrada", style: "tableHeader", alignment: "center" },
    { text: "Descr. Inicial", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Img. Saída", style: "tableHeader", alignment: "center", isImg: true },
    { text: "Saída", style: "tableHeader", alignment: "center" },
    { text: "Descr. Final", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Visitante", style: "tableHeader", alignment: "center" },
    { text: "Doc. Visitante", style: "tableHeader", alignment: "center" },
    { text: "Visitado", style: "tableHeader", alignment: "center" },
    { text: "Permanência", style: "tableHeader", alignment: "center" },
  ];

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  // montando o corpo
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      let tempBody = [
        { image: await getImgBase64(e.picturesEnter[0]), width: 50 },
        { text: e.placa, alignment: "center", style: "tableBody" },
        { text: new Date(e.createdAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: e.description, alignment: "center", style: "tableBody" },
        { image: await getImgBase64(e.picturesExit[0]), width: 50 },
        { text: new Date(e.updatedAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: e.descriptionExit, alignment: "center", style: "tableBody" },
        {
          columns: [
            img ? { image: await getImgBase64(e.visitantePic), width: 50 } : { text: '', width: 'auto' },
            { text: e.visitanteName, alignment: "center", style: "tableBody" },
          ]
        },
        { text: e.visitanteDoc, alignment: "center", style: "tableBody" },
        {
          columns: [
            img ? { image: await getImgBase64(e.visitadoPic), width: 50 } : { text: '', width: 'auto' },
            { text: e.visitadoName, alignment: "center", style: "tableBody" },
          ]
        },
        { text: getIntervalo(e.createdAt, e.updatedAt), alignment: "center", style: "tableBody" },
      ];

      body.push(img ? tempBody : tempBody.filter(item => !Object.hasOwnProperty.call(item, 'image')));

      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }
  }
  createReportPDF(body, title, filename);
};

exports.fleetSimple = async (data = [], title = "", filename = "", img = false) => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "Img. Inicial", style: "tableHeader", alignment: "center", isImg: true },
    { text: "Motorista", style: "tableHeader", alignment: "center", },
    { text: "Veículo", style: "tableHeader", alignment: "center" },
    { text: "Descrição", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Img. Final", style: "tableHeader", alignment: "center", isImg: true },
    { text: "Sentido", style: "tableHeader", alignment: "center" },
    { text: "Início", style: "tableHeader", alignment: "center" },
    { text: "Fim", style: "tableHeader", alignment: "center" },
    { text: "Tempo", style: "tableHeader", alignment: "center" },
    { text: "km Inicial", style: "tableHeader", alignment: "center" },
    { text: "Km Final", style: "tableHeader", alignment: "center" },
    { text: "Km Percorrido", style: "tableHeader", alignment: "center" },
  ];

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  // montando o corpo
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      let tempBody = [
        { image: await getImgBase64(e.picturesStart[0]), width: 50 },
        {
          columns: [
            img ? { image: await getImgBase64(e.driver.picture), width: 50 } : { text: '', width: 'auto' },
            { text: e.driver.name, alignment: "center", style: "tableBody" },
          ]
        },
        {
          columns: [
            img ? { image: await getImgBase64(e.vehicle.picture), width: 50 } : { text: '', width: 'auto' },
            { text: `${e.vehicle.plate}\n${e.vehicle.model}`, alignment: "center", style: "tableBody" },
          ]
        },
        { text: e.description, alignment: "center", style: "tableBody" },
        { image: await getImgBase64(e.picturesExit[0]), width: 50 },
        { text: `${e.direction ? "Entrada" : "Saída"}`, alignment: "center", style: "tableBody" },
        { text: new Date(e.createdAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: new Date(e.updatedAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: getIntervalo(e.createdAt, e.updatedAt), alignment: "center", style: "tableBody" },
        { text: e.kmStart, alignment: "center", style: "tableBody" },
        { text: e.kmEnd, alignment: "center", style: "tableBody" },
        { text: e.kmEnd - e.kmStart, alignment: "center", style: "tableBody" },
      ];

      body.push(img ? tempBody : tempBody.filter(item => !Object.hasOwnProperty.call(item, 'image')));

      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }
  }
  createReportPDF(body, title, filename);
};

exports.fleetMulti = async (data = [], title = "", filename = "", img = false) => {
  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "Img. Inicial", style: "tableHeader", alignment: "center", isImg: true },
    { text: "Motoristas Iniciais", style: "tableHeader", alignment: "center", },
    { text: "Veículos Iniciais", style: "tableHeader", alignment: "center" },
    { text: "Descrição Inicial", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Img. Final", style: "tableHeader", alignment: "center", isImg: true },
    { text: "Motoristas Finais", style: "tableHeader", alignment: "center", },
    { text: "Veículos Finais", style: "tableHeader", alignment: "center" },
    { text: "Descrição Final", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Sentido", style: "tableHeader", alignment: "center" },
    { text: "Início", style: "tableHeader", alignment: "center" },
    { text: "Fim", style: "tableHeader", alignment: "center" },
    { text: "Tempo", style: "tableHeader", alignment: "center" },
    { text: "km Inicial", style: "tableHeader", alignment: "center" },
    { text: "Km Final", style: "tableHeader", alignment: "center" },
    { text: "Km Percorrido", style: "tableHeader", alignment: "center" },
  ];

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  // montando o corpo
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      let tempBody = [
        { image: await getImgBase64(e.pictures[0]), width: 50 },
        /* SE INCLUIR IMAGEM NÃO CABE NA PÁGINA
         { table: { // tabela de 2 colunas para imagem e nome dos motoristas
             body: await Promise.all(e.drivers.map(async item => {
               let tmp = [];
               if (img) tmp.push({ image: await getImgBase64(item.picture), width: 50 });
               tmp.push({ text: item.name, alignment: "center" });
               return tmp;
             }))
           },
           layout: { defaultBorder: false }
         },*/
        { ul: e.drivers.map(item => item.name), alignment: "left", style: "tableBody" },
        { ul: e.fleetVehicles.map(item => item.plate), alignment: "left", style: "tableBody" },
        { text: e.description, alignment: "center", style: "tableBody" },
        { image: await getImgBase64(e.finalPictures[0]), width: 50 },
        { ul: e.finalDrivers.map(item => item.name), alignment: "left", style: "tableBody" },
        { ul: e.finalFleetVehicles.map(item => item.plate), alignment: "left", style: "tableBody" },
        { text: e.descriptionFinish, alignment: "center", style: "tableBody" },
        { text: `${e.direction ? "Entrada" : "Saída"}`, alignment: "center", style: "tableBody" },
        { text: new Date(e.createdAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: new Date(e.updatedAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: getIntervalo(e.createdAt, e.updatedAt), alignment: "center", style: "tableBody" },
        { text: e.kmStart, alignment: "center", style: "tableBody" },
        { text: e.kmEnd, alignment: "center", style: "tableBody" },
        { text: e.kmEnd - e.kmStart, alignment: "center", style: "tableBody" },
      ];

      body.push(img ? tempBody : tempBody.filter(item => !Object.hasOwnProperty.call(item, 'image')));

      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }
  }
  createReportPDF(body, title, filename);
};

exports.jornadas = async (data = [], title = "", filename = "", img = false) => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "Turno", style: "tableHeader", alignment: "center" },
    { text: "Imagem Inicial", style: "tableHeader", alignment: "center", isImg: true },
    { text: "Início", style: "tableHeader", alignment: "center" },
    { text: "Fim", style: "tableHeader", alignment: "center" },
    { text: "Intervalo", style: "tableHeader", alignment: "center" },
    { text: "Imagem Final", style: "tableHeader", alignment: "center", isImg: true },
    { text: "Horário da jornada", style: "tableHeader", alignment: "center" },
    { text: "Intervalo da jornada", style: "tableHeader", alignment: "center" },
    { text: "Tolerância (min)", style: "tableHeader", alignment: "center" },
    { text: "Posto", style: "tableHeader", alignment: "center" },
    { text: "Funcionário", style: "tableHeader", alignment: "center" },
    { text: "Dispositivo", style: "tableHeader", alignment: "center", starSizedCol: true },
  ];

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  // montando o corpo
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      let tempBody = [
        { text: e.turnoName, style: "tableBody" },
        { image: await getImgBase64(e.picturesStart[0]), width: 50 },
        { text: e.createdAt, alignment: "center", style: "tableBody" },
        { text: e.updatedAt, alignment: "center", style: "tableBody" },
        { text: e.interval, alignment: "center", style: "tableBody" },
        { image: await getImgBase64(e.picturesEnd[0]), width: 50 },
        { text: e.schedule, alignment: "center", style: "tableBody" },
        { text: e.intervalTurno, alignment: "center", style: "tableBody" },
        { text: e.turnoTolerance, alignment: "center", style: "tableBody" },
        { text: e.postoName, alignment: "center", style: "tableBody" },
        { text: e.userName, alignment: "center", style: "tableBody" },
        { text: e.deviceName, alignment: "center", style: "tableBody" },
      ];
      body.push(img ? tempBody : tempBody.filter(item => !Object.hasOwnProperty.call(item, 'image')));
      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }

  }
  createReportPDF(body, title, filename);
};

exports.documents = async (data = [], title = "", filename = "", img = false) => {

  // usado para calcular o progresso
  steps = data.length;
  sAtual = 0;

  const headers = [
    { text: "Nome do Documento", style: "tableHeader", alignment: "center", starSizedCol: true },
    { text: "Cliente", style: "tableHeader", alignment: "center" },
    { text: "Grupo", style: "tableHeader", alignment: "center" },
    { text: "Usuário", style: "tableHeader", alignment: "center" },
    { text: "Primeira Visualização", style: "tableHeader", alignment: "center" },
    { text: "Ultima Visualização", style: "tableHeader", alignment: "center" },
  ];

  // atualizando o progresso
  store.default.dispatch('setMessageDialog', "Gerando o Corpo do PDF");
  store.default.dispatch('setPctDialog', { actual: sAtual, total: steps });

  // inserindo o header
  let body = [img ? headers : headers.filter(item => !item.isImg)];

  // montando o corpo
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const e = data[key];
      let tempBody = [
        { text: e.docName, alignment: "center", style: "tableBody" },
        { text: e.clientName, alignment: "center", style: "tableBody" },
        { text: e.groupName, alignment: "center", style: "tableBody" },
        { text: e.userName, alignment: "center", style: "tableBody" },
        { text: new Date(e.createdAt).toLocaleString(), alignment: "center", style: "tableBody" },
        { text: new Date(e.updatedAt).toLocaleString(), alignment: "center", style: "tableBody" },
      ];
      body.push(img ? tempBody : tempBody.filter(item => !Object.hasOwnProperty.call(item, 'image')));
      // atualizando o progresso
      store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
    }

  }
  createReportPDF(body, title, filename);
};

async function createReportSolo(body = [], title = "", filename = "", pageOrientation = 'portrait', id, tipo) {
  // pegando a imagem do logo
  // let logo = await getImgBase64(`${axios.defaults.baseURL}`.replace('/api/', '/assets/image/MASTER100px.png'));
  let logo = await getImgBase64(`${store.default.getters.getUrlAssets}MASTER100px.png`);

  // criando o documento
  let dd = {
    header: function () {
      return {
        columns: [
          {
            image: logo,
            width: 50,
          },
          {
            text: `\n${title}`,
            style: 'Title',
            alignment: 'center'
          },
          {
            text: `\n${tipo}: ${id}`,
            style: 'Body',
            alignment: 'right',
            width: 80,
            fontSize: 8,
          },
        ],
        margin: [10, 10, 20, 10],
      }
    },
    content: [

      body
    ],
    styles: {
      Header: {
        bold: true,
        fontSize: 14,
        color: 'black',
      },
      Body: {
        bold: false,
        fontSize: 11,
        color: 'black'
      },
      Title: {
        bold: true,
        fontSize: 16,
      },
      Subtitle: {
        bold: true,
        fontSize: 12,
      }


    },
    pageSize: 'A4',
    pageOrientation: pageOrientation,
    footer: function (currentPage, pageCount) { return { text: currentPage.toString() + ' de ' + pageCount, alignment: 'center', fontSize: 8 } },
    pageMargins: [15, 65, 15, 15],
    alignment: 'justify'
  };
  // atualizando o progresso
  store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
  store.default.dispatch("setMessageDialog", "Baixando arquivo");

  pdfMake.createPdf(dd).open();
  console.log(`${filename}.pdf`);
  // pdfMake.createPdf(dd).download(`${filename}.pdf`);
  // fechando o dialog
  setTimeout(() => {
    store.default.dispatch("setShowDialog", false);
  }, 1000);
}

async function createReportPDF(body = [], title = "", filename = "", pageOrientation = 'landScape') {
  // pegando a imagem do logo
  // let logo = await getImgBase64(`${axios.defaults.baseURL}`.replace('/api/', '/assets/image/MASTER100px.png'));
  let logo = await getImgBase64(store.default.getters.getUrlAssets + "MASTER100px.png");
  let wid = calcWidth(body);
  // criando o documento
  let dd = {
    content: [
      {
        columns: [
          {
            image: logo,
            width: 50,
          },
          {
            text: `\n${title}`,
            style: 'header',
            alignment: 'center'
          },
          {
            text: `\n\n`,
            width: 50,
          },
        ],
      },
      {
        table: {
          widths: wid,
          headerRows: 1,
          body: [
            ...body
          ],
        },
        layout: {
          fillColor: function (rowIndex) {
            return (rowIndex % 2 === 0) ? '#DDDDDD' : null;
          },
          defaultBorder: false,
        }
      },
    ],
    styles: {
      tableHeader: {
        bold: true,
        fontSize: 11,
        color: '#FFFFFF',
        fillColor: "#1F5F37"
      },
      tableBody: {
        bold: false,
        fontSize: 8,
        color: 'black'
      },
      tableBodyPerson: {
        bold: false,
        fontSize: 8,
        color: 'black',
        fillColor: '#FFFFFF'
      },
      tableBodyPerson2: {
        bold: false,
        fontSize: 8,
        color: 'black',
        fillColor: '#DDDDDD'
      },

    },
    pageSize: 'LEGAL',
    pageOrientation: pageOrientation,
    footer: function (currentPage, pageCount) { return { text: currentPage.toString() + ' de ' + pageCount, alignment: 'center', fontSize: 8 } },
    pageMargins: [5, 5, 5, 15],
  };

  // atualizando o progresso
  store.default.dispatch('setPctDialog', { actual: ++sAtual, total: steps });
  store.default.dispatch("setMessageDialog", "Baixando arquivo");

  pdfMake.createPdf(dd).open();
  console.log(`${filename}.pdf`);
  // pdfMake.createPdf(dd).download(`${filename}.pdf`);
  // fechando o dialog
  setTimeout(() => {
    store.default.dispatch("setShowDialog", false);
  }, 1000);
}
// gerador de tamanho de colunas
function calcWidth(body) {
  let arr = [...body[0]]
  let ret = []
  arr.forEach((item) => {
    ret.push(item.starSizedCol ? '*' : 'auto')
  })
  return ret
}
//pegando a imagem em base64
async function getImgBase64(url) {
  if (!url) url = store.default.getters.getUrlAssets + "nopic.jpg";
  // if (!url) url = `${axios.defaults.baseURL}`.replace('/api/', '/images/images.jpg');
  //url = `${axios.defaults.baseURL}`.replace('/api/', '/images/images.jpg');
  console.log(url)
  try {

    let response = await axios.get(url, { responseType: 'arraybuffer' });
    let blob = new Blob([response.data], { type: response.headers['content-type'] });
    let base64 = await convertBlobToBase64(blob);
    return base64;

  } catch (error) {
    console.log(error);
  }
}
// pegando a imagem em base64 sem o axios
/*function getImgBase64(url) {
  if (!url) return require("@/assets/nopic.jpg");
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
 
    xhr.responseType = 'blob';
 
    // Process the response when the request is ready.
    xhr.onload = async function () {
      if (this.status == 200) {
        let base64 = await convertBlobToBase64(this.response);
        resolve(base64);
      }
    };
    xhr.onerror = function () {
      reject(new Error("Network Error"));
    }
 
    xhr.send();
  });
}*/

// convertendo blob para base64
const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
  const reader = new FileReader;
  reader.onerror = reject;
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.readAsDataURL(blob);
});

function getIntervalo(inicio, fim) {
  let dif = dateDiff.differenceDate(inicio, fim);
  let res;
  if (dif.day > 0)
    res = `${dif.day}d, ${dif.hour}h, ${dif.minute}m`;
  else
    res = `${dif.hour < 10 ? "0" + dif.hour : dif.hour}:${dif.minute < 10 ? "0" + dif.minute : dif.minute}:${dif.second < 10 ? "0" + dif.second : dif.second}`;
  return res;
}