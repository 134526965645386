<template>
  <v-tabs fixed-tabs background-color="primary" dark>
    <v-tab> Permissões BACK </v-tab>
    <v-tab-item> <Permissoes/> </v-tab-item>
    <v-tab> Menus FRONT </v-tab>
    <v-tab-item> <Menus/> </v-tab-item>
    <v-tab> Menus App </v-tab>
    <v-tab-item> <MenusApp/> </v-tab-item>
    <v-tab> Recursos </v-tab>
    <v-tab-item> <Recursos/> </v-tab-item>
    <v-tab> Níveis de Acesso </v-tab>
    <v-tab-item> <Niveis/> </v-tab-item>
    <v-tab> Icones </v-tab>
    <v-tab-item> <Icones/> </v-tab-item>
  </v-tabs>
</template>

<script>
import Recursos from './Recursos'
import Niveis from './Niveis'
import Menus from './Menus'
import MenusApp from './MenusApp'
import Permissoes from './Permissoes'
import Icones from './Icones.vue'
export default {
  name: "AdminPage",
  components: {
    Recursos,
    Niveis,
    Menus,
    Permissoes,
    Icones,
    MenusApp
  }
};
</script>

<style>
</style>