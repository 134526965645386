import axios from 'axios'
export default {
    state: {
        posto: [],
    },
    mutations: {
        setPostos(state, payload) {
            state.posto = payload
        },
    },
    actions: {
        setPostos(context, payload) {
            context.commit('setPostos', payload)
        },
        loadPostos(context, payload) {
            let gp = payload ? `?groupId=${payload}` : ''
            axios(`posto${gp}`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`,
                        element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO"
                });
                context.commit('setPostos', resp.data)
            });
        },
        savePosto(context, grupo) {
            const metodo = grupo.id ? "put" : "post";
            const finalUrl = grupo.id ? `/${grupo.id}` : "";
            axios[metodo](`posto${finalUrl}`, grupo).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Posto salvo com Sucesso");
                context.dispatch('loadPostos')
            });
        },
        deletPosto(context, grupo) {
            axios.delete(`posto/${grupo.id}`).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Posto Deletado com Sucesso");
                context.dispatch('loadPostos')
            });
        },
    },
    getters: {
        getPostos(state) {
            return state.posto
        },
        getPostoNameById: (state) => (id) => {
            if (!state.posto) {
                return id
            }
            let gp = state.posto.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : id + ' - Posto não encontrado'
        },
        getPostoHeaders() {
            return [
                { text: "ID", value: "id" },
                { text: "Nome", value: "name" },
                { text: "CNPJ", value: "cnpj" },
                { text: "Endereço", value: "address" },
                { text: "Telefone", value: "phone" },
                { text: "Grupo", value: "groupId" },
                { text: "Descrição", value: "description" },
                { text: "Ativo", value: "ativo" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        }
    },
}