import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router/index'
import permission from './modules/perm'
import usuarios from './modules/usuarios'
import niveis from './modules/niveis'
import snackbar from './modules/snackbar'
import cliente from './modules/cliente'
import veiculos from './modules/veiculos'
import dashBoard from './modules/dashBoard'
import grupo from './modules/grupo'
import visitado from './modules/visitado'
import visitante from './modules/visitante'
import posto from './modules/posto'
import agenda from './modules/agenda'
import apps from './modules/app'
import visitas from './modules/visitas'
import webSocket from './modules/webSocket'
import notificacao from './modules/notificacao'
import driver from './modules/driver'
import fleetVehicle from './modules/fleetVehicle'
import fleet from './modules/fleet'
import schedule from './modules/schedule'
import email from './modules/email'
import melhorias from './modules/melhorias'
import parameters from './modules/parameters'
import clienteType from './modules/clienteType'
import icones from './modules/icones'
import rondas from './modules/ronda'
import tarefas from './modules/tarefa'
import turno from './modules/turno'
import documento from './modules/documento'
import dialogLoading from './modules/dialogLoading'
import ocorrencia from './modules/ocorrencia'
import events from './modules/events'
import translate from './modules/translate'
import shortcut from './modules/shortcut'
import rondaVirtual from './modules/rondaVirtual'
import online from './modules/online'
import dispositivo from './modules/dispositivo'
import snapshot from './modules/snapshot'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // visitas: [],
        permissoes: [],
        user: {},
        token: null,
        logged: false,
        urlAssets:"",
                urlImagem:""
    },
    mutations: {
        setUrlAssets(state, payload) {
            state.urlAssets = payload
        },
        setUrlImagem(state, payload) {
            state.urlImagem = payload
        },
        setPermissoes(state, payload) {
            state.permissoes = payload
        },
        setLogged(state, payload) {
            state.logged = payload
        },
        setUser(state, payload) {
            state.user = payload
            state.logged = true
        },
        setToken(state, payload) {
            state.logged = true
            state.token = payload
            axios.defaults.headers.common['Authorization'] = `${payload}`
            localStorage.setItem('ca', payload)
            let data = payload.split('.')
            state.user = JSON.parse(atob(data[1]))
        },
        destroyUser(state, payload) {
            state.logged = false
            if (payload)
                state.token = null
            state.user = {}
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem('ca')
            router.push('/login')
        }
    },
    actions: {
        logOff(context) {
            this.state.logged = false
            context.commit('setVisitados', [])
            context.commit('setVisitantes', [])
            context.commit('setVisitas', [])
            context.commit('setPermissoes', [])
            context.commit('setGrupos', [])
            context.commit('setWorkGroup', null)
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem('ca')
            router.push({ path: '/login' })
        },
        setLogged(context, payload) {
            context.commit('setLogged', payload)
        },
        setUrlAssets(context, payload = "") {
            let url = payload.replace("api/", "assets/image/");
            context.commit('setUrlAssets', url)
        },
        setUrlImagem(context, payload = "") {
            context.commit('setUrlImagem', payload);
        },
    },
    getters: {
        getUsuario(state) {
            return state.user
        },
        getUrlAssets(state) {
            return state.urlAssets
        },
        setUrlImagem(state) {
            return state.urlImagem
        },
        getToken(state) {
            return state.token
        },
        getLogged(state) {
            return state.logged
        },
        getDefaultRoute(state) {
            return state.user.route
        },
    },
    modules: {
        permission,
        usuarios,
        niveis,
        snackbar,
        cliente,
        veiculos,
        dashBoard,
        grupo,
        visitado,
        visitante,
        posto,
        agenda,
        apps,
        visitas,
        webSocket,
        notificacao,
        driver,
        fleetVehicle,
        fleet,
        schedule,
        email,
        melhorias,
        parameters,
        clienteType,
        icones,
        rondas,
        tarefas,
        turno,
        documento,
        dialogLoading,
        ocorrencia,
        events,
        translate,
        shortcut,
        rondaVirtual,
        online,
        dispositivo,
        snapshot
    }
})