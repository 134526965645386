import axios from 'axios'
export default {
    state: {
        emails: [],
    },
    mutations: {
        setEmails(state, payload) {
            state.emails = payload
        },
    },
    actions: {
        setEmails(context, payload) {
            context.commit('setEmails', payload)
        },
        loadEmails(context, payload) {
            let gp = payload ? `?groupId${payload}` : ''
            axios(`email${gp}`).then((resp) => {
                resp.data.forEach((ele) => {
                    ele.seguro = ele.secure ? "SIM" : "NÃO";
                    ele.text = `${ele.name} - ${ele.user}`
                    ele.value = ele.id
                });
                context.dispatch('setEmails', resp.data)
            });
        },
        saveEmail(context, payload) {
            const metodo = payload.id ? "put" : "post";
            if (payload.id) {
                if (payload.password == "") {
                    delete payload.password;
                }
            }
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/email${finalUrl}`, payload).then(() => {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Email salvo com Sucesso");
                context.dispatch('loadEmails')
            });
        },
        deleteEmail(context, payload) {
            axios.delete(`/email/${payload.id}`).then(() => {
                context.dispatch("resetSnack");
                context.dispatch(
                    "showSuccessSnack",
                    "Email deletado com Sucesso!"
                );
                context.dispatch('loadEmails')
            });
        },
    },
    getters: {
        getEmails(state) {
            return state.emails
        },
        getEmailsTableHeader() {
            return [
                { text: "ID", value: "id" },
                { text: "Grupo", value: "groupId" },
                { text: "Nome", value: "name" },
                { text: "Email", value: "user" },
                { text: "Senha", value: "password" },
                { text: "SMTP", value: "smtp" },
                { text: "Porta", value: "port" },
                { text: "Secure", value: "seguro" },
                { text: "Corpo", value: "body" },
                { text: "Descrição", value: "description" },
                { text: "Ações", value: "actions", sortable: false },
            ];
        }
    }
}