<template>
  <div class="userCRUD">
    <v-card :loading="loading ? 'success' : false">
      <v-card-title>Ajustes nos eventos do monitoramento</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete v-model="usuario" label="Selecione um usuário de monitoramento para filtrar os eventos"
              hint="Selecione um usuário" :items="usuarios" persistent-hint outlined @change="getEvents" clearable>
              >
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="my-2" :loading="finishing ? 'success' : false">
      <v-card-title>Limpar Eventos</v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete v-model="payload.finishedUserId"
              label="Selecione um usuário de monitoramento para tratar os eventos" hint="Selecione um usuário"
              :items="usuarios" persistent-hint outlined clearable>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete v-model="typeSelected" label="Selecione o tipo de evento" hint="Tipo de evento" :items="types"
              persistent-hint outlined clearable>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete v-model="clientSelected" label="Selecione o cliente" hint="Cliente" :items="clientes"
              persistent-hint outlined clearable>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea v-model="payload.treatment" outlined rows="1" clearable
              label="Digite o tratamento para os eventos selecionados"></v-textarea>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="warning" class="mr-2" @click="clearObj">Cancelar</v-btn>
          <v-btn depressed color="success" @click="finishEvents">Finalizar</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-card class="my-1">
      <v-card-text>
        Quantidade de eventos: {{ eventos.length }}
        <DataTable :headers="headers" :items="eventos">
          <template v-slot:item.createdAt="{ item }">
            {{ new Date(item.createdAt).toLocaleString() }}
          </template>
        </DataTable>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DataTable from "@/components/DataTableGeneric";
export default {
  data() {
    return {
      eventos: [],
      usuario: null,
      payload: {},
      types: [],
      clientes: [],
      typeSelected: null,
      clientSelected: null,
      loading: false,
      finishing: false,
      headers: [
        { text: "EventId", value: "id" },
        { text: "Tipo do Evento", value: "eventName" },
        { text: "Cliente Id", value: "clientId" },
        { text: "Cliente", value: "clientesName" },
        { text: "Usuário", value: "createdUserName" },
        { text: "Dispositivo Id", value: "deviceId" },
        { text: "Dispositivo", value: "dispositivosName" },
        { text: "Recebido", value: "createdAt" },
      ],
    };
  },
  computed: {
    usuarios: {
      get() {
        return this.$store.getters.getUserSelect;
      },
    },
  },
  components: {
    DataTable
  },
  methods: {
    get() {
      this.$store.dispatch("loadUsers");
    },
    getEvents() {
      this.loading = true;
      this.types = [];
      this.clientes = [];
      this.eventos = [];
      let user = this.$store.getters.getUserById(this.usuario);
      if (user == null) {
        this.loading = false;
        return;
      }
      this.$http.get('event/byGroup/' + user.groupId).then((res) => {
        this.eventos = res.data;
        this.eventos.forEach(element => {
          this.types.push(element.eventName);
          this.clientes.push(element.clientesName);
        });
        this.types = [...new Set(this.types)];
        this.clientes = [...new Set(this.clientes)];
        this.loading = false;
      });
    },
    finishEvents() {

      let user = this.$store.getters.getUserById(this.usuario);
      if (user == null) {
        return;
      }
      this.finishing = true;
      if (this.typeSelected) {
        let event = this.eventos.find((element) => {
          return element.eventName == this.typeSelected;
        });
        if (event) {
          this.payload.type = event.type;
        }
      } else {
        delete this.payload.type;
      }
      if (this.clientSelected) {
        let event = this.eventos.find((element) => {
          return element.clientesName == this.clientSelected;
        });
        if (event) {
          this.payload.clientId = event.clientId;
        }
      } else {
        delete this.payload.clientId;
      }
      console.time("finishEvents");
      this.$http.put('event/byGroup/' + user.groupId, this.payload).then((res) => {
        // this.clearObj();
        if (res.status == 200) {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch(
            "showSuccessSnack",
            res.data.changedRows + " Eventos fechados com Sucesso!"
          );
          this.getEvents();
          this.finishing = false;
          console.timeEnd("finishEvents");
        }
      });
    },
    delet(event) {
      this.$store.dispatch("deleteVisitado", event);
      this.get();
    },
    clearObj() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  created() {
    this.get();
  },
};
</script>

<style></style>