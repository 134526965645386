<template>
  <div>
    <v-card>
      <v-card-title> Relatório de Frota </v-card-title>
      <v-card-text>
        <!-- linha do filtro -->
        <v-card outlined>
          <v-card-text class="pb-0">
            <p class="text-subtitle-1 pt-1 pb-3">Filtros</p>
            <v-form ref="formReport">
              <!-- linha dos input -->
              <!--v-row>
                <v-col class="py-0">
                  <AutComImg
                    v-model="relatorio.driverIds"
                    :items="getDriversSelect"
                    label="Selecione os Motoristas iniciais"
                    hint="Motoristas iniciais"
                    :persistenthint="true"
                  />
                </v-col>
                <v-col class="py-0">
                  <AutComImg
                    v-model="relatorio.fleetVehicleIds"
                    :items="getFleetsSelect"
                    label="Selecione os Veículos iniciais"
                    hint="Veículos iniciais"
                    :persistenthint="true"
                  />
                </v-col>
                <v-col class="py-0">
                  <AutComImg
                    v-model="relatorio.finalDriverIds"
                    :items="getDriversSelect"
                    label="Selecione os Motoristas finais"
                    hint="Motoristas finais"
                    :persistenthint="true"
                  />
                </v-col>
                <v-col class="py-0">
                  <AutComImg
                    v-model="relatorio.finalFleetVehicleIds"
                    :items="getFleetsSelect"
                    label="Selecione os Veículos finais"
                    hint="Veículos finais"
                    :persistenthint="true"
                  />
                </v-col>
              </v-row> -->
              <!-- linha dos input -->
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model="relatorio.start"
                    label="Data Inicial"
                    required
                    outlined
                    type="datetime-local"
                    @input="$refs.relatorioEnd.validate()"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field
                    ref="relatorioEnd"
                    v-model="relatorio.end"
                    label="Data Final"
                    required
                    outlined
                    type="datetime-local"
                    :rules="[v => validaDataFinal(v) || 'A data final deve ser maior que a inicial']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-4">
            <MenuExportReport
              :disabled="!getFleetReportM.length"
              :items="getFleetReportM"
              title="Relatório de Frotas"
              filename="relatorio_de_frotas"
              :reportItens="reportItens"
            />
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="loadReport">
              <v-icon class="mr-1">mdi-magnify</v-icon> Buscar Relatório
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
      <TableFrotaMulti
        :headers="headers"
        :items="getFleetReportM"
        :report="true"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableFrotaMulti from "@/components/Frota/TableFleetMulti";
import MenuExportReport from "@/components/Relatorios/MenuExportReportCustom.vue";
export default {
  name: "RelatorioVeiculo",
  components: {
    TableFrotaMulti,
    MenuExportReport,
  },
  props: ["cliente"],
  computed: {
    ...mapGetters(["getFleetReportM", "getFleetsSelect", "getDriversSelect"]),
  },
  data() {
    return {
      headers: [
        { text: "Imagens Iniciais", value: "pictures", align:'center', sortable: false, filterable: false, width: 70 },
        { text: "Motoristas Inciais", value: "drivers", sortable: false, filterable: false, width: 300 },
        { text: "Veículos Inciais", value: "fleetVehicles", sortable: false, filterable: false, width: 300 },
        { text: "Descrição Incial", value: "description", width: 200 },
        { text: "Imagens Finais", value: "finalPictures", align:'center', sortable: false, filterable: false, width: 70 },
        { text: "Motoristas Finais", value: "finalDrivers", sortable: false, filterable: false, width: 300 },
        { text: "Veiculos Finais", value: "finalFleetVehicles", sortable: false, filterable: false, width: 300 },
        { text: "Descrição Final", value: "descriptionFinish", width: 200 },
        { text: "Sentido", value: "direction" },
        { text: "Início", value: "createdAt" },
        { text: "Fim", value: "updatedAt" },
        { text: "Tempo", value: "timeDif" },
        { text: "Km Inicial", value: "kmStart" },
        { text: "Km Final", value: "kmEnd" },
        { text: "Km Percorrido", value: "kmDif" },
      ],
      relatorio: {
        clientId: this.cliente ? this.cliente.id : undefined,
        withPictures: true,
        driverIds: [],
        finalDriverIds: [],
        fleetVehicleIds: [],
        finalFleetVehicleIds: [],
        description: "",
        start: "",
        end: "",
        kmStart: "",
        kmEnd: "",
      },
      reportItens: [
        {
          text: "PDF sem imagens",
          type: "pdf",
          icon: "mdi-file-document-outline",
          module: "fleetMulti",
        },
        {
          text: "PDF com imagens",
          type: "pdfimg",
          icon: "mdi-file-image-outline",
          module: "fleetMulti",
        },
        {
          text: "EXCEL sem imagens",
          type: "excel",
          icon: "mdi-file-excel-outline",
          module: "fleetMulti",
        },
        {
          text: "EXCEL sem imagens (Antigo, 1 Motorista e 1 Veículo)",
          type: "excel",
          icon: "mdi-file-excel-outline",
          module: "fleetSimpleConv",
        },
      ],
    };
  },
  methods: {
    loadReport() {
      if (this.$refs.formReport.validate()){
        this.relatorio.groupId = this.$store.getters.getCliente.groupId;
        this.$store.dispatch("loadFleetsMReport", this.relatorio);
      }
    },
    validaDataFinal(dataFinal) { // função auxiliar pois este teste direto no rules não funciona corretamente
      return new Date(dataFinal) > new Date(this.relatorio.start);
    },
  },
  created() {
    this.$store.dispatch("setFleetReportM", []);
    // pega a data atual e seta no relatorio
    let now = new Date();
    let onlyDate = now.toISOString().substring(0, 11);
    this.relatorio.start = onlyDate + "00:00";
    this.relatorio.end = onlyDate + "23:59";
  },
};
</script>

<style>
</style>