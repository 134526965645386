<template>
  <div class="cliTurnoExec">
    <v-card-title>Turnos de Trabalho em execução</v-card-title>
    <v-card-text>
      <DataTable :items="turnos" :headers="headers">
        <template slot="item.interval" slot-scope="{ item }">
          <span>{{
            mostraHora(
              item.payload.horario.startInterval,
              item.payload.horario.endInterval
            )
          }}</span>
        </template>

        <template slot="item.turnoId" slot-scope="{ item }">
          {{ getTurnoNameById(item.turnoId) }}
        </template>

        <template slot="item.horario" slot-scope="{ item }">
          <span>{{
            mostraHora(item.payload.horario.start, item.payload.horario.end)
          }}</span>
        </template>

        <template slot="item.deviceId" slot-scope="{ item }">
          {{ item.deviceId ? getDispositivoNameById(item.deviceId) : "-" }}
        </template>

        <template slot="item.status" slot-scope="{ item }">
          <v-chip :color="item.userId ? 'green' : 'red'" outlined>
            {{ item.userId ? "Posto coberto" : "Posto sem cobertura" }}
          </v-chip>
        </template>

        <template slot="item.userId" slot-scope="{ item }">
          {{ item.userId ? getUserNameById(item.userId) : "-" }}
        </template>

        <template slot="item.createdAt" slot-scope="{ item }">
          {{ item.userId ? new Date(item.createdAt).toLocaleString() : "" }}
        </template>

        <template slot="item.actions" slot-scope="{ item }">
          <!-- botão forçar finalização -->
          <TooltipIcon
            color="green"
            :text="`Forçar finalização`"
            icon="mdi-check"
            left="true"
            :disabled="item.userId == 0"
            @click="finalizaTurno(item)"
          />
        </template>
      </DataTable>
    </v-card-text>

    <!-- Dialog de finalização -->
    <v-dialog v-model="dialogFinalizacao" persistent max-width="800">
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-0"
          >Finalizar turno de trabalho<v-spacer></v-spacer>
          <v-btn icon @click="closeCadastro">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-form ref="formTurno">
            <v-card outlined class="mb-5 py-2">
              <v-row class="mx-3">
                <v-col class="pb-0">
                  <span>
                    Turno de trabalho:
                    <strong>{{
                      turno.turnoId ? getTurnoNameById(turno.turnoId) : "-"
                    }}</strong>
                  </span>
                </v-col>
                <v-col>
                  <span>
                    Funcionário:
                    <strong>{{
                      turno.userId ? getUserNameById(turno.userId) : "-"
                    }}</strong>
                  </span>
                </v-col>
              </v-row>
              <v-row class="mx-3">
                <v-col class="pb-0">
                  <span>
                    Posto:
                    <strong>{{
                      turno.payload.postoName ? turno.payload.postoName  : "-"
                    }}</strong>
                  </span>
                </v-col>
                <v-col>
                  <span>
                    Dispositivo:
                    <strong>{{
                      turno.deviceId
                        ? getDispositivoNameById(turno.deviceId)
                        : "-"
                    }}</strong>
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-row>
              <v-col class="pt-0">
                <v-textarea
                  v-model="turno.payload.justificativa"
                  label="Justificativa da finalização"
                  outlined
                  :rules="requiredField"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn text @click="closeCadastro">Cancelar</v-btn>
          <v-btn color="success" @click="salvarTurno()"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import DataTable from "../../DataTableGeneric";
import ConfirmDialog from "@/components/ConfirmDialog";
import { mapGetters } from "vuex";
import TooltipIcon from "../../Template/TooltipIcon";

export default {
  name: "CliTurnoExec",
  components: {
    DataTable,
    ConfirmDialog,
    TooltipIcon,
  },
  props: {
    cliente: {
      type: Object,
      default: () => {},
    },
    turnos: {
      type: Array,
      default: () => {},
    },
  },
  data: () => {
    return {
      mensagem: "",
      dialogFinalizacao: false,
      requiredField,
      turno: {
        payload: {
          horario: {
            start: "",
            end: "",
            startInterval: "",
            endInterval: "",
          },
          justificativa: "",
        },
      },
      headers: [
        { text: "Nome do Turno", value: "turnoId" },
        { text: "Posto", value: "payload.postoName" },
        { text: "Horário Turno", value: "horario" },
        {
          text: "Intervalo Turno",
          value: "interval",
          sortable: false,
        },
        { text: "Funcionário", value: "userId" },
        { text: "Dispositivo", value: "deviceId" },
        { text: "Iniciado", value: "createdAt" },
        { text: "Status", value: "status" },
        {
          text: "Ações",
          value: "actions",
          align: "center",
          sortable: false,
          filterable: false,
          width: 100,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getDispositivoNameById",
      "getTurnos",
      "getTurnoNameById",
      "getUserNameById",
    ]),
  },
  methods: {
    reset() {
      this.turno = {
        id: null,
        postoId: null,
        clientId: this.cliente.id,
        payload: {
          horario: {
            start: "",
            end: "",
            startInterval: "",
            endInterval: "",
          },
          justificativa: "",
        },
      };
    },
    closeCadastro() {
      this.$refs.formTurno.resetValidation();
      this.dialogFinalizacao = false;
      this.reset();
    },
    salvarTurno() {
      if (this.$refs.formTurno.validate()) {
        this.$store.dispatch("forceFinishTurno", this.turno);
        this.dialogFinalizacao = false;
        this.$refs.formTurno.resetValidation();
        this.reset();
      }
    },
    mostraHora(temp1, temp2) {
      if (temp1 == "00:00:00" && temp2 == "00:00:00") return "Sem intervalo";
      if (!temp1 || !temp2) return "Sem intervalo";
      let hora1 = temp1.split(":");
      let hora2 = temp2.split(":");
      return `${hora1[0]}:${hora1[1]} - ${hora2[0]}:${hora2[1]}`;
    },
    finalizaTurno(item) {
      this.turno = { ...item };
      this.turno.payload.justificativa = "";
      this.dialogFinalizacao = true;
    },
  },
  created() {
    this.reset();
    this.$store.dispatch(
      "loadDispositivos",
      this.$store.getters.getCliente.groupId
    );
    this.$store.dispatch("loadUsers", this.$store.getters.getCliente.groupId);
  },
  mounted() {},
};
</script>

<style scoped>
.btn-dropdown {
  background-color: #fff;
}
</style>

<style>
</style>