var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.report)?[_c('v-card-title',{staticClass:"pt-6"},[_vm._v("Rondas")]),_c('v-card-subtitle',[_vm._v("Clique na tabela para mais detalhes")])]:_vm._e(),_c('v-card-text',[_c('DataTableGeneric',{attrs:{"headers":_vm.headers,"items":_vm.items},on:{"headersChanged":function($event){return _vm.$emit('headersChanged', $event)},"rowClick":_vm.openModalRondas},scopedSlots:_vm._u([{key:"item.finished",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.finished ? "Sim" : "Não"))])]}},{key:"item.rondaId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.verificaNomeRonda(item.rondaId)))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDia(item.createdAt)))])]}},{key:"item.payload.checkpoints",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.verificaPonto(item.payload.checkpoints)))]),(item.payload.checkpoints.length > 1)?_c('v-menu',{attrs:{"open-on-hover":"","top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.payload.checkpoints),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.formataData(item.checkedAt)))])],1)],1)}),1)],1):_vm._e()]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getUserNameById(item.userId).split(' - ')[1]))])]}},{key:"item.syncAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.payload.syncAt ? new Date(item.payload.syncAt).toLocaleString() : ''))])]}},{key:"item.clientId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getClienteNameById(item.clientId).split(' - ')[1]))])]}}])})],1),_c('DialogRonda',{ref:"dialogRonda"}),(!_vm.report)?_c('ConfirmDialog',{ref:"confirmDialog"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }