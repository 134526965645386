var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.report)?[_c('v-card-title',{staticClass:"pt-6"},[_vm._v("Frotas Iniciadas (Antiga)")]),_c('v-card-subtitle',[_vm._v("Clique na tabela para mais detalhes")])]:_vm._e(),_c('v-card-text',[_c('DataTable',{attrs:{"headers":_vm.headers,"items":_vm.items},on:{"rowClick":function($event){return _vm.openFleetDetails($event)}},scopedSlots:_vm._u([{key:"item.picturesStart",fn:function(ref){
var item = ref.item;
return [_c('CardImg',{attrs:{"items":item.picturesStart,"width":70,"multiple":false}})]}},{key:"item.picturesExit",fn:function(ref){
var item = ref.item;
return [_c('CardImg',{attrs:{"items":item.picturesExit,"width":70,"multiple":false}})]}},{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_c('ItemMotorista',{staticClass:"px-0",attrs:{"item":item.driver}})]}},{key:"item.vehicle",fn:function(ref){
var item = ref.item;
return [_c('ItemVeiculo',{staticClass:"px-0",attrs:{"item":item.vehicle}})]}},{key:"item.direction",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("" + (item.direction ? "Entrada" : "Saída")))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formataData(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formataData(item.updatedAt))+" ")]}},{key:"item.timeDif",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getIntervalo(item.createdAt, item.updatedAt))+" ")]}},{key:"item.kmDif",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Math.abs(item.kmEnd - item.kmStart))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"orange lighten-1"},on:{"click":function($event){$event.stopPropagation();return _vm.regOutFrota(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1)]}}],null,true)},[_c('span',[_c('strong',[_vm._v("Finalizar Frota")])])])]}}])})],1),_c('FleetDetails',{ref:"modalFleetDetails",attrs:{"actions":_vm.report ? false : _vm.actions},on:{"out":function($event){return _vm.regOutFrota($event)}}}),(!_vm.report)?_c('DialogFrotaFinish',{ref:"dialogFleetFinish",on:{"closeFleetDetails":_vm.closeFleetDetails}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }