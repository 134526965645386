import axios from 'axios'
export default {
    state: {
        schedules: [],
        triggers: [],
        tasks: [],
        tasksAvailable: [],
        schedule: {}
    },
    mutations: {
        setSchedules(state, payload) {
            state.schedules = payload
        },
        setTriggers(state, payload) {
            state.triggers = payload
        },
        setTasks(state, payload) {
            state.tasks = payload
        },
        setTasksAvailable(state, payload) {
            state.tasksAvailable = payload
        },
        setSchedule(state, payload) {
            state.schedule = payload
        },
        clearSelected(state) {
            state.schedule = []
            state.tasks = []
            state.triggers = []
        },
    },
    actions: {
        setSchedules(context, payload) {
            context.commit('setSchedules', payload)
        },
        setTriggers(context, payload) {
            context.commit('setTriggers', payload)
        },
        setTasks(context, payload) {
            context.commit('setTasks', payload)
        },
        setTasksAvailable(context, payload) {
            context.commit('setTasksAvailable', payload)
        },
        setSchedule(context, payload) {
            context.commit('setSchedule', payload)
            context.dispatch('loadTriggers', payload)
            context.dispatch('loadTasks', payload)
        },
        clearSelected(context) {
            context.commit('clearSelected')
        },
        loadSchedules(context) {
            axios(`cron`).then((resp) => {
                resp.data.forEach((ele) => {
                    ele.ativo = ele.enabled ? "SIM" : "NÃO";
                });
                context.dispatch('setSchedules', resp.data)
            });
        },
        // loadSchedules(context) {
        //     axios(`schedule`).then((resp) => {
        //         resp.data.forEach((ele) => {
        //             ele.ativo = ele.enabled ? "SIM" : "NÃO";
        //         });
        //         context.dispatch('setSchedules', resp.data)
        //     });
        // },
        loadTriggers(context, payload) {
            let id = context.getters.getSchedule.id
            if (payload) {
                if (payload.id) {
                    id = payload.id
                }
            }
            axios(`schedule/triggers/${id}`).then((resp) => {
                if (resp.status == 200) {
                    resp.data.forEach((ele) => {
                        // ele.weeklyRepeatDay = JSON.parse(ele.weeklyRepeatDay)
                        // ele.monthlyM = JSON.parse(ele.monthlyM)
                        // ele.monthlyD = JSON.parse(ele.monthlyD)
                        ele.ativo = ele.enabled ? "SIM" : "NÃO";
                    });
                    context.dispatch('setTriggers', resp.data)
                }
            });
        },
        loadTasksAvailable(context) {
            axios(`schedule/tasksavailable`).then((resp) => {
                context.dispatch('setTasksAvailable', resp.data)
            });
        },
        loadTasks(context, payload) {
            // let id = payload ? payload.id : context.getters.getSchedule.id
            // if (id) {
            axios(`schedule/tasks/${payload.id}`).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch('setTasks', resp.data)
                }
            });
            // }
        },
        saveSchedule(context, obj) {
            const metodo = obj.id ? "put" : "post";
            const finalUrl = obj.id ? `/${obj.id}` : "";
            axios[metodo](`/cron${finalUrl}`, obj).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Agendamento salvo com Sucesso");
                    // context.dispatch('setSchedule', resp.data)
                    // context.dispatch('loadSchedules')
                    setTimeout(() => {
                        context.dispatch('loadSchedules')
                    }, 1000)
                }
            });
        },
        // saveSchedule(context, obj) {
        //     const metodo = obj.id ? "put" : "post";
        //     const finalUrl = obj.id ? `/${obj.id}` : "";
        //     axios[metodo](`/schedule${finalUrl}`, obj).then((resp) => {
        //         if (resp.status < 299) {
        //             context.dispatch("resetSnack");
        //             context.dispatch("showSuccessSnack", "Agendamento salvo com Sucesso");
        //             context.dispatch('setSchedule', resp.data)
        //             context.dispatch('loadSchedules')
        //         }
        //     });
        // },
        saveTrigger(context, obj) {
            // const metodo = obj.id ? "put" : "post";
            // const finalUrl = obj.id ? `/${obj.id}` : "";
            // axios[metodo](`/schedule/triggers${finalUrl}`, obj).then((resp) => {
            //     if (resp.status < 299) {
            //         context.dispatch("resetSnack");
            //         context.dispatch("showSuccessSnack", "Disparador salvo com Sucesso");
            //         context.dispatch('loadTriggers')
            //         context.dispatch('loadSchedules')
            //     }
            // });
            console.log(context);
            console.log(obj);
        },
        saveTask(context, obj) {
            const metodo = obj.id ? "put" : "post";
            const finalUrl = obj.id ? `/${obj.id}` : "";
            axios[metodo](`/schedule/tasks${finalUrl}`, obj).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Ação salvo com Sucesso");
                    setTimeout(() => {
                        context.dispatch('loadTasks', { id: obj.schedulerId })
                    }, 1000)
                }
            });
        },
        runTask(context, obj) {
            const finalUrl = obj.id ? `/${obj.id}` : "";
            axios.post(`/schedule/tasks/run${finalUrl}`, obj).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Ação Executada com Sucesso");
                }
            });
        },
        delTrigger(context, obj) {
            axios.delete(`/schedule/triggers/${obj.id}`, obj).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Disparador removido com Sucesso");
                    context.dispatch('loadTriggers')
                }
            });
        },
        delTask(context, obj) {
            axios.delete(`/schedule/tasks/${obj.id}`, obj).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Ação removido com Sucesso");
                    setTimeout(() => {
                        context.dispatch('loadTasks', { id: obj.schedulerId })
                    }, 1000)

                }
            });
        },
        deleteSchedule(context, obj) {
            axios.delete(`/cron/${obj.id}`).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Agendamento removido com Sucesso");
                    context.dispatch('loadSchedules')
                }
                setTimeout(() => {
                    context.dispatch('loadSchedules')
                }, 1000)

            });
        },
        // deleteSchedule(context, obj) {
        //     axios.delete(`/schedule/${obj.id}`).then((resp) => {
        //         if (resp.status < 299) {
        //             context.dispatch("resetSnack");
        //             context.dispatch("showSuccessSnack", "Agendamento removido com Sucesso");
        //             // context.dispatch('loadTasks')
        //             context.dispatch('loadSchedules')
        //         }
        //         // context.dispatch("resetSnack");
        //         // context.dispatch(
        //         //     "showSuccessSnack",
        //         //     "Agendamento deletado com Sucesso!"
        //         // );
        //     });
        // },
        clearSelectedTask(context) {
            context.dispatch('clearSelected')
        },
    },
    getters: {
        getSchedules(state) {
            return state.schedules
        },
        getSchedule(state) {
            return state.schedule
        },
        getTriggers(state) {
            return state.triggers
        },
        getTasks(state) {
            return state.tasks
        },
        getTasksAvailable(state) {
            return state.tasksAvailable
        },
    }
}