<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5 pr-4 mb-2">
          {{ aviso.id ? "Editar" : "Novo" }} aviso
          <v-spacer></v-spacer>
          <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pt-1 pb-0">
          <v-form ref="formAviso">
            <v-select
              v-model="aviso.eventId"
              :items="eventos"
              item-text="name"
              item-value="id"
              label="Evento"
              required
              outlined
              :rules="[rules.required]"
            ></v-select>
          </v-form>
          <v-text-field
            label="Adicionar Email"
            v-model="tempEmail"
            @keyup.enter="addEmail"
            outlined
            :rules="[verificaEmailDuplicado, ruleEmail]"
          >
            <template v-slot:append-outer>
              <TooltipIcon
                :text="`Adicionar email`"
                icon="mdi-plus"
                @click="addEmail"
                :top="'true'"
                :color="'success'"
                :disabled="!tempEmail"
              />
            </template>
          </v-text-field>
          <v-card outlined class="mb-3">
            <v-simple-table dense height="150px" fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Email</th>
                    <th class="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(email, index) in aviso.emails" :key="index">
                    <td>{{ email }}</td>
                    <td class="text-right">
                      <v-btn icon small @click="delEmail(index)" color="red">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-checkbox
            v-model="aviso.sendWithoutTreatment"
            label="Enviar sem Tratamento"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">Cancelar</v-btn>
          <v-btn color="success" @click="save">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>
<script>
import TooltipIcon from "../../Template/TooltipIcon.vue";
import ConfirmDialog from "../../ConfirmDialog.vue";

export default {
  name: "CadAvisoEventos",
  components: {
    TooltipIcon,
    ConfirmDialog,
  },
  data: () => ({
    dialog: false,
    aviso: {
      id: null,
      eventId: null,
      clientId: null,
      emails: [],
      groupId: null,
      sendWithoutTreatment: false,
    },
    eventos: [],
    tempEmail: "",
    rules: {
      required: (value) => !!value || "Campo obrigatório",
      email: (value) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(value) || "E-mail inválido";
      },
    },
    ruleEmail: "",
  }),
  methods: {
    open(cliente, eventTypes, evento) {
      this.aviso.clientId = cliente.id;
      this.aviso.groupId = cliente.groupId;
      this.eventos = eventTypes;
      if (evento) {
        this.aviso.eventId = evento.eventId;
        this.aviso.emails = evento.emails;
        this.aviso.id = evento.id;
        this.aviso.sendWithoutTreatment = evento.sendWithoutTreatment;
      }
      this.dialog = true;
    },
    close() {
      this.$refs.formAviso.resetValidation();
      this.aviso = {
        id: null,
        eventId: null,
        clientId: null,
        emails: [],
        groupId: null,
        sendWithoutTreatment: false,
      };
      this.dialog = false;
    },
    save() {
      this.$store.dispatch("resetSnack");
      if (!this.aviso.emails || this.aviso.emails.length === 0) {
        this.$refs.formAviso.validate();
        this.$store.dispatch("showErrorSnack", "Informe pelo menos um e-mail");
        return;
      }
      const valid = this.$refs.formAviso.validate();
      if (valid) {
        if(!this.aviso.sendWithoutTreatment) this.aviso.sendWithoutTreatment = false;
        if (this.aviso.id) {
          this.$http
            .put(`/avisoeventos/${this.aviso.id}`, this.aviso)
            .then(() => {
              this.$emit("refresh");
              this.close();
            })
            .catch((err) => {
              console.log(err);
            });
          return;
        }
        this.$http
          .post("/avisoeventos", this.aviso)
          .then(() => {
            this.$emit("refresh");
            this.close();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addEmail() {
      if (!this.tempEmail) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showErrorSnack", "Adicione um e-mail");
        return;
      }
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!pattern.test(this.tempEmail)) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showErrorSnack", "E-mail inválido");
        return;
      }
      if (!this.aviso.emails) {
        this.aviso.emails = [];
      }
      if (this.aviso.emails.indexOf(this.tempEmail) < 0) {
        this.aviso.emails.push(this.tempEmail);
        this.tempEmail = "";
        this.$refs.formAviso.resetValidation();
      }
    },
    verificaEmailDuplicado() {
      if (!this.aviso.emails) {
        return true;
      }
      return (
        this.aviso.emails.indexOf(this.tempEmail) < 0 || "E-mail já adicionado"
      );
    },
    delEmail(index) {
      this.$refs.confirmDialog.open({
        title: "Excluir E-mail",
        text: `Deseja realmente excluir o e-mail <strong>${this.aviso.emails[index]}</strong>?`,
        confirmCallback: () => {
          this.aviso.emails.splice(index, 1);
        },
      });
    },
  },
  watch: {
    tempEmail(val) {
      if (val && val.length > 0) {
        this.ruleEmail = this.rules.email(val);
      } else {
        this.ruleEmail = true;
        this.$refs.formAviso.resetValidation();
      }
    },
  },
};
</script>