<template>
  <div>
    <v-card>
      <v-card-title> Relatório de Visitas </v-card-title>
      <v-card-text>
        <!-- linha do filtro -->
        <v-card outlined>
          <v-card-text class="pb-0">
            <p class="text-subtitle-1 pt-1 pb-3">Filtros</p>
            <v-form ref="formReport">
              <!-- linha das pessoas -->
              <v-row>
                <v-col class="py-0">
                  <AutComImg
                    v-model="relatorio.visitado"
                    :items="getVisitadosSelect"
                    label="Selecione os moradores"
                  />
                </v-col>
                <v-col class="py-0">
                  <AutComImg
                    v-model="relatorio.visitante"
                    :items="getVisitantesSelect"
                    label="Selecione os visitantes"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0">
                  <v-text-field
                    v-model="relatorio.start"
                    label="Data Inicial"
                    required
                    outlined
                    type="datetime-local"
                    @input="$refs.relatorioEnd.validate()"
                  ></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field
                    ref="relatorioEnd"
                    v-model="relatorio.end"
                    label="Data Final"
                    required
                    outlined
                    type="datetime-local"
                    :rules="[
                      (v) =>
                        validaDataFinal(v) ||
                        'A data final deve ser maior que a inicial',
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-4">
            <MenuExportReport
              :disabled="!getRelVisitas.length"
              :items="getRelVisitas"
              module="visitasCond"
              title="Relatório de Visitas"
              filename="relatorio_de_visitas"
            />
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="loadReport">
              <v-icon class="mr-1">mdi-magnify</v-icon> Buscar Relatório
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- linha da tabela -->
      </v-card-text>
      <TablePresents
        :headers="headers"
        :items="getRelVisitas"
        :report="true"
        @headersChanged="headersChanged"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AutComImg from "@/components/Relatorios/AutoCompleteImages.vue";
import MenuExportReport from "@/components/Relatorios/MenuExportReport.vue";
import TablePresents from "./TablePresents.vue";
export default {
  name: "RelatorioVisita",
  components: {
    AutComImg,
    TablePresents,
    MenuExportReport,
  },
  computed: {
    ...mapGetters([
      "getRelVisitas",
      "getVisitadosSelect",
      "getVisitantesSelect",
    ]),
  },
  data() {
    return {
      headers: [
        { text: "Visitado", value: "visitadoNameReport" },
        { text: "Visitante", value: "visitanteNameReport" },
        { text: "Documento visitante", value: "visitanteDoc" },
        { text: "Emp. Visitante", value: "visitanteRef" },
        { text: "Tipo da Visita", value: "visitaMotivo" },
        { text: "Descrição", value: "description" },
        { text: "Entrada", value: "createdAtReport" },
        { text: "Saída", value: "updatedAtReport" },
        { text: "Permanência", value: "perm" },
      ],
      relatorio: {},
      selectedHeaders: [],
    };
  },
  methods: {
    loadReport() {
      if (this.$refs.formReport.validate()){
        this.relatorio.groupId = this.$store.getters.getCliente.groupId;
        this.$store.dispatch("loadReportVisit", this.relatorio);
      }
    },
    validaDataFinal(dataFinal) {
      // função auxiliar pois este teste direto no rules não funciona corretamente
      return new Date(dataFinal) > new Date(this.relatorio.start);
    },
    // pego o evento de modificação dos headers
    headersChanged(headers) {
      this.selectedHeaders = headers;
    },
  },
  created() {
    // pega a data atual e seta no relatorio
    let now = new Date();
    let onlyDate = now.toISOString().substring(0, 11);
    this.relatorio.start = onlyDate + "00:00";
    this.relatorio.end = onlyDate + "23:59";
    this.$store.dispatch("setShowDialog", false);
  },
};
</script>

<style>
.mao {
  cursor: pointer;
}
</style>