<template>
	<div>
		<v-dialog
			v-model="dialog"
			max-width="800"
			@click:outside="closeOcorrencia"
			scrollable
		>
			<v-card>
				<v-card-title class="text-h5 pb-4">Detalhes da Ocorrência</v-card-title>
				<v-card-text class="py-0">
					<v-card outlined>
						<v-card-title class="py-2 intermed-title"
							>Ocorrência: {{ ocorrencia.name }}</v-card-title
						>
						<v-card-text>
							<v-row class="mb-3">
								<v-col class="py-2">
									<span
										><strong>Cliente:</strong> {{ ocorrencia.clientId }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Funcionário:</strong> {{ ocorrencia.userId }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Comunicante:</strong>
										{{ ocorrencia.communicant }}</span
									>
								</v-col>
							</v-row>
							<v-row class="mb-3">
								<v-col class="py-2">
									<span
										><strong>Data do Fato:</strong>
										{{ formataData(ocorrencia.factDate) }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Data do registro:</strong>
										{{ formataData(ocorrencia.createdAt) }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Prioridade: </strong
										>{{
											prioridade[ocorrencia.priority - 1]
												? prioridade[ocorrencia.priority - 1].text
												: ""
										}}
									</span>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Tipo:</strong> {{ ocorrencia.type == 'ronda' ? `Ronda (Id - ${ocorrencia.idType})` : 'Registro' }}</span
									>
								</v-col>
							</v-row>
							<v-row class="mb-3">
								<v-col class="pt-2 pb-0">
									<span
										><strong>Descrição:</strong>
										{{ ocorrencia.description }}</span
									>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-card-title class="py-2 mb-2 intermed-title"
								>Imagens</v-card-title
							>
							<v-row class="my-3" v-show="ocorrencia.pictures.length === 0">
								<v-col>
									<span class="my-5">Não há imagens.</span>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="py-0" > 
									<v-carousel
										v-if="ocorrencia.pictures && ocorrencia.pictures.length"
										v-model="carouselPosition"
										height="200"
										hide-delimiters
										class="mb-5"
									>
										<template v-for="(img, index) in ocorrencia.pictures">
											<v-carousel-item
												v-if="
													(index + 1) % carouselColumns === 1 ||
													carouselColumns === 1
												"
												:key="index"
											>
												<v-row
													class="flex-nowrap"
													style="height: 100%; margin: -4px"
												>
													<template v-for="(col, i) in carouselColumns">
														<template
															v-if="+index + i < ocorrencia.pictures.length"
														>
															<v-col
																:key="i"
																:cols="12 / carouselColumns"
																class="pa-1"
															>
																<v-img
																	hover
																	:src="ocorrencia.pictures[+index + i]"
																	width="100%"
																	height="100%"
																	class="d-block rounded"
																	@click="openImg(+index + i)"
																></v-img>
															</v-col>
														</template>
													</template>
												</v-row>
											</v-carousel-item>
										</template>
									</v-carousel>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-card-title class="py-2 mb-2 intermed-title"
								>Áudios</v-card-title
							>
							<v-row class="my-3" v-show="ocorrencia.audios? ocorrencia.audios.length === 0 : true">
								<v-col>
									<span class="my-5">Não há áudios.</span>
								</v-col>
							</v-row>
							<v-row v-for="(aud, idx) in ocorrencia.audios" :key="idx">
								<v-col class="text-center">
									<AudioPlayer
										:audio="aud"
										:transcricao="
											ocorrencia.transcriptions
												? ocorrencia.transcriptions[idx].text
												: ''
										"
									/>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="gerarRelatorioSolo(ocorrencia)">
						Exportar Relatório
					</v-btn>
					<v-btn color="success" text @click="closeOcorrencia">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Modal Imagem Grande -->
		<v-dialog v-model="dialogImg" max-width="800px" scrollable>
			<v-card>
				<v-card-title>
					<v-icon class="mr-1">mdi-image</v-icon>
					Imagem: {{ ocorrencia.name }}
				</v-card-title>
				<v-card-text>
					<v-carousel v-model="position">
						<v-carousel-item
							v-for="(img, idx) in ocorrencia.pictures"
							:key="idx"
						>
							<v-img :src="img" width="100%" height="100%"></v-img>
						</v-carousel-item>
					</v-carousel>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="downloadImg(ocorrencia.pictures[position])"
					>
						<v-icon class="mr-1">mdi-download</v-icon> Baixar
					</v-btn>
					<v-btn color="success" text @click="dialogImg = false">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import AudioPlayer from "../Ocorrencias/AudioPlayer.vue";
import PDF from "@/Utils/PDF";
import File from "@/Utils/File";

export default {
	name: "DialogOcorrencia",
	components: {
		AudioPlayer,
	},
	data: () => ({
		dialog: false,
		position: 0,
		imgs: [],
		img: "",
		dialogImg: false,
		carouselColumns: 3,
		carouselPosition: 0,
		ocorrencia: {
			id: null,
			name: "",
			description: "",
			priority: 0,
			userId: null,
			communicant: null,
			factDate: null,
			clientId: null,
			groupId: null,
			pictures: [],
			type: "",
		},
		prioridade: [
			{ text: "Alta", value: 1, color: "red" },
			{ text: "Média", value: 2, color: "orange" },
			{ text: "Baixa", value: 3, color: "green" },
			{ text: "Somente Registro", value: 4, color: "blue" },
		],
	}),
	methods: {
		openOcorrencia(ocorrencia) {
			this.ocorrencia = { ...ocorrencia };
			this.dialog = true;
		},
		closeOcorrencia() {
			this.dialog = false;
		},
		openImg(index) {
			this.position = index;
			this.dialogImg = true;
		},
		downloadImg(img) {
			let imag = new Image();
			imag.onload = function () {
				let canvas = document.createElement("canvas");
				let context = canvas.getContext("2d");
				canvas.width = this.width;
				canvas.height = this.height;
				context.drawImage(this, 0, 0);
				let imageData = canvas.toDataURL();
				let blob = File.dataURItoBlob(imageData);
				File.downloadFile(blob, "ImageName.png");
			};
			imag.crossOrigin = "anonymous";
			imag.src = img;
		},
		formataData(data) {
			if (data) {
				let dataFormatada = new Date(data);
				return dataFormatada.toLocaleString();
			}
		},
		gerarRelatorioSolo(item) {
			this.$store.dispatch("setTitleDialog", "Gerando o Relátorio");
			this.$store.dispatch("setShowDialog", true);
			let ocorrencia = { ...item };
			ocorrencia.priority = this.prioridade[item.priority - 1].text;
			ocorrencia.type = ocorrencia.type == 'ronda' ? `Ronda (Id - ${ocorrencia.idType})` : 'Registro'
			let itens = [];
			itens.push(ocorrencia);
			PDF["ocorrenciaSolo"](
				itens,
				"Relatório de Ocorrência",
				"relatorio_de_ocorrencia.pdf"
			);
		},
	},
};
</script>

<style>
</style>