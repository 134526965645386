import axios from 'axios'
export default {
    state: {
        ClientTypes: [],
    },
    mutations: {
        setClientTypes(state, payload) {
            state.ClientTypes = payload
        },

    },
    actions: {
        setClientTypes(context, payload) {
            context.commit('setClientTypes', payload)
        },
        loadClientTypes(context, payload) {
            let gp = payload ? `?groupId=${payload}` : ''
            axios(`clientType${gp}`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                });
                context.commit('setClientTypes', resp.data)
            });
        },
        loadAllClientTypes(context) {
            axios(`clientType/all`).then((resp) => {
                resp.data.forEach(element => {
                    element.text = `${element.id} - ${element.name}`
                    element.value = element.id
                    element.ativo = element.enabled ? "SIM" : "NÃO";
                });
                context.commit('setClientTypes', resp.data)
            });
        },
        saveClientType(context, grupo) {
            const metodo = grupo.id ? "put" : "post";
            const finalUrl = grupo.id ? `/${grupo.id}` : "";
            axios[metodo](`/clientType${finalUrl}`, grupo).then((resp) => {
                if (resp.status < 300) {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "ClientType salvo com Sucesso");
                context.dispatch('loadClientTypes')
                }
            });
        },
        deletClientType(context, grupo) {
            axios.delete(`/clientType/${grupo.id}`).then((resp) => {
                if (resp.status < 300) {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "ClientType Deletado com Sucesso");
                context.dispatch('loadClientTypes')
                }
            });
        },
    },
    getters: {
        getClientTypes(state) {
            return state.ClientTypes
        },
        getClientTypeNameById: (state) => (id) => {
            if (!state.ClientTypes) {
                return id
            }
            let gp = state.ClientTypes.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : ''
        },
        getClientTypesSelect(state) {
            let sel = []
            state.ClientTypes.forEach(e =>{
                sel.push({value: e.id, text:`${e.id} - ${e.name}`})
            })
            return sel
        },
        getClientTypeById: (state) => (id) => {
            if (!state.ClientTypes) {
                return id
            }
            let obj = state.ClientTypes.find(g =>
                g.id == id
            )
            return obj
        },
        getClientTypesHeaders() {
            return [
                { text: "ID", value: "id" },
                { text: "Nome", value: "name" },
                { text: "Descrição", value: "description" },
                { text: "Ativo", value: "ativo" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        }
    },
}