import axios from 'axios'
export default {
    state: {
        dashBoard: {},
        graphData: []
    },
    mutations: {
        setDashBoard(state, payload) {
            state.dashBoard = payload
        },
        setGraphData(state, payload) {
            state.graphData = payload
        },

    },
    actions: {
        setDashBoard(context, payload) {
            context.commit('setDashBoard', payload)
        },
        setGraphData(context, payload) {
            context.commit('setGraphData', payload)
        },
        loadDashBoard(context) {
            axios.get('dashboard').then(resp => {
                if (resp.status != 200) {
                    return
                }
                context.commit('setDashBoard', resp.data)
            })
        },
        loadGraphData(context, payload) {
            axios.get(`dashboard/days${payload}`).then(resp => {
                if (resp.status != 200) {
                    return
                }
                context.commit('setGraphData', resp.data)
            })
        },
    },
    getters: {
        getDashBoard(state) {
            return state.dashBoard
        },
        getGraphData(state) {
            return state.graphData
        },
    },
}