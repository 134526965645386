<template>
	<div class="cliTurno">
		<v-card-title>Cadastros de Turnos de Trabalho</v-card-title>
		<v-card-text>
			<DataTable :items="turnos" :headers="headers">
				<template slot="item.name" slot-scope="{ item }">
					<TextTooltip :text="item.name" :maxWidth="'100'" />
				</template>

				<template slot="item.daysOfWeek" slot-scope="{ item }">
					<TextTooltip
						:text="daysToString(item.daysOfWeek)"
						:maxWidth="'180'"
					/>
				</template>

				<template slot="item.interval" slot-scope="{ item }">
					<span>{{ mostraHora(item.startInterval, item.endInterval) }}</span>
				</template>

				<template slot="item.horario" slot-scope="{ item }">
					<span>{{ mostraHora(item.start, item.end) }}</span>
				</template>

				<template slot="item.description" slot-scope="{ item }">
					<TextTooltip :text="item.description" :maxWidth="'200'" />
				</template>

				<template slot="item.tolerance" slot-scope="{ item }">
					<span>{{
						item.tolerance ? item.tolerance + " min" : "Sem tolerância"
					}}</span>
				</template>

				<template slot="item.postoId" slot-scope="{ item }">
					{{ getPostoNameById(item.postoId) }}
				</template>

				<template slot="item.actions" slot-scope="{ item }">
					<!-- botão editar -->
					<TooltipIcon
						color="warning"
						:text="`Editar: ${item.name}`"
						icon="mdi-pencil"
						left="true"
						@click="editTurno({ ...item })"
					/>
					<!-- botão excluir -->
					<TooltipIcon
						color="red"
						:text="`Excluir: ${item.name}`"
						icon="mdi-trash-can"
						left="true"
						@click="delTurno(item)"
					/>
				</template>
			</DataTable>
		</v-card-text>

		<!-- Dialog de cadastro -->
		<v-dialog v-model="dialogCadastro" persistent max-width="800">
			<v-card>
				<v-card-title class="text-h5 pr-4 mb-2"
					>{{ turno.id ? "Editar" : "Cadastrar" }} Turno de Trabalho<v-spacer
					></v-spacer>
					<v-btn icon @click="closeCadastro">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="pt-1 pb-0">
					<v-form ref="formTurno">
						<v-row>
							<v-col class="pb-0">
								<v-text-field
									v-model="turno.name"
									label="Nome do Turno"
									required
									:rules="requiredField"
									outlined
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="pt-0 pb-0">
								<v-select
									v-model="turno.daysOfWeek"
									:items="daysOfWeek"
									:menu-props="{ offsetY: true }"
									label="Dias da semana"
									multiple
									chips
									deletable-chips
									small-chips
									outlined
									:rules="[
										(v) => v.length > 0 || 'Selecione pelo menos um dia',
									]"
								>
									<template v-slot:prepend-item>
										<v-list-item
											ripple
											@mousedown.prevent
											@click="selAllSelectSemana"
										>
											<v-list-item-action>
												<v-icon>{{ iconSelectSemana }}</v-icon>
											</v-list-item-action>
											<v-list-item-content>
												<v-list-item-title>Todos os dias</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
										<v-divider class="mt-2"></v-divider>
									</template>
								</v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="pt-0 pb-0">
								<v-text-field
									v-model="turno.start"
									label="Hora inicial"
									type="time"
									required
									:rules="requiredField"
									@input="$refs.turnoEnd.validate()"
									outlined
								></v-text-field>
							</v-col>
							<v-col class="pt-0 pb-0">
								<v-text-field
									v-model="turno.end"
									ref="turnoEnd"
									label="Hora Final"
									type="time"
									required
									:rules="requiredField"
									outlined
								></v-text-field>
							</v-col>
							<v-col class="pt-0 pb-0">
								<v-text-field
									v-model="turno.startInterval"
									label="Inicio do Intervalo"
									type="time"
									@input="$refs.intervaloEnd.validate()"
									outlined
									clearable
									hint="Deixe em branco se não houver intervalo"
									:rules="[
										(v) =>
											testaPreenchimento(v, turno.endInterval) ||
											'Preencha o início do intervalo',
									]"
								></v-text-field>
							</v-col>
							<v-col class="pt-0 pb-0">
								<v-text-field
									v-model="turno.endInterval"
									ref="intervaloEnd"
									label="Fim do Intervalo"
									type="time"
									clearable
									:rules="[
										(v) =>
											testaPreenchimento(v, turno.startInterval) ||
											'Preencha o fim do intervalo',
										(v) =>
											validaIntervalo(
												turno.startInterval,
												v,
												turno.start,
												turno.end
											) || mensagem,
									]"
									outlined
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col class="pt-0 pb-0" cols="sm-9">
								<v-autocomplete
									v-model="turno.postoId"
									:items="opPostos"
									hide-selected
									label="Posto de Trabalho"
									outlined
									item-text="name"
									item-value="id"
									:rules="requiredField"
								>
									<template v-slot:item="{ item }">
										<span class="flex-grow-1">{{ item.name }}</span>
										<v-chip v-if="!item.enabled" x-small color="warning"
											>Posto inativo</v-chip
										>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col class="pt-0 pb-0" cols="sm-3">
								<v-text-field
									v-model="turno.tolerance"
									label="Tolerância de Atraso"
									type="number"
									min="0"
									outlined
									suffix="min"
									hint="Deixe em branco para usar a tolerância padrão"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="pt-0">
								<v-textarea
									v-model="turno.description"
									label="Observação"
									outlined
								></v-textarea>
							</v-col>
						</v-row>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="py-4">
					<v-spacer></v-spacer>
					<v-btn text @click="closeCadastro">Cancelar</v-btn>
					<v-btn color="success" @click="salvarTurno()"> Salvar </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-tooltip left>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="amber darken-1"
					dark
					absolute
					bottom
					right
					fab
					fixed
					class="mb-10"
					v-bind="attrs"
					v-on="on"
					@click="dialogCadastro = true"
				>
					<v-icon color="black">mdi-plus</v-icon>
				</v-btn>
			</template>
			<span>Adicionar Turno de Trabalho</span>
		</v-tooltip>

		<ConfirmDialog ref="confirmDialog" />
	</div>
</template>

<script>
import { requiredField } from "../../../Utils/InputValidation";
import DataTable from "../../DataTableGeneric";
import ConfirmDialog from "@/components/ConfirmDialog";
import TextTooltip from "../../Template/TextTooltip.vue";
import { mapGetters } from "vuex";
import cliente from "../../../store/modules/cliente";
import TooltipIcon from "../../Template/TooltipIcon";

export default {
	name: "CliTurnos",
	components: {
		DataTable,
		ConfirmDialog,
		TextTooltip,
		TooltipIcon,
	},
	props: {
		cliente: {
			type: Object,
			default: () => {},
		},
		turnos: {
			type: Array,
			default: () => {},
		},
		postos: {
			type: Array,
			default: () => {},
		},
	},
	data: () => {
		return {
			opPostos: [],
			mensagem: "",
			tempCliente: cliente,
			dialogCadastro: false,
			requiredField,
			turno: {
				id: null,
				name: "",
				start: "",
				end: "",
				startInterval: "",
				endInterval: "",
				tolerance: 0,
				daysOfWeek: [],
				description: "",
				postoId: null,
				clientId: null,
			},
			headers: [
				{ text: "Nome do Turno", value: "name" },
				{ text: "Horário", value: "horario", align: "center" },
				{
					text: "Intervalo",
					value: "interval",
					sortable: false,
					align: "center",
				},
				{ text: "Tolerancia", value: "tolerance" },
				{ text: "Posto", value: "postoId" },
				{ text: "Dias da Semana", value: "daysOfWeek", sortable: false },
				{ text: "Observação", value: "description" },
				{
					text: "Ações",
					value: "actions",
					align: "center",
					sortable: false,
					filterable: false,
					width: 100,
				},
			],
			daysOfWeek: [
				{ text: "domingo", value: 0, short: "dom" },
				{ text: "segunda-feira", value: 1, short: "seg" },
				{ text: "terça-feira", value: 2, short: "ter" },
				{ text: "quarta-feira", value: 3, short: "qua" },
				{ text: "quinta-feira", value: 4, short: "qui" },
				{ text: "sexta-feira", value: 5, short: "sex" },
				{ text: "sábado", value: 6, short: "sab" },
			],
		};
	},
	computed: {
		iconSelectSemana() {
			if (this.turno.daysOfWeek.length == this.daysOfWeek.length)
				return "mdi-close-box";
			else if (this.turno.daysOfWeek.length > 0) return "mdi-minus-box";
			else return "mdi-checkbox-blank-outline";
		},
		...mapGetters(["getPostos", "getPostoNameById", "getTurnos"]),
	},
	methods: {
		reset() {
			this.turno = {
				id: null,
				name: "",
				start: "",
				end: "",
				startInterval: "",
				endInterval: "",
				tolerance: 0,
				daysOfWeek: [],
				description: "",
				postoId: null,
				clientId: this.cliente.id,
			};
			this.verificaPostoAtivo(this.postos);
		},
		closeCadastro() {
			this.dialogCadastro = false;
			this.$refs.formTurno.resetValidation();
			this.reset();
		},
		selAllSelectSemana() {
			if (this.turno.daysOfWeek.length == this.daysOfWeek.length)
				this.turno.daysOfWeek = [];
			else this.turno.daysOfWeek = this.daysOfWeek.map((dia) => dia.value);
		},
		// validaHoraFinal(horaInicial, horaFinal) { // função auxiliar pois este teste direto no rules não funciona corretamente
		//   if (!horaInicial || !horaFinal) return;
		//   const minutes = (h, m) => (h*60+m);
		//   const secInicial = minutes(...horaInicial.split(":"));
		//   const secFinal = minutes(...horaFinal.split(":"));
		//   return new Date(secFinal) > new Date(secInicial);
		// },
		validaIntervalo(horaInicial, horaFinal, inTurno, fimTurno) {
			if (!horaInicial || !horaFinal) return true;
			if (inTurno < fimTurno) {
				//Se é diurno
				if (horaInicial < inTurno || horaFinal > fimTurno) {
					this.mensagem = "O intervalo deve estar dentro do turno";
					return false;
				} // intervalo deve estar dentro do turno
				else {
					return true;
				}
			} else {
				if (horaInicial < inTurno && horaFinal > fimTurno) {
					this.mensagem = "O intervalo deve estar dentro do turno";
					return false;
				} // intervalo deve estar dentro do turno
				else {
					return true;
				}
			}
		},
		daysToString(days) {
			let daysShort = this.daysOfWeek
				.filter((dia) => days.includes(dia.value))
				.map((dia) => dia.short);
			return daysShort.toString().replaceAll(",", ", ");
		},
		salvarTurno() {
			if (this.$refs.formTurno.validate()) {
				this.turno.clientId = this.cliente.id;
				if(this.turno.startInterval == null || this.turno.endInterval == null){
					this.turno.startInterval = null;
					this.turno.endInterval = null;
				}
				this.turno.groupId = this.$store.getters.getCliente.groupId;
				this.$store.dispatch("saveTurno", this.turno);
				this.dialogCadastro = false;
				this.$refs.formTurno.resetValidation();
				this.reset();
			}
		},
		verificaPostoAtivo(postos) {
			postos.forEach((posto) => {
				if (posto.enabled) {
					this.opPostos.push(posto);
				}
			});
		},
		delTurno(item) {
			this.$refs.confirmDialog.open({
				title: "Excluir Tarefa",
				text: `Deseja excluir o turno <strong>${item.name}</strong>?`,
				confirmCallback: () => {
					this.$store.dispatch("deleteTurno", item);
				},
			});
		},
		mostraHora(temp1, temp2) {
			if (temp1 == "00:00:00" && temp2 == "00:00:00") return "Sem intervalo";
			if (!temp1 || !temp2) return "Sem intervalo";
			let hora1 = temp1.split(":");
			let hora2 = temp2.split(":");
			return `${hora1[0]}:${hora1[1]} - ${hora2[0]}:${hora2[1]}`;
		},
		editTurno(item) {
			this.turno = { ...item };
			if (
				this.turno.startInterval == "00:00:00" &&
				this.turno.endInterval == "00:00:00"
			) {
				this.turno.startInterval = null;
				this.turno.endInterval = null;
			}
			this.dialogCadastro = true;
		},
		testaPreenchimento(v, teste) {
			if (teste == "") return true;
			else return v != "";
		},
	},
	created() {
		this.reset();
		this.tempCliente = this.cliente;
		this.$store.dispatch("loadPostos", this.$store.getters.getCliente.groupId);
		this.$store.dispatch("loadTurnos", this.$store.getters.getCliente.id);
	},
	mounted() {
		this.verificaPostoAtivo(this.postos);
	},
};
</script>

<style scoped>
.btn-dropdown {
	background-color: #fff;
}
</style>

<style>
</style>