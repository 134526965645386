var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Cadastros de Visitantes")]),_c('v-card-text',[_c('DataTable',{attrs:{"headers":_vm.headers,"items":_vm.getVisitantes},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toLocaleString())+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.updatedAt).toLocaleString())+" ")]}},{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-2",attrs:{"size":"70"}},[_c('v-img',{attrs:{"src":item.picture,"lazy-src":"/img/nopic.jpg"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"warning"},on:{"click":function($event){return _vm.editar(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar : "+_vm._s(item.name))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.excluir(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-trash-can")])]}}],null,true)},[_c('span',[_vm._v("Excluir : "+_vm._s(item.name))])])]}}])})],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-10",attrs:{"color":"amber darken-1","dark":"","absolute":"","bottom":"","right":"","fab":"","fixed":""},on:{"click":function($event){_vm.dialogForm = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Adicionar Visitante")])]),_c('Dialog',{attrs:{"item":_vm.item},on:{"close":function($event){_vm.item = {}},"save":_vm.salvar},model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}}),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }