import axios from 'axios'
export default {
    state: {
        visitados: [],
        visitadoFilter: '',
    },
    mutations: {
        setVisitados(state, payload) {
            state.visitados = payload
        },
        setFilterVisitado(state, payload) {
            state.visitadoFilter = payload
        },
    },
    actions: {
        loadVisitados(context, payload) {
            let gp = payload ? `?groupId=${payload}` : ''
            axios(`visitado${gp}`).then((resp) => {
                resp.data.forEach((element) => {
                    element.groupName = context.getters.getGroupNameById(element.groupId)
                });
                context.commit('setVisitados', resp.data)
            });
        },
        saveVisitado(context, visitado) {
            const metodo = visitado.id ? "put" : "post";
            const finalUrl = visitado.id ? `/${visitado.id}` : "";
            axios[metodo](`/visitado${finalUrl}`, visitado).then(() => {
                context.dispatch('loadVisitados')
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Visitado salvo com Sucesso");
            });
        },
        saveVisitado2(context, payload) {
            delete payload.picture;
            let formData = new FormData();
            for (const key in payload) {
                if (Object.hasOwnProperty.call(payload, key)) {
                    const element = payload[key];
                    formData.append(key, element);
                }
            }
            const metodo = payload.id ? "put" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/visitado2${finalUrl}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((resp) => {
                if (resp.status < 299) {
                    if (typeof payload.callback == 'function') {
                        payload.callback();
                    }
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Visitado salvo com Sucesso");
                }
            });
        },
        deleteVisitado(context, visitado) {
            axios.delete(`/visitado/${visitado.id}`).then((resp) => {
                if (resp.status < 299) {
                    context.dispatch('loadVisitados')
                    context.dispatch("resetSnack");
                    context.dispatch(
                        "showSuccessSnack",
                        "Visitado deletado com Sucesso!"
                    );
                }
            });
        },
    },
    getters: {
        getVisitados(state) {
            return state.visitados
        },
        getVisitadosSelect(state) {
            return state.visitados.map((v) => {
                return {
                    value: v.id,
                    text: `${v.id} - ${v.name}`,
                    picture: v.picture,
                };
            });
        },
        getVisitado: (state) => (id) => {
            if (!state.visitados) {
                return id
            }
            let vto = state.visitados.find(g =>
                g.id == id
            )
            return vto ? `${vto.id} - ${vto.name}` : ''
        },
        getHeadersVisitados() {
            return [
                { text: "Id", value: "id" },
                {
                    text: "Imagem",
                    align: "center",
                    filterable: false,
                    value: "picture",
                },
                { text: "Nome", value: "name" },
                { text: "Telefone", value: "phone" },
                { text: "Celular", value: "celPhone" },
                { text: "Local", value: "place" },
                { text: "Posição", value: "hierarchy" },
                { text: "Grupo", value: "groupName" },
                { text: "Posição", value: "hierarchy" },
                { text: "Observação", value: "obs" },
                { text: "Ações", value: "actions", sortable: false },
            ]
        },
        getVisitadosFilter(state) {
            const filterModelStr = (str = "", search = "") => {
                if (typeof str == 'string'){
                    str = str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    search = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    return str.includes(search);
                }
                else return true;
            }
            let cf = state.visitados;
            // filtro por texto
            cf = cf.filter((visitado) => {
                return filterModelStr(visitado.name, state.visitadoFilter) ||
                       filterModelStr(visitado.place, state.visitadoFilter);
            });
            return cf
        },
    }

}