var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.report)?[_c('v-card-title',{staticClass:"pt-6"},[_vm._v("Ocorrencias")]),_c('v-card-subtitle',[_vm._v("Clique na tabela para mais detalhes")])]:_vm._e(),_c('v-card-text',[_c('DataTableGeneric',{attrs:{"headers":_vm.headers,"items":_vm.items},on:{"headersChanged":function($event){return _vm.$emit('headersChanged', $event)},"rowClick":_vm.openModalOcorrencias},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-icon',{attrs:{"color":_vm.prioridade[item.priority - 1].color}},[_vm._v("mdi-alert-circle ")]),_vm._v(" "+_vm._s(_vm.prioridade[item.priority - 1].text)+" ")],1)]}},{key:"item.factDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formataData(item.factDate)))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formataData(item.createdAt)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('TextTooltip',{attrs:{"text":item.description,"maxWidth":'250'}})]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.userId))])]}},{key:"item.clientId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getClienteNameById(item.clientId)))])]}},{key:"item.media",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('strong',[_vm._v("Imagens: ")]),_vm._v(_vm._s(item.pictures ? item.pictures.length : "0")+" | "),_c('strong',[_vm._v("Audios: ")]),_vm._v(" "+_vm._s(item.audios ? item.audios.length : "0")+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.type == 'ronda' ? ("Ronda (Id - " + (item.idType) + ")") : 'Registro'))])]}}])})],1),_c('DialogOcorrencia',{ref:"dialogOcorrencia"}),(!_vm.report)?_c('ConfirmDialog',{ref:"confirmDialog"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }