<template>
	<div>
		<v-card>
			<v-card-title> Relatório de Tarefas </v-card-title>
			<v-card-text>
				<!-- linha do filtro -->
				<v-card outlined>
					<v-card-text class="pb-0">
						<p class="text-subtitle-1 pt-1 pb-3">Filtros</p>
						<v-form ref="formReport">
							<v-row>
								<v-col class="py-0">
									<v-text-field
										v-model="relatorio.start"
										label="Data Inicial"
										required
										outlined
										type="datetime-local"
										@input="$refs.relatorioEnd.validate()"
									></v-text-field>
								</v-col>
								<v-col class="py-0">
									<v-text-field
										ref="relatorioEnd"
										v-model="relatorio.end"
										label="Data Final"
										required
										outlined
										type="datetime-local"
										:rules="[
											(v) =>
												validaDataFinal(v) ||
												'A data final deve ser maior que a inicial',
										]"
									></v-text-field>
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-card-actions class="px-4">
						<MenuExportReport
							:disabled="!getRelTarefas.length"
							:items="geraitensRel(getRelTarefas)"
							module="tarefas"
							title="Relatório de Tarefas"
							filename="relatorio_de_tarefas"
							:optionImg="true"
						/>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="loadReport">
							<v-icon class="mr-1">mdi-magnify</v-icon> Buscar Relatório
						</v-btn>
					</v-card-actions>
				</v-card>
				<!-- linha da tabela -->
			</v-card-text>
			<TableTarefas
				:headers="headers"
				:items="getRelTarefas"
				:report="true"
			/>
		</v-card>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuExportReport from "@/components/Relatorios/MenuExportReport.vue";
import TableTarefas from "./TableTarefas.vue";

export default {
	name: "RelatorioTarefas",
	components: {
		MenuExportReport,
		TableTarefas,
	},
	computed: {
		...mapGetters([
			"getRelTarefas",
			"getOcorrencias",
			"getUserNameById",
			"getClienteNameById",
			"getTarefas",
			"getAmbienteNameById",
		]),
	},
	data() {
		return {
			ocorrencia: {},
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Tarefa", value: "tarefaId" },
				{ text: "Ambiente", value: "payload.ambientId"},
				{ text: "Data", value: "createdAt"},
				{ text: "Inicio", value: "payload.horario.start" },
				{ text: "Fim", value: "payload.horario.end" },
				{ text: "Usuário", value: "userId" },
				{ text: "Dispositivo", value: "deviceId" },
				{ text: "Cliente", value: "clientId" },
				{ text: "Finalizada", value: "finished" },
				{ text: "Atividades", value: "payload.activities" },
			],
			relatorio: {},
		};
	},
	methods: {
		loadReport() {
			if (this.$refs.formReport.validate()) {
				this.relatorio.clientId = this.$store.getters.getCliente.id;
				this.$store.dispatch("loadReportTarefas", this.relatorio);
			}
		},
		verificaNomeTarefa(id) {
			let tarefa = this.getTarefas.find((tarefa) => tarefa.id === id);
			if (tarefa) {
				return tarefa.name;
			}
			return "Tarefa não encontrada";
		},
		verificaNomeDispositivo(id) {
			let dispositivo = this.dispositivos.find(
				(dispositivo) => dispositivo.id === id
			);
			if (dispositivo) {
				return dispositivo.name;
			}
			return "Dispositivo não encontrado";
		},
		getDia(data) {
			if (data) {
				let dataFormatada = new Date(data);
				return dataFormatada.toLocaleDateString();
			}
		},
		geraitensRel(itens) {
			let itensRel = [];
			if (itens) {
				itens.forEach((item) => {
					let itemRel = { ...item };
					itemRel.deviceId = this.verificaNomeDispositivo(item.deviceId);
					itemRel.userId = this.getUserNameById(item.userId).split(" - ")[1];
					itemRel.clientId = this.getClienteNameById(item.clientId).split(
						" - "
					)[1];
					itemRel.tarefaId = this.verificaNomeTarefa(item.tarefaId);
					itemRel.ambientId = this.getAmbienteNameById(item.payload.ambientId);
					itemRel.createdAt = this.getDia(item.createdAt);
					itensRel.push({...itemRel});
				});
			}
			return itensRel;
		},
		validaDataFinal(dataFinal) {
			// função auxiliar pois este teste direto no rules não funciona corretamente
			return new Date(dataFinal) > new Date(this.relatorio.start);
		},
	},
	created() {
		this.$store.dispatch("setRelTarefas", []);
		this.$store.dispatch("loadTarefas", this.$store.getters.getCliente.clientId);
		this.$store.dispatch(
			"loadClientes",
			this.$store.getters.getCliente.groupId
		);
		this.$store.dispatch("loadAmbientes", this.$store.getters.getCliente.clientId || null);
		let complemento = this.$store.getters.getCliente.groupId
			? `?groupId=${this.$store.getters.getCliente.groupId}`
			: "";
		this.$http(
			`dispositivo${complemento}`
		).then((resp) => {
			this.dispositivos = resp.data.filter((item) => item.enabled);
		});
		// pega a data atual e seta no relatorio
		let now = new Date();
		let onlyDate = now.toISOString().substring(0, 11);
		this.relatorio.start = onlyDate + "00:00";
		this.relatorio.end = onlyDate + "23:59";
	},
};
</script>

<style>
</style>