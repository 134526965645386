import axios from 'axios'
export default {
    state: {
        usuarios: [],
    },
    mutations: {
        setUsers(state, payload) {
            state.usuarios = payload
        },
    },
    actions: {
        setUsers(context, payload) {
            context.commit('setUsers', payload)
        },
        loadUsers(context, payload) {
            let gp = payload ? `?groupId=${payload}` : ''
            axios(`usuario${gp}`).then((resp) => {
                resp.data.forEach((ele) => {
                    ele.ativo = ele.enabled ? "SIM" : "NÃO";
                });
                context.dispatch('setUsers', resp.data)
            });
        },
        saveUser(context, user) {
            const metodo = user.id ? "put" : "post";
            if (user.id) {
                if (user.password == "") {
                    delete user.password;
                }
            }
            const finalUrl = user.id ? `/${user.id}` : "";
            axios[metodo](`/usuario${finalUrl}`, user).then((resp) => {
                if(resp.status < 300) {
                context.dispatch("resetSnack");
                context.dispatch("showSuccessSnack", "Usuário salvo com Sucesso");
                context.dispatch('loadUsers')
                if(typeof user.cb == 'function') {
                    user.cb()
                }
            }
            }).catch(() => {
                context.dispatch("resetSnack");
                context.dispatch("showErrorSnack", "Usuário já existe");
            });
        },
        deleteUser(context, user) {
            axios.delete(`/usuario/${user.id}`).then(() => {
                context.dispatch("resetSnack");
                context.dispatch(
                    "showSuccessSnack",
                    "Usuário deletado com Sucesso!"
                );
                context.dispatch('loadUsers')
            });
        },
    },
    getters: {
        getUsers(state) {
            return state.usuarios
        },
        getUserNameById: (state) => (id) => {
            if (!state.usuarios) {
                return id
            }
            let gp = state.usuarios.find(g =>
                g.id == id
            )
            return gp ? `${gp.id} - ${gp.name}` : id + ''
        },
        getTableHeader() {
            return [
                { text: "ID", value: "id" },
                {
                    text: "Imagem",
                    align: "center",
                    filterable: false,
                    value: "picture",
                },
                { text: "Nome", value: "name" },
                { text: "Usuário", value: "userName" },
                { text: "Descrição", value: "description" },
                { text: "Tipo", value: "acessoName" },
                { text: "Grupo", value: "groupName" },
                { text: "Ativo", value: "ativo" },
                { text: "Ações", value: "actions", sortable: false },
            ];
        },
        getUserSelect(state) {
            let sl = []
            state.usuarios.forEach(e =>{
                sl.push({value: e.id, text: `${e.id} - ${e.name}`})
            })
            return sl
        },
        getUserById: (state) => (id) => {
            if (!state.usuarios) {
                return id
            }
            let gp = state.usuarios.find(g =>
                g.id == id
            )
            return gp;
        }
    }
}