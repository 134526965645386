<template>
	<div>
		<v-dialog
			v-model="dialog"
			max-width="800"
			@click:outside="closeTarefa"
			scrollable
		>
			<v-card>
				<v-card-title class="text-h5 pb-4">Detalhes da Tarefa</v-card-title>
				<v-card-text class="py-0">
					<v-card outlined>
						<v-card-title class="py-2 intermed-title"
							>Tarefa: {{ verificaNomeTarefa(tarefa.tarefaId) }}</v-card-title
						>
						<v-card-text>
							<v-row class="mb-3">
								<v-col class="py-2">
									<span
										><strong>Cliente:</strong>
										{{
											tarefa.clientId
												? getClienteNameById(tarefa.clientId).split(" - ")[1]
												: ""
										}}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Ambiente:</strong>
										{{ getAmbienteNameById(tarefa.payload.ambientId) }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Dispositivo:</strong>
										{{ verificaNomeDispositivo(tarefa.deviceId) }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Funcionário:</strong>
										{{
											tarefa.userId
												? getUserNameById(tarefa.userId).split(" - ")[1]
												: ""
										}}</span
									>
								</v-col>
							</v-row>
							<v-row class="mb-3">
								<v-col class="py-2">
									<span
										><strong>Data:</strong> {{ getDia(tarefa.createdAt) }}</span
									>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Inicio:</strong>
										{{
											tarefa.payload.horario ? tarefa.payload.horario.start : "Horário não informado"
										}}
									</span>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Fim: </strong>
										{{
											tarefa.payload.horario ? tarefa.payload.horario.end : "Horário não informado"
										}}
									</span>
								</v-col>
								<v-col class="py-2">
									<span
										><strong>Finalizada: </strong>
										{{ tarefa.finished ? "Sim" : "Não" }}
									</span>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-card-title class="py-2 mb-2 intermed-title"
								>Imagens</v-card-title
							>
							<v-row>
								<v-col class="py-4 mb-6" cols="6">
									<v-row>
										<v-col class="text-center py-0"><span>Inicio:</span></v-col>
									</v-row>
									<v-row>
										<v-carousel height="200" show-arrows-on-hover>
											<v-carousel-item
												v-for="(img, idx) in tarefa.picturesStart"
												:key="idx"
												:src="img"
											>
											</v-carousel-item>
										</v-carousel>
									</v-row>
								</v-col>
								<v-col class="py-4 mb-6" cols="6">
									<v-row>
										<v-col class="text-center py-0"><span>Fim:</span></v-col>
									</v-row>
									<v-row>
										<v-carousel height="200" show-arrows-on-hover>
											<v-carousel-item
												v-for="(img, idx) in tarefa.picturesEnd"
												:key="idx"
												:src="img"
											></v-carousel-item>
										</v-carousel>
									</v-row>
								</v-col>
							</v-row>
							<v-divider></v-divider>
							<v-card-title class="py-2 mb-2 intermed-title"
								>Atividades concluídas</v-card-title
							>
							<v-row v-show="tarefa.payload.activities.length == 0">
								<v-col cols="12" class="py-3 text-center">
									<span>Nenhuma atividade concluída</span>
								</v-col>
							</v-row>
							<v-row>
								<v-col
									cols="12"
									md="6"
									lg="4"
									v-for="(item, index) in tarefa.payload.activities"
									:key="index"
								>
									<v-card outlined>
										<v-card-title class="py-2">
											{{ item.name }}
										</v-card-title>
										<v-card-text>
											<v-row>
												<v-col cols="12">
													<span
														><strong>concluída em:</strong>
														{{
															item.checkedAt ? formataData(item.checkedAt) : ""
														}}</span
													>
												</v-col>
												<v-col cols="12">
													<span
														><strong>Intervalo entre tarefas:</strong>
														{{
															item.checkedAt ? calculaIntervalos(index) : ""
														}}</span
													>
												</v-col>
											</v-row>
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
							<v-divider></v-divider>
						</v-card-text>
					</v-card>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="gerarRelatorioSolo(tarefa)">
						Exportar Relatório
					</v-btn>
					<v-btn color="success" text @click="closeTarefa">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- Modal Imagem Grande -->
		<v-dialog v-model="dialogImg" max-width="800px" scrollable>
			<v-card>
				<v-card-title>
					<v-icon class="mr-1">mdi-image</v-icon>
					Imagem: {{ tarefa.name }}
				</v-card-title>

				<v-card-text>
					<v-img :src="image" contain height="500" />
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="downloadImg(image)">
						<v-icon class="mr-1">mdi-download</v-icon> Baixar
					</v-btn>
					<v-btn color="success" text @click="dialogImg = false">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import PDF from "@/Utils/PDF";
import File from "@/Utils/File";
import { mapGetters } from "vuex";
import { differenceDate } from "@/Utils/DateDiff";

export default {
	name: "DialogTarefa",
	components: {},
	computed: {
		...mapGetters([
			"getUserNameById",
			"getClienteNameById",
			"getTarefas",
			"getAmbientes",
			"getAmbienteNameById"
		]),
	},
	data: () => ({
		dialog: false,
		imgs: [],
		img: "",
		dialogImg: false,
		dispositivos: [],
		tarefa: {
			payload: {
				activities: [],
				horario: {
					start: "",
					end: "",
				},
				ambientId: "",
			},
		},
		ultimoHorario: "",
		image: "",
	}),
	methods: {
		verificaNomeTarefa(id) {
			let tarefa = this.getTarefas.find((tarefa) => tarefa.id === id);
			if (tarefa) {
				return tarefa.name;
			}
			return "Tarefa não encontrada";
		},
		verificaNomeDispositivo(id) {
			let dispositivo = this.dispositivos.find(
				(dispositivo) => dispositivo.id === id
			);
			if (dispositivo) {
				return dispositivo.name;
			}
			return "Dispositivo não encontrado";
		},
		openTarefa(tarefa) {
			this.tarefa = { ...tarefa };
			if(this.tarefa.payload.horario == null){
				this.tarefa.payload.horario = {
					start: "Não informado",
					end: "Não informado",
				}
			}
			if (tarefa.payload.activities.length > 0) {
				this.ultimoHorario = tarefa.payload.activities[0].checkedAt;
			}
			this.dialog = true;
		},
		closeTarefa() {
			this.dialog = false;
		},
		openImg(img) {
			this.image = img;
			this.dialogImg = true;
		},
		calculaIntervalos(idx) {
			let horario = this.tarefa.payload.activities[idx].checkedAt;
			if (idx === 0) {
				return `0m`;
			} else {
				let dif = differenceDate(
					this.tarefa.payload.activities[idx - 1].checkedAt,
					horario
				);
				return `${Math.round(dif.totalMinutes)}m`;
			}
		},
		downloadImg(img) {
			let imag = new Image();
			imag.onload = function () {
				let canvas = document.createElement("canvas");
				let context = canvas.getContext("2d");
				canvas.width = this.width;
				canvas.height = this.height;
				context.drawImage(this, 0, 0);
				let imageData = canvas.toDataURL();
				let blob = File.dataURItoBlob(imageData);
				File.downloadFile(blob, "ImageName.png");
			};
			imag.crossOrigin = "anonymous";
			imag.src = img;
		},
		formataData(data) {
			if (data) {
				let dataFormatada = new Date(data);
				return dataFormatada.toLocaleString();
			}
		},
		getDia(data) {
			if (data) {
				let dataFormatada = new Date(data);
				return dataFormatada.toLocaleDateString();
			}
		},
		gerarRelatorioSolo(item) {
			// this.$store.dispatch("setTitleDialog", "Gerando o Relátorio");
			// this.$store.dispatch("setShowDialog", true);
			let tarefa = { ...item };
			let itens = [];
			tarefa.clientId = this.getClienteNameById(tarefa.clientId).split(
				" - "
			)[1];
			tarefa.deviceId = this.verificaNomeDispositivo(tarefa.deviceId);
			tarefa.userId = this.getUserNameById(tarefa.userId).split(" - ")[1];
			tarefa.createdAt = this.getDia(tarefa.createdAt);
			for (let i = 0; i < tarefa.payload.activities.length; i++) {
				tarefa.payload.activities[i].interval = this.calculaIntervalos(i);
			}
			tarefa.payload.ambientId = this.getAmbienteNameById(tarefa.payload.ambientId);
			itens.push(tarefa);
			PDF["tarefaSolo"](
				itens,
				"Relatório de Tarefa",
				"relatorio_de_tarefa.pdf"
			);
		},
	},
	created() {
		this.$store.dispatch(
			"loadTarefas",
			this.$store.getters.getCliente.clientId
		);
		this.$store.dispatch(
			"loadClientes",
			this.$store.getters.getCliente.groupId
		);
		this.$store.dispatch(
			"loadAmbientes",
			this.$store.getters.getCliente.clientId
		);
		// this.$http(
		// 	`dispositivo?groupId=${this.$store.getters.getCliente.groupId}`
		// ).then((resp) => {
		// 	this.dispositivos = resp.data.filter((item) => item.enabled);
		// });
	},
};
</script>

<style></style>