<template>
	<div>
		<template v-if="!report">
			<v-card-title class="pt-6">Ocorrencias</v-card-title>
			<v-card-subtitle>Clique na tabela para mais detalhes</v-card-subtitle>
		</template>
		<v-card-text>
			<DataTableGeneric
				:headers="headers"
				:items="items"
				@headersChanged="$emit('headersChanged', $event)"
				@rowClick="openModalOcorrencias"
			>
				<template v-slot:item.name="{ item }">
					<span>{{ item.name }}</span>
				</template>

				<template v-slot:item.priority="{ item }">
					<span>
						<v-icon :color="prioridade[item.priority - 1].color"
							>mdi-alert-circle
						</v-icon>
						{{ prioridade[item.priority - 1].text }}
					</span>
				</template>

				<template v-slot:item.factDate="{ item }">
					<span>{{ formataData(item.factDate) }}</span>
				</template>

				<template v-slot:item.createdAt="{ item }">
					<span>{{ formataData(item.createdAt) }}</span>
				</template>

				<template v-slot:item.description="{ item }">
					<TextTooltip :text="item.description" :maxWidth="'250'" />
				</template>

				<template v-slot:item.userId="{ item }">
					<span>{{ item.userId }}</span>
				</template>

				<template v-slot:item.clientId="{ item }">
					<span>{{ getClienteNameById(item.clientId) }}</span>
				</template>

				<template v-slot:item.media="{ item }">
					<span>
						<strong>Imagens: </strong>{{ item.pictures ? item.pictures.length : "0" }} |
						<strong>Audios: </strong> {{ item.audios ? item.audios.length : "0" }}
					</span>
				</template>

				<template v-slot:item.type="{ item }">
					<span>{{ item.type == 'ronda' ? `Ronda (Id - ${item.idType})` : 'Registro' }}</span>
				</template>

				<!-- <template slot="item.actions" slot-scope="{ item }">
					<TooltipIcon
						:text="`Exportar Relatório: ${item.name}`"
						icon="mdi-file-document"
						@click="exportReport(item)"
						top="true"
					/>
				</template> -->
			</DataTableGeneric>
		</v-card-text>
		<DialogOcorrencia ref="dialogOcorrencia"/>
		<ConfirmDialog v-if="!report" ref="confirmDialog" />
	</div>
</template>

<script>
import DataTableGeneric from "../DataTableGeneric";
import ConfirmDialog from "../ConfirmDialog";
import TextTooltip from "../Template/TextTooltip.vue";
import DialogOcorrencia from "../Ocorrencias/DialogOcorrencia.vue";
import File from "@/Utils/File";
import { mapGetters } from "vuex";

export default {
	name: "TableDialog",
	computed: {
		...mapGetters(["getClienteNameById", "getUserNameById"]),
	},
	components: {
		DataTableGeneric,
		ConfirmDialog,
		TextTooltip,
		DialogOcorrencia,
	},
	data: () => {
		return {
			position: 0,
			imgs: [],
			img: "",
			dialogImg: false,
			carouselColumns: 3,
			carouselPosition: 0,
			dialogOcorrencia: false,
			search: "",
			selected: [],
			ocorrencia: {
				id: null,
				name: "",
				description: "",
				priority: 0,
				userId: null,
				communicant: null,
				factDate: null,
				clientId: null,
				groupId: null,
				pictures: [],
			},
			prioridade: [
				{ text: "Alta", value: 1, color: "red" },
				{ text: "Média", value: 2, color: "orange" },
				{ text: "Baixa", value: 3, color: "green" },
				{ text: "Somente Registro", value: 4, color: "blue" },
			],
		};
	},
	props: {
		items: {
			type: Array,
		},
		headers: {
			type: Array,
		},
		report: {
			type: Boolean,
			default: false,
		},
		actions: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		formataData(dataUTC) {
			return new Date(dataUTC).toLocaleString();
		},
		openModalOcorrencias(item) {
			// this.ocorrencia = item;
			// this.dialogOcorrencia = true;
			this.$refs.dialogOcorrencia.openOcorrencia(item);
		},
		openImg(position) {
			this.position = position;
			this.dialogImg = true;
		},
		downloadImg(img) {
			let imag = new Image();
			imag.onload = function () {
				let canvas = document.createElement("canvas");
				let context = canvas.getContext("2d");
				canvas.width = this.width;
				canvas.height = this.height;
				context.drawImage(this, 0, 0);
				let imageData = canvas.toDataURL();
				let blob = File.dataURItoBlob(imageData);
				File.downloadFile(blob, "ImageName.png");
			};
			imag.crossOrigin = "anonymous";
			imag.src = img;
		},
	},
	created() {
		this.$store.dispatch(
			"loadClientes",
			this.$store.getters.getCliente.groupId
		);
	},
	watch: {},
};
</script>

<style scoped>
.intermed-title {
	font-size: 1.2rem;
	font-weight: bold;
}
</style>