<template>
  <div>
    <v-card>
      <v-card-title> Relatório de Rondas </v-card-title>
      <v-card-text>
        <!-- linha do filtro -->
        <v-card outlined>
          <v-card-text class="pb-0">
            <p class="text-subtitle-1 pt-1 pb-3">Filtros</p>
            <v-form ref="formReport">
              <v-row>
                <v-col class="py-0">
                  <v-text-field v-model="relatorio.start" label="Data Inicial" required outlined type="datetime-local"
                    @input="$refs.relatorioEnd.validate()"></v-text-field>
                </v-col>
                <v-col class="py-0">
                  <v-text-field ref="relatorioEnd" v-model="relatorio.end" label="Data Final" required outlined
                    type="datetime-local" :rules="[
                    (v) =>
                      validaDataFinal(v) ||
                      'A data final deve ser maior que a inicial',
                  ]"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-autocomplete v-model="relatorio.rondaId" label="Ronda" :items="getRondas" item-text="name"
                    item-value="id" outlined clearable></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete v-model="relatorio.checkpointId" label="Ponto de Ronda" :items="getCheckpoints"
                    item-text="name" item-value="id" outlined clearable></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete v-model="relatorio.finished" label="Finalizada" :items="[
                    { text: 'Finalizada', value: true },
                    { text: 'Não Finalizada', value: false },
                  ]" outlined clearable persistent-hint></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-4">
            <MenuExportReport :disabled="!rondasRel.length" :items="rondasRel" module="rondas"
              title="Relatório de Rondas" filename="relatorio_de_rondas" :optionImg="true" />
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="loadReport">
              <v-icon class="mr-1">mdi-magnify</v-icon> Buscar Relatório
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- linha da tabela -->
      </v-card-text>
      <TableRondas :headers="headers" :items="rondas" :report="true" />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuExportReport from "@/components/Relatorios/MenuExportReport.vue";
import TableRondas from "./TableRondas.vue";

export default {
  name: "RelatorioRondas",
  components: {
    MenuExportReport,
    TableRondas,
  },
  computed: {
    ...mapGetters([
      "getRelRondas",
      "getOcorrencias",
      "getUserNameById",
      "getClienteNameById",
      "getRondas",
      "getCheckpoints",
    ]),
  },
  data() {
    return {
      ocorrencia: {},
      rondas: [],
      rondasRel: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Ronda", value: "rondaId" },
        { text: "Data", value: "createdAt" },
        { text: "Inicio", value: "payload.horario.start" },
        { text: "Fim", value: "payload.horario.end" },
        { text: "Usuário", value: "userName" },
        { text: "Dispositivo", value: "deviceName" },
        { text: "Finalizada", value: "finished" },
        { text: "Sinc", value: "syncAt" },
        { text: "Pontos de ronda registrados", value: "payload.checkpoints" },
      ],
      relatorio: {},
    };
  },
  methods: {
    loadReport() {
      if (this.$refs.formReport.validate()) {
        this.relatorio.clientId = this.$store.getters.getCliente.id;
        this.relatorio.callback = (itens) => {
          itens.forEach(element => {
            element.userName = this.getUserNameById(element.userId).split(" - ")[1];
            element.deviceName = this.verificaNomeDispositivo(element.deviceId);
            element.clientName = this.getClienteNameById(element.clientId).split(" - ")[1];
          });
          this.geraItensTable(itens);
          this.geraitensRel(itens);
        };
        this.$store.dispatch("loadReportRonda", this.relatorio);
      }
    },
    verificaNomeRonda(id) {
      let ronda = this.getRondas.find((ronda) => ronda.id === id);
      if (ronda) {
        return ronda.name;
      }
      return "Ronda não encontrada";
    },
    verificaNomeDispositivo(id) {
      let dispositivo = this.dispositivos.find(
        (dispositivo) => dispositivo.id == id
      );
      if (dispositivo) {
        return dispositivo.name;
      }
      return "Dispositivo não encontrado";
    },
    getDia(data) {
      if (data) {
        let dataFormatada = new Date(data);
        return dataFormatada.toLocaleDateString();
      }
    },
    geraItensTable(itens) {
      let itensTable = [];
      if (itens) {
        itens.forEach((item) => {
          let itemTable = { ...item };
          if (this.relatorio.checkpointId) {
            this.verificaPontos(
              itemTable.payload.checkpoints,
              this.relatorio.checkpointId
            )
              ? itensTable.push(
                this.addPontoUnico(itemTable, this.relatorio.checkpointId)
              )
              : false;
          } else itensTable.push(itemTable);
        });
      }
      this.rondas = itensTable;
      if (itensTable.length == 0) {
        this.$store.dispatch("resetSnack");
        this.$store.dispatch("showErrorSnack", "Nenhum registro encontrado de acordo com o filtro");
      }
    },
    addPontoUnico(item, id) {
      let ponto = item.payload.checkpoints.find((ponto) => ponto.id == id);
      item.payload.checkpoints = [ponto];
      return item;
    },
    verificaPontos(pontos, pontoId) {
      let ponto = pontos.find((ponto) => ponto.id == pontoId);
      return ponto ? true : false;
    },
    geraitensRel(itens) {
      let itensRel = [];
      if (itens) {
        itens.forEach((item) => {
          let itemRel = { ...item };
          itemRel.name = this.verificaNomeRonda(item.rondaId);
          // itemRel.deviceId = this.verificaNomeDispositivo(item.deviceId);
          // itemRel.userId = this.getUserNameById(item.userId).split(" - ")[1];
          // itemRel.clientId = this.getClienteNameById(item.clientId).split(
          //   " - "
          // )[1];
          itemRel.createdAt = this.getDia(item.createdAt);
          if (this.relatorio.checkpointId) {
            this.verificaPontos(
              itemRel.payload.checkpoints,
              this.relatorio.checkpointId
            )
              ? itensRel.push(
                this.addPontoUnico(itemRel, this.relatorio.checkpointId)
              )
              : false;
          } else itensRel.push(itemRel);
        });
      }
      this.rondasRel = itensRel;
    },
    validaDataFinal(dataFinal) {
      // função auxiliar pois este teste direto no rules não funciona corretamente
      return new Date(dataFinal) > new Date(this.relatorio.start);
    },
  },
  created() {
    this.$store.dispatch("setRelRondas", []);
    this.$store.dispatch("loadRondas", this.$store.getters.getCliente.id || null);
    // this.$store.dispatch(
    //   "loadClientes",
    //   this.$store.getters.getCliente.groupId
    // );
    this.$store.dispatch("loadCheckpoints", this.$store.getters.getCliente.id || null);
    this.$http(
      `dispositivo`
    ).then((resp) => {
      this.dispositivos = resp.data;
      // this.dispositivos = resp.data.filter((item) => item.enabled);
    });
    // pega a data atual e seta no relatorio
    let now = new Date();
    let onlyDate = now.toISOString().substring(0, 11);
    this.relatorio.start = onlyDate + "00:00";
    this.relatorio.end = onlyDate + "23:59";
  },
};
</script>

<style></style>